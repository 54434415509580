import { put, select } from 'redux-saga/effects';
import { getUserSubscriptionsSuccess, getUserSubscriptionsFailure } from 'redux/actions';
import { getUserSubscriptionsApiRequest } from 'api/balloon';
import { hasSubscription } from 'redux/query';

export function* getUserSubscriptionsSaga() {
  try {
    const state = yield select();
    const hasUserSubscription = hasSubscription(state);

    if (!hasUserSubscription) {
      yield put(getUserSubscriptionsSuccess({ subscriptions: [] }));
      return;
    }

    const { data: response } = yield getUserSubscriptionsApiRequest();

    if (response.errors) {
      yield put(getUserSubscriptionsFailure(response.errors));
      return;
    }
    yield put(getUserSubscriptionsSuccess({ subscriptions: response.data.me.subscriptions }));
  } catch (error) {
    yield put(getUserSubscriptionsFailure(error));
  }
}
