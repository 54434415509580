import React, { useMemo } from 'react';
import { Col } from 'Common';
import bubble1 from 'images/gift/1.svg';
import bubble2 from 'images/gift/2.svg';
import bubble3 from 'images/gift/3.svg';
import { TEXT } from 'locales/translations';

export const GiftFaq = ({ number }) => {
  const bubbleImg = useMemo(() => {
    switch (number) {
      case 1:
        return bubble1;
      case 2:
        return bubble2;
      case 3:
        return bubble3;
      default:
        return bubble1;
    }
  }, [number]);

  return (
    <Col xs={{ size: '12' }} sm={{ size: '12' }} md={{ size: '3' }} lg={{ size: '3' }} className="d-flex flex-column align-items-center">
      <img className="buy-gift__wrapper-works-bubble-img" src={bubbleImg} alt={`FAQ Bubble ${number}`} />
      <p className="buy-gift__wrapper-works-bubble-headline">{TEXT.gift.works[`bubble_${number}`].headline}</p>
      <p className="buy-gift__wrapper-works-bubble-text">{TEXT.gift.works[`bubble_${number}`].subline}</p>
    </Col>
  );
};
