import React, { useEffect } from 'react';
import { Button, Form, Headline, Input, Paragraph, Link } from 'Common';
import { ImplicitTermsAcceptance } from 'Component';
import Style from './RegistrationForm.style';
import { GoogleLoginButton } from 'components';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { registerUserRequest } from 'redux/actions';
import LoginRegisterSocialErrors from '../../LoginRegisterSocialErrors';
import { isRegisterError } from 'redux/query';

export const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });
  const hasRegisterError = useSelector((state) => isRegisterError(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRegisterError) {
      setError('email', {
        type: 'manual',
        message: 'Diese E-Mail-Adresse kann nicht verwendet werden, bitte überprüfe deine Eingabe.'
      });
    }
  }, [hasRegisterError, setError]);

  const onSubmit = (user) => dispatch(registerUserRequest(user));

  return (
    <Style>
      <Headline level={2} className="text-center">
        {TEXT.registration.title}
      </Headline>
      <Paragraph className="extra-small text-center">{TEXT.registration.subtitle}</Paragraph>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('firstname', {
            required: 'Bitte gib deinen Namen ein.'
          })}
          dataCyName="register-firstname-input"
          placeholder="Name"
          feedbackMessage={errors.firstname?.message}
          valid={!!errors.firstname ? false : undefined}
        />
        <Input
          {...register('email', {
            required: 'Bitte gib deine E-Mail ein.',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Keine valide E-Mail-Adresse.'
            },
            onChange: () => {
              if (errors.email) {
                // needed to remove hasRegisterError message
                clearErrors('email');
              }
            }
          })}
          dataCyName="register-mail-input"
          placeholder="E-Mail"
          type="email"
          feedbackMessage={errors.email?.message}
          valid={!!errors.email ? false : undefined}
        />
        <ImplicitTermsAcceptance />
        <Button type="submit" dataCyName="submit-register-button">
          {TEXT.registration.registerButton}
        </Button>
      </Form>
      <GoogleLoginButton textButton={TEXT.registration.headline_google} />
      <LoginRegisterSocialErrors isRegister={true} />
      <Paragraph className="small text-center">
        {TEXT.registration.hint}
        <Link className="bold" to={ROUTES.login} dataCyName="to-login-page-button">
          {TEXT.registration.loginHint}
        </Link>
      </Paragraph>
    </Style>
  );
};
