import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'components/Theme';

BooleanPresenter.propTypes = {
  value: PropTypes.bool.isRequired
};

export default function BooleanPresenter({ value }) {
  return (
    <span style={{ color: value ? colors.green.c500 : colors.pink.c500 }} className="payment-state">
      {value ? 'bezahlt' : 'unbezahlt'}
    </span>
  );
}
