import React from 'react';
import { TEXT } from 'locales/translations';
import { DecorativeImage } from 'components';
import { ROUTES } from 'utils/constants';
import { Col, Container, Button, Row } from 'Common';
import { ThemeContainer } from 'Container';
import { themeCalm } from 'Theme';

import Ballonie1Desktop from 'images/landingpages/health_insurance_marketing/desktop-step-1.png';
import Ballonie2Desktop from 'images/landingpages/health_insurance_marketing/desktop-step-2.png';
import Ballonie3Desktop from 'images/landingpages/health_insurance_marketing/desktop-step-3.png';
import Ballonie4Desktop from 'images/landingpages/health_insurance_marketing/desktop-step-4.png';

export const BalloonieSection = () => {
  return (
    <ThemeContainer theme={themeCalm}>
      <section className="balloonie-marketing">
        <Container>
          <Row>
            <Col>
              <h2 className="poppins-light">{TEXT.healthMarketingPage.balloonieSection.headline_1}</h2>
              <h2>{TEXT.healthMarketingPage.balloonieSection.headline_2}</h2>
            </Col>
          </Row>
          <Row className="balloonie-marketing__balloonie-row">
            <Col xs={6} lg={3} className="balloonie-marketing__img-container">
              <DecorativeImage
                src={Ballonie1Desktop}
                alt={TEXT.healthMarketingPage.balloonieSection.altText_1}
                className="balloonie-marketing__img"
              />
              <div className="balloonie-marketing__text-container">
                <h4>{TEXT.healthMarketingPage.balloonieSection.balloonie_1}</h4>
              </div>
            </Col>
            <Col xs={6} lg={3} className="balloonie-marketing__img-container">
              <DecorativeImage
                src={Ballonie2Desktop}
                alt={TEXT.healthMarketingPage.balloonieSection.altText_2}
                className="balloonie-marketing__img"
              />
              <div className="balloonie-marketing__text-container">
                <h4>{TEXT.healthMarketingPage.balloonieSection.balloonie_2}</h4>
              </div>
            </Col>
            <Col xs={6} lg={3} className="balloonie-marketing__img-container">
              <DecorativeImage
                src={Ballonie3Desktop}
                alt={TEXT.healthMarketingPage.balloonieSection.altText_3}
                className="balloonie-marketing__img"
              />
              <div className="balloonie-marketing__text-container">
                <h4>{TEXT.healthMarketingPage.balloonieSection.balloonie_3}</h4>
              </div>
            </Col>
            <Col xs={6} lg={3} className="balloonie-marketing__img-container">
              <DecorativeImage
                src={Ballonie4Desktop}
                alt={TEXT.healthMarketingPage.balloonieSection.altText_4}
                className="balloonie-marketing__img"
              />
              <div className="balloonie-marketing__text-container">
                <h4>{TEXT.healthMarketingPage.balloonieSection.balloonie_4}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button to={ROUTES.krankenkasse + '#section-checkout'}>{TEXT.healthMarketingPage.balloonieSection.ctaBtn}</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </ThemeContainer>
  );
};

export default BalloonieSection;
