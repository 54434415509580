/* eslint-disable array-callback-return */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import AppAdTile from './AppAdTile';
import CouponAdTile from './CouponAdTile';
import CategoryList from './CategoryList';
import ListLayoutBlock from './ListLayoutBlock';
import ListItem from './ListItem';
import { TEXT } from 'locales/translations';
import { usePageTitle } from '../../containers/PageTitleContainer/PageTitleContainer';

const ContentByCategory = ({ categories, hasSubscription, category }) => {
  let hasCoursesOrSingles = false;
  const currentCategory = category[0];
  if (category.length > 0 && (currentCategory.courses.length > 0 || currentCategory.singles.length > 0)) {
    hasCoursesOrSingles = true;
  }

  usePageTitle(`${currentCategory?.title} | Bibliothek`);

  return (
    <main className="main-decorated library">
      <Container>
        <Row className="flex-column flex-lg-row">
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 3, offset: 0 }} className="fixed-top library-nav-col">
            <CategoryList categories={categories} className="library-nav" />
          </Col>

          <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 9, offset: 0 }}>
            <div className="content-by-category">
              {hasCoursesOrSingles && (
                <>
                  <div className="content-by-category__header">
                    <div
                      className="content-by-category__img-wrapper"
                      style={{
                        backgroundImage: `url(${currentCategory.picture})`
                      }}
                    />
                    <div className="content-by-category__text-wrapper">
                      <h4 className="content-by-category__title">{currentCategory.title}</h4>
                      <p className="content-by-category__description">{currentCategory.description}</p>
                    </div>
                  </div>
                  <ListLayoutBlock key={category.id}>
                    {currentCategory.courses.concat(currentCategory.singles).map((item, ix) => {

                      return (
                        <ListItem
                          key={ix}
                          newBadge={item.isNew ? 'new-badge' : 'no-badge'}
                          specialBadge={item.isSpecial ? 'special-badge' : 'no-badge'}
                          title={item.title}
                          time={Math.round(item.length / 60) + ' Min'}
                          type={item._Typename.toLowerCase()}
                          unitsCount={item._Typename === 'Course' ? `${item.unitsCount} ${TEXT.library.units}` : ''}
                          color={item.backgroundColor}
                          illustration={item._Typename === 'Course' ? item.pictureV2 : undefined}
                          icon={!item.locked || hasSubscription ? 'icon' : 'locked-icon'}
                          slug={item.slug}
                          categoryIcon={item._Typename === 'Single' && item.category.icon ? item.category.icon : undefined}
                        />
                      );
                    })}
                  </ListLayoutBlock>
                </>
              )}
            </div>
            {!hasSubscription && <AppAdTile />}
            <CouponAdTile />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

ContentByCategory.propTypes = {
  categories: PropTypes.array.isRequired,
  category: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired
};

export default ContentByCategory;
