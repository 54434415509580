import * as types from 'redux/actions/actionTypes';

const initialState = {
  unit: {},
  process: 0,
  status: 'pausing',
  creatingProcess: false,
  protectedFile: ''
};

export default function player(state = initialState, action) {
  switch (action.type) {
    case types.TRACK_PLAYER: {
      return {
        ...state,
        unit: {
          id: action.unit.id,
          completed: action.unit.completed
        },
        process: 0,
        status: 'pausing',
        creatingProcess: false,
        protectedFile: ''
      };
    }
    case types.SET_PLAYER_PROCESS: {
      return {
        ...state,
        process: action.process
      };
    }
    case types.SET_PLAYER_STATUS: {
      return {
        ...state,
        status: action.status
      };
    }
    case types.CREATE_PROCESS_PENDING: {
      return {
        ...state,
        creatingProcess: true
      };
    }
    case types.CREATE_PROCESS_SUCCESS: {
      return {
        ...state,
        creatingProcess: false
      };
    }
    case types.GET_UNIT_URL_PENDING: {
      return {
        ...state,
        gettingUrl: true
      };
    }
    case types.GET_UNIT_URL_FAILURE: {
      return {
        ...state,
        gettingUrl: false
      };
    }
    case types.GET_UNIT_URL_SUCCESS: {
      let protectedFile = '';
      if (action.payload) {
        protectedFile = action.payload.protectedFile;
      }
      return {
        ...state,
        gettingUrl: false,
        protectedFile: protectedFile
      };
    }
    case types.STOP_PLAYER:
      return {
        ...state,
        unit: {}
      };

    default:
      return state;
  }
}

export const getAll = (state) => state;
export const getCurrentUnit = (state) => state && state.unit;
export const getProcess = (state) => state && state.process;
export const getStatus = (state) => state && state.status;
export const getCreatingProcessStatus = (state) => state && state.creatingProcess;
export const getProtectedFileUrl = (state) => state && state.protectedFile;
