import { requestApi } from 'utils';

export const getNewContent = () =>
  requestApi.query(`
  query getNewContent {
    newContent {
      ... on Course {
        id
        title
        pictureV2
        isFree
        isSpecial
        backgroundColor
        isNew
        intro
        length
        slug
        locked
        unitsCount
        __typename
      }
      ... on Single {
        id
        title
        length
        isFree
        isSpecial
        backgroundColor
        category {
          icon
        }
        isNew
        locked
        slug
        __typename
      }
    }
  }
`);
