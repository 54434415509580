import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import { Section } from 'Container';

export default styled(Section)`
  color: ${colors.blueGrey.c500};
  
  .headline {
    @media (min-width: ${breakpoints.md}) {
      margin-top: -${asRem(160)};
    }
  }

  .image-xs {
    width: 100%;
    max-width: ${asRem(240)};
  }

  .image-md {
    position: relative;
    display: none;
    top: -${asRem(110)};
    width: ${asRem(240)};
    aspect-ratio: 240 / 192;

    @media (min-width: ${breakpoints.md}) {
      display: block;
      width: ${asRem(180)};
      left: ${asRem(250)};
      top: -${asRem(110)};
    }

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(240)};
      left: ${asRem(340)};
      top: -${asRem(150)};
    }

    @media (min-width: ${breakpoints.xl}) {
      left: ${asRem(376)};
    }

    @media (min-width: ${breakpoints.xxl}) {
      left: ${asRem(420)};
    }
  }
`;
