import { requestApi } from 'utils';

export const transferSubscriptionApiRequest = ({ guestUserId }) =>
  requestApi.mutate(`
  mutation TransferSubscription {
    transferSubscription(guestUserId:"${guestUserId}") {
      subscription {
        id
      }
    }
  }
`);
