export const GET_INSURANCE_COMPANIES_REQUEST = 'GET_INSURANCE_COMPANIES_REQUEST';
export const GET_INSURANCE_COMPANIES_SUCCESS = 'GET_INSURANCE_COMPANIES_SUCCESS';
export const GET_INSURANCE_COMPANIES_FAILURE = 'GET_INSURANCE_COMPANIES_FAILURE';

export const getInsuranceCompaniesRequest = (isCooperationPartner) => ({
  type: GET_INSURANCE_COMPANIES_REQUEST,
  isCooperationPartner
});

export const getInsuranceCompaniesSuccess = (payload) => ({
  type: GET_INSURANCE_COMPANIES_SUCCESS,
  payload
});

export const getInsuranceCompaniesFailure = (error) => ({
  type: GET_INSURANCE_COMPANIES_FAILURE,
  error
});
