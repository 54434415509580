export const REDEEM_GIFT_COUPON = 'REDEEM_GIFT_COUPON';
export const REDEEM_GIFT_COUPON_PENDING = 'REDEEM_GIFT_COUPON_PENDING';
export const REDEEM_GIFT_COUPON_FAILURE = 'REDEEM_GIFT_COUPON_FAILURE';
export const REDEEM_GIFT_COUPON_SUCCESS = 'REDEEM_GIFT_COUPON_SUCCESS';
export const SET_REDEEM_GIFT_COUPON = 'SET_REDEEM_GIFT_COUPON';

export const redeemGiftCoupon = ({ code, showFlashError }) => ({
  type: REDEEM_GIFT_COUPON,
  payload: {
    code,
    showFlashError
  }
});

export const redeemingGiftCoupon = () => ({
  type: REDEEM_GIFT_COUPON_PENDING
});

export const redeemGiftCouponFailure = (error) => ({
  type: REDEEM_GIFT_COUPON_FAILURE,
  error
});

export const redeemGiftCouponSuccess = (payload) => ({
  type: REDEEM_GIFT_COUPON_SUCCESS,
  payload
});

export const setRedeemGiftCoupon = (code) => ({
  type: SET_REDEEM_GIFT_COUPON,
  code
});
