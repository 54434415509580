import PropTypes from 'prop-types';

export const inputFieldDefaultProps = {
  showLabel: false,
  disabled: false,
  required: false
};

export const inputFieldPropTypes = {
  showLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};
