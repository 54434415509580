import { requestApi } from 'utils';

export const getSubscriptionPlansApiRequest = (group, coupon) =>
  requestApi.query(
    `
  query WebappSubscriptionPlans($group: String, $couponCode: String) {
    webappSubscriptionPlans(group: $group, couponCode: $couponCode) {
      id
      chargeAmount
      priceExplainer
      provider
      interval
      title
      isPromo
      badgeColor
      badgeTitle
      priceIntervalInfo
      buttonColor
      idAtProvider
      subscriptionPlanGroup
      taxRatePercentage
      zppCertified
      crossedOutPrice
      coupon {
        code
        name
        duration
        durationInMonths
      }
    }
  }
`,
    {
      group,
      couponCode: coupon
    }
  );
