import styled from 'styled-components';
import { spacing, breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export default styled(Section)`
  padding-top: ${asRem(spacing.twelve)} !important;
  padding-bottom: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${asRem(spacing.eight * 2)} !important;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: ${asRem(spacing.nine * 2)} !important;
  }

  .button {
    margin-bottom: ${asRem(spacing.two)};
    margin-right: ${asRem(spacing.two)};

    @media (min-width: ${breakpoints.md}) {
      margin: ${asRem(spacing.three)} 0;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: ${asRem(spacing.eight * 2)};
    }
  }

  .header-image {
    background-position: top;
    aspect-ratio: 589 / 384;

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(589)};
    }
  }
`;
