import { requestApi } from 'utils';

export const getSingle = (slug) =>
  requestApi.query(`
  query GetSingle {
    singles(slug: "${slug}") {
      id
      title
      description
      protectedFile
      length
      isFree
      isSpecial
      locked
      backgroundColor
      isNew
      slug
      locked
      completed
      specialLogo
      category {
        id
        title
        icon
        singles {
          id
          title
          length
          isFree
          isSpecial
          locked
          backgroundColor
          isNew
          slug
          specialLogo
        }
      }
    }
  }
`);
