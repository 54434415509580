import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'redux/actions';
import { isRegisteredUser, isUserLoading } from 'redux/query';
import { useUserError } from 'hookApi';

export const withUser = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const registeredUser = useSelector(isRegisteredUser);
  const isFetching = useSelector(isUserLoading);
  const error = useUserError();

  if (error) {
    return error;
  }

  if (!registeredUser) {
    return dispatch(logoutUser());
  }

  return <WrappedComponent {...props} isFetching={isFetching} />;
};
