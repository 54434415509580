import React from 'react';
import { TEXT } from 'locales/translations';
import { AccordionItem, Col, Headline, List, Paragraph, Row } from 'Common';
import { Accordion } from 'Component';
import { Section } from 'Container';

export default () => {
  return (
    <Section className="faq text-center" backgroundColor="lightGrey">
      <Headline level={4}>{TEXT.healthAdvisor.faq.headline}</Headline>
      <Row>
        <Col xs="12" md={{ size: 10, offset: 1 }}>
          <Accordion>
            <AccordionItem
              header={<Paragraph className="bold">{TEXT.healthAdvisor.faq.headline_1}</Paragraph>}
              body={<Paragraph>{TEXT.healthAdvisor.faq.body_1}</Paragraph>}
              index={0}
            />
            <AccordionItem
              header={<Paragraph className="bold">{TEXT.healthAdvisor.faq.headline_2}</Paragraph>}
              body={
                <List>{[TEXT.healthAdvisor.faq.body_2.li_1, TEXT.healthAdvisor.faq.body_2.li_2, TEXT.healthAdvisor.faq.body_2.li_3]}</List>
              }
              index={1}
            />
          </Accordion>
        </Col>
      </Row>
    </Section>
  );
};
