export const PERSIST_HEALTH_INSURANCE_USER_DATA = 'PERSIST_HEALTH_INSURANCE_USER_DATA';

export const persistHealthInsuranceUserData = ({ name, email, insuranceNumber, gender, age, companyName }) => ({
  type: PERSIST_HEALTH_INSURANCE_USER_DATA,
  payload: {
    name,
    email,
    insuranceNumber,
    gender,
    age,
    companyName
  }
});
