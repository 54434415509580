import styled from 'styled-components';
import { spacing, breakpoints, colors } from 'components/Theme';
import { asRem } from 'utils';
import { Section } from 'Container';

export default styled(Section)`
  overflow: hidden;
  align-items: center;
  height: ${asRem(855)};
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    height: ${asRem(700)};
  }

  @media (min-width: ${breakpoints.lg}) {
    height: ${asRem(730)};
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    height: ${asRem(390)};

    @media (min-width: ${breakpoints.md}) {
      padding-top: ${asRem(spacing.three)};
      height: ${asRem(440)};
    }

    @media (min-width: ${breakpoints.lg}) {
      padding-top: ${asRem(spacing.six)};
      height: ${asRem(450)};
    }

    .audio-button {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
      margin: 0 auto 0 0;
    }
  }

  .mobile-image {
    aspect-ratio: 330 / 670;
    margin-top: ${asRem(spacing.seven)};
    width: ${asRem(215)};
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(300)};
      margin-left: ${asRem(-spacing.two)};
      margin-top: ${asRem(-spacing.four)};
    }

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(400)};
      margin-top: ${asRem(-spacing.five)};
      margin-left: auto;
      margin-right: 0;
    }
  }

  .indicators-wrapper {
    position: absolute;
    width: 100%;
    height: ${asRem(456)};
    align-items: flex-end;

    @media (min-width: ${breakpoints.md}) {
      height: ${asRem(495)};
    }

    @media (min-width: ${breakpoints.lg}) {
      height: ${asRem(510)};
    }

    .mime-elements-carousel-indicators {
      position: relative !important;
      display: block;
      width: auto;
      z-index: 1;
      justify-content: center;
      align-items: center;
      height: 10px;

      button {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 0 6px;
      }
    }
  }

  .carousel-indicators [data-bs-target] {
    opacity: 0.8;
    background-color: ${colors.white.c500};
    transition: background-color 0.3s ease;
  }

  .carousel-indicators .active {
    background-color: ${colors.purple.c600};
  }

  &.active-color-work {
    .container-fluid {
      background-color: ${colors.purple.c500};
      transition: background-color 1s ease;
      .btn-round-border {
        &.active {
          background-color: ${colors.purple.c600};
          border-color: ${colors.purple.c600};
        }
      }
    }
  }

  &.active-color-calm {
    .container-fluid {
      background-color: ${colors.blue.c500};
      transition: background-color 1s ease;

      .btn-round-border {
        background-color: ${colors.blue.c500};

        &.active {
          background-color: ${colors.blue.c600};
          border-color: ${colors.blue.c600};
        }
      }

      .btn-round-border:hover {
        background-color: ${colors.blue.c600};
        border-color: ${colors.blue.c600};
      }

      .btn-inverted {
        color: ${colors.blue.c500};
      }

      .btn-inverted:hover {
        color: ${colors.white.c500};
        background-color: ${colors.blue.c600};
        border-color: ${colors.blue.c600};
      }

      .carousel-indicators .active {
        background-color: ${colors.blue.c600};
      }
    }
  }

  &.active-color-feeling {
    .container-fluid {
      background-color: ${colors.pink.c500};
      transition: background-color 1s ease;

      .btn-round-border {
        background-color: ${colors.pink.c500};

        &.active {
          background-color: ${colors.pink.c600};
          border-color: ${colors.pink.c600};
        }
      }

      .btn-round-border:hover {
        background-color: ${colors.pink.c600};
        border-color: ${colors.pink.c600};
      }

      .btn-inverted {
        color: ${colors.pink.c500};
      }

      .btn-inverted:hover {
        color: ${colors.white.c500};
        background-color: ${colors.pink.c600};
        border-color: ${colors.pink.c600};
      }

      .carousel-indicators .active {
        background-color: ${colors.pink.c600};
      }
    }
  }
`;
