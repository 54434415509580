import {
  ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST,
  ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE,
  VALIDATE_INSURANCE_NUMBER_REQUEST,
  SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST,
  SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST,
  REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST,
  REGISTER_HEALTH_INSURANCE_USER_REQUEST,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST,
  SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST
} from 'redux/actions';
import { takeLatest, all } from 'redux-saga/effects';
import { validateInsuranceNumberSaga } from './ValidateInsuranceNumberSaga/ValidateInsuranceNumberSaga';
import { requestOtpByInsuranceNumberSaga } from './RequestOtpByInsuranceNumberSaga';
import { signInWithOtpAndInsuranceNumberSaga } from './SignInWithOtpAndInsuranceNumberSaga';
import { attendInsuranceCooperationUserDataRequestSaga } from './AttendInsuranceCooperationUserDataRequestSaga';
import { attendInsuranceCooperationUserDataFailureSaga } from './AttendInsuranceCooperationUserDataFailureSaga';
import { registerUserWithInsuranceNumberSaga } from './RegisterUserWithInsuranceNumberSaga';
import { registerHealthInsuranceUserSaga } from './RegisterHealthInsuranceUserSaga';
import { authenticateInsuranceCooperationUserSaga } from './authenticateInsuranceCooperationUserSaga';
import { sendInsuranceCooperationOAuthTokenSaga } from './sendInsuranceCooperationOAuthTokenSaga/sendInsuranceCooperationOAuthTokenSaga';

export function* insuranceWatcher() {
  yield all([
    yield takeLatest(VALIDATE_INSURANCE_NUMBER_REQUEST, validateInsuranceNumberSaga),

    yield takeLatest(SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST, requestOtpByInsuranceNumberSaga),
    yield takeLatest(SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST, signInWithOtpAndInsuranceNumberSaga),
    yield takeLatest(ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST, attendInsuranceCooperationUserDataRequestSaga),
    yield takeLatest(ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE, attendInsuranceCooperationUserDataFailureSaga),
    yield takeLatest(REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST, registerUserWithInsuranceNumberSaga),
    yield takeLatest(REGISTER_HEALTH_INSURANCE_USER_REQUEST, registerHealthInsuranceUserSaga),
    yield takeLatest(AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST, authenticateInsuranceCooperationUserSaga),
    yield takeLatest(SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST, sendInsuranceCooperationOAuthTokenSaga)
  ]);
}
