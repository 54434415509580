import React from 'react';
import { Headline, Icon, List } from 'Common';
import Style from './RegistrationDescription.style';
import calmnessImg from 'images/authentication/calmness.png';
import { TEXT } from 'locales/translations';
import { colors } from 'Theme';

export const RegistrationDescription = () => {
  return (
    <Style>
      <div className="container">
        <Headline level={3} className="text-center" textColor="white">
          {TEXT.registration.descriptionTitle}
        </Headline>
        <List
          className="small"
          icon={<Icon color={[colors.green.c100, colors.green.c600]} size={{ sm: 32, md: 32, lg: 32 }} name="Checkmark" />}
        >
          {[TEXT.registration.descriptionElement1, TEXT.registration.descriptionElement2, TEXT.registration.descriptionElement3]}
        </List>
        <img src={calmnessImg} alt={TEXT.registration.descriptionImageAlt} className="calmness-img" />
      </div>
    </Style>
  );
};
