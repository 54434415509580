import React from 'react';
import PropTypes from 'prop-types';

export const LogoGesundLeben = ({ primaryColor, secondaryColor }) => (
  <svg enableBackground="new 0 0 150 60" viewBox="0 0 150 60" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h150v60h-150z" fill="none" />
    <g transform="translate(26 15.190289)">
      <path
        d="m0 .3v9.7h7.1v-9.7zm6.9 5.4-1.9.1v1.8l-1.1-1.1-3.8 3.2 1.9-3.7h-1.7l2-1.7-2-3.8 3.5 2.7 3.1-2.7-1.7 3.8z"
        fill={primaryColor}
      />
      <path d="m3.8 3.2-3.5-2.7 2 3.9-2 1.7 1.7-.1-1.9 3.7 3.8-3.2 1.1 1.1v-1.8l1.9-.1-1.7-1.4 1.7-3.8z" fill={secondaryColor} />
    </g>
    <g clipRule="evenodd" fill={primaryColor} fillRule="evenodd" transform="translate(34.594253 15.190289)">
      <path d="m69.1 15.9h-4.3v14.1h3.5v-9.2l6.3 9.2h3.5v-14.1h-3.5v8.3zm-6 10.9h-8.4v-2.4h6.1v-3.2h-6.1v-2.1h8.2v-3.2h-11.9v14.1h12.1zm-19.6-2.6c1.3 0 1.9.6 1.9 1.4s-.6 1.3-1.9 1.3h-3.5v-2.7zm-3.5-5.3h3.2c1.3 0 1.8.6 1.8 1.4 0 .7-.5 1.3-1.8 1.3h-3.2zm5.6 3.8c1.8-.2 3.2-1.3 3.2-2.9 0-2.4-1.6-3.9-5.3-3.9h-7.3v14.1h7.8c3.3 0 5.2-1.4 5.2-3.9.1-2-1.6-3.2-3.6-3.4zm-11 4.1h-8.4v-2.4h6.1v-3.2h-6.1v-2.1h8.2v-3.2h-11.9v14.1h12.1zm-25.4-10.9v14.1h11.7v-3.4h-7.9v-10.7z" />
      <path d="m78.4 11.4v-8.1h2.1c2.5 0 3.9 1.5 3.9 4 0 2.6-1.5 4.1-3.9 4.1zm-3.8 3h6.2c4.7 0 7.6-2.6 7.6-7.1 0-4.4-2.9-7.1-7.6-7.1h-6.2zm-11.2-14.1h-4.4v14.1h3.5v-9.2l6.3 9.2h3.5v-14.1h-3.5v8.3zm-13.1 14.4c3.6 0 6.7-1.9 6.7-6v-8.4h-3.8v7.7c0 2.2-.9 3.5-2.8 3.5-2 0-2.8-1.3-2.8-3.5v-7.7h-3.8v8.4c-.2 4.1 2.8 6 6.5 6zm-21.3-2.5c1.7 1.6 3.9 2.5 6.9 2.5 3.5 0 6.3-1.6 6.3-4.5 0-2.3-1.4-3.6-4.4-4.3l-3.2-.7c-.6-.1-1.2-.3-1.2-1s.9-1.2 2.1-1.2c1.9 0 2.9.8 3.7 1.9l2.5-2.4c-1.4-1.5-3.4-2.5-6.1-2.5-3.6 0-5.9 1.8-5.9 4.6 0 2.5 1.7 3.6 4 4.1l3.3.6c.7.1 1.4.3 1.4 1.1s-1 1.2-2.5 1.2c-1.8 0-3.5-.7-4.6-2.1zm-.8-1h-8.4v-2.4h6.1v-3.2h-6.1v-2.1h8.2v-3.2h-11.9v14.1h12.1zm-14-7.1c-.9-2.6-3.3-4.1-6.6-4.1-4.1 0-7.6 3-7.6 7.3 0 4.4 3.1 7.3 7 7.3 2 0 3.5-.7 4.3-2l.4 1.8h2.6v-7.4h-7.2v2.6h3.7c-.6 1.1-1.5 1.9-3.1 1.9-2.4 0-3.8-1.6-3.8-4.1 0-2.4 1.5-4 3.7-4 1.9 0 2.9 1.1 3.2 2.4z" />
    </g>
  </svg>
);

LogoGesundLeben.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string
};
