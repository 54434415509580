import React from 'react';
import PropTypes from 'prop-types';
import Style from './InsuranceHeaderSection.style';
import { BackgroundImage } from 'Common';
import { cloudLeftImage, cloudRightImage, blueBorderContainerBottomImage } from 'images/landingpages/health_insurance';

export const InsuranceHeaderSection = ({ className = '', children }) => {
  return (
    <Style backgroundColor="blue" verticalPadding="none" className={`header-section ${className}`} dataCyName='header-section'>
      <BackgroundImage image={cloudLeftImage} className="cloud cloud-left" />
      <BackgroundImage image={cloudRightImage} className="cloud cloud-right" />
      <div className="content">{children}</div>
      <img src={blueBorderContainerBottomImage} alt="" className="blue-bottom-line" />
    </Style>
  );
};

InsuranceHeaderSection.propTypes = {
  children: PropTypes.node
};
