import React, { useMemo } from 'react';
import { CreditCard, PayPal, SepaDebit } from 'Common/PaymentCards';
import { TEXT } from 'locales/translations';
import { getUserPaymentDetails, getUserPaymentInformation, getUserPaymentMethod } from 'redux/query';
import { useSelector } from 'react-redux';
import { toCamelCase } from 'utils';

export const UserPaymentMethod = () => {
  const userPaymentMethod = useSelector(getUserPaymentMethod);
  const userPaymentInformation = useSelector(getUserPaymentInformation);
  const userPaymentDetails = useSelector(getUserPaymentDetails);

  const paymentMethod = useMemo(() => {
    if (userPaymentMethod) {
      return userPaymentMethod;
    }
    if (userPaymentDetails.renewalInfo && userPaymentDetails.renewalInfo.paymentMethod) {
      const { paymentMethod } = userPaymentDetails.renewalInfo;
      return {
        type: paymentMethod.type,
        ...paymentMethod[toCamelCase(paymentMethod.type)]
      };
    }
    if (userPaymentDetails.paymentDetails) {
      const { paymentMethod } = userPaymentDetails.paymentDetails;
      return {
        type: paymentMethod.type,
        ...paymentMethod[toCamelCase(paymentMethod.type)]
      };
    }

    return {
      type: userPaymentInformation.paymentMethod
    };
  }, [userPaymentDetails]);

  switch (paymentMethod?.type) {
    case 'paypal':
      return <PayPal paypal={paymentMethod} />;
    case 'card':
      return <CreditCard card={paymentMethod} />;
    case 'debit':
    case 'sepa_debit':
      return <SepaDebit sepaDebit={paymentMethod} />;
    default:
      return TEXT.checkout.paymentMethodNone;
  }
};
