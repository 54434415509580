import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { TEXT } from 'locales/translations';
import AppStage from 'components/AppStage';
import KaifuHero from './KaifuHero';
import HowToKaifu from './HowToKaifu';
import CallToActionKaifu from './CallToActionKaifu';

import { BrandBar } from 'components/images';

class KaifuLandingPage extends Component {
  render() {
    return (
      <Fragment>
        <main className="lp-kaifu main-decorated">
          <KaifuHero />
          <Container>
            <Row>
              <Col xs={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }}>
                <div className="brandbar">
                  <BrandBar className="kaifuandballoon" />
                </div>
              </Col>
            </Row>
          </Container>
          <section className="steps-howto howto-kaifu">
            <Container>
              <Row>
                <Col>
                  <h1 className="mb-5">{TEXT.kaifuHowTo.title}</h1>
                  <HowToKaifu />
                  <CallToActionKaifu />
                </Col>
              </Row>
            </Container>
          </section>
          <AppStage />
        </main>
      </Fragment>
    );
  }
}

export default KaifuLandingPage;
