import React from 'react';
import PropTypes from 'prop-types';
import HappyCloudBalloon1x from 'images/webplayer/cloud-balloon@1x.png';
import HappyCloudBalloon2x from 'images/webplayer/cloud-balloon@2x.png';
import HappyCloudBalloon3x from 'images/webplayer/cloud-balloon@3x.png';

HappyCloudBalloon.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.string
};

export default function HappyCloudBalloon({ className, sizes }) {
  return (
    <img
      className={className}
      srcSet={`${HappyCloudBalloon1x} 200w, ${HappyCloudBalloon2x} 400w, ${HappyCloudBalloon3x} 600w`}
      sizes={sizes}
      src={HappyCloudBalloon1x}
      alt="Balloon Illustration "
    />
  );
}
