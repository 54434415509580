import MenuStyle from './Menu.style';
import { NavLink, NavbarBrand } from 'Component';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import React from 'react';

export const Menu = ({ children, ...props }) => {
  return (
    <MenuStyle
      brandLink={
        <NavbarBrand
          tag={() => (
            <NavLink className="navbar-brand p-0" tag={Link} to={ROUTES.home}>
              Balloon Home
            </NavLink>
          )}
          to={'#home'}
        />
      }
      {...props}
    >
      {children}
    </MenuStyle>
  );
};
