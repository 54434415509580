import React from 'react';
import PropTypes from 'prop-types';
import { localizedAmount } from 'utils/localeHelpers';

/**
 * @deprecated transfer this function as a utils function
 */
export const currencySymbol = (currency) => {
  switch (currency) {
    case 'eur':
      return '€';
    default:
      return '?';
  }
};

export const Amount = ({ value, currency, className }) => {
  return (
    <span className={className}>
      {localizedAmount(value / 100)} {currencySymbol(currency)}
    </span>
  );
};

Amount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.string.isRequired,
  className: PropTypes.string
};
