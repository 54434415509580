import { put } from 'redux-saga/effects';
import { sendInsuranceCooperationOAuthTokenSuccess, sendInsuranceCooperationOAuthTokenFailure } from 'redux/actions';
import { cooperationPartnerAuthCodeFlowApiRequest } from 'api/balloon';

export function* sendInsuranceCooperationOAuthTokenSaga({ payload }) {
  const { insuranceCooperationCompany, token } = payload;

  try {
    const { data: response } = yield cooperationPartnerAuthCodeFlowApiRequest({
      b2bClientKey: insuranceCooperationCompany,
      authorizationCode: token
    });

    if (response.errors) {
      yield put(sendInsuranceCooperationOAuthTokenFailure(response.errors));
      return;
    }

    if (!response.data.cooperationPartnerAuthCodeFlow) {
      yield put(sendInsuranceCooperationOAuthTokenFailure(new Error('TOKEN_NOT_SET')));
      return;
    }

    yield put(
      sendInsuranceCooperationOAuthTokenSuccess({
        ...response.data.sendInsuranceCooperationOAuthToken,
        oAuthTokenExpiresAt: payload.tokenExpirationDate
      })
    );
  } catch (error) {
    yield put(sendInsuranceCooperationOAuthTokenFailure(error));
  }
}
