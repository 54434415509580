export const POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST = 'POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST';
export const POST_CHECKOUT_SUBSCRIPTION_UPGRADE_SUCCESS = 'POST_CHECKOUT_SUBSCRIPTION_UPGRADE_SUCCESS';
export const POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE = 'POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE';

export const postCheckoutSubscriptionUpgradeRequest = () => ({
  type: POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST
});

export const postCheckoutSubscriptionUpgradeSuccess = (data) => ({
  type: POST_CHECKOUT_SUBSCRIPTION_UPGRADE_SUCCESS,
  payload: data
});

export const postCheckoutSubscriptionUpgradeFailure = (error) => ({
  type: POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE,
  error
});
