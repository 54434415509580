import React from 'react';
import { TEXT } from 'locales/translations';
import { BackgroundImage, Paragraph } from 'Common';
import { ScrollableRow } from 'Container';
import { CompanyTrustStyle } from './CompanyTrust.style';
import { logoGJ, logoXing, logoHansefit, logoSwissLife, logoRTL } from 'images/landingpages/b2b_landingpage';

export const CompanyTrust = ({ backgroundColor = 'lightGrey', verticalPadding = 'small' }) => {
  return (
    <CompanyTrustStyle className="text-center" type="full-width" verticalPadding={verticalPadding} backgroundColor={backgroundColor}>
      <Paragraph className="bold">{TEXT.company.companyTrust.headline}</Paragraph>

      <ScrollableRow>
        <BackgroundImage image={logoGJ} className="logo" />
        <BackgroundImage image={logoXing} className="logo" />
        <BackgroundImage image={logoHansefit} className="logo" />
        <BackgroundImage image={logoSwissLife} className="logo" />
        <BackgroundImage image={logoRTL} className="logo" />
      </ScrollableRow>
    </CompanyTrustStyle>
  );
};
