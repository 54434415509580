import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { actions } from 'react-redux-form';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/users';
import { setErrorsFor } from './helpers';
import {
  REGISTER_USER_PENDING,
  CLEAR_REGISTER_USER_FAILURE_ERROR,
  REGISTER_USER_OTP_FAILURE,
  REQUEST_OTP_SUCCESS,
  SIGN_IN_USER_OTP_SUCCESS,
  SIGN_IN_USER_OTP_VOUCHER_SUCCESS,
  SIGN_IN_USER_OTP_FAILURE,
  CLEAR_SIGN_IN_USER_OTP_FAILURE_ERROR,
  SET_USER_AS_AUTHENTICATED
} from 'redux/actions/actionTypes';
import { redeemGiftCoupon, loggingInUser, registerUserFailure } from 'redux/actions';
import { registerGuestUserApiRequest, requestOnetimePasswordApiRequest, signInUserWithOnetimePasswordApiRequest } from 'api/balloon';

const newUserModel = 'entities.forms.user';

const newLoginModel = 'entities.forms.login';

export const registeringUser = () => ({
  type: REGISTER_USER_PENDING
});

export const clearRegisterUserFailureError = () => ({
  type: CLEAR_REGISTER_USER_FAILURE_ERROR
});

export const registerUserOtpFailure = (error) => ({
  type: REGISTER_USER_OTP_FAILURE,
  error
});

export const signInUserOtpSuccess = (payload) => ({
  type: SIGN_IN_USER_OTP_SUCCESS,
  payload
});
export const signInUserOtpVoucherSuccess = (payload) => ({
  type: SIGN_IN_USER_OTP_VOUCHER_SUCCESS,
  payload
});

export const signInUserOtpFailure = (error) => ({
  type: SIGN_IN_USER_OTP_FAILURE,
  error
});

export const setUserAsAuthenticated = () => ({
  type: SET_USER_AS_AUTHENTICATED
});

export const clearSignInUserFailureError = () => ({
  type: CLEAR_SIGN_IN_USER_OTP_FAILURE_ERROR
});

export const requestOnetimePasswordSuccess = (payload) => ({
  type: REQUEST_OTP_SUCCESS,
  payload
});

export const requestOnetimePassword = (email, forwardToConfirmationCodePage) => (dispatch) => {
  dispatch(registeringUser());
  dispatch(actions.setPending(newLoginModel, true));

  return requestOnetimePasswordApiRequest(email).then(
    (response) => {
      dispatch(clearRegisterUserFailureError());
      dispatch(actions.setPending(newLoginModel, false));

      if (response.data.errors) {
        dispatch(registerUserOtpFailure(response.data.errors));
      } else {
        dispatch(requestOnetimePasswordSuccess({ email }));
        if (forwardToConfirmationCodePage) {
          dispatch(loggingInUser());
        }
      }
    },
    (error) => {
      dispatch(actions.setPending(newLoginModel, false));
      handleAxiosError(error, () => {
        dispatch(registerUserOtpFailure(error));
      });
    }
  );
};

export const signInUserWithOnetimePassword =
  ({ email, token }) =>
  (dispatch) => {
    dispatch(registeringUser());
    dispatch(actions.setPending(newLoginModel, true));
    dispatch(actions.setPending(newUserModel, true));
    return signInUserWithOnetimePasswordApiRequest({ email, token }).then(
      (response) => {
        dispatch(clearSignInUserFailureError());
        dispatch(actions.setPending(newLoginModel, false));
        dispatch(actions.setPending(newUserModel, false));
        if (response.data.errors) {
          dispatch(signInUserOtpFailure(response.data.errors));
        } else {
          dispatch(actions.reset(newUserModel));
          dispatch(actions.reset(newLoginModel));
          dispatch(
            signInUserOtpSuccess(
              normalize(response.data, {
                user: schema.user
              })
            )
          );
        }
      },
      (error) => {
        dispatch(actions.setPending(newLoginModel, false));
        dispatch(actions.setPending(newUserModel, false));
        handleAxiosError(error, () => {
          dispatch(signInUserOtpFailure(error));
        });
      }
    );
  };

export const signInUserWithOnetimePasswordAndRedeemGiftCoupon =
  ({ email, token, voucherCode }) =>
  (dispatch) => {
    dispatch(registeringUser());
    dispatch(actions.setPending(newLoginModel, true));
    dispatch(actions.setPending(newUserModel, true));
    return signInUserWithOnetimePasswordApiRequest({ email, token }).then(
      (response) => {
        dispatch(clearSignInUserFailureError());
        dispatch(actions.setPending(newLoginModel, false));
        dispatch(actions.setPending(newUserModel, false));
        if (response.data.errors) {
          dispatch(signInUserOtpFailure(response.data.errors));
        } else {
          dispatch(actions.reset(newUserModel));
          dispatch(actions.reset(newLoginModel));
          dispatch(
            signInUserOtpVoucherSuccess(
              normalize(response.data, {
                user: schema.user
              })
            )
          );
          dispatch(
            redeemGiftCoupon({
              code: voucherCode,
              showFlashError: true
            })
          );
        }
      },
      (error) => {
        dispatch(actions.setPending(newLoginModel, false));
        dispatch(actions.setPending(newUserModel, false));
        handleAxiosError(error, () => {
          dispatch(signInUserOtpFailure(error));
        });
      }
    );
  };
