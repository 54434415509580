import { put } from 'redux-saga/effects';
import { getStoreInfosFailure, getStoreInfosSuccess } from 'redux/actions';

import { getStoreInfosApiRequest } from 'api/balloon';

export function* getStoreInfosSaga() {
  try {
    const { data: response } = yield getStoreInfosApiRequest();

    if (response.errors) {
      yield put(getStoreInfosFailure(response.errors));
      return;
    }

    yield put(getStoreInfosSuccess(response.data.storeInfos));
  } catch (error) {
    yield put(getStoreInfosFailure(error));
  }
}
