import { all, put } from 'redux-saga/effects';
import { getUserPaymentInfosSuccess, getUserPaymentInfosFailure, clearUserPaymentInfos } from 'redux/actions';
import { getPayableInfosApiRequest, getPayableInfosWithRenewalInfoApiRequest } from 'api/balloon/Payment';

export function* getUserPaymentInfosSaga({ payload }) {
  const renewable = payload ? payload.renewable : false;
  try {
    let response;
    if (renewable) {
      response = (yield getPayableInfosWithRenewalInfoApiRequest()).data;
    } else {
      response = (yield getPayableInfosApiRequest()).data;
    }

    if (response.errors) {
      yield put(getUserPaymentInfosFailure(response.errors));
      return;
    }

    if (response.data.payableInfos.length === 0) {
      yield all([
        put(
          getUserPaymentInfosSuccess({
            paymentInfos: null
          })
        ),
        put(clearUserPaymentInfos())
      ]);
      return;
    }
    yield put(
      getUserPaymentInfosSuccess({
        paymentInfos: response.data.payableInfos[0]
      })
    );
  } catch (error) {
    yield put(getUserPaymentInfosFailure(error));
  }
}
