import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { MainPage } from 'Container';
import { SubscriptionWithdrawCancelation } from 'components';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';
import { isFetchingWithdrawCancelation } from 'redux/reducers';
import { hasSubscription, getUserPaymentDetails, getCurrentUserSubscriptionPlan } from 'redux/query';
import {
  getSubscriptionPlansRequest,
  clearFlashes,
  createRequestWithdrawCancelationSubcription,
  getUserSubscriptionsRequest
} from 'redux/actions';

// ToDo: Refactor this component
/** @deprecated replace new cancelation process connected to the correct business logic */
export class WithdrawCancelationContainer extends PureComponent {
  componentDidMount() {
    const { hasSubscription, getSubscriptionPlansRequest } = this.props;
    this.props.getUserSubscriptionsRequest();
    if (hasSubscription) {
      getSubscriptionPlansRequest();
    }
  }

  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  onClick = () => {
    const { subscriptionId, createRequestWithdrawCancelationSubcription } = this.props;
    createRequestWithdrawCancelationSubcription(subscriptionId);
  };

  render() {
    const { subscriptionTitle, validTil, isFetchingWithdrawCancelation, plan } = this.props;
    return (
      <Fragment>
        <MainPage
          className="checkout mt-0 mt-md-5"
          title="Dein Balloon-Abo verlängern"
          bodyClassName="main-decorated-discreet withdraw-cancelation-subscription"
          containerClassName="px-0"
        >
          <Fragment>
            {plan ? (
              <SubscriptionWithdrawCancelation
                onClick={this.onClick}
                subscriptionTitle={subscriptionTitle}
                validTil={validTil}
                isFetching={isFetchingWithdrawCancelation}
                currentPlan={plan}
              />
            ) : (
              <h1 className="plans__heading">{TEXT.subscriptionPlans.loadingHeading}</h1>
            )}
          </Fragment>
        </MainPage>
      </Fragment>
    );
  }
}

WithdrawCancelationContainer.propTypes = {
  hasSubscription: PropTypes.bool,
  subscriptionTitle: PropTypes.string,
  subscriptionId: PropTypes.string,
  validTil: PropTypes.string,
  selectedPlan: PropTypes.any,
  getUserSubscriptionsRequest: PropTypes.func.isRequired,
  getSubscriptionPlansRequest: PropTypes.func.isRequired,
  createRequestWithdrawCancelationSubcription: PropTypes.func.isRequired,
  payableInfo: PropTypes.any,
  isFetchingWithdrawCancelation: PropTypes.bool
};

export const mapStateToProps = (state) => {
  const hasUserSubscription = hasSubscription(state);
  const userSubscription = getUserPaymentDetails(state);

  return {
    hasSubscription: hasUserSubscription,
    isFetchingWithdrawCancelation: isFetchingWithdrawCancelation(state),
    subscriptionTitle: userSubscription ? userSubscription.title : undefined,
    validTil: hasUserSubscription ? userSubscription.endsAt : undefined,
    subscriptionId: userSubscription ? userSubscription.payableId : undefined,
    payableInfo: userSubscription,
    plan: getCurrentUserSubscriptionPlan(state)
  };
};

export default connect(mapStateToProps, {
  getSubscriptionPlansRequest,
  clearFlashes,
  getUserSubscriptionsRequest,
  createRequestWithdrawCancelationSubcription
})(WithdrawCancelationContainer);
