import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import { Section } from 'Container';

export default styled(Section)`
  color: ${colors.blueGrey.c500};
  
  .image {
    width: ${asRem(405)};
    aspect-ratio: 405 / 206;
  }

  .btn {
    width: 100%;
    @media (min-width: ${breakpoints.md}) {
      width: auto;
    }
  }
`;
