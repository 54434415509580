export const ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST = 'ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST';
export const ATTEND_INSURANCE_COOPERATION_USER_DATA_SUCCESS = 'ATTEND_INSURANCE_COOPERATION_USER_DATA_SUCCESS';
export const ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE = 'ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE';

export const attendInsuranceCooperationUserDataRequest = ({ onetimePassword, attendUserData }) => ({
  type: ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST,
  payload: {
    attendUserData,
    onetimePassword
  }
});

export const attendInsuranceCooperationUserDataSuccess = (payload) => ({
  type: ATTEND_INSURANCE_COOPERATION_USER_DATA_SUCCESS,
  payload
});

export const attendInsuranceCooperationUserDataFailure = (error) => ({
  type: ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE,
  error
});
