import React from 'react';
import PropTypes from 'prop-types';

const BottomHeaderLine = ({ imageSrc }) => {
  return (
    <div className={'header-line-container'}>
      <a target="_blank" rel="noopener noreferrer" href="https://www.xing.com/premium/partners/balloon">
        <img src={imageSrc} alt="xing" />
      </a>
    </div>
  );
};

BottomHeaderLine.propTypes = {
  imageSrc: PropTypes.node.isRequired
};

export default BottomHeaderLine;
