export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'GET_SUBSCRIPTION_PLANS_FAILURE';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';

export const getSubscriptionPlansRequest = (group, coupon) => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST,
  payload: {
    group,
    coupon
  }
});

export const getSubscriptionPlansFailure = (error) => ({
  type: GET_SUBSCRIPTION_PLANS_FAILURE,
  error
});

export const getSubscriptionPlansSuccess = ({ plans }) => ({
  type: GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: {
    plans
  }
});
