import { handleAxiosError } from 'utils';
import { addFlash, clearFlashes } from 'redux/actions';
import { REDEEM_CODE_FAILURE, REDEEM_CODE_PENDING, REDEEM_CODE_SUCCESS, CLEAR_UNLOCKCODE_MESSAGES } from 'redux/actions/actionTypes';
import { getUnlockCodeErrorMessage } from 'redux/query';
export const redeemingCode = () => ({
  type: REDEEM_CODE_PENDING
});

export const redeemCodeFailure = (error) => ({
  type: REDEEM_CODE_FAILURE,
  error
});

export const redeemCodeSuccess = (payload) => ({
  type: REDEEM_CODE_SUCCESS,
  payload
});
export const clearRedeemUnlockCodeMessage = () => ({
  type: CLEAR_UNLOCKCODE_MESSAGES
});

export const redeemUnlockCode =
  (code) =>
  (dispatch, getState, { api }) => {
    dispatch(clearFlashes());
    dispatch(redeemingCode());
    return api.redeemUnlockCode({ code }).then(
      (response) => {
        if (response.data.errors) {
          dispatch(redeemCodeFailure(response.data.errors));
          dispatch(addFlash(getUnlockCodeErrorMessage(getState()), 'danger'));
        } else {
          dispatch(redeemCodeSuccess());
          dispatch(
            addFlash(
              'Dein Special wurde erfolgreich freigeschaltet. Du findest deine freigeschalteten Inhalte ab jetzt in der App. Du kannst im Entdecken-Bereich auch nach Themen filtern, um deine Inhalte schneller zu finden.',
              'success'
            )
          );
        }
      },
      (error) => {
        handleAxiosError(error, () => {
          dispatch(redeemCodeFailure(error));
          dispatch(addFlash(getUnlockCodeErrorMessage(getState()), 'danger'));
        });
      }
    );
  };
