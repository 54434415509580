import React from 'react';
import { TEXT } from 'locales/translations';
import { Col, Row, Container } from 'reactstrap';
import { DecorativeImage } from 'components';

import CourseOnMobileTablet from 'images/landingpages/health_insurance_marketing/mobile_course.png';
import CourseOnMobile from 'images/landingpages/health_insurance_marketing/mobile_course_extract.png';
import AudioPlayerModal from './AudioPlayerModal';

export const BenefitSection = () => {
  return (
    <section className="benefit-marketing">
      <Container>
        <Row>
          <Col className="benefit-marketing__img-container" xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <DecorativeImage
              src={CourseOnMobile}
              alt={TEXT.healthMarketingPage.benefitSection.altText}
              className="benefit-marketing__img d-block d-md-none d-lg-block"
            />
            <DecorativeImage
              src={CourseOnMobileTablet}
              alt={TEXT.healthMarketingPage.benefitSection.altText}
              className="benefit-marketing__img d-none d-md-block d-lg-none"
            />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} className="benefit-marketing__content-container">
            <h2 className="text-pink-c500">{TEXT.healthMarketingPage.benefitSection.headline}</h2>
            <ul className="benefit-marketing__content-container-list">
              <p className="benefit-marketing__content-container-list-element">{TEXT.healthMarketingPage.benefitSection.checkmark_1}</p>
              <p className="benefit-marketing__content-container-list-element">{TEXT.healthMarketingPage.benefitSection.checkmark_2}</p>
              <p className="benefit-marketing__content-container-list-element">{TEXT.healthMarketingPage.benefitSection.checkmark_3}</p>
              <p className="benefit-marketing__content-container-list-element">{TEXT.healthMarketingPage.benefitSection.checkmark_4}</p>
            </ul>
            <Row>
              <Col className="benefit-marketing__btn-container">
                <AudioPlayerModal />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
