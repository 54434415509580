import React, { Fragment, useContext } from 'react';
import { Button, Row, Col } from 'Common';
import { TEXT } from 'locales/translations';
import { ACTIVE_SECTION, CheckoutContext } from '../Checkout';
import { PaymentCollapse, SectionHeader, UserBillingAddressForm } from 'Component';
import { useDispatch } from 'react-redux';
import { setUserPaymentInformation } from 'redux/actions';

export const AddressSection = () => {
  const dispatch = useDispatch();

  const { activeCollapse, userData, setActiveCollapse, onChangeUser } = useContext(CheckoutContext);

  const checked = activeCollapse > ACTIVE_SECTION.ADDRESS;
  const active = activeCollapse === ACTIVE_SECTION.ADDRESS;

  const handleOnChange = ({ field, value }) => {
    onChangeUser({ [field]: value });
  };

  const toggleEditable = () => {
    setActiveCollapse(ACTIVE_SECTION.ADDRESS);
  };

  const handleOnSubmit = (values) => {
    dispatch(setUserPaymentInformation(values));
    setActiveCollapse(ACTIVE_SECTION.PAYMENT);
  };

  return (
    <PaymentCollapse
      className={'background-white-c500'}
      headline={
        <SectionHeader number={2} checked={checked}>
          {TEXT.checkout.sectionHeadlineContact}
        </SectionHeader>
      }
    >
      {active ? (
        <UserBillingAddressForm onChange={handleOnChange} onSubmit={handleOnSubmit} submitButtonLabel={TEXT.checkout.btnContact} />
      ) : checked ? (
        <Fragment>
          <Row>
            <Col className="checkout-text m-0" xs="12">
              <p className="checkout-text mt-3 mb-0">
                {userData?.firstName} {userData?.lastName}
                <br />
                {userData?.street}
                <br />
                {userData?.zipCode}&nbsp;{userData?.city}
                <br />
                {userData?.country}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end" xs="12">
              <Button className="btn-link p-0" onClick={toggleEditable}>
                {TEXT.checkout.btnEdit}
              </Button>
            </Col>
          </Row>
        </Fragment>
      ) : null}
    </PaymentCollapse>
  );
};
