import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Common';

export const PartnerLogo = ({ imageSrc, logoLink, altText }) => {
  return (
    <div className="partner-logo-bubble">
      <Link href={logoLink}>
        <img src={imageSrc} alt={altText} className="partner-logo-bubble__image" />
      </Link>
    </div>
  );
};

PartnerLogo.propTypes = {
  imageSrc: PropTypes.node.isRequired,
  logoLink: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired
};
