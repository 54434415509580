export const GET_USER_SUBSCRIPTIONS_REQUEST = 'GET_USER_SUBSCRIPTIONS_REQUEST';
export const GET_USER_SUBSCRIPTIONS_SUCCESS = 'GET_USER_SUBSCRIPTIONS_SUCCESS';
export const GET_USER_SUBSCRIPTIONS_FAILURE = 'GET_USER_SUBSCRIPTIONS_FAILURE';

export const getUserSubscriptionsRequest = () => ({
  type: GET_USER_SUBSCRIPTIONS_REQUEST
});

export const getUserSubscriptionsFailure = (error) => ({
  type: GET_USER_SUBSCRIPTIONS_FAILURE,
  error
});

export const getUserSubscriptionsSuccess = ({ subscriptions }) => ({
  type: GET_USER_SUBSCRIPTIONS_SUCCESS,
  payload: {
    subscriptions
  }
});
