import React from 'react';
import PropTypes from 'prop-types';

TextBlock.propTypes = {
  heading: PropTypes.string,
  extraStyleHeading: PropTypes.string,
  body: PropTypes.string,
  extraStyleBody: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  textAlign: PropTypes.string
};

export default function TextBlock({ heading, body, textColor, textAlign, extraStyleHeading, extraStyleBody }) {
  return (
    <React.Fragment>
      {heading ? <h2 className={`text-block text-block__heading ${textColor} ${textAlign} ${extraStyleHeading}`}>{heading}</h2> : null}
      {body ? <p className={`text-block text-block__body ${textColor} ${textAlign} ${extraStyleBody}`}>{body}</p> : null}
    </React.Fragment>
  );
}
