import React from 'react';
import brigitteLogo from 'images/landingpages/b2b_clients//Logo_Brigite.svg';
import flowLogo from 'images/landingpages/b2b_clients//Logo_flow.svg';
import gesundLogo from 'images/landingpages/b2b_clients//Logo_GesundLeben.svg';
import { TEXT } from 'locales/translations';

/** @deprecated use LogoChain instead */
const TrustBandSectionB2b = () => {
  return (
    <section className="trust-band-section-b2b">
      <h4>{TEXT.dak.trustBandSection.headline}</h4>
      <div className="logo-container">
        <img className="logo-container__logo" src={brigitteLogo} alt="brigitte" />
        <img className="logo-container__logo" src={flowLogo} alt="flow" />
        <img className="logo-container__logo" src={gesundLogo} alt="Gesund leben" />
      </div>
    </section>
  );
};

export default TrustBandSectionB2b;
