import React from 'react';
import PropTypes from 'prop-types';
import { DecorativeImage } from 'components';
import TextBlock from './TextBlock';

AdvantageItem.propTypes = {
  image: PropTypes.node.isRequired,
  classNameImg: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default function AdvantageItem({ heading, extraStyleHeading, body, image, alt, classNameImg }) {
  return (
    <div className="advantage_item">
      <DecorativeImage src={image} alt={alt} className={classNameImg} />
      <TextBlock heading={heading} extraStyleHeading={extraStyleHeading} body={body} textColor="greyBlue" textAlign="center" />
    </div>
  );
}
