import Style from './InsuranceContainer.style';
import { useSelector } from 'react-redux';
import { isAttendInsuranceNumberFailed, isAttendUserToInsuranceCooperationLoading } from 'redux/query';
import PropTypes from 'prop-types';
import { InsuranceContainerContext } from './InsuranceContainerContext';
import { FormView } from 'Component/InsuranceForm/InsuranceContainer/FormView/InsuranceContainer.FormView';
import { Form } from 'Component/InsuranceForm/InsuranceContainer/Form/InsuranceContainer.Form';
import { OtpView } from 'Component/InsuranceForm/InsuranceContainer/OtpView/InsuranceContainer.OtpView';
import { SuccessView } from 'Component/InsuranceForm/InsuranceContainer/SuccessView/InsuranceContainer.SuccessView';
import { ErrorView } from 'Component/InsuranceForm/InsuranceContainer/ErrorView/InsuranceContainer.ErrorView';
import { SpinnerView } from 'Component/InsuranceForm/InsuranceContainer/Spinner/InsuranceContainer.SpinnerView';

export const InsuranceContainer = ({ b2bClientKey, children }) => {
  const hasAttendError = useSelector(isAttendInsuranceNumberFailed);
  const isLoading = useSelector(isAttendUserToInsuranceCooperationLoading);

  const insuranceFormContextProps = {
    b2bClientKey
  };

  const renderChildren = () => {
    if (hasAttendError) {
      return <InsuranceContainer.ErrorView />;
    }
    if (isLoading) {
      return <InsuranceContainer.SpinnerView />;
    }
    return children;
  };

  return (
    <Style id="anmeldung-krankenkasse">
      <InsuranceContainerContext.Provider value={insuranceFormContextProps}>{renderChildren()}</InsuranceContainerContext.Provider>
    </Style>
  );
};

InsuranceContainer.FormView = FormView;

InsuranceContainer.Form = Form;

InsuranceContainer.OtpView = OtpView;

InsuranceContainer.SuccessView = SuccessView;

InsuranceContainer.ErrorView = ErrorView;

InsuranceContainer.SpinnerView = SpinnerView;

InsuranceContainer.propTypes = {
  b2bClientKey: PropTypes.string.isRequired
};
