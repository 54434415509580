import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiftCouponForm } from 'components';
import { redeemGiftCoupon, setRedeemGiftCoupon } from 'redux/actions';
import { getGiftCouponCode } from 'redux/reducers';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-form';

class GiftCouponFormContainer extends React.Component {
  redeemGiftCoupon = (e) => {
    const { code, redeemGiftCoupon, setRedeemGiftCoupon } = this.props;

    redeemGiftCoupon({ code });
    setRedeemGiftCoupon(code);
  };

  componentWillUnmount() {
    this.props.resetForm('entities.forms.giftCoupon');
  }

  render() {
    const { defaultValue } = this.props;
    return <GiftCouponForm onSubmit={this.redeemGiftCoupon} defaultValue={defaultValue} />;
  }
}

const mapStateToProps = (state) => ({
  code: getGiftCouponCode(state)
});

const mapDispatchToProps = (dispatch) => ({
  redeemGiftCoupon: bindActionCreators(redeemGiftCoupon, dispatch),
  resetForm: bindActionCreators(actions.reset, dispatch),
  setRedeemGiftCoupon: bindActionCreators(setRedeemGiftCoupon, dispatch)
});

GiftCouponFormContainer.propTypes = {
  redeemGiftCoupon: PropTypes.func.isRequired,
  // code is not required because initially, the input field is empty
  code: PropTypes.string,
  defaultValue: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  setRedeemGiftCoupon: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCouponFormContainer);
