import React from 'react';
import { TEXT } from 'locales/translations';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils';
import { Col, Container, Paragraph, Row } from 'Common';
import { useSelector } from 'react-redux';
import { isAuthed, isGuest } from 'redux/query';
import { LoginForm, RegistrationForm, RoundedBox, Tabs } from 'Component';
import { CheckoutHint } from 'components';
import MainPage from './AuthenticationPage.style';
import { RegistrationDescription } from '../../Component/RegistrationDescription/RegistrationDescription';
import { LoginDescription } from '../../Component/LoginDescription/LoginDescription';

export const AuthenticationPage = () => {
  const location = useLocation();
  const isAuthedUser = useSelector(isAuthed);
  const isGuestUser = useSelector(isGuest);
  const history = useHistory();

  const activeTab = location.pathname === ROUTES.login ? 1 : 0;
  const title = activeTab === 0 ? TEXT.registration.title : TEXT.login.title;
  const from = location.state?.from || {
    pathname: ROUTES.profilePersonalData
  };

  const handleOnChangeTab = (tab) => {
    history.push(tab === 0 ? ROUTES.signup : ROUTES.login, { from });
  };

  return (
    <MainPage title={title}>
      <Row>
        {isAuthedUser && !isGuestUser ? (
          <Redirect to={from} />
        ) : (
          <>
            {from.pathname === ROUTES.checkout && (
              <Col xs={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }}>
                <CheckoutHint />
              </Col>
            )}
            {from.pathname === ROUTES.voucher && (
              <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }} className="p-0">
                <div className="voucher-hint">
                  <Paragraph className="text-center mb-0">{TEXT.login.voucherHint}</Paragraph>
                </div>
              </Col>
            )}
            <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }} className="p-0">
              <RoundedBox className="rounded-box p-0">
                <Container>
                  <Row>
                    <Col xs={12} md={7} className="p-3 p-sm-5 pe-sm-6">
                      <Tabs
                        onChange={handleOnChangeTab}
                        activeTab={activeTab}
                        items={[
                          {
                            title: 'Registrieren',
                            content: <RegistrationForm />
                          },
                          {
                            title: 'Anmelden',
                            content: <LoginForm />
                          }
                        ]}
                      />
                    </Col>
                    <Col xs={12} md={5} className="d-flex align-items-stretch p-0">
                      {activeTab === 0 ? <RegistrationDescription /> : <LoginDescription />}
                    </Col>
                  </Row>
                </Container>
              </RoundedBox>
            </Col>
          </>
        )}
      </Row>
    </MainPage>
  );
};

export default AuthenticationPage;
