import PropTypes from 'prop-types';

export const subscriptionPlan = PropTypes.shape({
  id: PropTypes.string.isRequired,
  badgeColor: PropTypes.string,
  badgeTitle: PropTypes.string,
  chargeAmount: PropTypes.number.isRequired,
  crossedOutPrice: PropTypes.number,
  currency: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  taxPercentage: PropTypes.number,
  zppCertified: PropTypes.bool
});

export const subscriptionPlans = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
);
