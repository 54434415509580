import PropTypes from 'prop-types';

export const error = PropTypes.shape({
  // See https://stripe.com/docs/api/node#errors
  type: PropTypes.oneOf([
    'api_connection_error',
    'api_error',
    'authentication_error',
    'card_error',
    'idempotency_error',
    'invalid_request_error',
    'rate_limit_error',
    'validation_error'
  ]),
  charge: PropTypes.string,
  message: PropTypes.string,
  code: PropTypes.string,
  // For card_error, code can be
  // invalid_number, invalid_expiry_month, invalid_expiry_year, invalid_cvc, invalid_swipe_data,
  // incorrect_number, expired_card, incorrect_cvc, incorrect_zip, card_declined, missing,
  // processing_error.
  decline_code: PropTypes.string,
  param: PropTypes.string
});
