import React, { useState } from 'react';
import AudioSamplesStyle from './AudioSamples.style';
import { AudioSamplesFilter } from './AudioSamplesFilter';
import { AudioSamplesJob } from './AudioSamplesJob';
import { AudioSamplesResilienz } from './AudioSamplesResilienz';
import { AudioSamplesStress } from './AudioSamplesStress';
import { AudioSamplesSleep } from './AudioSamplesSleep';
import { CarouselMindful } from './CarouselMindful';
import { CarouselIndicators } from 'reactstrap';
import { Row, Col } from 'Common';

const itemsCarousel = [
  {
    component: <AudioSamplesJob color="work" audioId="tutorial-audio" />,
    key: 1,
    color: 'work'
  },
  {
    component: <AudioSamplesResilienz color="calm" audioId="tutorial-audio" />,
    key: 2,
    color: 'calm'
  },
  {
    component: <AudioSamplesStress color="feeling" audioId="tutorial-audio" />,
    key: 3,
    color: 'feeling'
  },
  {
    component: <AudioSamplesSleep color="calm" audioId="tutorial-audio" />,
    key: 4,
    color: 'calm'
  }
];

export const AudioSamples = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [animating, setAnimating] = useState(false);

  const handleNext = () => {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === itemsCarousel.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const handlePrevious = () => {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === 0 ? itemsCarousel.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (index) => {
    if (animating) {
      return;
    }
    switch (index) {
      case 0:
        document.getElementsByClassName('scrollable-row')[1].scrollLeft = 0;
        break;
      case 1:
        document.getElementsByClassName('scrollable-row')[1].scrollLeft = 200;
        break;
      case 2:
        document.getElementsByClassName('scrollable-row')[1].scrollLeft = 400;
        break;
      case 3:
        document.getElementsByClassName('scrollable-row')[1].scrollLeft = 600;
        break;
      case 4:
        document.getElementsByClassName('scrollable-row')[1].scrollLeft = 800;
        break;
    }
    setActiveIndex(index);
  };

  const handleOnStartAnimation = () => {
    setAnimating(true);
  };

  const handleOnEndAnimation = () => {
    setAnimating(false);
  };

  return (
    <AudioSamplesStyle
      verticalPadding="none"
      type="full-width"
      className={`audio-samples text-center text-md-start active-color-${itemsCarousel[activeIndex].color} `}
    >
      <AudioSamplesFilter activeIndex={activeIndex} goToIndex={goToIndex} />
      <Row className={'indicators-wrapper'}>
        <Col xs="12" md={{ size: 7, offset: 4, order: 1 }}>
          <CarouselIndicators
            items={itemsCarousel}
            activeIndex={activeIndex ?? 0}
            onClickHandler={goToIndex}
            className="mime-elements-carousel-indicators"
          />
        </Col>
      </Row>
      <CarouselMindful
        items={itemsCarousel}
        activeIndex={activeIndex}
        next={handleNext}
        previous={handlePrevious}
        goToIndex={goToIndex}
        onStartAnimation={handleOnStartAnimation}
        onEndAnimation={handleOnEndAnimation}
      />
    </AudioSamplesStyle>
  );
};
