import styled from 'styled-components';
import { colors } from 'Theme';
import { asRem } from 'utils';
export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${asRem(288)};
  border-radius: ${asRem(8)};
  border: 1px solid ${colors.grey.c500};

  &.color- {
    &pink {
      background: linear-gradient(180deg, ${colors.white.c500} 50%, ${colors.pink.c500} 50%);
      border-color: ${colors.pink.c500};
    }

    &blue {
      background: linear-gradient(180deg, ${colors.white.c500} 50%, ${colors.blue.c500} 50%);
      border-color: ${colors.blue.c500};
    }

    &green {
      background: linear-gradient(180deg, ${colors.white.c500} 50%, ${colors.green.c500} 50%);
      border-color: ${colors.green.c500};
    }
  }

  .subscription-image {
    max-width: ${asRem(300)};
    width: 100%;
  }

  .subscription-name {
    font-size: ${asRem(24)};
    text-align: center;
    color: ${colors.white.c500};
    margin: ${asRem(5)} 0;
    padding: ${asRem(8)} 0;
  }

  .subscription-button {
    width: 80%;
    padding: ${asRem(8)} ${asRem(24)};
    margin-bottom: ${asRem(24)};
    height: ${asRem(40)};
    font-size: ${asRem(14)};
  }
`;
