import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ContentByCategory } from 'components';
import { getCurrentCategory, getAllCategories } from 'redux/reducers';
import { hasSubscription, getToken } from 'redux/query';
import PropTypes from 'prop-types';

import withSpinner from 'components/withSpinner';
import withCategory from 'containers/withCategory';

class CategoryContainer extends Component {
  render() {
    const { categories, hasSubscription, category } = this.props;
    return <ContentByCategory categories={categories} category={category} hasSubscription={hasSubscription} />;
  }
}

CategoryContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  category: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  token: PropTypes.string
};

CategoryContainer.defaultProps = {
  hasSubscription: false,
  category: [],
  categories: []
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  category: getCurrentCategory(state),
  hasSubscription: hasSubscription(state),
  token: getToken(state)
});

export default withCategory(withSpinner(connect(mapStateToProps, {})(CategoryContainer)));
