import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'Theme';

export default styled.main.attrs({ className: 'landingpage' })`
  overflow-wrap: break-word;
  
  .header-section {
    .container {
      padding: 0 ${asRem(32)};
    }

    &.lg-p-4 .content {
      @media (min-width: ${breakpoints.lg}) {
        padding: 0 ${asRem(68)};
      }
    }
    
    .content {
      max-width: ${asRem(920)};
     

      .mime-elements-headline-1 {
        padding: 0;
        font-family: Poppins-SemiBold;
      }

      .intro {
        padding: 0 0};

      @media (min-width: ${breakpoints.md}) {
        padding: 0 ${asRem(28)};
      }

      @media (min-width: ${breakpoints.lg}) {
        padding: 0 ${asRem(100)};
      }


    }
  }
  
}

  .image-course-phone {
    position: relative;
    margin-bottom: ${asRem(-240)};
    width: 100%;

    align-self: center;

    @media (min-width: ${breakpoints.md}) {
      position: absolute;
      min-width: ${asRem(100)};
      max-width: ${asRem(340)};
      margin-bottom: 0;
      top: ${asRem(40)};
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: ${asRem(480)};
      top: ${asRem(20)};
    }
  }

  &.insuranceCooperationLandingPage {
    section {
      padding: 0;
      
      .container {
        max-width: ${asRem(1008)};
        margin: 0 auto ${asRem(40)};
      }

      &.checkmark-section {
        overflow: hidden;
        padding-left: ${asRem(20)};
        padding-right: ${asRem(64)};
        @media (min-width: ${breakpoints.lg}) {
          padding: 0;
        }

        .container {
          margin-bottom: 0;
        }
      }
    }
  }

  
  .brands-section {
    z-index: 0;
    display: none;
    
    &.bottom {
      margin: 0;
      padding-top: ${asRem(40)};
      padding-bottom: ${asRem(40)};
    }

    @media (min-width: ${breakpoints.md}) {
      display: block;
      margin: ${asRem(40)} 0;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo-chain {
      margin: 0 auto;
      max-width: ${asRem(630)};
    }
  }

  .insurance-form {
    display: inline-block;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto ${asRem(48)};
      max-width: ${asRem(780)};
      background-color: ${colors.white.c500};
      color: ${colors.blueGrey.c500};
      border-radius: ${asRem(10)};
      overflow: hidden;
      padding: ${asRem(48)} ${asRem(64)};
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .rounded-box {
      padding: 0;
    }
  }

  .mime-elements-section .image-paragraph-row .mime-elements-paragraph {
    padding: 0 ${asRem(24)};

    @media (min-width: ${breakpoints.md}) {
      padding: 0 ${asRem(160)};
    }

    @media (min-width: ${breakpoints.lg}) {
      padding: 0;
    }
  }
`;
