import React from 'react';
import { connect } from 'react-redux';
import { Amount, Spinner } from 'Common';
import { Button, Col, Row } from 'Common';
import PropTypes from 'prop-types';
import { selectSubscriptionPlan } from 'redux/actions';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils/constants';
import * as reactRouterPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { withRouter } from 'react-router-dom';
import TrustBandSectionB2b from './B2B/TrustBandSectionB2b';
import { ThemeContainer } from 'Container';
import { themeFeeling } from 'Theme';

const LandingpageBuyBoxSection = (props) => {
  const onClick = () => {
    const { plan, selectSubscriptionPlan, history } = props;
    selectSubscriptionPlan(plan.id);
    history.push(ROUTES.checkout);
  };

  const { plan, isFetching, headline, description, relator, disclaimer, buttonLinkClass } = props;
  return (
    <ThemeContainer theme={themeFeeling}>
      {Object.keys(plan).length > 0 && (
        <section id="form-section-container">
          <TrustBandSectionB2b />
          <div id="form-box-container">
            <div className="form-box">
              <Row>
                <Col>
                  <h2 className="profile-subheading">{headline}</h2>
                  <div className="pb-4 pt-2 text-center profile-subline">{description}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {isFetching ? (
                    <div className="w-100 d-flex justify-content-center">
                      <Spinner className="m-0" />
                    </div>
                  ) : (
                    <div className="plans-btn">
                      <div className="plans-btn__info">
                        <p className="plans-btn__title" dangerouslySetInnerHTML={{ __html: plan.title }} />
                        <div className="plans-btn__pricing">
                          <p className="plans-btn__amount">
                            <Amount value={plan.chargeAmount} currency={plan.currency} />
                          </p>
                        </div>
                      </div>
                      <p className="plans-btn__relator">{relator}</p>
                      <div className="plans-btn__button-wrapper">
                        <>
                          <Button className={buttonLinkClass} onClick={onClick}>
                            {TEXT.subscriptionCard.oneTimePayment_btn}
                          </Button>
                        </>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }}>
                  <p className="small text-center subscription-disclaimer mt-3">{disclaimer}</p>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      )}
    </ThemeContainer>
  );
};

LandingpageBuyBoxSection.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  plan: PropTypes.any,
  selectSubscriptionPlan: PropTypes.func.isRequired,
  history: reactRouterPropTypes.history,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  relato: PropTypes.string,
  disclaimer: PropTypes.string.isRequired,
  buttonLinkClass: PropTypes.string.isRequired
};

export default withRouter(
  connect(null, {
    // eslint-disable-next-line no-restricted-globals
    history,
    selectSubscriptionPlan
  })(LandingpageBuyBoxSection)
);
