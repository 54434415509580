import styled from 'styled-components';
import { asRem } from 'utils';
import { colors } from 'Theme';

export default styled.div.attrs({ className: 'section-header' })`
  display: flex;
  justify-items: center;
  margin-bottom: ${asRem(14)};
  //ToDo: replace it with mime_elements
  font-weight: 600;
  position: relative;
  left: ${asRem(-4)};

  .number,
  .checkmark {
    width: ${asRem(30)};
    height: ${asRem(30)};
    border-radius: 50%;
    display: inline-block;

    &::before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${asRem(29)};
      height: ${asRem(29)};
      color: var(--green-c500);
      padding-right: ${asRem(3)};
      padding-bottom: ${asRem(2)};
    }
  }

  .number {
    border: ${asRem(2)} solid ${colors.green.c500};

    &::before {
      content: attr(data-text);
    }
  }

  .checkmark {
    background-color: ${colors.green.c500};
  }

  .title {
    text-transform: uppercase;
    margin-left: ${asRem(11)};
    letter-spacing: ${asRem(0.6)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${asRem(2)};
  }
`;
