import React from 'react';
import PropTypes from 'prop-types';
import ImgMobileGreen from 'images/home/phoneMockupGreen@2x.png';
import DecorativeImage from 'components/DecorativeImage';
import { TEXT } from 'locales/translations';

MobileScreenMeditation.propTypes = {
  className: PropTypes.string
};

export default function MobileScreenMeditation({ className }) {
  return <DecorativeImage alt={TEXT.mobileShowcase.altScreenMeditation} src={ImgMobileGreen} className={className} />;
}
