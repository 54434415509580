import React from 'react';
import Badge from 'images/google-play-badge.svg';
import { ROUTES } from 'utils/constants';

export default function GooglePlayBadge(props) {
  const googlePlayStoreLink = ROUTES['google'];
  return (
    <a
      href={googlePlayStoreLink}
      target="_blank"
      data-cy-name="google-play-badge"
      style={{
        display: 'block',
        overflow: 'hidden',
        background: `url(${Badge}) no-repeat`,
        width: '135px',
        height: '40px',
        backgroundSize: 'contain'
      }}
      rel="noreferrer"
    >
      {' '}
    </a>
  );
}
