import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppStoreBadge, GooglePlayBadge } from 'components';
import { ROUTES } from 'utils/constants';
import PropTypes from 'prop-types';
import TrustBand from 'components/TrustBand/TrustBand';
import { Col, Container, Button, Row, ScrollButton } from 'Common';
import AppStage from 'components/AppStage';

const BeurerHeader = ({ yourProduct, backgroundImageClass }) => {
  return (
    <div id="beurer-header" className={backgroundImageClass}>
      <Container>
        <Row>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 8, offset: 2 }} xl={{ size: 10, offset: 1 }}>
            <h1>Schön, dass du dich für Balloon entschieden hast!</h1>
            <p className="mt-3">
              In Zusammenarbeit mit BEURER hat BALLOON einen exklusiven Audio-Kurs entwickelt – als ideale Ergänzung zu {yourProduct}
            </p>
            <div className="cta-buttons mt-5">
              <ScrollButton to="section-appstores">App herunterladen</ScrollButton>
              <Button type="outline" className="mt-4 mt-lg-0 ms-0 ms-md-4 me-4" to={ROUTES.plans}>
                Jetzt abonnieren
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

BeurerHeader.propTypes = {
  yourProduct: PropTypes.string.isRequired,
  backgroundImageClass: PropTypes.string.isRequired
};

const Explanation = ({ yourProduct, description }) => {
  return (
    <div className="section section-explanation">
      <Container>
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 6, offset: 6 }} lg={{ size: 7, offset: 5 }}>
            <div className="explanation-text">
              <h2>Meditation per App mit {yourProduct}</h2>
              <p className="mt-3">{description}</p>
              <p>
                Alle Kurs-Einheiten sind entwickelt und eingesprochen vom Diplom-Psychologen und Neurowissenschaftler Dr. Boris Bornemann
                und basieren auf dem neuesten wissenschaftlichen Kenntnisstand.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Explanation.propTypes = {
  yourProduct: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const Instruction = ({ code, productImage, productName, isAuthed, isGuest }) => {
  return (
    <div className="section section-instruction">
      <Container>
        <Row>
          <Col sm={{ size: 12 }}>
            <h2>So schaltest du den Kurs frei</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }}>
            <div className="instruction-table">
              <div className="instruction-point">
                <div className="instruction-count">
                  <span>1</span>
                </div>
                {isAuthed && !isGuest ? (
                  <p>
                    <Link to={ROUTES.profilePersonalData}>Hier</Link> gehts zu deinem Profil
                  </p>
                ) : (
                  <p>
                    <Link to={ROUTES.signup}>Hier</Link> gratis bei Balloon als Benutzer registrieren
                  </p>
                )}
              </div>
              <div className="instruction-point">
                <div className="instruction-count">
                  <span>2</span>
                </div>
                <p>Im Profil auf der Website auf &#34;Special freischalten&#34; gehen</p>
              </div>
              <div className="instruction-point">
                <div className="instruction-count">
                  <span>3</span>
                </div>
                <p>
                  Code eingeben: <strong>{code}</strong>
                </p>
              </div>
              <div className="instruction-point">
                <div className="instruction-count">
                  <span>4</span>
                </div>
                <p>BALLOON App herunterladen</p>
                <div className="store-badges">
                  <AppStoreBadge />
                  <GooglePlayBadge />
                </div>
              </div>
              <div className="instruction-point">
                <div className="instruction-count">
                  <span>5</span>
                </div>
                <p>
                  Mit registrierten Benutzer anmelden und die freigeschaltete
                  <strong> Meditation starten!</strong>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="product-image" src={productImage} alt={productName} />
    </div>
  );
};

Instruction.propTypes = {
  code: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired
};

Instruction.defaultProps = {
  isAuthed: false,
  isGuest: true
};

class BeurerPage extends Component {
  render() {
    const { yourProduct, backgroundImageClass, description, code, productImage, productName, isAuthed, isGuest } = this.props;
    return (
      <div className="beurer-wrapper">
        <BeurerHeader yourProduct={yourProduct} backgroundImageClass={backgroundImageClass} />
        <Explanation yourProduct={yourProduct} description={description} />
        <Instruction code={code} productImage={productImage} productName={productName} isAuthed={isAuthed} isGuest={isGuest} />
        <TrustBand />
        <AppStage />
      </div>
    );
  }
}

BeurerPage.propTypes = {
  code: PropTypes.string.isRequired,
  yourProduct: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  backgroundImageClass: PropTypes.string.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired
};

export default BeurerPage;
