import React from 'react';
import PropTypes from 'prop-types';
import Style from './ResilienceInstructionCard.style';
import { Headline, Paragraph } from 'Common';

export function ResilienceInstructionCard({ num, headlineLight, headlineBold, cardBody }) {
  return (
    <Style className="mb-5 mb-md-6">
      <div className="header mb-1">
        <div className="count me-3">
          <Headline level={3} className="index">
            {num}
          </Headline>
        </div>
        <div className="d-flex flex-column">
          <Paragraph className="mb-0">{headlineLight}</Paragraph>
          <Headline level={5} className="mb-1">
            {headlineBold}
          </Headline>
        </div>
      </div>
      <Paragraph>{cardBody}</Paragraph>
    </Style>
  );
}

ResilienceInstructionCard.propTypes = {
  num: PropTypes.number.isRequired,
  headlineLight: PropTypes.string.isRequired,
  headlineBold: PropTypes.string.isRequired,
  cardBody: PropTypes.string.isRequired
};
