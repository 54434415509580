import { put } from 'redux-saga/effects';
import { getBgmCompaniesFailure, getBgmCompaniesSuccess } from 'redux/actions';
import { getBgmCompaniesApiRequest } from 'api/balloon';

export function* getBgmCompaniesSaga() {
  try {
    const { data: response } = yield getBgmCompaniesApiRequest();

    if (response.errors) {
      yield put(getBgmCompaniesFailure(response.errors));
      return;
    }

    yield put(getBgmCompaniesSuccess(response.data.bgmCompanies));
  } catch (error) {
    yield put(getBgmCompaniesFailure(error));
  }
}
