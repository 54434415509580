import React, { Component } from 'react';
import releazerProduct from 'images/landingpages/beurer/releazerProduct.png';
import { isAuthed, isGuest } from 'redux/query';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BeurerPage from './BeurerPage';

class Stress extends Component {
  render() {
    const { isGuest, isAuthed } = this.props;
    return (
      <BeurerPage
        code="RELEAZE"
        yourProduct="deinem stressreleaZer"
        description="Die Aktivierung des parasympathischen Nervensystems, die durch den stressreleaZer ausgelöst wird,
                     wird durch die geführten Audio-Meditationen von BALLOON in optimaler Weise begleitet. Der
                     mehrteilige Audio-Kurs enthält Einheiten zum Abbau von Stress und zur Tiefenentspannung."
        productImage={releazerProduct}
        productName="deinem stressreleaZer"
        backgroundImageClass="stress-header"
        isAuthed={isAuthed}
        isGuest={isGuest}
      />
    );
  }
}

Stress.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired
};

export const mapStateToProps = (state) => {
  return {
    isAuthed: isAuthed(state),
    isGuest: isGuest(state)
  };
};

export default connect(mapStateToProps, {})(Stress);
