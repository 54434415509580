import React, { createContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { MainPage, PaymentContainer } from 'Container';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import { EmailSection } from './EmailSection/EmailSection';
import { AddressSection } from './AddressSection/AddressSection';
import { ConfirmationSection } from './ConfirmationSection/ConfirmationSectoin';
import { CheckoutPaymentSection } from './CheckoutPaymentSection/CheckoutPaymentSection';
import {
  getCurrentUser,
  isRegisteredUser,
  getSelectedSubscriptionPlan,
  getUserPaymentInformation,
  isConfirmPaymentMethodSuccessfully
} from 'redux/query';
import { setUserPaymentInformation } from 'redux/actions';
import { useCheckoutRefererRoute } from 'hookApi';
import { Redirect } from 'react-router-dom';
import Style from './Checkout.style';

export const CheckoutContext = createContext({
  activeCollapse: 1,
  userData: {
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    zipCode: '',
    city: '',
    country: 'DE',
    paymentFilled: false
  },
  confirmation: false,
  setActiveCollapse: () => {},
  onChangeUser: () => {}
});

export const ACTIVE_SECTION = {
  MAIL: 1,
  ADDRESS: 2,
  PAYMENT: 3,
  CONFIRMATION: 4
};

export const Checkout = () => {
  const dispatch = useDispatch();

  const registeredUser = useSelector(isRegisteredUser);
  const user = useSelector(getCurrentUser);
  const plan = useSelector(getSelectedSubscriptionPlan);
  const userPaymentInformation = useSelector(getUserPaymentInformation);

  const wasConfirmPaymentMethodSuccessfully = useSelector(isConfirmPaymentMethodSuccessfully);
  const checkoutRefererRoute = useCheckoutRefererRoute();

  const [activeCollapse, setActiveCollapse] = useState(registeredUser ? ACTIVE_SECTION.ADDRESS : ACTIVE_SECTION.MAIL);
  const [userData, setUserData] = useState({
    email: registeredUser ? user?.email : '',
    firstName: '',
    lastName: '',
    street: '',
    zipCode: '',
    city: '',
    country: 'DE',
    paymentFilled: false
  });

  useEffect(() => {
    if (!wasConfirmPaymentMethodSuccessfully) {
      return;
    }
    setUserData({
      email: userPaymentInformation.email,
      firstName: userPaymentInformation.firstName,
      lastName: userPaymentInformation.lastName,
      street: userPaymentInformation.street,
      zipCode: userPaymentInformation.zipCode,
      city: userPaymentInformation.city,
      country: userPaymentInformation.country,
      paymentFilled: true
    });
    setActiveCollapse(ACTIVE_SECTION.CONFIRMATION);
  }, [wasConfirmPaymentMethodSuccessfully]);

  useEffect(() => {
    if (registeredUser && userPaymentInformation?.email !== user?.email) {
      dispatch(
        setUserPaymentInformation({
          email: user?.email
        })
      );
    }
  }, [registeredUser]);

  const handleChangeUserData = (userData) => {
    setUserData((prevState) => ({
      ...prevState,
      ...userData
    }));
  };

  const providerProps = {
    activeCollapse,
    userData,
    setActiveCollapse,
    onChangeUser: handleChangeUserData
  };

  if (checkoutRefererRoute) {
    return <Redirect to={checkoutRefererRoute} />;
  }

  return (
    <Style>
      <Row>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <div className="card card-decorated mt-0 mb-3 mb-md-7">
            <Header totalAmount={plan.chargeAmount} plan={plan} />
            <CheckoutContext.Provider value={providerProps}>
              <PaymentContainer>
                <EmailSection />
                <AddressSection />
                <CheckoutPaymentSection />
                <ConfirmationSection />
              </PaymentContainer>
            </CheckoutContext.Provider>
          </div>
        </Col>
      </Row>
    </Style>
  );
};
