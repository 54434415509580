import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Common';

class FormHeader extends Component {
  render() {
    const { headline, infotext_1, infotext_2, variableText, isMail, isLink, isText, colorHeadline, colorBody } = this.props;

    const vaiableTextVals = !!isMail || !!isLink || !!isText;

    const showVariableText = (vaiableTextVals) => {
      switch (vaiableTextVals) {
        case isMail:
          return (
            <Link href={`mailto:${variableText}`} className="form-header__link">
              {variableText}
            </Link>
          );
        case isLink:
          return <a className="form-header__link">{variableText}</a>;
        case isText:
          return <>{variableText}</>;
        default:
          return;
      }
    };

    return (
      <div className="form-header">
        <h3 className={`form-header__headline ${colorHeadline ? colorHeadline : null}`}>{headline}</h3>
        <p className={`form-header__infotext ${colorBody ? colorBody : null}`}>
          {infotext_1}
          {showVariableText(vaiableTextVals)}
          {infotext_2}
        </p>
      </div>
    );
  }
}

FormHeader.propTypes = {
  headline: PropTypes.string,
  infotext_1: PropTypes.string,
  infotext_2: PropTypes.string,
  variableText: PropTypes.string,
  isMail: PropTypes.bool,
  isLink: PropTypes.bool,
  isText: PropTypes.bool,
  color: PropTypes.string
};

export default FormHeader;
