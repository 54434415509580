import React from 'react';
import EpisodeIllu from './EpisodeIllu';
import Episode from './Episode';
import { TEXT } from 'locales/translations';

const EpisodeSection = () => {
  return (
    <section id="episode-section-container">
      <h2>{TEXT.podcast.episode_section.headline}</h2>
      <h3>{TEXT.podcast.episode_section.subline}</h3>
      <EpisodeIllu />
      <div id="bottom-container">
        <Episode
          episode={TEXT.podcast.episode_section.episode_1.episode}
          headline={TEXT.podcast.episode_section.episode_1.headline}
          shortDescription={TEXT.podcast.episode_section.episode_1.short_description}
          description={TEXT.podcast.episode_section.episode_1.description}
          linkSpotify={TEXT.podcast.episode_section.episode_1.link_spotify_url}
          linkITunes={TEXT.podcast.episode_section.episode_1.link_itunes_url}
        />
        <Episode
          episode={TEXT.podcast.episode_section.episode_3.episode}
          headline={TEXT.podcast.episode_section.episode_3.headline}
          shortDescription={TEXT.podcast.episode_section.episode_3.short_description}
          description={TEXT.podcast.episode_section.episode_3.description}
          linkSpotify={TEXT.podcast.episode_section.episode_3.link_spotify_url}
          linkITunes={TEXT.podcast.episode_section.episode_3.link_itunes_url}
        />
        <Episode
          episode={TEXT.podcast.episode_section.episode_33.episode}
          headline={TEXT.podcast.episode_section.episode_33.headline}
          shortDescription={TEXT.podcast.episode_section.episode_33.short_description}
          description={TEXT.podcast.episode_section.episode_33.description}
          linkSpotify={TEXT.podcast.episode_section.episode_33.link_spotify_url}
          linkITunes={TEXT.podcast.episode_section.episode_33.link_itunes_url}
        />
      </div>
    </section>
  );
};
export default EpisodeSection;
