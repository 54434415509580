import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TEXT } from 'locales/translations';

class CallToActionKaifu extends Component {
  render() {
    return (
      <div>
        <Link className="btn btn-secondary btn-special" to={'/buy?coupon=KAIFU'}>
          {TEXT.kaifuHeroCard.button}
        </Link>
      </div>
    );
  }
}

export default CallToActionKaifu;
