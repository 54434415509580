import styled from 'styled-components';
import { asRem } from 'utils';
import { colors } from 'Theme';
import { Modal } from 'Component';

export const DownloadTrigger = styled.div`
  margin: 0 ${asRem(8)};
  border-top: 1px solid ${colors.grey.c100};
  border-bottom: 1px solid ${colors.grey.c100};

  .icon {
    height: ${asRem(28)};
    width: ${asRem(28)};
  }
`;

export const DownloadModal = styled(Modal)`
  .modal-body {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${asRem(300)};
  }

  .image {
    margin: ${asRem(24)} 0 ${asRem(36)};
    width: ${asRem(188)};
    height: ${asRem(129)};
  }

  .mime-elements-headline-2 {
    text-align: center;
  }
`;
