import React, { Component, Fragment } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HappyCloudBalloon } from 'components/images';
import { DateTimePresenter } from 'Common';
import { MainPage } from 'Container';
import { getRequestCancellation } from 'redux/reducers';
import downloadIcon from 'images/terminate/icn-download.svg';
import { Col, Container, Link, Row } from 'Common';

class TerminateUserAboSuccess extends Component {
  render() {
    const { requestCancellation } = this.props;

    return (
      <MainPage bodyClassName="main-decorated" className="terminate-user-abo-success">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
            <Container>
              <Row>
                <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                  <div className="card-terminate card-email card mb-5 pb-5 px-5">
                    <Row className="mb-3">
                      <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} className="text-center">
                        <h2 className="card-title">Kündigung erhalten</h2>
                      </Col>
                      <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                        <div className="newsletter-disclaimer text-center">
                          Wir haben deinen Kündigungswunsch am <DateTimePresenter date={requestCancellation.receivedDate} /> Uhr erhalten
                          und bestätigen dir den Eingang. Wir kümmern uns umgehend darum, ihn zu bearbeiten. Du kannst deine
                          Eingangs-Bestätigung hier herunterladen - wir haben sie dir zudem per E-Mail an{' '}
                          <strong>{requestCancellation.email}</strong> geschickt.
                          <br />
                          Solltest du Rückfragen haben, erreichst du unseren Kundensupport{' '}
                          <Link href="mailto:info@balloonapp.de">
                            <strong>info@balloonapp.de</strong>
                          </Link>
                          .{' '}
                        </div>
                      </Col>
                    </Row>
                    <Fragment>
                      <FormGroup row>
                        <Col sm={{ size: 12 }} className="mt-2">
                          <a
                            className="btn btn-green-c500 font-weight-bold mb-4 d-flex justify-content-center align-items-center btn-download-pdf cta-download-pdf"
                            href={requestCancellation.pdfUrl}
                          >
                            <img className="d-block btn-download-pdf" src={downloadIcon} alt="download" />
                            <span className="d-block btn-download-pdf">Eingangsbestätigung herunterladen</span>
                          </a>
                        </Col>
                      </FormGroup>
                    </Fragment>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <HappyCloudBalloon className="ad-tile__illustration mt-2" />
          </Col>
        </Row>
      </MainPage>
    );
  }
}

TerminateUserAboSuccess.propTypes = {
  requestCancellation: PropTypes.object
};

const mapStateToProps = (state) => ({
  requestCancellation: getRequestCancellation(state)
});

export default connect(mapStateToProps, {})(TerminateUserAboSuccess);
