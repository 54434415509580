import React, { useEffect, useState } from 'react';
import { isSubscriptionPlansLoading } from 'redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Form, Col, Row } from 'Common';
// ToDo: replace with mime_elements component
import { Button } from 'reactstrap';
import qs from 'query-string';
import { TEXT } from 'locales/translations';
import { TextField } from 'Component';
import { addFlash, getSubscriptionPlansRequest } from 'redux/actions';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';

// superdeal1234
export const CheckoutCouponForm = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSubscriptionPlansLoading);
  const [coupon, setCoupon] = useState('');
  const [hasUrlCoupon, setHasUrlCoupon] = useState(false);

  useEffect(() => {
    const { coupon } = qs.parse(location.search);
    coupon && setCoupon(coupon);
    setHasUrlCoupon(!!coupon);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const handleOnShowCouponForm = () => {
    setCoupon('');
    setHasUrlCoupon(false);
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();

    if (!coupon.length) {
      dispatch(addFlash('Bitte gib den Rabatt-Coupon ein.', 'danger', 'NO_COUPON_CODE'));
      return;
    }

    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL, coupon));
  };

  if (hasUrlCoupon) {
    return (
      <Row>
        <Col>
          <Button color="link" onClick={handleOnShowCouponForm} className="coupon-link">
            {TEXT.plans.couponQuestion}
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Form onSubmit={handleOnSubmit} className="coupon-row">
      <div className="form-container">
        <Col className="flex-grow-1">
          <TextField
            dataCyName="coupon-code-field"
            placeholder="Rabatt-Coupon"
            className="form-coupon-text-field"
            showLabel={false}
            showRequiredHint={false}
            onChange={setCoupon}
          />
        </Col>
        <Button className="btn-promo" type="submit" data-cy-name="submit-coupon-code">
          <span className="d-none d-sm-block">{TEXT.plans.redeem}</span>
          <span className="d-sm-none">{TEXT.plans.redeemShort}</span>
        </Button>
      </div>
    </Form>
  );
};
