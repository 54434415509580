import * as React from 'react';
import PropTypes from 'prop-types';
import {
  isAppleTokenError,
  isGoogleMailError,
  isGoogleMailAlreadyExistErrorSocial,
  isAppleMailAlreadyExistErrorSocial,
  isPasswordAuthExpected
} from 'redux/query';
import { useSelector } from 'react-redux';
import { TEXT } from 'locales/translations';

const ErrorComponent = ({ isRegister }) => {
  const hasPasswordAuthExpected = useSelector(isPasswordAuthExpected);
  const hasAppleMailAlreadyExistErrorSocial = useSelector(isAppleMailAlreadyExistErrorSocial);
  const googleMailAlreadyExistErrorSocial = useSelector(isGoogleMailAlreadyExistErrorSocial);
  const hasAppleTokenError = useSelector(isAppleTokenError);
  const hasGoogleMailError = useSelector(isGoogleMailError);

  if (hasPasswordAuthExpected) {
    return (
      <div className="fb-error">
        <span>{TEXT.registration.alreadyTakenSocialError}</span>
      </div>
    );
  }

  if (googleMailAlreadyExistErrorSocial) {
    return (
      <div className="fb-error">
        <span>{TEXT.registration.alreadyTakenGoogleError}</span>
        <a href="/login">{TEXT.login.cta_google_login}</a>
      </div>
    );
  }

  if (hasAppleMailAlreadyExistErrorSocial) {
    return (
      <div className="fb-error">
        <span>{TEXT.registration.alreadyTakenAppleError}</span>
        <a href="/login">{TEXT.login.ctaAppleLogin}</a>
      </div>
    );
  }

  if (hasAppleTokenError) {
    return (
      <div className="fb-error error-apple-siginin">
        <span>{TEXT.registration.alreadyTokenUsedApple}</span>
      </div>
    );
  }

  if (hasGoogleMailError) {
    return (
      <div className="fb-error">
        <span>{TEXT.login.googleEmailUsedError}</span>
      </div>
    );
  }

  return null;
};

ErrorComponent.propTypes = {
  isRegister: PropTypes.bool.isRequired
};

export default ErrorComponent;
