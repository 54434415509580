import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TEXT } from 'locales/translations';
import { Headline, Image, Paragraph, ScrollButton } from 'Common';
import Style from './ResilienceIntro.style';

import balloonieHeart from 'images/landingpages/resilience/balloonie-heart.png';

export function ResilienceIntro() {
  return (
    <Style type="full-width" backgroundColor="lightGrey">
      <Container>
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 6 }}>
            <Paragraph>{TEXT.resiliencePage.intro.text_1}</Paragraph>
            <Paragraph>{TEXT.resiliencePage.intro.text_2}</Paragraph>
            <Paragraph>{TEXT.resiliencePage.intro.text_3}</Paragraph>
          </Col>
          <Col xs={{ size: 12 }} md={{ size: 6 }}>
            <div className="d-flex flex-column align-items-center">
              <Headline level={2} className="text-center mb-2 mb-md-4">
                {TEXT.resiliencePage.intro.headline}
              </Headline>
              <ScrollButton className="btn mb-5" to="section-checkout">
                {TEXT.resiliencePage.cta}
              </ScrollButton>
              <Image className="image mt-auto" src={balloonieHeart} alt="Balloon mit Herz" />
            </div>
          </Col>
        </Row>
      </Container>
    </Style>
  );
}
