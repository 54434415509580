import React from 'react';
import { Headline, Paragraph } from 'Common';
import Style from './LoginDescription.style';
import indifferentImg from 'images/authentication/indifferent.png';
import { TEXT } from 'locales/translations';
import { AppStoreBadge, GooglePlayBadge } from 'components';
import { Row } from 'reactstrap';

export const LoginDescription = () => {
  return (
    <Style>
      <div className="container">
        <Headline level={3} className="title text-center" textColor={'white'}>
          {TEXT.login.descriptionTitle}
        </Headline>
        <Paragraph className="small description text-center mb-4">{TEXT.login.description}</Paragraph>
        <Row className="app-badges w-100 mb-5">
          <AppStoreBadge />
          <GooglePlayBadge />
        </Row>
        <img src={indifferentImg} alt={TEXT.login.descriptionImageAlt} className="indifferent-img" />
      </div>
    </Style>
  );
};
