import Style from './SepaDebit.style';
import sepaDebitImage from 'images/checkout/img-sepa.svg';

export const SepaDebit = ({ sepaDebit }) => {
  return (
    <Style data-cy-name="user-payment-method-sepa-debit">
      <img src={sepaDebitImage} alt="Logo SEPA Debit" className="logo" />
      {sepaDebit?.bankCode && (
        <div className="card-row">
          <div>BLZ</div>
          <div>{sepaDebit?.bankCode}</div>
        </div>
      )}
      {sepaDebit?.last4 && (
        <div className="card-row">
          <div>IBAN:</div>
          <div>****{sepaDebit?.last4}</div>
        </div>
      )}
    </Style>
  );
};
