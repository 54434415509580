import React from 'react';
import { FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { Control, Errors } from 'react-redux-form';

export default function BkkTermsField() {
  return (
    <FormGroup className="pb-4 ps-0" check inline>
      <Control.checkbox
        component={Input}
        defaultValue={false}
        mapProps={{
          valid: ({ fieldValue: { touched, valid } }) => (touched && !valid ? false : undefined)
        }}
        model=".agreed"
        id="agreed"
        required={true}
      />
      <Label check for="agreed" className="letter-spacing-04">
        Hiermit bestätige ich, dass ich Versicherte/r der Bertelsmann BKK bin. Ich bin damit einverstanden, dass die Gruner + Jahr
        Deutschland GmbH Vor- und Nachnamen zum Abgleich an die Bertelsmann BKK übergibt, um unberechtigte Zugriffe zu unterbinden.
      </Label>
      <FormFeedback>
        <Errors
          model=".agreed"
          show={(field) => !field.pristine || field.touched}
          messages={{
            valueMissing: 'Du musst dem Datenabgleich zustimmen.'
          }}
        />
      </FormFeedback>
    </FormGroup>
  );
}
