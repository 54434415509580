import { useSelector } from 'react-redux';
import { getCurrentUser, isAuthed, isGuest } from 'redux/query';

export const useInsuranceFormType = ({ b2bClientKey }) => {
  const user = useSelector(getCurrentUser);
  const isAuthedUser = useSelector(isAuthed);
  const isGuestUser = useSelector(isGuest);

  if (isAuthedUser && !(user?.b2bClientKeys || []).includes(b2bClientKey)) {
    return 'RegisterFormView';
  }
  if (!isAuthedUser && !isGuestUser) {
    return 'OtpView';
  }
  return 'SuccessView';
};
