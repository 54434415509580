import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'Common';
import { Form } from 'react-redux-form';
import { GiftCouponField } from 'components';

GiftCouponForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string
};

export default function GiftCouponForm({ onSubmit, defaultValue }) {
  return (
    <Form className="giftcoupon-form pb-4" model="entities.forms.giftCoupon" hideNativeErrors onSubmit={onSubmit}>
      <Row className="">
        <Col>
          <GiftCouponField
            showRequiredHint={false}
            id="giftcouponcode"
            placeholder="Gutschein-Code"
            messages={{
              validLength: 'Sicher wird der Gutschein-Code nicht soo lang sein? 😉',
              valueMissing: 'Bitte gib den Gutschein-Code ein.'
            }}
            defaultValue={defaultValue}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button className="btn-promo w-100" dataCyName="redeem-gift-voucher-button">
            GUTSCHEIN EINLÖSEN
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
