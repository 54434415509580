import { requestApi } from 'utils';

export const getAlerts = () =>
  requestApi.query(`
  query GetAlerts {
    alerts {
      id
      severity
      subject
      webUrl
      webUrlTitle
      webMessage
      __typename
    }
  }
`);
