import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PlayerListItem extends Component {
  render() {
    const { state, title, time, process, selectUnit } = this.props;
    let minutes = Math.floor(time / 60);
    if (minutes > 59) {
      minutes = Math.floor(time / 60) - 60;
    }

    let seconds = Math.round(time - minutes * 60);
    if (seconds > 3599) {
      seconds = Math.round(time - minutes * 60) - 3600;
    }

    if (seconds === 60) {
      minutes += 1;
      seconds = 0;
    }

    let runtime = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    return (
      <div onClick={selectUnit} className={`player-list-item player-list-item--${state}`}>
        <div className="player-list-item-content">
          <div className="player-list-item-content__title">{title}</div>
          {time ? <div className="player-list-item-content__time">{runtime} Min</div> : ''}
          <div className="player-list-item-content__progress-bar" style={{ width: process + '%' }} />
        </div>
      </div>
    );
  }
}

PlayerListItem.propTypes = {
  state: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.number,
  process: PropTypes.number,
  selectUnit: PropTypes.func.isRequired
};

PlayerListItem.defaultProps = {
  process: 0
};

export default PlayerListItem;
