// Based on
// https://github.com/Javascript-Ninja/react-router-prop-types/blob/master/src/index.js

import PropTypes from 'prop-types';

export const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string, // only in createBrowserHistory and createMemoryHistory
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.number, PropTypes.object, PropTypes.string]) // only in createBrowserHistory and createMemoryHistory
});

export const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func, // only in createMemoryHistory
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location), // only in createMemoryHistory
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number, // only in createMemoryHistory
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
});

export const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

const childRoute = PropTypes.shape({
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.element.isRequired,
  routes: PropTypes.any
});

export const route = PropTypes.shape({
  component: PropTypes.element,
  routes: PropTypes.arrayOf(childRoute)
});

export default {
  location,
  history,
  match,
  route
};
