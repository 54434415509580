import React from 'react';
import PropTypes from 'prop-types';

import Flow1x from 'images/trust_band/flow.png';
import Flow2x from 'images/trust_band/flow@2x.png';
import Flow3x from 'images/trust_band/flow@3x.png';

import FlowSw1x from 'images/home/flow.png';
import FlowSw2x from 'images/home/flow@2x.png';
import FlowSw3x from 'images/home/flow@3x.png';

import DecorativeImage from '../../components/DecorativeImage';

FlowLogo.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  isColored: PropTypes.bool
};

export default function FlowLogo({ className, sizes, isColored }) {
  return (
    <DecorativeImage
      className={className}
      srcSet={`${isColored ? Flow1x : FlowSw1x} 95w, ${isColored ? Flow2x : FlowSw2x} 190w, ${isColored ? Flow3x : FlowSw3x} 285w`}
      sizes={sizes}
      src={Flow1x}
    />
  );
}
