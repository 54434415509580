export const compareRouteWithPaths = (compareRoute, route) => {
  compareRoute = compareRoute.split('?').shift().toLowerCase();
  route = route.split('?').shift().toLowerCase();

  const routeSegments = `${route}`.split('/').filter((segment) => segment.trim() !== '');
  const compareSegments = `${compareRoute}`.split('/').filter((segment) => segment.trim() !== '');

  if (compareSegments.length === 0 && routeSegments.length > 0) {
    return false;
  }

  for (let i = 0, l = compareSegments.length; i < l; i++) {
    const routeSegment = routeSegments[i];
    if (!routeSegment) {
      return false;
    }

    if (routeSegment.match(/^\*$/)) {
      return true;
    }
    if (routeSegment !== compareSegments[i] && !routeSegment.startsWith(':')) {
      return false;
    }
  }

  return compareSegments.length === routeSegments.length;
};
