import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from 'redux/actions';
import { withUser } from 'containers/withUser';
import withSpinner from 'components/withSpinner';
import { ProfileNavigationHeader } from 'components';
import { hasSubscription, getUserPaymentDetails, getCurrentUser, getUserFirstname } from 'redux/query';

const ProfileNavigationHeaderContainer = (props) => {
  return <ProfileNavigationHeader {...props} />;
};

ProfileNavigationHeaderContainer.propTypes = {
  userName: PropTypes.string,
  subscriptionTitle: PropTypes.string
};

export const mapStateToProps = (state) => {
  const uid = getCurrentUser(state)?.id;

  const hasUserSubscription = hasSubscription(state);
  const { provider, product } = getUserPaymentDetails(state);

  const origin = hasUserSubscription ? provider : undefined;

  return {
    userName: getUserFirstname(state),
    userId: uid,
    hasUserSubscription,
    origin,
    subscriptionTitle: hasUserSubscription ? product : 'Kein Abo'
  };
};

export default withUser(withSpinner(connect(mapStateToProps, { getUser })(ProfileNavigationHeaderContainer)));
