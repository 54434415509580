import styled from 'styled-components';
import { colors } from 'Theme';

export const NavLinkStyle = styled.div`
  .nav-link {
    color: ${colors.white.c500};

    &:focus,
    &:hover {
      // use underline instead of outline
      // for highlight. btn-link has this by
      // default, but <a> does not.
      outline: none;
      color: ${colors.white.o60};
      transition: color 0.15s ease-in-out;
      text-decoration: underline;
    }
  }
`;
