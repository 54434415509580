import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthed, isGuest } from 'redux/query';
import { PrivateRoute } from 'components';

class PrivateRouteContainer extends Component {
  render() {
    return <PrivateRoute {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthed: isAuthed(state),
  isGuest: isGuest(state)
});

export default connect(mapStateToProps)(PrivateRouteContainer);
