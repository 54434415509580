import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as routerPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InvoicesContent } from 'components';
import { getInvoicesForUser, isFetchingInvoices } from 'redux/reducers';
import { isAuthed, getToken } from 'redux/query';
import { getInvoices } from 'redux/actions';

class InvoicesContentContainer extends Component {
  componentDidMount() {
    const { isAuthed } = this.props;
    if (isAuthed) {
      this.props.getInvoices();
    }
  }

  render() {
    return <InvoicesContent {...this.props} />;
  }
}

InvoicesContentContainer.propTypes = {
  getInvoices: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  token: PropTypes.string,
  isAuthed: PropTypes.bool.isRequired,
  location: routerPropTypes.location,
  history: routerPropTypes.history,
  match: routerPropTypes.match.isRequired
};

const mapStateToProps = (state) => ({
  getInvoices: PropTypes.func.isRequired,
  isFetching: isFetchingInvoices(state),
  invoices: getInvoicesForUser(state),
  token: getToken(state),
  isAuthed: isAuthed(state)
});

export default withRouter(connect(mapStateToProps, { getInvoices })(InvoicesContentContainer));
