import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/newContent';
import { GET_NEW_CONTENT_PENDING, GET_NEW_CONTENT_FAILURE, GET_NEW_CONTENT_SUCCESS } from 'redux/actions/actionTypes';

export const gettingNewContent = () => ({
  type: GET_NEW_CONTENT_PENDING
});

export const getNewContentFailure = (error) => ({
  type: GET_NEW_CONTENT_FAILURE,
  error
});

export const getNewContentSuccess = (data) => ({
  type: GET_NEW_CONTENT_SUCCESS,
  payload: data
});

export const getNewContent = () => (dispatch) => {
  dispatch(gettingNewContent());
  return api.getNewContent().then(
    (response) => {
      if (response.data.errors) {
        dispatch(getNewContentFailure(response.data.errors));
      } else {
        dispatch(
          getNewContentSuccess(
            normalize(response.data.data.newContent, {
              new_content: [schema.newContent]
            })
          )
        );
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(getNewContentFailure(error));
      });
    }
  );
};
