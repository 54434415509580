import { requestApi } from 'utils';

const paymentMethodFragment = `
  paymentMethod {
    __typename
    ... on StripeCardPaymentMethod {
      type
      card {
        last4
        expYear
        expMonth
        brand
        country
      }
    }
    ... on StripeSepaDebitPaymentMethod {
      type
      sepaDebit {
        last4
        bankCode
        country
      }
    }
    ... on StripePaypalPaymentMethod {
      type
      paypal {
        payerEmail
      }
    }
  }
`;

// Todo: add StripePayPalPaymentMethod.paypal.payerName

export let getPayableInfosWithRenewalInfoApiRequest = () =>
  requestApi.query(`
  query GetPayableInfosWithRenewalInfo {
    payableInfos {
      payableId
      payableType
      provider
      interval
      product
      startsAt
      endsAt
      canceled
      cancelationWithdrawable
      active
      renewalInfo {
        ${paymentMethodFragment}
        address {
          name
          street
          country
          city
          zipCode
        }
      }
      paymentDetails {
        ${paymentMethodFragment}
        address {
            name
            street
            country
            city
            zipCode
        }
      }
    }
  }
`);
