import styled from 'styled-components';
import { colors, breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const OfferStyle = styled(Section)`
  align-items: center;
  justify-content: center;
  position: relative;

  .mime-elements-card {
    .image {
      aspect-ratio: 1 / 1;
      width: ${asRem(64)};

      @media (min-width: ${breakpoints.md}) {
        width: ${asRem(82)};
      }
    }
  }

  .landscape {
    bottom: 5%;
    position: absolute;

    &-left {
      aspect-ratio: 247 / 307;
      height: ${asRem(307)};
      width: ${asRem(247)};
      left: 0;
      z-index: -1;
    }

    &-right {
      aspect-ratio: 518/892;
      height: ${asRem(445)};
      width: ${asRem(258)};
      right: 0;
      z-index: -1;
    }
  }

  .hashlink {
    color: ${colors.purple.c500};
    :hover,
    :focus {
      color: ${colors.white.c500};
    }
  }
`;
