export const CONFIRM_PAYMENT_METHOD_REQUEST = 'CONFIRM_PAYMENT_METHOD_REQUEST';
export const CONFIRM_PAYMENT_METHOD_SUCCESS = 'CONFIRM_PAYMENT_METHOD_SUCCESS';
export const CONFIRM_PAYMENT_METHOD_FAILURE = 'CONFIRM_PAYMENT_METHOD_FAILURE';

export const confirmPaymentMethodRequest = ({ confirmPayment }) => ({
  type: CONFIRM_PAYMENT_METHOD_REQUEST,
  payload: { confirmPayment }
});

export const confirmPaymentMethodSuccess = ({ setupIntent, paymentMethodAutoAssigned = false }) => ({
  type: CONFIRM_PAYMENT_METHOD_SUCCESS,
  payload: {
    setupIntent,
    paymentMethodAutoAssigned
  }
});

export const confirmPaymentMethodFailure = (error) => ({
  type: CONFIRM_PAYMENT_METHOD_FAILURE,
  error
});
