import { put } from 'redux-saga/effects';
import { stripeSetupIntentSuccess, stripeSetupIntentFailure } from 'redux/actions';
import { createStripeSetupIntentApiRequest } from 'api/balloon';

export function* stripeSetupIntentSaga() {
  try {
    const { data: response } = yield createStripeSetupIntentApiRequest();

    if (response.errors) {
      yield put(stripeSetupIntentFailure(response.errors));
      return;
    }

    yield put(stripeSetupIntentSuccess(response.data.createStripeSetupIntent));
  } catch (error) {
    yield put(stripeSetupIntentFailure(error));
  }
}
