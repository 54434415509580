import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import { actions } from 'react-redux-form';

import * as api from 'api/balloon/requestCancellation';

import {
  CREATE_REQUEST_CANCELLATION_PENDING,
  CREATE_REQUEST_CANCELLATION_FAILURE,
  CREATE_REQUEST_CANCELLATION_SUCCESS
} from 'redux/actions/actionTypes';

export const createRequestCancellationPending = () => ({
  type: CREATE_REQUEST_CANCELLATION_PENDING
});

export const createRequestCancellationFailure = () => ({
  type: CREATE_REQUEST_CANCELLATION_FAILURE
});

export const createRequestCancellationSuccess = (payload) => ({
  type: CREATE_REQUEST_CANCELLATION_SUCCESS,
  payload
});

export const updateFormValidation = (formModel, validationObject) => {
  return (dispatch) => {
    dispatch(actions.setValidity(formModel, validationObject));
  };
};
export const updateFormErrors = (formModel, errorObject) => {
  return (dispatch) => {
    dispatch(actions.setErrors(formModel, errorObject));
  };
};

export const createRequestCancellation =
  ({ cancellationType, product, cancellationDateType, requestedCancellationAt, email, cancellationReason }) =>
  (dispatch) => {
    dispatch(createRequestCancellationPending());
    return api
      .createRequestCancellation({
        cancellationType,
        product,
        cancellationDateType,
        requestedCancellationAt,
        email,
        cancellationReason
      })
      .then(
        (response) => {
          if (response.data.errors) {
            dispatch(createRequestCancellationFailure(response.data.errors));
          } else {
            dispatch(
              createRequestCancellationSuccess(
                normalize(response.data.data.requestCancellation, {
                  requestCancellation: schema.requestCancellation
                })
              )
            );
          }
        },
        (error) => {
          handleAxiosError(error, () => {
            dispatch(createRequestCancellationFailure(error));
          });
          throw error;
        }
      );
  };
