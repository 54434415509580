import { put } from 'redux-saga/effects';
import { getUserProrationPriceSuccess, getUserProrationPriceFailure } from 'redux/actions';
import { getPreviewingProrationApiRequest } from 'api/balloon';

export function* getUserProrationPriceSaga({ payload }) {
  const { subscriptionPlanId } = payload;
  try {
    const { data: response } = yield getPreviewingProrationApiRequest({ subscriptionPlanId });

    if (response.errors) {
      yield put(getUserProrationPriceFailure(response.errors));
      return;
    }
    yield put(
      getUserProrationPriceSuccess({
        reducedPrice: response.data.previewingProration[0].reducedPrice
      })
    );
  } catch (error) {
    yield put(getUserProrationPriceFailure(error));
  }
}
