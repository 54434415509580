import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DatePresenter } from 'Common';

export const PeriodPresenter = ({ start, end }) => {
  return (
    <Fragment>
      <DatePresenter date={start} /> – <DatePresenter date={end} />
    </Fragment>
  );
};

PeriodPresenter.propTypes = {
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  end: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date), PropTypes.string]).isRequired
};
