import styled from 'styled-components';
import { spacing, colors } from 'components/Theme';
import { asRem } from 'utils';

export const FormB2BContactStyle = styled.div`
  width: 100%;

  .whitepaper-form {
    padding-top: ${asRem(3)};

    display: flex;
    flex-direction: column;

    div.mb-3 {
      width: 100%;
    }

    div input::placeholder,
    div textarea::placeholder {
      color: ${colors.blueGrey.c500};
    }

    select.form-select {
      padding-left: ${asRem(spacing.two)};
      color: ${colors.blueGrey.c500};
    }

    .invalid-feedback {
      font-size: 0.875em;
      font-weight: 400;
      margin-left: 2px;
    }

    .fb-error {
      text-align: left;
      font-size: 0.875em;
      margin: -14px 0 15px 2px;
    }

    .form-check-inline {
      margin: 0 0 2rem;
      padding: 0;
    }

    a {
      color: ${colors.blueGrey.c500};
      text-decoration: underline !important;
    }
  }
`;
