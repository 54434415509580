import React from 'react';
import LandingpageTopBlueLine from './../../LandingpageTopBlueLine';
import LandingpageBottomBlueLine from './../../LandingpageBottomBlueLine';
import PropTypes from 'prop-types';
import { Link } from 'Common';
import { ThemeContainer } from 'Container';
import { themeCalm } from 'Theme';

const BallooniSectionXing = ({ headline, partnerName, duration, ballooni_first, ballooni_last, bookmark }) => {
  return (
    <section id="ballooni-section-container">
      <ThemeContainer theme={themeCalm}>
        <LandingpageTopBlueLine severalClass="ballooni-top" />
        <h1>{headline}</h1>
        <div id="bottom-container">
          <section className="ballooni-container">
            <div className="ballooni1-image" />
            <p>{ballooni_first}</p>
          </section>
          <section className="ballooni-container">
            <div className="ballooni2-image" />
            <p>
              Du kannst dich dann{' '}
              <Link href={bookmark + '#form-section-container'} color="white">
                hier
              </Link>{' '}
              mit diesem Code registrieren und das Kurs-Paket für {duration} Monate freischalten.
            </p>
          </section>
          <section className="ballooni-container">
            <div className="ballooni3-image" />
            <p>{ballooni_last}</p>
          </section>
        </div>
        <LandingpageBottomBlueLine severalClass="ballooni-bottom" />
      </ThemeContainer>
    </section>
  );
};

BallooniSectionXing.propTypes = {
  headline: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  ballooni_first: PropTypes.object.isRequired,
  ballooni_last: PropTypes.object.isRequired,
  bookmark: PropTypes.string.isRequired
};

export default BallooniSectionXing;
