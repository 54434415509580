import React, { Component, Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Button, Col, Container, Link, Row, Spinner } from 'Common';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import moment from 'moment';
import { createRequestCancellation, getSubscriptionPlansRequest, updateFormErrors, updateFormValidation } from 'redux/actions';
import { HappyCloudBalloon } from 'components/images';
import { subscriptionPlans } from 'utils/propTypes';
import { DateField, EmailTextField, SelectField, TextField } from 'components';
import { MainPage } from 'Container';
import { getCurrentUserEmail } from 'redux/reducers';
import { getSubscriptionPlans, isGuest, isSubscriptionPlansLoading } from 'redux/query';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';

class TerminateUserAbo extends Component {
  componentDidMount() {
    this.props.getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cancellationTypeValue && prevProps.cancellationTypeValue !== this.props.cancellationTypeValue) {
      if (this.props.cancellationTypeValue.includes('REASONED')) {
        this.props.updateFormValidation('entities.forms.terminateUserAbo.cancellationReason', { valueMissing: false });
        this.props.updateFormErrors('entities.forms.terminateUserAbo.cancellationReason', { valueMissing: true });
      } else {
        this.props.updateFormValidation('entities.forms.terminateUserAbo.cancellationReason', {});
        this.props.updateFormErrors('entities.forms.terminateUserAbo.cancellationReason', {});
      }
    }
  }

  handleSubmit(terminateUserAbo) {
    const { createRequestCancellation } = this.props;

    let params = {
      cancellationType: terminateUserAbo.cancellationType,
      product: terminateUserAbo.product,
      requestedCancellationAt: terminateUserAbo.requestedCancellationAt,
      email: terminateUserAbo.email,
      cancellationReason: terminateUserAbo.cancellationReason
    };

    if (terminateUserAbo.cancellationDateType) {
      params.cancellationDateType = terminateUserAbo.cancellationDateType;
    }
    createRequestCancellation(params);
  }

  render() {
    const { email, plans, isFetching, cancellationDateTypeValue, cancellationTypeValue, isGuest } = this.props;

    const productOptions = [{ value: '', name: 'bitte auswählen' }];

    const emailInputValue = isGuest ? '' : email ? email : '';

    plans.forEach((plan) => {
      if (plan.interval !== 'ONETIME' && !plan.zppCertified) {
        productOptions.push({
          value: plan.backendIntervalNaming.toUpperCase(),
          name: plan.title
        });
      }
    });

    const cancellationTypeOptions = [
      {
        value: 'ON_TIME',
        name: 'Fristgemäße Kündigung'
      },
      { value: 'REASONED', name: 'aus wichtigem Grund' }
    ];

    const cancellationDateTypeOptions = [
      {
        name: 'bitte auswählen',
        value: 'default'
      },
      {
        name: 'Vor nächster Rechnung',
        value: 'END_OF_TERM'
      },
      {
        name: 'Frühestmöglich',
        value: 'EARLIEST'
      },
      {
        name: 'Wunschdatum wählen',
        value: 'REQUESTED'
      }
    ];

    return (
      <MainPage bodyClassName="main-decorated" className="terminate-user-abo">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
            <Container>
              <Row>
                <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                  <div className="card-terminate card-email card mb-5 pb-5 px-5">
                    <Row>
                      <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                        <h2 className="card-title">Dein Kündigungswunsch</h2>
                      </Col>
                      <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                        <div className="newsletter-disclaimer text-start">
                          Wir bedauern sehr, dass Du Dein Abo kündigen möchtest. Wenn wir dir irgendwie weiterhelfen können oder es etwas
                          gibt, dass dich zum Bleiben überzeugen würde, schreibe uns doch gerne eine E-Mail an{' '}
                          <Link href="mailto:info@balloonapp.de">info@balloonapp.de</Link> mit dem Betreff „meine Kündigung“ und lass uns
                          wissen, was wir tun können.{' '}
                        </div>
                      </Col>
                      <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                        <div className="pb-4 newsletter-disclaimer text-start">
                          Bitte beachte: Wenn du dein Abo im Appstore oder Playstore abgeschlossen hast, musst du es dort kündigen. Hier
                          kannst du nur Abos kündigen, die du über unsere Website gekauft hast.
                        </div>
                      </Col>
                    </Row>
                    {isFetching ? (
                      <div className="w-100 d-flex justify-content-center">
                        <Spinner className="m-0" />
                      </div>
                    ) : (
                      <Fragment>
                        <Form
                          model="entities.forms.terminateUserAbo"
                          hideNativeErrors
                          onSubmit={this.handleSubmit.bind(this)}
                          className="terminate-user-abo-form"
                        >
                          <FormGroup row>
                            <Label for="created_at" sm={3}>
                              Eingang des Kündigungwunsches:
                            </Label>
                            <Col sm={9}>
                              <input id={'created_at'} disabled={true} value={'DD.MM.JJJJ - HH:MM'} className="form-control mb-2" />
                              <p className="date-hint">Datum und Uhrzeit werden automatisch bei Absenden eingetragen.</p>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="cancellation_type" sm={3}>
                              Art der Kündigung:
                            </Label>
                            <Col sm={9}>
                              <SelectField
                                fieldId={'cancellation_type'}
                                model={'.cancellationType'}
                                showLabel={false}
                                label={'Art der Kündigung:'}
                                defaultValue={'ON_TIME'}
                                showRequiredHint={false}
                                options={cancellationTypeOptions}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="cancellation_type" sm={3}>
                              Zu kündigendes Abonnement:
                            </Label>
                            <Col sm={9}>
                              <SelectField
                                fieldId={'product'}
                                model={'.product'}
                                showLabel={false}
                                required={true}
                                label={'Zu kündigendes Abonnement:'}
                                showRequiredHint={false}
                                options={productOptions}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="cancellation_date_type" sm={3}>
                              Gewünschter Kündigungszeitpunkt:
                            </Label>
                            <Col sm={cancellationDateTypeValue === 'REQUESTED' ? 5 : 9}>
                              <SelectField
                                fieldId={'cancellation_date_type'}
                                model={'.cancellationDateType'}
                                showLabel={false}
                                required={false}
                                label={'Gewünschter Kündigungszeitpunkt:'}
                                showRequiredHint={false}
                                options={cancellationDateTypeOptions}
                              />
                            </Col>
                            {cancellationDateTypeValue === 'REQUESTED' && (
                              <Col sm={4}>
                                <DateField
                                  fieldId={'requested_cancellation_at'}
                                  model={'.requestedCancellationAt'}
                                  showLabel={false}
                                  defaultValue={moment().format('YYYY-MM-DD')}
                                />
                              </Col>
                            )}
                          </FormGroup>
                          <FormGroup row>
                            <Label for="email" sm={3}>
                              Deine E-Mail-Adresse*:
                            </Label>
                            <Col sm={9}>
                              <EmailTextField
                                fieldId={'email'}
                                model={'.email'}
                                showLabel={false}
                                defaultValue={emailInputValue}
                                label={'Deine E-Mail-Adresse*:'}
                                placeholder={'Deine E-Mail-Adresse*'}
                                showRequiredHint={false}
                              />

                              <p className="date-hint">
                                * Bitte gib hier die E-Mail-Adresse an mit der du deinen Zugang gekauft hast, so dass wir deine Kündigung
                                richtig zuordnen können. An diese E-Mail-Adresse senden wir ebenfalls deine Kündigungsbestätigung.
                              </p>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="cancellation_reason" sm={3}>
                              Kündigungsgrund:
                            </Label>
                            <Col sm={9}>
                              <TextField
                                id={'cancellation_reason'}
                                type={'textarea'}
                                defaultValue={''}
                                model={'.cancellationReason'}
                                placeholder={'Kündigungsgrund'}
                                messages={{
                                  valueMissing:
                                    'Bitte gib an, aus welchem wichtigen Grund du außerordentlich kündigen möchtest, damit wir deine Anfrage bearbeiten können.'
                                }}
                                required={cancellationTypeValue === 'REASONED'}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col sm={{ size: 9, offset: 3 }} className="text-end">
                              <Button dataCyName="terminate-button">JETZT VERBINDLICH KÜNDIGEN</Button>
                            </Col>
                          </FormGroup>
                        </Form>
                      </Fragment>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col sm={12}>
            <HappyCloudBalloon className="ad-tile__illustration mt-2" />
          </Col>
        </Row>
      </MainPage>
    );
  }
}

TerminateUserAbo.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  email: PropTypes.string,
  plans: subscriptionPlans.isRequired,
  getSubscriptionPlansRequest: PropTypes.func.isRequired,
  createRequestCancellation: PropTypes.func.isRequired,
  updateFormValidation: PropTypes.func.isRequired,
  updateFormErrors: PropTypes.func.isRequired,
  cancellationDateTypeValue: PropTypes.string,
  cancellationType: PropTypes.string,
  isGuest: PropTypes.bool
};

TerminateUserAbo.defaultProps = {
  isGuest: true
};

const mapStateToProps = (state) => ({
  isFetching: isSubscriptionPlansLoading(state),
  email: getCurrentUserEmail(state),
  plans: getSubscriptionPlans(state),
  cancellationDateTypeValue: state.entities.forms.terminateUserAbo.cancellationDateType,
  cancellationTypeValue: state.entities.forms.terminateUserAbo.cancellationType,
  isGuest: isGuest(state)
});

export default connect(mapStateToProps, {
  createRequestCancellation,
  getSubscriptionPlansRequest,
  updateFormValidation,
  updateFormErrors
})(TerminateUserAbo);
