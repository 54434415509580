import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/users';

import {
  CLEAR_LOGIN_USER_FAILURE_ERROR,
  LOGIN_APPLE_USER_FAILURE,
  LOGIN_APPLE_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  SOCIAL_LOGIN_USER_PENDING
} from 'redux/actions/actionTypes';

import { redeemGiftCoupon } from 'redux/actions';

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const loggingInUser = () => ({
  type: LOGIN_USER_PENDING
});

export const socialLoginUserPending = () => ({
  type: SOCIAL_LOGIN_USER_PENDING
});

export const loginUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload
});

export const loginUserFailure = (error) => ({
  type: LOGIN_USER_FAILURE,
  error
});

export const clearLoginUserFailureError = () => ({
  type: CLEAR_LOGIN_USER_FAILURE_ERROR
});


export const loginAppleUserSuccess = (payload) => ({
  type: LOGIN_APPLE_USER_SUCCESS,
  payload
});

export const loginAppleUserFailure = (error) => ({
  type: LOGIN_APPLE_USER_FAILURE,
  error
});

export const loginWithApple =
  ({ uid, grantCode, voucherCode }) =>
  (dispatch) => {
    dispatch(socialLoginUserPending());
    return api.loginWithApple({ uid, grantCode, forWebapp: true }).then(
      (response) => {
        dispatch(clearLoginUserFailureError());
        if (response.data.errors) {
          dispatch(loginAppleUserFailure(response.data.errors));
        } else {
          dispatch(
            loginAppleUserSuccess(
              normalize(response.data, {
                user: schema.user
              })
            )
          );
          if (voucherCode.length > 0) {
            dispatch(
              redeemGiftCoupon({
                code: voucherCode,
                showFlashError: true
              })
            );
          }
        }
      },
      (error) => {
        handleAxiosError(error, () => {
          if (error.response.status === 406) {
            dispatch(loginAppleUserFailure(error));
          } else {
            dispatch(clearLoginUserFailureError());
          }
          dispatch(loginUserFailure(error));
        });
      }
    );
  };
