import { requestApi } from 'utils';

export const getInsuranceCompaniesApiRequest = (isCooperationPartner) =>
  requestApi.query(
    `
  query GetInsuranceCompanies($isCooperationPartner: Boolean) {
    insuranceCompanies(isCooperationPartner: $isCooperationPartner) {
      name
      reductionRate
      cooperationUrl
      cooperationPartner
      cooperationLogo
      cooperationLogoHover
    }
  }
  `,
    { isCooperationPartner }
  );
