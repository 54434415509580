import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BrigitteTestimonial, FlowTestimonial, SternTestimonial } from 'components/Testimonial';

const TrustRow = () => (
  <Container className="trust-row">
    <Row>
      <Col sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 0 }}>
        <BrigitteTestimonial />
      </Col>
      <Col sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 0 }}>
        <FlowTestimonial />
      </Col>
      <Col sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 0 }}>
        <SternTestimonial />
      </Col>
    </Row>
  </Container>
);

export default TrustRow;
