import styled from 'styled-components';
import { asRem } from 'utils';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${asRem(520)};
  padding: ${asRem(100)} ${asRem(20)};
`;
