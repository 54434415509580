import { requestApi } from 'utils';

export const getCategories = () =>
  requestApi.query(`
  query GetCategories {
    categories {
      id
      title
      description
      picture
      prio
      slug
      courses {
        id
        title
        pictureV2
        description
        isFree
        isSpecial
        isNotFastForwardable
        backgroundColor
        isNew
        intro
        prio
        featuredPrio
        length
        slug
        locked
        units {
          position
          length
        }
        category {
          title
        }
        __typename
      }
      singles {
        id
        title
        description
        file
        length
        isFree
        isSpecial
        backgroundColor
        isNew
        locked
        prio
        featuredPrio
        slug
        category {
          icon
        }
        __typename
      }
    }
  }
`);

export const getCategoriesTitle = () =>
  requestApi.query(`
  query getCategoriesTitle {
    categories {
      id
      title
      slug
    }
  }
`);

export const getCategoryBySlug = (slug) =>
  requestApi.query(`
  query getCategoryBySlug {
    categories (slug: "${slug}") {
      id
      title
      description
      picture
      prio
      slug
      courses {
        id
        title
        pictureV2
        isFree
        isSpecial
        backgroundColor
        isNew
        length
        slug
        locked
        unitsCount
        __typename
      }
      singles {
        id
        title
        length
        isFree
        isSpecial
        backgroundColor
        isNew
        locked
        slug
        category {
          icon
        }
        __typename
      }
    }
  }
`);
