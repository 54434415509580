import React from 'react';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';

import ImgMobileBlue from 'images/home/phoneMockupBlue@2x.png';
import DecorativeImage from 'components/DecorativeImage';

MobileScreenCourse.propTypes = {
  className: PropTypes.string
};

export default function MobileScreenCourse({ className }) {
  return <DecorativeImage alt={TEXT.mobileShowcase.altScreenCourse} src={ImgMobileBlue} className={className} />;
}
