import React, { useEffect } from 'react';
import { TEXT } from 'locales/translations';
import { Form } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { PrivacyPolicyLink } from 'components';
import { createB2bContactRequest } from 'redux/actions/b2bContact';
import { Button, Input, Paragraph } from 'Common';
import { InquiryFormStyle } from './InquiryForm.style';
import { useForm } from 'react-hook-form';
import { isValidEmail, isValidUrl } from 'utils/validators';

export const InquiryForm = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setValue('contactType', 'HEALTH_ADVISER');
  }, [setValue]);

  const onSubmit = (formValues) => {
    dispatch(createB2bContactRequest(formValues));
  };

  return (
    <InquiryFormStyle>
      <Form className="inquiry-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('name', {
            required: TEXT.formsGeneral.requiredName
          })}
          dataCyName="name-input"
          placeholder={TEXT.healthAdvisor.inquiry.placeholderName}
          feedbackMessage={errors.name?.message}
          valid={!!errors.name ? false : undefined}
        />
        <Input
          {...register('email', {
            required: TEXT.formsGeneral.requiredEmail,
            validate: (value) => isValidEmail(value) || TEXT.formsGeneral.validEmail,
            onChange: () => {
              if (errors.email) {
                // needed to remove hasRegisterError message
                clearErrors('email');
              }
            }
          })}
          dataCyName="mail-input"
          placeholder="E-Mail"
          type="email"
          feedbackMessage={errors.email?.message}
          valid={!!errors.email ? false : undefined}
        />
        <Input
          {...register('companyName', {
            required: TEXT.healthAdvisor.inquiry.requiredOffice
          })}
          dataCyName="company-name-input"
          placeholder={TEXT.healthAdvisor.inquiry.placeholderOffice}
          feedbackMessage={errors.companyName?.message}
          valid={!!errors.companyName ? false : undefined}
        />
        <Input
          {...register('website', {
            required: TEXT.healthAdvisor.inquiry.requiredUrl,
            validate: (value) => isValidUrl(value) || TEXT.formsGeneral.validUrl
          })}
          dataCyName="website-input"
          placeholder={TEXT.healthAdvisor.inquiry.placeholderUrl}
          feedbackMessage={errors.website?.message}
          valid={!!errors.website ? false : undefined}
        />
        <Input
          type="textarea"
          {...register('message')}
          dataCyName="message-input"
          placeholder={TEXT.healthAdvisor.inquiry.placeholderMessage}
        />
        <Paragraph className="small">
          {TEXT.healthAdvisor.inquiry.asterixText}
          <PrivacyPolicyLink buttonText={TEXT.healthAdvisor.inquiry.privacyPolicyLinkText} className="privacy-link" />
          {TEXT.healthAdvisor.inquiry.zu}
        </Paragraph>
        <Button className="darkActionEffect w-100" dataCyName="submit-button">
          {TEXT.formsGeneral.sendBtn}
        </Button>
      </Form>
    </InquiryFormStyle>
  );
};
