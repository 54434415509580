import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import { isAuthed, isGuest, getRefererUrlAfterAuthentication } from 'redux/query';
import PropTypes from 'prop-types';
import * as reactRouterPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { ROUTES } from 'utils/constants';
import { bindActionCreators } from 'redux';
import {
  changeRefererAfterSuccessAuthentication,
  clearRefererAfterSuccessAuthentication
} from 'redux/actions/users/authenticationRefererActions';
import { resetUserGuestStateAuthentication } from 'redux/actions/users/clearUserGuestStateActions';
import { healthInsuranceCooperationCompanies } from 'utils/constants';

const allowedUserSignInRoutes = [ROUTES.signup, ROUTES.confirmMailCode, ROUTES.confirmMail, ROUTES.fallbackAppOtp, ROUTES.login];

const disabledRoutes = [
  ...healthInsuranceCooperationCompanies.map(({ route }) => route),
  ROUTES.dakBgm,
  ROUTES.xing,
  ROUTES.xingProJobs,
  ROUTES.stressreduktion
];

const resetUserGuestStateRoutes = [ROUTES.signup, ROUTES.login];

class AuthenticationContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const { state } = this.props.location;

    if (disabledRoutes.includes(this.props.location.pathname)) {
      return;
    }

    // set location state from route for none authenticated user
    if (
      (!this.props.isAuthed || this.props.isGuest) &&
      state?.from?.pathname &&
      state?.from?.pathname !== this.props.refererUrlAfterAuthentication
    ) {
      this.props.changeRefererAfterSuccessAuthentication(state?.from?.pathname);
    }

    // clear alternative referer url, when referer url are not the default route
    if (this.props.refererUrlAfterAuthentication !== ROUTES.profilePersonalData && !this.props.hasAllowedPath) {
      this.props.clearRefererAfterSuccessAuthentication();
    }

    // redirect the authenticated user to the referer url
    // isAuthed && isGuest   => initial state
    // !isAuthed && !isGuest => pending user (waiting for OTP)
    // isAuthed && !isGuest  => authenticated user
    if (this.props.isAuthed && !prevProps.isAuthed && !prevProps.isGuest && !this.props.isGuest) {
      const returnToUserPage = this.props.refererUrlAfterAuthentication;
      this.props.clearRefererAfterSuccessAuthentication();
      this.props.history.push(returnToUserPage);
    }

    // redirect the user to OTP screen
    if (!this.props.isAuthed && !this.props.isGuest && prevProps.isAuthed && prevProps.isGuest) {
      if (window.innerWidth <= 760) {
        this.props.history.push(ROUTES.confirmMail + '#sign-in-user-with-otp');
      } else {
        this.props.history.push(ROUTES.confirmMail);
      }
    }

    if (!this.props.isAuthed && !this.props.isGuest && !prevProps.isResetRoute && this.props.isResetRoute) {
      this.props.resetUserGuestStateAuthentication();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => ({
  isAuthed: isAuthed(state),
  isGuest: isGuest(state),
  refererUrlAfterAuthentication: getRefererUrlAfterAuthentication(state),
  hasAllowedPath: !!allowedUserSignInRoutes.filter((path) => matchPath(props.location.pathname, { path, exact: true, strict: false }))
    .length,
  isResetRoute: !!resetUserGuestStateRoutes.filter((path) => {
    return props.location.pathname.length === path.length;
  }).length
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeRefererAfterSuccessAuthentication,
      clearRefererAfterSuccessAuthentication,
      resetUserGuestStateAuthentication
    },
    dispatch
  );

AuthenticationContainer.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired,
  location: reactRouterPropTypes.location,
  history: reactRouterPropTypes.history,
  hasAllowedPath: PropTypes.bool.isRequired,
  isResetRoute: PropTypes.bool.isRequired,
  refererUrlAfterAuthentication: PropTypes.string.isRequired,
  changeRefererAfterSuccessAuthentication: PropTypes.func,
  clearRefererAfterSuccessAuthentication: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer));
