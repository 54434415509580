import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInvoiceByNumber } from 'redux/reducers';
import { Invoice } from 'components';

class InvoiceContainer extends Component {
  render() {
    return <Invoice {...this.props} />;
  }
}

const mapStateToProps = (state, { match }) => ({
  invoice: getInvoiceByNumber(state, match.params.id)
});

export default connect(mapStateToProps)(InvoiceContainer);
