import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Invoices } from 'components';

class InvoicesContainer extends Component {
  render() {
    return <Invoices />;
  }
}

export default connect()(InvoicesContainer);
