import styled from 'styled-components';
import { colors } from 'Theme';
import { asRem } from 'utils';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .dev-frame {
    margin-top: ${asRem(-40)};
  }

  .data-provider {
    max-width: 400px;
    margin: 0 auto;
    text-align: left;
  }
  .decline-button {
    background-color: ${colors.error.c500};
    border-color: ${colors.error.c500};
    color: white;
  }
`;
