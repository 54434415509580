import React from 'react';
import { TEXT } from 'locales/translations';
import { BackgroundImage, Row, Col, Paragraph, List, Icon, Headline, Button } from 'Common';
import { colors } from 'Theme';
import { useAudioPlayerModal } from 'hookApi/useAudioPlayerModal';
import AudioPlayerModal from './AudioPlayerModal';
import { mobileStressReduzieren, audioBgStressReduzieren as image } from 'images/landingpages/b2b_landingpage';
import audio from 'audios/b2b_landingpage/Stress_Short.mp3';

export const AudioSamplesStress = ({ color, audioId }) => {
  const modalProps = useAudioPlayerModal(audioId, TEXT.company.audioSamples.btn.stressBtn);
  return (
    <Row>
      <Col xs={{ size: 8, offset: 2, order: 2 }} md={{ size: 5, offset: 0, order: 1 }}>
        <BackgroundImage image={mobileStressReduzieren} className="mobile-image" />
      </Col>

      <Col xs="12" md={{ size: 7, offset: 0, order: 1 }} className="content-wrapper">
        <div>
          <Headline level={2}>{TEXT.company.audioSamples.stress.headline}</Headline>
          <Paragraph>{TEXT.company.audioSamples.stress.body}</Paragraph>
          <List icon={<Icon color={[colors.white.c500, colors.pink.c600]} size={{ sm: 32, md: 32, lg: 40 }} name="Checkmark" />}>
            {[TEXT.company.audioSamples.stress.li_1, TEXT.company.audioSamples.stress.li_2, TEXT.company.audioSamples.stress.li_3]}
          </List>
        </div>
        <Button type="inverted" className={'audio-button'} onClick={modalProps.toggle}>
          <Icon name="Play" size={{ sm: 27, md: 27 }} color={colors.pink.c500} />
          {TEXT.company.audioSamples.btn.audioBtn}
        </Button>

        <AudioPlayerModal
          id={audioId}
          cololor={color}
          toggle={modalProps.toggle}
          isOpen={modalProps.isOpen}
          courseAudio={audio}
          courseImage={image}
          onClose={modalProps.closeModalAfterAudioPlayed}
        />
      </Col>
    </Row>
  );
};
