import React from 'react';
import { ROUTES } from 'utils/constants';
import { Container, Row, Col } from 'Common';
import { Link } from 'react-router-dom';
import { TEXT } from 'locales/translations';
import birdImg from 'images/thank_you/angry_bird.png';

export const PaymentError = () => {
  return (
    <section className="main-decorated payment-error">
      <Container>
        <Row>
          <Col
            xs={{ size: '12', offset: '0' }}
            sm={{ size: '10', offset: '1' }}
            md={{ size: '8', offset: '2' }}
            lg={{ size: '6', offset: '3' }}
            className="card payment-error__card d-block text-center mt-6 mb-0 mb-sm-7 my-md-8"
          >
            <h2 className="text-pink-c500 mb-3 mb-sm-2 mb-0">{TEXT.paymentError.title}</h2>
            <p className="text-grey-c600 px-2 px-md-5">{TEXT.paymentError.copy}</p>
            <img className="mx-auto d-block" src={birdImg} alt="Aufgeregter Vogel" />
            <Link to={ROUTES.plans} className="btn btn-pink-c500 text-white-c500 d-sm-inline-block">
              {TEXT.paymentError.btn}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
