import React from 'react';
import { TEXT } from 'locales/translations';
import { Button } from 'Common';
import { ScrollableRow } from 'Container';
import { AudioSamplesFilterStyle } from './AudioSamplesFilter.style';

export const AudioSamplesFilter = ({ activeIndex, goToIndex }) => {
  const buttons = ['jobBtn', 'resilienzBtn', 'stressBtn', 'sleepBtn'];

  return (
    <AudioSamplesFilterStyle>
      <ScrollableRow>
        {buttons.map((button, index) => (
          <Button key={index} type="round-border" className={activeIndex === index ? 'active' : ''} onClick={() => goToIndex(index)}>
            {TEXT.company.audioSamples.btn[button]}
          </Button>
        ))}
      </ScrollableRow>
    </AudioSamplesFilterStyle>
  );
};
