import React from 'react';
import PropTypes from 'prop-types';
import { getUser } from 'redux/actions';
import { getCurrentUserEmail } from 'redux/reducers';
import { hasUnconfirmedEmail, getUnconfirmedEmail, getUserFirstname, isUserLoading } from 'redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'Common';
import { withUser } from 'containers/withUser';
import { PersonalDataContent } from 'components';

const PersonalDataContentContainer = () => {
  const dispatch = useDispatch();
  const email = useSelector(getCurrentUserEmail);
  const firstname = useSelector(getUserFirstname);
  const hasNotConfirmedEmail = useSelector(hasUnconfirmedEmail);
  const unconfirmedEmail = useSelector((state) => (hasNotConfirmedEmail ? getUnconfirmedEmail(state) : ''));

  const isLoading = useSelector(isUserLoading);

  React.useEffect(() => {
    dispatch(getUser());
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PersonalDataContent
      email={email}
      firstname={firstname}
      unconfirmedEmail={unconfirmedEmail}
      hasUnconfirmedEmail={hasNotConfirmedEmail}
    />
  );
};

export default withUser(PersonalDataContentContainer);
