import React from 'react';
import PropTypes from 'prop-types';

export const LogoFlow = ({ primaryColor }) => (
  <svg enableBackground="new 0 0 150 60" viewBox="0 0 150 60" xmlns="http://www.w3.org/2000/svg">
    <g fill={primaryColor}>
      <path d="m109.4 25c-4-.4-7.9 1.8-8.7 5.6l-2 8h-.1l-2.6-11.4h-5.5l-2.5 11.4h-.1l-3-11.4h-5.8l5.5 17.5h5.8l2.8-11.7h.1l2.7 11.7h5.8l3.7-11.9c.1-.2.1-.4.2-.6l1.5-4.6c.5-1.3 1-1.5 2.3-1.4.7.1 1.3.6 1.2 1.2 0 .2-.1.4-.3.8-.3.5-.4.9-.5 1.3-.1 1.4.8 2.5 2.2 2.7 1.5.1 2.7-.9 2.8-2.4v-.6c-.1-2.2-2.3-3.9-5.5-4.2z" />
      <path d="m70.9 26.3c-5.5 0-9.3 4-9.3 9.3 0 1.8.5 3.5 1.3 4.9-.9 1.6-2.1 2.5-3.5 2.5-2.2 0-2.9-1.3-2.9-5.1v-19.4h-9.2v-3.5c-.3 0-.6 0-.9 0-.6 0-1.2 0-1.8 0h-1.6c-3.1 0-5.2 2.3-5.2 5.2v.8 2.6.2h-2.8v3.7h2.8v17.3h5.8v-17.4h3.4v-3.7h-3.5v-1.9c.1-1.4.7-2 2.1-2h2.9c1.6 0 2.1.7 2.1 2.7v16.4c0 2 .3 3 1.1 4.1 1 1.3 2.7 2 4.7 2 2.5 0 5.3-1.1 7.2-3.3 1.7 2 4.2 3.2 7.2 3.2 5.5 0 9.3-4 9.3-9.3.1-5.2-3.7-9.3-9.2-9.3zm0 14.2c-2.7 0-3.5-2.6-3.5-4.9 0-2.2.8-4.9 3.5-4.9s3.5 2.7 3.5 4.9c0 2.3-.8 4.9-3.5 4.9z" />
    </g>
  </svg>
);

LogoFlow.propTypes = {
  primaryColor: PropTypes.string
};
