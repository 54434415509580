export const getBaseUrl = (includeProtocol = true, removeTrailingSlash = false) => {
  if (!window || !window.location || !window.location.hostname || !window.location.protocol) {
    console.error(`The getCurrentBaseURL function must be called from a context in which window object exists. Yet, window is ${window}`, [
      window,
      window.location,
      window.location.hostname,
      window.location.protocol
    ]);
    throw new TypeError('Whole or part of window is not defined.');
  }

  return `${includeProtocol ? `${window.location.protocol}//` : ''}${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }${removeTrailingSlash ? '' : '/'}`;
};
