import React, { Component } from 'react';
import GradientLine from './components/GradientLine';
import Header from './components/Header';
import LinksSection from './components/LinksSection';
import TeamSection from './components/TeamSection';
import EpisodeSection from './components/EpisodeSection';
import ReviewSection from './components/ReviewSection';

class PodcastLandingPage extends Component {
  render() {
    return (
      <main className="podcast">
        <GradientLine />
        <Header />
        <LinksSection />
        <TeamSection />
        <EpisodeSection />
        <ReviewSection />
      </main>
    );
  }
}

export default PodcastLandingPage;
