import { requestApi } from 'utils';

export const getEntryContent = () =>
  requestApi.query(`
  query GetEntryContent {
    entryContent {
      headingKey
      content {
        ... on Course {
          id
          title
          description
          pictureV2
          backgroundColor
          length
          slug
          unitsCount
        }
      }
    }
  }
`);
