import React, { Fragment } from 'react';
import { Col, Button, Row } from 'Common';
import { MainPage } from 'Container';
import { ROUTES } from 'utils/constants';

const NotFoundPage = () => (
  <Fragment>
    <MainPage bodyClassName="background-green-c500" className="thank-you" title="404 - Seite nicht gefunden">
      <div className="container hero">
        <Row>
          <Col>
            <h2>Diese Seite konnten wir leider nicht finden.</h2>
            <br />
            <Button to={ROUTES.home} type="inverted">
              Zur Startseite
            </Button>
          </Col>
        </Row>
      </div>
    </MainPage>
  </Fragment>
);

export default NotFoundPage;
