import React from 'react';
import { AppStoreBadge, GooglePlayBadge } from 'components';
import DecorativeImage from 'components/DecorativeImage';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';
import { Button, Row, Col, Container } from 'Common';
import { redirectToStoreDownload } from 'utils';

import sittingBalloonieImage from 'images/home/balloonieSitting@2x.png';

export default function CtaInfoSection(props) {
  return (
    <section id="section-appstores" className="cta-info-section">
      <Container>
        <Row>
          <Col xs={{ size: 12, offset: 0, order: 1 }} md={{ size: 6, offset: 0, order: 2 }} className="cta-info-section__image-warpper">
            <DecorativeImage
              alt={TEXT.ctaInfoSection.alt}
              src={sittingBalloonieImage}
              className="cta-info-section__sitting-balloonie-image"
            />
          </Col>
          <Col className="cta-info-section__content" xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0, order: 2 }} lg={{ size: 4 }}>
            <TextBlock heading={TEXT.ctaInfoSection.heading} body={TEXT.ctaInfoSection.body} textColor="white" />
            <section className="cta-info-section__button-section">
              <Button type="inverted" onClick={redirectToStoreDownload}>
                {TEXT.ctaInfoSection.btn}
              </Button>

              <ul className="cta-info-section__store-button-row">
                <li>
                  <AppStoreBadge />
                </li>
                <li>
                  <GooglePlayBadge />
                </li>
              </ul>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
