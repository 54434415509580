import React, { Fragment, useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { Button } from 'Common';
import { colors } from 'components/Theme';
import { TEXT } from 'locales/translations';
import TutorialAudio from 'images/landingpages/health_insurance_marketing/Balloon-KKK-Intro.mp3';
import CourseImg from 'images/landingpages/health_insurance_marketing/player_modal_background.png';
import { ThemeContainer } from 'Container';
import { themeFeeling } from 'Theme';

const AudioPlayerModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const closeModalAfterAudioPlayed = () => {
    const audio = document.getElementById('tutorial-audio');
    if (audio) {
      audio.addEventListener('ended', () => {
        setIsOpen(false);
      });
    }
  };

  useEffect(() => {
    closeModalAfterAudioPlayed();
  }, []);

  return (
    <ThemeContainer theme={themeFeeling}>
      <Button style={{ color: colors.white.c500 }} onClick={toggle}>
        <svg
          width="12px"
          height="16px"
          viewBox="0 0 12 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className="play-btn__play-icon"
        >
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="single-icon" fill="#fff">
              <path
                fill="ff22aa"
                d="M10.9699497,9.7321234 C12.3520359,8.8237977 12.3469582,7.347551 10.9699497,6.4422902 L2.50248842,0.8756858 C1.12040223,-0.0329133 1.8189894e-12,0.5762773 1.8189894e-12,2.2349855 L1.8189894e-12,13.9372587 C1.8189894e-12,15.5965782 1.12547995,16.2020419 2.50248842,15.2970533 L10.9699497,9.7321234 Z"
                id="Path"
              />
            </g>
          </g>
        </svg>
        {TEXT.healthMarketingPage.benefitSection.playBtn}
      </Button>

      <Modal className="audio-player-modal" isOpen={isOpen} toggle={toggle} onOpened={closeModalAfterAudioPlayed}>
        <div className="audio-player-modal__body">
          <Button type="transparent" className="audio-player-modal__close-btn" onClick={toggle}>
            <span aria-hidden="true">×</span>
          </Button>
          <img
            className="audio-player-modal__img"
            style={{ backgroundSize: 'contain' }}
            src={CourseImg}
            alt={TEXT.healthMarketingPage.benefitSection.altTextPlayer}
          />
          <div className="audio-player-modal__player">
            <audio src={TutorialAudio} id="tutorial-audio" controls={true} autoPlay />
          </div>
        </div>
      </Modal>
    </ThemeContainer>
  );
};

export default AudioPlayerModal;
