import { requestApi } from 'utils';

export const getUserSubscriptionsApiRequest = () =>
  requestApi.query(`
      query GetUserSubscriptions {
        me {
          id
          subscriptions {
            id
            subscriptionPlanId
          }
        }
      }
    `);
