import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from 'reactstrap';
import { ROUTES } from 'utils/constants';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';
import { Button, ScrollButton } from 'Common';
import { ThemeContainer } from 'Container';
import { themeCalm } from 'Theme';

class BenefitInfoSection extends Component {
  render() {
    const { hasSubscription } = this.props;
    return (
      <ThemeContainer theme={themeCalm}>
        <section className="benefit-info-section">
          <Container>
            <Row>
              <Col xs={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} lg={{ size: 10, offset: 1 }}>
                <TextBlock
                  heading={TEXT.benefitInfoSection.heading}
                  body={TEXT.benefitInfoSection.body}
                  textColor="greyBlue"
                  textAlign="center"
                />
              </Col>
            </Row>
            <Row>
              <Col className="benefit-info-section__left-col" xs={{ size: 8, offset: 2, order: 2 }} md={{ size: 6, offset: 0, order: 1 }}>
                <ScrollButton className="width-100" to="section-appstores" duration={600}>
                  App herunterladen
                </ScrollButton>
              </Col>
              {!hasSubscription && (
                <Col
                  className="benefit-info-section__right-col"
                  xs={{ size: 8, offset: 2, order: 1 }}
                  md={{ size: 6, offset: 0, order: 2 }}
                >
                  <Button type="outline" className="width-100" to={ROUTES.plans}>
                    {TEXT.benefitInfoSection.btnAbo}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </ThemeContainer>
    );
  }
}

BenefitInfoSection.propTypes = {
  hasSubscription: PropTypes.bool
};

BenefitInfoSection.defaultProps = {
  hasSubscription: false
};

export default BenefitInfoSection;
