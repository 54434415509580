import { Paragraph, Spinner } from 'Common';
import { HoverImageLink, Slider } from 'Component';
import { useSliderSettings } from 'hookApi/useSliderSettings';
import { TEXT } from 'locales/translations';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCompaniesRequest } from 'redux/actions';
import { getInsuranceCompanies } from 'redux/query';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CooperationStyle } from './Cooperations.style';

export const HealthCooperations = ({ id }) => {
  const dispatch = useDispatch();
  const [totalLogoCount, setTotalLogoCount] = useState(0);

  useEffect(() => {
    dispatch(getInsuranceCompaniesRequest(true));
  }, [dispatch]);

  const insuranceCompanies = useSelector(getInsuranceCompanies);

  useEffect(() => {
    setTotalLogoCount(insuranceCompanies.length);
  }, [insuranceCompanies]);

  const settings = useSliderSettings(totalLogoCount);

  if (!insuranceCompanies || totalLogoCount === 0) {
    return <Spinner />;
  }

  return (
    <CooperationStyle
      id={id}
      className="text-center"
      verticalPadding={{ sm: 40, md: 30, lg: 40 }}
      backgroundColor="lightGrey"
      type="full-width"
    >
      <Paragraph className="bold">{TEXT.healthCooperations.headline}</Paragraph>
      <Slider key={settings.initialSlide} settings={settings} className="pink-dots">
        {insuranceCompanies.map((cooperation) =>
          cooperation.cooperationLogo && cooperation.cooperationLogoHover ? (
            <HoverImageLink
              key={cooperation.cooperationUrl}
              image={cooperation.cooperationLogo}
              hoverImage={cooperation.cooperationLogoHover}
              href={cooperation.cooperationUrl}
              className="logo"
            />
          ) : null
        )}
      </Slider>
    </CooperationStyle>
  );
};
