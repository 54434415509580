import styled from 'styled-components';
import { breakpoints, spacing } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';
import { illuInquiryForm } from 'images/landingpages/b2b_landingpage';

export const InquirySectionStyle = styled(Section)`
  position: relative;
  min-height: ${asRem(800)};

  &:before {
    display: none;

    @media (min-width: ${breakpoints.lg}) {
      content: '';
      background-image: url(${illuInquiryForm});
      width: ${asRem(654)};
      height: ${asRem(322)};
      position: absolute;
      top: ${asRem(460)};
      left: 0;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
  }

  ul {
    margin-left: ${asRem(32)};
  }

  .rounded-box {
    width: 100%;
    display: flex;
    z-index: 1;
  }

  .image-wrapper {
    z-index: -1 !important;
    position: relative;
  }

  .content-image {
    aspect-ratio: 654 / 322;
    width: 100%;
    height: auto;
    margin: ${asRem(spacing.two)} ${asRem(-spacing.two)};

    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }
  }

  .success-image {
    margin-top: ${asRem(48)};
    aspect-ratio: 447 / 230;
    width: 100%;
    height: auto;
  }

  .subtitle {
    max-width: ${asRem(470)};
  }

  .anchor {
    scroll-margin-top: ${asRem(100)};
  }
`;
