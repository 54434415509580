import { clearFlashes, redeemingGiftCoupon, addFlash, redeemGiftCouponFailure, redeemGiftCouponSuccess } from 'redux/actions';
import { put, all, select } from 'redux-saga/effects';
import { redeemGiftCouponApiRequest } from 'api/balloon';
import { getGiftCouponErrorMessage } from 'redux/reducers';

export function* redeemGiftCouponSaga({ payload: { code, showFlashError } }) {
  yield all([yield put(clearFlashes()), yield put(redeemingGiftCoupon())]);
  try {
    const { data } = yield redeemGiftCouponApiRequest({ code });
    if (data.errors) {
      yield put(redeemGiftCouponFailure(data.errors));
      if (showFlashError) {
        yield put(addFlash(getGiftCouponErrorMessage(), 'danger'));
      }
      return;
    }
    // ToDo: check for correct working, because normalizr is not used
    yield put(redeemGiftCouponSuccess(data.data.redeemGiftCoupon));
  } catch (error) {
    const state = yield select();
    yield put(redeemGiftCouponFailure(error));
    yield put(addFlash(getGiftCouponErrorMessage(state), 'danger'));
  }
}
