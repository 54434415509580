import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { dateFromUnixTimeStamp } from 'utils';

const dateFormat = 'DD.MM.YYYY';

export const DatePresenter = ({ date }) => (
  <>
    {typeof date === 'string' ? (
      <span>{moment(date).format(dateFormat)}</span>
    ) : (
      <span>{moment(date instanceof Date ? date : dateFromUnixTimeStamp(date)).format(dateFormat)}</span>
    )}
  </>
);

DatePresenter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date), PropTypes.string]).isRequired
};
