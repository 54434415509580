import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { CourseTeaser, Player, PlayerListItem, SingleTeaser } from 'components/Webplayer';
import { createProcessForUnit, getUnitProtectedFileUrl, setPlayerProcess, setPlayerStatus, stopPlayer, trackPlayer } from 'redux/actions';
import {
  getCreatingProcessStatus,
  getCurrentPlayerProcess,
  getCurrentPlayerUnit,
  getPlayerStatus,
  getProtectedFileUrl
} from 'redux/reducers';
import { hasSubscription } from 'redux/query';
import PropTypes from 'prop-types';
import { CtaInfoSection, NotFoundPage } from 'components';
import { TEXT } from 'locales/translations';
import { Link } from 'react-router-dom';
import { Link as MMLink } from 'Common';
import { ROUTES } from 'utils/constants';
import IconNavLeft from 'images/webplayer/icon-nav-left.svg';
import SubscriptionTeaser from './SubscriptionTeaser';
import { usePageTitle } from '../../containers/PageTitleContainer/PageTitleContainer';
import { browserHistory } from 'redux/configureStore';

class Webplayer extends Component {
  componentDidMount() {
    const { content, trackPlayer, contentType, getUnitProtectedFileUrl } = this.props;
    if (contentType === 'course') {
      let selectedUnit = null;
      if (content && content.units) {
        // eslint-disable-next-line array-callback-return
        content.units.map((unit, key) => {
          if (unit.completed && content.units.length >= key + 1 && !content.locked) {
            selectedUnit = content.units[key + 1];
          }
        });
      }
      if (selectedUnit) {
        trackPlayer(selectedUnit);
        getUnitProtectedFileUrl(selectedUnit.id);
        setPlayerStatus('load');
      } else {
        trackPlayer(content);
      }
    } else {
      trackPlayer(content);
    }
  }

  selectUnit = (unit) => {
    const { trackPlayer, content, currentlyPlayedUnit, setPlayerStatus, playerStatus, getUnitProtectedFileUrl, contentType } = this.props;
    if (!content.locked) {
      getUnitProtectedFileUrl(unit.id);
      if (currentlyPlayedUnit.id === unit.id) {
        if (playerStatus !== 'playing') {
          setPlayerStatus('resume');
        } else {
          setPlayerStatus('pausing');
        }
      } else {
        trackPlayer(unit);
      }
    }
    if (content.locked && content.protectedFile === unit.protectedFile && contentType === 'course') {
      trackPlayer(unit);
      if (playerStatus !== 'playing') {
        setPlayerStatus('resume');
      }
    }
  };

  goToPlans = () => {
    browserHistory.push(ROUTES.plans);
  };

  handleSelectUnit = (unit, unitState) => {
    switch (unitState) {
      case 'locked':
        this.goToPlans();
        break;
      case 'available':
        return;
      default:
        this.selectUnit(unit);
    }
  };

  setProcess = (process) => {
    const { setPlayerProcess } = this.props;
    setPlayerProcess(process);
  };

  setPlayerStatus = (status) => {
    const { setPlayerStatus } = this.props;
    setPlayerStatus(status);
  };

  createPlayerListItem = (unit, key, course) => {
    const { currentlyPlayerProcess, content } = this.props;
    const unitState = this.determineUnitState(unit, key);

    return (
      <PlayerListItem
        key={unit.id}
        title={unit.title}
        time={key >= 0 ? unit.length : undefined}
        id={unit.id}
        process={currentlyPlayerProcess}
        state={unitState}
        selectUnit={() => this.handleSelectUnit(unit, unitState)}
      />
    );
  };

  determineUnitState = (unit, key) => {
    const { content, playerStatus, currentlyPlayedUnit } = this.props;
    const isCurrentUnit = unit.id === currentlyPlayedUnit.id;
    const isUnitLocked = content.locked;
    const isUnitCompleted = unit.completed;
    const isPreviousUnitCompleted = key > 0 && content.units[key - 1] && content.units[key - 1].completed;

    if (isCurrentUnit) {
      return playerStatus === 'playing' ? 'playing' : 'selected';
    }
    if (isUnitCompleted) {
      return 'completed';
    }
    if (isUnitLocked) {
      return 'locked';
    }
    if (isPreviousUnitCompleted || key === 0 || key === -1) {
      // or is intro
      return 'default';
    }
    return 'available';
  };

  playlist = () => {
    const { content, contentType, currentlyPlayedUnit } = this.props;

    if (!content || !currentlyPlayedUnit || !currentlyPlayedUnit.id) {
      return <></>;
    }

    return [
      this.createPlayerListItem(
        {
          ...content,
          title: contentType === 'course' ? 'Intro' : content.title
        },
        -1
      ),
      ...(content.units || []).map(this.createPlayerListItem)
    ];
  };

  render() {
    const { content, contentType, hasSubscription } = this.props;
    const rightImage = content.pictureV2 || content.category?.icon;
    return (
      <div>
        {content && content.error && content.error.status === 404 ? (
          <NotFoundPage />
        ) : (
          <main className="webplayer">
            {!hasSubscription && (
              <Container fluid>
                <Row className="banner p-3 justify-content-center">
                  <Col xs="auto">
                    <span>
                      {TEXT.webplayer.bannerText}
                      <MMLink to={ROUTES.plans}>{TEXT.webplayer.bannerLink}</MMLink>
                    </span>
                  </Col>
                </Row>
              </Container>
            )}
            <section className="player-container" style={{ backgroundColor: content.backgroundColor }}>
              <div className="before-player">
                <Link className="back" to={ROUTES.library}>
                  <img src={IconNavLeft} alt={TEXT.webplayer.iconNavLeft} className="left-icon" />
                  <span>{TEXT.webplayer.backButton}</span>
                </Link>
              </div>
              <Player {...this.props} setProcess={this.setProcess} setStatus={this.setPlayerStatus}>
                {this.playlist()}
              </Player>
              <div className="after-player d-none d-lg-flex">
                {rightImage && (
                  <img
                    src={rightImage}
                    alt={TEXT.webplayer.rightDecorativeImage}
                    className={contentType === 'course' ? 'w-100 h-auto' : 'right-image'}
                  />
                )}
              </div>
            </section>
            <section className="teaser-container">
              {contentType === 'course'
                ? content?.category?.courses?.length > 1 && (
                    <CourseTeaser category={content.category} course={content} buttonColor={content.backgroundColor} />
                  )
                : content?.category?.singles?.length > 1 && (
                    <SingleTeaser category={content.category} single={content} buttonColor={content.backgroundColor} />
                  )}
            </section>
            {!hasSubscription && <SubscriptionTeaser />}
            <CtaInfoSection />
          </main>
        )}
      </div>
    );
  }
}

Webplayer.propTypes = {
  content: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  currentlyPlayedUnit: PropTypes.object,
  currentlyPlayedUnitState: PropTypes.string,
  trackPlayer: PropTypes.func.isRequired,
  stopPlayer: PropTypes.func.isRequired,
  createProcessForUnit: PropTypes.func.isRequired,
  getUnitProtectedFileUrl: PropTypes.func.isRequired,
  setPlayerProcess: PropTypes.func.isRequired,
  currentlyPlayerProcess: PropTypes.number,
  setPlayerStatus: PropTypes.func.isRequired,
  playerStatus: PropTypes.string.isRequired,
  protectedFileUrl: PropTypes.string,
  token: PropTypes.string,
  creatingProcessStatus: PropTypes.bool,
  match: PropTypes.object.isRequired
};

Webplayer.defaultProps = {
  content: {},
  playerStatus: 'pausing'
};

const mapStateToProps = (state) => ({
  currentlyPlayedUnit: getCurrentPlayerUnit(state),
  currentlyPlayerProcess: getCurrentPlayerProcess(state),
  playerStatus: getPlayerStatus(state),
  creatingProcessStatus: getCreatingProcessStatus(state),
  protectedFileUrl: getProtectedFileUrl(state),
  hasSubscription: hasSubscription(state)
});

const withPageTitle = (WrappedComponent) => {
  return (props) => {
    const { content, contentType } = props;
    usePageTitle(`${content.title} | ${contentType === 'course' ? 'Kurs' : 'Übung'}`);
    return <WrappedComponent {...props} />;
  };
};

export default connect(mapStateToProps, {
  trackPlayer,
  stopPlayer,
  setPlayerProcess,
  setPlayerStatus,
  createProcessForUnit,
  getUnitProtectedFileUrl
})(withPageTitle(Webplayer));
