import { requestApi } from 'utils';

export const propertyToModel = {
  first_name: 'name',
  email: 'email'
};

// Note: The validity keys must be one of of the validity keys hardcoded in
// react-redux-form (there, see lib/constants/validity-keys.js):
//
// - badInput
// - customError
// - patternMismatch
// - rangeOverflow
// - rangeUnderflow
// - stepMismatch
// - tooLong
// - tooShort
// - typeMismatch
// - valueMissing
//
// Otherwise an error message will not be cleared when the form input is changed.
//
// It *should* be possible to instead add {async: true}, as in
// setValidity(model, error, {async: true})
// but I tried this and couldn't get it to work.
// Also see
// https://github.com/davidkpiano/react-redux-form/issues/860
// https://github.com/davidkpiano/react-redux-form/issues/776

export const errorToValidityKey = {
  first_name: {
    FIRST_NAME_CANNOT_BE_BLANK: 'valueMissing'
  },
  email: {
    EMAIL_HAS_ALREADY_BEEN_TAKEN: 'customError',
    EMAIL_IS_INVALID: 'typeMismatch',
    EMAIL_CANNOT_BE_BLANK: 'valueMissing',
    NO_USER_FOUND: 'badInput'
  }
};

export const updateUserName = (name) =>
  requestApi.mutate( `
        mutation UpdateUserName {
          updateUser(firstName: "${name}") {
            user {
              id
              email
              firstName
              lastName
              isGuest
              unconfirmedEmail
              isGoogleUser
            }
          }
        }
      `
    );

export const getUser = () =>
  requestApi.query( `
        query GetUser {
          me {
            email
            firstName
            lastName
            id
            isGuest
            isGoogleUser
            unconfirmedEmail
          }
        }
      `
    );

export const loginWithApple = ({ uid, grantCode, forWebapp }) =>
  requestApi.mutate(
    `
  mutation LoginWithApple($uid: String!, $grantCode: String!, $forWebapp: Boolean!) {
    signInUserWithAppleToken(
      uid: $uid,
      token: $grantCode,
      forWebapp: $forWebapp
    ) {
      token
      user {
        id
        email
        firstName
        lastName
        isGuest
        unconfirmedEmail
      }
    }
  }
`,
    {
      uid,
      grantCode,
      forWebapp
    }
  );

export const updateUserEmail = ({ email, origin }) =>
  requestApi.mutate(
    `
  mutation UpdateUserEmail($email: String!, $origin: AppOrigin!) {
    requestEmailAddressChange(
      email: $email,
      origin: $origin
    ) {
      user {
        id
        email
        firstName
        unconfirmedEmail
      }
    }
  }
`,
    {
      email,
      origin
    }
  );

export const confirmEmailAddressChange = (code) =>
  requestApi.mutate(
    `
  mutation ConfirmEmailAddressChange($code: String!) {
    confirmEmailAddressChange(
      token: $code,
    ) {
      user {
        id
        email
        firstName
        unconfirmedEmail
      }
    }
  }
`,
    {
      code
    }
  );

export const revokeEmailAddressChange = () =>
  requestApi.mutate(`
  mutation RevokeEmailAddressChange {
    revokeEmailAddressChange {
      user {
        id
        email
        firstName
        unconfirmedEmail
      }
    }
  }
`);
