import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LandingpageHeaderSimple from '../LandingpageHeaderSimple';
import CheckmarkBoxSection from './components/CheckmarkBoxSection';
import BallooniSection from './components/BallooniSection';
import { TEXT } from 'locales/translations';
import LandingpageBottomBlueLine from '../LandingpageBottomBlueLine';
import LandingpageBuyBoxSection from '../LandingpageBuyBoxSection';
import { ROUTES } from 'utils/constants';
import { getSubscriptionPlansRequest } from 'redux/actions';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { getSubscriptionPlanByGroup, isSubscriptionPlansLoading } from 'redux/query';

const BkkLandingPage = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSubscriptionPlansLoading);
  const plan = useSelector(getSubscriptionPlanByGroup(SUBSCRIPTION_PLAN_GROUPS.BKK));

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.BKK));
  }, [dispatch]);

  return (
    <main className="bkk">
      <LandingpageHeaderSimple
        headline={TEXT.bkk.header.headline}
        subline={TEXT.bkk.header.subline}
        buttonLink={ROUTES.bkk}
        buttonLinkClass="btn-bkk"
        buttonText={TEXT.bkk.header.button}
      />
      <LandingpageBottomBlueLine />
      <CheckmarkBoxSection />
      <BallooniSection />
      <LandingpageBuyBoxSection
        plan={plan}
        isFetching={isLoading}
        headline={TEXT.bkk.buy_box_section.headline}
        description={TEXT.bkk.buy_box_section.description}
        relator={TEXT.bkk.buy_box_section.relator}
        disclaimer={TEXT.landingpages.disclaimer}
        buttonLinkClass="plan-btn-bkk"
      />
    </main>
  );
};
export default BkkLandingPage;
