import styled from 'styled-components';
import { spacing, colors } from 'components/Theme';
import { asRem } from 'utils';

export const InquiryFormStyle = styled.div`
  width: 100%;

  .inquiry-form {
    padding-top: ${asRem(3)};

    display: flex;
    flex-direction: column;

    div.mb-3 {
      width: 100%;
    }

    div input::placeholder,
    div textarea::placeholder {
      color: ${colors.blueGrey.c500};
    }

    select.form-select {
      padding-left: ${asRem(spacing.two)};
      color: ${colors.blueGrey.c500};
    }

    .invalid-feedback {
      margin-left: ${asRem(2)};
    }

    a {
      color: ${colors.blueGrey.c500};
      text-decoration: underline !important;
    }
  }
`;
