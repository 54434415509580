import React from 'react';
import ThankYouComponent from './ThankYouComponent';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils/constants';
import { Link } from 'react-router-dom';

export const ThankYou = () => {
  return (
    <ThankYouComponent showFooter>
      <Link to={ROUTES.library} className="btn btn-grey-c100 d-sm-inline-block">
        {TEXT.thankYou.btnLibrary}
      </Link>
    </ThankYouComponent>
  );
};
