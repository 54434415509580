import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

import vogelImg from 'images/email_confirmation/vogel.png';
import mailImg from 'images/email_confirmation/mail.png';

class EmailConfirmation extends Component {
  render() {
    return (
      <Fragment>
        <section className="main-decorated email-confirmation">
          <Container>
            <Row>
              <Col
                xs={{ size: '12', offset: '0' }}
                sm={{ size: '10', offset: '1' }}
                md={{ size: '8', offset: '2' }}
                lg={{ size: '6', offset: '3' }}
                className="card email-confirmation__card d-block text-center mt-6 mb-0 mb-sm-7 my-md-8"
              >
                <h2 className="text-green-c500 mb-3 mb-sm-2 mb-0">E-Mail bestätigt</h2>
                <p className="text-grey-c800 px-2 px-md-5 mb-3 mb-md-4">Vielen Dank, dass du deine E-Mail bestätigt hast!</p>

                <img className="mx-auto mail-img d-block mb-5" src={mailImg} alt="Email Zeichnung" />

                <img className="ms-auto vogel-img d-block" src={vogelImg} alt="Vogel" />
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default EmailConfirmation;
