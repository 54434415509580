import React from 'react';
import { ThemeContainer } from 'components/Container';
import { Link } from 'Common';
import ReactMarkdown from 'react-markdown';
import slug from 'rehype-slug-custom-id';
import { themeUniversal } from 'components/Theme';

export function MarkdownPanel({ children, className, id }) {
  return (
    <ThemeContainer theme={themeUniversal}>
      <ReactMarkdown
        className={className}
        id={id}
        rehypePlugins={[[slug, { removeAccents: true }]]}
        components={{
          a: ({ ...props }) => {
            return <Link href={props.href}>{props.children}</Link>;
          }
        }}
      >
        {children}
      </ReactMarkdown>
    </ThemeContainer>
  );
}
