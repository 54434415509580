import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';

export class SubscriptionPlan {
  constructor(data) {
    this.id = data.id;
    this.stripeId = data.idAtProvider;
    this.badgeColor = data.badgeColor;
    this.badgeTitle = data.badgeTitle;
    this.buttonColor = data.buttonColor;
    this.provider = data.provider;
    this.isPromo = data.isPromo;
    this.priceExplainer = data.priceExplainer;
    this.priceIntervalInfo = data.priceIntervalInfo;
    this.sort = data.sort;
    this.title = data.title;
    this.chargeAmount = data.chargeAmount;
    this.crossedOutPrice = data.crossedOutPrice;
    this.interval = data.interval;
    this.backendIntervalNaming = data.interval;
    this.subscriptionPlanGroup = data.subscriptionPlanGroup;
    this.taxRatePercentage = data.taxRatePercentage;
    this.zppCertified = data.zppCertified;
    this.currency = 'eur';
    this.coupon = data.coupon;

    if (data.subscriptionPlanGroup === SUBSCRIPTION_PLAN_GROUPS.INSURANCE) {
      this.interval = 'YEARLY';
    }
    if (data.subscriptionPlanGroup === SUBSCRIPTION_PLAN_GROUPS.GIFT) {
      this.interval = 'ONETIME';
    }
  }
}
