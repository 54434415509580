import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getSetupIntent, getStripeClientSecret } from 'redux/query';
import { CONFIG } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPaymentMethodRequest, setUserPaymentInformation, setUserPaymentRefererUrl, stripeSetupIntentRequest } from 'redux/actions';
import { Form, Spinner } from 'Common';
import { getBaseUrl, ROUTES } from 'utils';

export const PaymentContainer = ({ children }) => {
  const stripePromise = loadStripe(CONFIG.STRIPE_PUBLIC_API_KEY);

  const dispatch = useDispatch();

  const clientSecret = useSelector(getStripeClientSecret);
  useEffect(() => {
    if (!!clientSecret) {
      return;
    }
    dispatch(stripeSetupIntentRequest());
  }, [clientSecret]);

  const options = {
    clientSecret,
    locale: 'de'
  };

  if (!clientSecret) {
    return <Spinner />;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

PaymentContainer.Form = ({ children, onChange, onSubmit, billingDetails, refererUrl = null }) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const [isBootstrapped, setIsBootstrapped] = useState(false);
  const setupIntent = useSelector(getSetupIntent);

  const options = {
    fields: {
      billingDetails: {
        address: 'never',
        email: 'never'
      }
    }
  };

  useEffect(() => {
    if (!setupIntent || !isBootstrapped) {
      return;
    }
    onChange({ paymentFilled: true });
    onSubmit();
  }, [setupIntent]);

  useEffect(() => {
    setIsBootstrapped(true);
  }, []);

  const handleOnChangePaymentMethod = (evt) => {
    const paymentMethod = evt.value.type;
    dispatch(setUserPaymentInformation({ paymentMethod }));
    onChange({ paymentFilled: evt.complete });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const confirmPayment = async () => {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return { error: submitError };
      }

      dispatch(setUserPaymentRefererUrl(refererUrl));

      return stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${getBaseUrl(true, true)}${ROUTES.checkoutConfirm}`,
          payment_method_data: {
            billing_details: billingDetails
          }
        }
      });
    };

    dispatch(confirmPaymentMethodRequest({ confirmPayment }));
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <br />
      <PaymentElement options={options} onChange={handleOnChangePaymentMethod} />
      <br />
      {children}
    </Form>
  );
};

PaymentContainer.PaymentSetup = ({ secret = null, onError = () => {}, onComplete = () => {} }) => {
  const stripe = useStripe();

  useEffect(() => {
    if (!secret || !stripe) {
      return;
    }
    stripe.retrieveSetupIntent(secret).then(({ setupIntent, error }) => {
      if (error) {
        return onError(error);
      }
      onComplete(setupIntent);
    });
  }, [stripe, secret]);

  return null;
};
