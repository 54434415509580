import { put, all } from 'redux-saga/effects';
import { clearRegisterUserFailureError, registeringUser, registerUserFailure, registerUserSuccess } from 'redux/actions';
import { actions } from 'react-redux-form';
import { createUserWithOnetimePasswordApiRequest } from 'api/balloon';
import { handleAxiosError } from 'utils';

export function* registerUserSaga({ payload }) {
  const { firstname, email, sendDeepLinkOTP } = payload;
  try {
    yield all([yield put(registeringUser()), yield put(actions.setPending('entities.forms.user', true))]);

    const { data } = yield createUserWithOnetimePasswordApiRequest({
      firstname,
      email,
      mailLinks: sendDeepLinkOTP,
      termsAccepted: true,
      origin: 'WEBAPP'
    });
    if (data.errors) {
      return yield put(registerUserFailure(data.errors));
    }

    yield all([yield put(clearRegisterUserFailureError()), yield put(registerUserSuccess({ result: data }))]);
  } catch (error) {
    yield new Promise((resolve) => {
      handleAxiosError(error, resolve);
    });
    yield put(registerUserFailure(error));
  }
}
