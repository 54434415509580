import { ROUTES } from 'utils';

const getInitialState = () => ({
  isAuthed: false,
  confirmedSubscription: {
    isGiftCoupon: false,
    confirmedPurchase: false,
    autoAssigned: false
  },
  token: null,
  currentUser: null,
  paymentMethodAutoAssigned: false,
  hasSubscription: false,
  isFetching: false,
  zppAlreadyApplied: false,
  isGuest: true,
  isGoogleUser: false,
  isLoginError: false,
  unconfirmedEmail: '',
  refererAfterAuthenticateUrl: ROUTES.profilePersonalData,
  openRequests: [],
  failedRequests: [],
  updatedAt: 0,
  insuranceProvider: '',
  error: null
});

export default {
  getInitialState
};
