import { requestApi } from 'utils';

export const redeemGiftCouponApiRequest = ({ code }) =>
  requestApi.mutate(`
  mutation RedeemGiftCoupon {
    redeemGiftCoupon (code: "${code}") {
      subscription {
        id
      }
    }
  }
`);

export const redeemUnlockCode = ({ code }) =>
  requestApi.mutate(`
  mutation RedeemUnlockCode {
    redeemUnlockCode (code: "${code}") {
      unlocked {
        singles {
          id
        }
        courses {
          id
        }
      }
    }
  }
`);
