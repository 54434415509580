import React, { useEffect, useState } from 'react';
import { Button, Headline, Icon, Image, List } from 'Common';
import PropTypes from 'prop-types';
import { redirectToStoreDownload } from 'utils';
import { usePrevious } from 'hookApi';
import { DownloadModal, DownloadTrigger } from './DownloadAd.style';
import { colors } from 'Theme';
import balloonieWithApp from 'images/webplayer/balloonie-with-app.png';
import downloadIcon from 'images/webplayer/download-icon.svg';
import { TEXT } from 'locales/translations';

function DownloadAd({ openModal, contentType, setStatus }) {
  const [modal, setModal] = useState(openModal);
  const prevModal = usePrevious(modal);

  const toggle = () => {
    setModal(!modal);
  };

  const openStore = () => {
    redirectToStoreDownload();
    setModal(!modal);
  };

  useEffect(() => {
    if (openModal) {
      setModal(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (prevModal && !modal) {
      setTimeout(() => {
        setStatus('end');
      }, 3);
    }
  }, [modal, prevModal, setStatus]);

  let typ = TEXT.downloadAd.typeSingle;
  if (contentType === 'course') {
    typ = TEXT.downloadAd.typeCourse;
  }

  return (
    <DownloadTrigger>
      <Button className="w-auto d-md-none" type="transparent" color="blueGrey" onClick={toggle}>
        <Image src={downloadIcon} className="icon" />
        {typ} herunterladen
      </Button>
      <DownloadModal isOpen={modal} toggle={toggle}>
        <div className="top-container">
          <Image className="image" src={balloonieWithApp} alt="Balloonie mit App" />
          <Headline level={2}>Mit der Balloon-App kannst du:</Headline>
          <List icon={<Icon color={[colors.green.c100, colors.green.c600]} size={{ sm: 32, md: 32, lg: 32 }} name="Checkmark" />}>
            {[TEXT.downloadAd.benefit1, TEXT.downloadAd.benefit2, TEXT.downloadAd.benefit3]}
          </List>
          <Button onClick={openStore}>{TEXT.downloadAd.cta}</Button>
          <Button className="mt-2" type="transparent" color="blueGrey" onClick={toggle}>
            {TEXT.downloadAd.decline}
          </Button>
        </div>
      </DownloadModal>
    </DownloadTrigger>
  );
}

DownloadAd.propTypes = {
  openModal: PropTypes.bool,
  contentType: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};

export default DownloadAd;
