import React from 'react';
import Style from './SectionHeader.style';

export const SectionHeader = ({ checked = false, dataCyName, number, children, className = '' }) => {
  const containerProps = {
    className
  };

  if (dataCyName) {
    containerProps['data-cy-name'] = dataCyName;
  }

  return (
    <Style {...containerProps}>
      {/*ToDo: Span austauschen mit mime_elements*/}
      <span data-text={number} className={number ? (checked ? 'checkmark' : 'number') : ''} />
      <span className="title">{children}</span>
    </Style>
  );
};
