import { HealthCooperations } from 'components/Component';
import React from 'react';
import {
  BalloonieSection,
  BenefitSection,
  BorisSection,
  CourseSection,
  FaqSection,
  HeaderSection,
  RatingSection,
  RefundSection
} from './components';

const HealthInsuranceMarketingPage = () => {
  return (
    <div className="health-insurance-marketing-page">
      <HeaderSection />
      <BenefitSection />
      <BalloonieSection />
      <RefundSection />
      <BorisSection />
      <RatingSection />
      <FaqSection />
      <HealthCooperations id="cooperations-slider" />
      <CourseSection />
    </div>
  );
};
export default HealthInsuranceMarketingPage;
