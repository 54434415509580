import styled from 'styled-components';
import { colors } from 'Theme';
import { asRem } from 'utils';

export default styled.div`
  .logo {
    padding: 0 ${asRem(12)};
    background-color: ${colors.white.c500};
    width: ${asRem(120)};
    box-shadow: 2px 2px 6px ${colors.grey.c600};
    color: ${colors.grey.c800};
    border-radius: ${asRem(8)};
    opacity: 1;
    margin-bottom: ${asRem(16)};
  }
  .card-row {
    max-width: ${asRem(350)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;
