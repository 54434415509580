import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'utils/constants';
import { Link } from 'Common';

ImprintLink.propTypes = {
  className: PropTypes.string
};

export default function ImprintLink({ className }) {
  return (
    <Link className={className} to={ROUTES.imprint}>
      Impressum
    </Link>
  );
}
