import React, { useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { usePageTitle } from 'containers/PageTitleContainer/PageTitleContainer';

export const MainPage = ({ bodyClassName, children, className, containerClassName, title }) => {
  const bodyClassNameRef = useRef(bodyClassName);
  const setPageTitle = usePageTitle();

  if (!!title) {
    setPageTitle(title);
  }

  useLayoutEffect(() => {
    const element = document.getElementsByClassName('rnc__notification-container--top-full');
    if (element.length > 0 && window.notificationId && window.notificationId.length > 0) {
      if (element[0].lastChild) {
        bodyClassNameRef.current = bodyClassNameRef.current + ' notification-box-active';
      }
    } else {
      if (window.notificationId && window.notificationId.length === 0 && document.body.classList.contains('notification-box-active')) {
        document.body.classList.remove('notification-box-active');
      }
    }
  });
  return (
    <main className={className}>
      <Helmet>
        <body className={bodyClassNameRef.current} />
      </Helmet>
      <Container className={containerClassName}>{children}</Container>
    </main>
  );
};
