import { put } from 'redux-saga/effects';
import { validateInsuranceNumberApiRequest } from 'api/balloon';
import { validateInsuranceNumberFailure, validateInsuranceNumberSuccess } from 'redux/actions';

export function* validateInsuranceNumberSaga({ payload }) {
  try {
    const { data } = yield validateInsuranceNumberApiRequest({
      insuranceNumber: payload.insuranceNumber,
      email: payload.email
    });
    if (data.errors?.length) {
      yield put(validateInsuranceNumberFailure(data.errors));
      return;
    }
    yield put(validateInsuranceNumberSuccess());
  } catch (error) {
    yield put(validateInsuranceNumberFailure(error));
  }
}
