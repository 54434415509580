import styled from 'styled-components';

export default styled.div.attrs({ className: 'pb-4 ps-0 form-check mm-checkbox' })`
  cursor: pointer;

  * {
    cursor: pointer;
  }

  .invalid-feedback {
    display: inline-block;
  }
`;
