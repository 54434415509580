import React from 'react';
import Style from './SubscriptionHint.style';
import { useSelector } from 'react-redux';
import { getUserPaymentDetails } from 'redux/query';
import { TEXT } from 'locales/translations';

export const SubscriptionHint = () => {
  const { provider } = useSelector(getUserPaymentDetails);

  if (!provider) {
    return null;
  }

  return (
    <Style className="pb-5">
      {provider === 'APPLE' && <div className="subscription-status__details">{TEXT.subscriptionStatusDetail.iTunesInfo}</div>}
      {(provider === 'GOOGLE_PLAY_STORE' || provider === 'GOOGLE_PAYMENT') && (
        <div className="subscription-status__details">{TEXT.subscriptionStatusDetail.googleInfo}</div>
      )}
      {provider === 'STRIPE' && <div className="subscription-status__details">{TEXT.subscriptionStatusDetail.stripeInfo}</div>}
    </Style>
  );
};
