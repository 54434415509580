import { GET_BGM_COMPANIES_REQUEST, GET_BGM_COMPANIES_SUCCESS, GET_BGM_COMPANIES_FAILURE } from 'redux/actions';

const initialState = {
  bgmCompanies: []
};

export default function bgmCompanies(state = initialState, action) {
  switch (action.type) {
    case GET_BGM_COMPANIES_REQUEST:
      return {
        ...state
      };

    case GET_BGM_COMPANIES_SUCCESS:
      return {
        ...state,
        bgmCompanies: action.payload.map((company) => company.name)
      };

    case GET_BGM_COMPANIES_FAILURE:
      return {
        ...state,
        error: true
      };

    default:
      return state;
  }
}

export const getCompanies = (state) => state.bgmCompanies;
