export const SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST = 'SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST';
export const SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS = 'SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS';
export const SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_FAILURE = 'SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_FAILURE';

export const signInWithOtpAndInsuranceNumberRequest = ({ insuranceNumber, token }) => ({
  type: SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST,
  payload: {
    insuranceNumber,
    token
  }
});

export const signInWithOtpAndInsuranceNumberSuccess = (payload) => ({
  type: SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS,
  payload
});

export const signInWithOtpAndInsuranceNumberFailure = (error) => ({
  type: SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_FAILURE,
  error
});
