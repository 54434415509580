import React from 'react';
import checkmark from 'images/landingpages/b2b_clients/checkmark.svg';
import checkmarkPlus from 'images/landingpages/xing_pro_jobs/checkmark-plus-blue.svg';
import PropTypes from 'prop-types';

const XingCheckmarkBoxSection = ({
  headline,
  subline,
  checkmark_1,
  checkmark_2,
  checkmark_3,
  hasAdditionalCheckmarks,
  additionalCheckmark_1,
  additionalCheckmark_2,
  phoneMobil,
  phoneMobil_300,
  phoneMobil_768,
  phoneMobil_1280,
  phoneLarge
}) => {
  return (
    <section id="checkmark-section-container">
      <div id="content-container">
        <div id="bottom-container">
          <div id="text-container">
            <h2>{headline}</h2>
            <p>{subline}</p>
            <div className="checkmark-container">
              <div className="checkmark-content">
                <img src={checkmark} alt="checkmark" className="img-checkmark" />
                <p>{checkmark_1}</p>
              </div>
              <div className="checkmark-content pt-2">
                <img src={checkmark} alt="checkmark" className="img-checkmark" />
                <p>{checkmark_2}</p>
              </div>
              <div className="checkmark-content pt-2">
                <img src={checkmark} alt="checkmark" className="img-checkmark" />
                <p>{checkmark_3}</p>
              </div>
              {hasAdditionalCheckmarks && (
                <>
                  <div className="checkmark-content pt-2">
                    <img src={checkmarkPlus} alt="plus" className="img-checkmark" />
                    <p>{additionalCheckmark_1}</p>
                  </div>
                  <div className="checkmark-content pt-2">
                    <img src={checkmarkPlus} alt="plus" className="img-checkmark-plus" />
                    <p>{additionalCheckmark_2}</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div id="link-container">
            <img
              className="img-course-phone img-course-phone_mobil"
              src={phoneMobil}
              srcSet={`${phoneMobil_300} 300w, ${phoneMobil_768} 768w, ${phoneMobil_1280} 1280w`}
              alt="Kursbeispiel Mobil"
            />
            <img className="img-course-phone img-course-phone_desktop" src={phoneLarge} alt="Kursbeispiel Mobil" />
          </div>
        </div>
      </div>
    </section>
  );
};

XingCheckmarkBoxSection.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  checkmark_1: PropTypes.string.isRequired,
  checkmark_2: PropTypes.string.isRequired,
  checkmark_3: PropTypes.string.isRequired,
  hasAdditionalCheckmarks: PropTypes.bool.isRequired,
  additionalCheckmark_1: PropTypes.object,
  additionalCheckmark_2: PropTypes.string,
  phoneMobil: PropTypes.node.isRequired,
  phoneMobil_300: PropTypes.node.isRequired,
  phoneMobil_768: PropTypes.node.isRequired,
  phoneMobil_1280: PropTypes.node.isRequired,
  phoneLarge: PropTypes.node.isRequired
};
export default XingCheckmarkBoxSection;
