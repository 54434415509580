import cloudLeft from './hintergrund-illu-wolke-links.png';
import cloudRight from './hintergrund-illu-wolke-rechts.png';
import blauerRandUnten from './blauer-rand-unten.svg';

export const cloudLeftImage = cloudLeft;
export const cloudRightImage = cloudRight;
export const blueBorderContainerBottomImage = blauerRandUnten;

export { default as balloon1x } from './image-kostenlos.png';
export { default as balloon2x } from './image-kostenlos@2x.png';
export { default as balloon3x } from './image-kostenlos@3x.png';

export { default as balloonCheckout1x } from './balloon_checkout.png';
export { default as balloonCheckout2x } from './balloon_checkout@2x.png';
export { default as balloonCheckout3x } from './balloon_checkout@3x.png';

export { default as balloon_intro1x } from './balloon_intro.png';
export { default as balloon_intro2x } from './balloon_intro@2x.png';
export { default as balloon_intro3x } from './balloon_intro@3x.png';
