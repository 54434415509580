import React, { useMemo } from 'react';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import TermsOfServiceLink from 'components/TermsOfServiceLink';
import SocialMediaLink from 'components/SocialMediaLink';
import { AppStoreBadge, GooglePlayBadge, ImprintLink } from 'components';
import { ADDRESSES, hiddenFooterRoutes, ROUTES } from 'utils/constants';
import Style from './Footer.style';
import { Col, Container, Paragraph, Row, Link } from 'Common';
import { TEXT } from 'locales/translations';
import { useLocation } from 'react-router-dom';
import { compareRouteWithPaths } from 'utils';
import { useSelector } from 'react-redux';
import { hasSubscription } from 'redux/query';
import { NavLink } from './NavLink';

export const Footer = () => {
  const location = useLocation();
  const compareRouteWithPath = (route) => compareRouteWithPaths(location.pathname, route);

  const showSubscriptionLink = !useSelector(hasSubscription);

  const isHidden = useMemo(() => hiddenFooterRoutes.some(compareRouteWithPath), [location]);

  const SocialMediaLinks = ({ providers }) => {
    return providers.map((provider) => {
      return <SocialMediaLink key={ROUTES[provider]} sink={ROUTES[provider]} type={provider} className="nav-link social-icon" />;
    });
  };
  const SocialMediaProviders = ['facebook', 'instagram', 'youtube'];

  return !isHidden ? (
    <Style>
      <Container fluid>
        <Row className="m-0 p-0 mt-5 mb-4">
          <Col xs={{ size: 6, order: 2, offset: 0 }} md={{ size: 6, order: 1, offset: 0 }} className="p-0 d-md-flex">
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 6, order: 0, offset: 0 }} className="nav-col">
              <div className="nav-container mb-4 mb-md-0 pe-md-2">
                <Paragraph className="extra-small w-auto mb-2 mb-md-4">{TEXT.footer.yourBalloon}</Paragraph>
                <NavLink to={ROUTES.library}>{TEXT.footer.library}</NavLink>
                <NavLink to={ROUTES.company}>{TEXT.footer.company}</NavLink>
                <NavLink to={ROUTES.krankenkasse}>{TEXT.footer.krankenkasse}</NavLink>
                <NavLink to={ROUTES.healthAdvisor}>{TEXT.footer.healthAdvisor}</NavLink>
                <NavLink to={ROUTES.trustPortal}>{TEXT.footer.trustPortal}</NavLink>
                <NavLink href={'mailto:' + ADDRESSES.supportEmail}>{TEXT.footer.contact}</NavLink>
              </div>
            </Col>
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 6, order: 1, offset: 0 }} className="nav-col">
              <div className="nav-container pe-md-2">
                <Paragraph className="extra-small w-auto mb-2 mb-md-4">{TEXT.footer.partnerships}</Paragraph>
                <NavLink to={ROUTES.home + '#health-section'} offset={{ sm: -20, md: 100, lg: 110 }}>
                  {TEXT.footer.cooperations}
                </NavLink>
                <NavLink to={ROUTES.krankenkasse + '#section-checkout'}>{TEXT.footer.refundCheck}</NavLink>
              </div>
            </Col>
          </Col>
          <Col xs={{ size: 6, order: 3, offset: 0 }} md={{ size: 3, order: 2, offset: 0 }} className="nav-col">
            <div className="nav-container pe-md-3">
              <Paragraph className="extra-small w-auto mb-2 mb-md-4">{TEXT.footer.yourAccess}</Paragraph>
              {showSubscriptionLink && <NavLink to={ROUTES.plans}>{TEXT.footer.plans}</NavLink>}
              <NavLink to={ROUTES.buyGift}>{TEXT.footer.buyGift}</NavLink>
              <NavLink to={ROUTES.voucher}>{TEXT.footer.voucher}</NavLink>
              <NavLink to={ROUTES.terminate}>{TEXT.footer.terminate}</NavLink>
            </div>
          </Col>
          <Col xs={{ size: 12, order: 1, offset: 0 }} md={{ size: 3, order: 3, offset: 0 }} className="p-0 d-flex d-md-block">
            <Col xs={{ size: 6, offset: 0 }} md={{ size: 12, offset: 0 }} className="nav-col">
              <div className="nav-container">
                <Paragraph className="extra-small m-0 w-auto text-start mb-2 mb-md-4">{TEXT.footer.getApp}</Paragraph>
                <div className="mb-2">
                  <AppStoreBadge />
                </div>
                <div className="mb-4">
                  <GooglePlayBadge />
                </div>
              </div>
            </Col>
            <Col xs={{ size: 6, offset: 0 }} md={{ size: 12, offset: 0 }} className="nav-col">
              <div className="nav-container flex-row pt-5 pt-md-2">
                <SocialMediaLinks providers={SocialMediaProviders} />
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Container className="bottom-footer" fluid>
        <Row className="m-0 p-0">
          <Col xs="12" md={{ size: 3, offset: 0 }} className="bottom-nav-col">
            <div className="bottom-nav-container">
              <Paragraph className="extra-small-bold w-auto mt-3 mt-md-0 mb-3 mb-md-0">
                {`${TEXT.footer.copyright} ${new Date().getFullYear()}`}
              </Paragraph>
            </div>
          </Col>
          <Col xs="12" md={{ size: 6, offset: 3 }} xl={{ size: 3, offset: 6 }} className="bottom-footer-end">
            <Col xs={12} md="auto" className="p-0">
              <Paragraph className="extra-small-bold mb-3 mb-md-0">
                <ImprintLink />
              </Paragraph>
            </Col>
            <Col xs={12} md="auto" className="p-0">
              <Paragraph className="extra-small-bold mb-3 mb-md-0 ms-md-3">
                <TermsOfServiceLink />
              </Paragraph>
            </Col>
            <Col xs={12} md="auto" className="p-0">
              <Paragraph className="extra-small-bold mb-3 mb-md-0 ms-md-3">
                <PrivacyPolicyLink />
              </Paragraph>
            </Col>
          </Col>
        </Row>
      </Container>
    </Style>
  ) : null;
};
