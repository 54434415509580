import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/entryContent';
import { GET_ENTRY_CONTENT_PENDING, GET_ENTRY_CONTENT_FAILURE, GET_ENTRY_CONTENT_SUCCESS } from 'redux/actions/actionTypes';

export const gettingEntryContent = () => ({
  type: GET_ENTRY_CONTENT_PENDING
});

export const getEntryContentFailure = (error) => ({
  type: GET_ENTRY_CONTENT_FAILURE,
  error
});

export const getEntryContentSuccess = (data) => ({
  type: GET_ENTRY_CONTENT_SUCCESS,
  payload: data
});

export const getEntryContent = () => (dispatch) => {
  dispatch(gettingEntryContent());
  return api.getEntryContent().then(
    (response) => {
      if (response.data.errors) {
        dispatch(getEntryContentFailure(response.data.errors));
      } else {
        dispatch(getEntryContentSuccess(normalize(response.data.data.entryContent, [schema.entryContent])));
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(getEntryContentFailure(error));
      });
    }
  );
};
