import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { platform } from 'config';
import reducers from './reducers';
import entitiesReducer from './reducers/entities';
import { usersReducer } from './reducers/entities/usersReducer/usersReducer';
import thunk from 'redux-thunk';
import * as api from 'api/balloon';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { rootSaga } from './sagas';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getToken } from './query';
import { REGISTER_USER_GUEST_REQUEST, REGISTER_USER_GUEST_SUCCESS } from './actions';

const persistConfig = {
  key: 'mindful_website',
  storage,
  debug: !platform.isProduction,
  whitelist: ['payment', 'persistApp']
};

const usersPersistConfig = {
  key: 'mindful_website_users',
  storage,
  debug: !platform.isProduction,
  whitelist: ['isAuthed', 'isGuest', 'token', 'currentUser', 'insuranceProvider', 'userMetaData', 'refererAfterAuthenticateUrl']
};

export let Persistor;

export const rehydrateStore = (storeToHydrate) =>
  new Promise((resolve, reject) => {
    Persistor = persistStore(storeToHydrate, null, () => {
      resolve();
    });
  });

export const browserHistory = createBrowserHistory();

const composeEnhancers = platform.isProduction ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let waitForAuthentication = false;
let actionQueue = [];
const authenticationQueue = (store) => (next) => (action) => {
  const state = store.getState();
  const dispatch = store.dispatch;

  const token = getToken(state);
  if (!token && [REGISTER_USER_GUEST_REQUEST].includes(action.type)) {
    waitForAuthentication = true;
    next(action);
    return;
  }
  if ([REGISTER_USER_GUEST_SUCCESS].includes(action.type)) {
    const result = next(action);

    waitForAuthentication = false;
    actionQueue.forEach(dispatch);
    actionQueue = [];

    return result;
  }

  if (waitForAuthentication && action.type && action.type.match(/REQUEST/i)) {
    actionQueue.push(action);
    return next({ type: 'WAIT_FOR_AUTHENTICATION', payload: { queuedAction: action.type } });
  }

  return next(action);
};

const getMiddlewares = ({ history, sagaMiddleware }) => {
  const middlewares = [authenticationQueue, thunk.withExtraArgument({ api }), routerMiddleware(history), sagaMiddleware];
  if (window.__REDUX_CYPRESS__) {
    middlewares.push(window.__REDUX_CYPRESS__);
  }
  if (!platform.isProduction) {
    middlewares.push(logger);
  }
  return middlewares;
};

export default function configureStore(history) {
  const sagaMiddleware = createSagaMiddleware();
  let store = createStore(
    persistCombineReducers(persistConfig, {
      ...reducers,
      entities: combineReducers({
        ...entitiesReducer,
        users: persistReducer(usersPersistConfig, usersReducer)
      }),
      router: connectRouter(history)
    }),
    composeEnhancers(applyMiddleware(...getMiddlewares({ history, sagaMiddleware })))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore(browserHistory);
