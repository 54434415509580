import styled from 'styled-components';
import { asRem } from 'utils';
import { Section } from 'Container';

export default styled(Section)`
  
  .image {
    width: 100%;
    max-width: ${asRem(346)};
    aspect-ratio: 346 / 320;
    height: auto;
  }
`;
