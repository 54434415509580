import styled from 'styled-components';
import { breakpoints, spacing } from 'components/Theme';
import { asRem } from 'utils';
import { Section } from 'Container';

export const CollaborationBenefitStyle = styled(Section)`
  .row {
    padding-top: ${asRem(spacing.four)};

    @media (min-width: ${breakpoints.md}) {
      padding-top: ${asRem(spacing.two)};
    }
  }

  .text {
    width: ${asRem(180)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(220)};
    }
  }
`;
