import React from 'react';
import { TEXT } from 'locales/translations';
import { AccordionItem, Col, Headline, Paragraph, Link, Row } from 'Common';
import { Accordion } from 'Component';
import { Section } from 'Container';
import { ROUTES } from 'utils/constants';

export const Faq = () => {
  return (
    <Section className="faq text-center" backgroundColor="lightGrey">
      <Headline level={4}>{TEXT.company.faq.headline}</Headline>
      <Row>
        <Col xs="12" md={{ size: 10, offset: 1 }}>
          <Accordion>
            <AccordionItem
              header={<Paragraph className="bold">{TEXT.company.faq.headline_1}</Paragraph>}
              body={
                <>
                  <Paragraph>
                    {TEXT.company.faq.body_1a}
                    <Link to={ROUTES.signup}>{TEXT.company.faq.body_1a_linkText}</Link>
                    {'.'}
                    {TEXT.company.faq.body_1b}
                    <Link to={ROUTES.voucher}>{TEXT.company.faq.body_1b_linkText}</Link>
                    {TEXT.company.faq.body_1c}
                  </Paragraph>
                </>
              }
              index={0}
            />
            <AccordionItem
              header={<Paragraph className="bold">{TEXT.company.faq.headline_2}</Paragraph>}
              body={<Paragraph>{TEXT.company.faq.body_2}</Paragraph>}
              index={1}
            />
            <AccordionItem
              header={<Paragraph className="bold">{TEXT.company.faq.headline_3}</Paragraph>}
              body={<Paragraph>{TEXT.company.faq.body_3}</Paragraph>}
              index={2}
            />
          </Accordion>
        </Col>
      </Row>
    </Section>
  );
};
