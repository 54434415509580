import { requestApi } from 'utils';

export const attendInsuranceCooperationApiRequest = ({ insuranceNumber, b2bClientKey, userMetaData }, bearerToken) => {
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json',
    'X-Authorization': `Bearer ${bearerToken}`
  };

  const variables = {
    insuranceNumber: null,
    b2bClientKey,
    dateOfBirth: null
  };

  if (!!`${insuranceNumber || ''}`.trim()) {
    variables.insuranceNumber = insuranceNumber;
  }
  if (!!userMetaData?.dateOfBirth) {
    variables.dateOfBirth = userMetaData.dateOfBirth;
  }
  if (!!userMetaData?.gender) {
    variables.gender = userMetaData.gender;
  }
  if (!!userMetaData?.age) {
    variables.age = parseInt(`${userMetaData.age}`, 10);
  }

  return requestApi.request({
    headers,
    data: {
      variables,
      query: `
        mutation AttendInsuranceCooperation($insuranceNumber: String, $b2bClientKey: String!, $dateOfBirth: ISO8601Date, $gender: Gender, $age: Int ) {
          attendInsuranceCooperation(insuranceNumber: $insuranceNumber, b2bClientKey: $b2bClientKey, dateOfBirth: $dateOfBirth, gender: $gender, age: $age) {
            user {
              id
              email
              firstName
              b2bClientKeys
            }
          }
        }
      `
    }
  });
};
