import styled from 'styled-components';
import { breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const CooperationStyle = styled(Section)`
  align-items: center;
  height: ${asRem(200)};

  @media (min-width: ${breakpoints.md}) {
    height: ${asRem(210)};
  }

  @media (min-width: ${breakpoints.lg}) {
    .container-fluid {
      margin: auto;
      width: 80%;
    }
  }

  .logo {
    aspect-ratio: 100 / 50;
    width: ${asRem(100)};
    height: ${asRem(50)};
    margin: 0 ${asRem(4)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(140)};
      height: ${asRem(70)};
      margin: 0 ${asRem(10)};
    }
  }
`;
