import React from 'react';
import { TEXT } from 'locales/translations';
import { Button, Col, Container, Image, Row } from 'Common';
import { ROUTES } from 'utils';
import seal from 'images/landingpages/health_insurance_marketing/seal-12-months.png';
import balloonie from 'images/landingpages/health_insurance_marketing/header_balloonie_mobile.png';

export const HeaderSection = () => {
  return (
    <section className="header-marketing">
      <Container>
        <Row>
          <Col xs={12} md={7} className="header-marketing__content-container">
            <Image
              src={balloonie}
              alt="Balloonie hört entspannt eine Meditation"
              className="header-marketing__balloonie"
            />
            <h1 className="h1_kk">{TEXT.healthMarketingPage.headerSection.headline}</h1>
            <p className="p_lg_kk">{TEXT.healthMarketingPage.headerSection.copy}</p>
            <Row className="header-marketing__button-row">
              <Col xs={12} lg={6}>
                <Button type="inverted" className="w-100" to={ROUTES.krankenkasse + '#section-checkout'}>
                  {TEXT.healthMarketingPage.headerSection.refundCheckBtn}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Image src={seal} alt="Anerkannt von gesetzlichen Krankenkassen Siegel." className="header-marketing__seal" />
    </section>
  );
};
