import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, ScrollButton } from 'Common';
import { ROUTES } from 'utils/constants';
import seal from 'images/home/seal.png';

class Stage extends Component {
  render() {
    const { hasSubscription } = this.props;

    return (
      <section className="stage">
        <h1 className="stage__heading mb-5">
          Meditieren war noch
          <br />
          nie so einfach!
        </h1>
        <div className="cta-buttons">
          <ScrollButton type="outline-inverted" className="btn-primary mb-2 mb-md-0" to="section-appstores" offset={-40}>
            App herunterladen
          </ScrollButton>

          {!hasSubscription && (
            <Button className="btn-secondary" type="inverted" to={ROUTES.plans}>
              Jetzt abonnieren
            </Button>
          )}
        </div>
        <div className="moving-clouds">
          <div className="breathing-balloon" />
        </div>
        <Image src={seal} alt="Anerkannt von gesetzlichen Krankenkassen Siegel." className="seal" />
      </section>
    );
  }
}
Stage.propTypes = {
  hasSubscription: PropTypes.bool
};

Stage.defaultProps = {
  hasSubscription: false
};

export default Stage;
