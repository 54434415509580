import { put, take, select } from 'redux-saga/effects';
import {
  attendInsuranceCooperationUserDataFailure,
  attendInsuranceCooperationUserDataSuccess,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS,
  authenticateInsuranceCooperationUserRequest,
  setUserAsAuthenticated,
  signInUserOtpFailure
} from 'redux/actions';
import { actions } from 'react-redux-form';
import { attendInsuranceCooperationApiRequest } from 'api/balloon';
import { getUserMetaData, getCurrentUser } from 'redux/query';

export function* attendInsuranceCooperationUserDataRequestSaga({ payload }) {
  const { onetimePassword, attendUserData } = payload;
  yield onetimePassword;
  yield attendUserData;

  const currentUser = yield select(getCurrentUser);
  const userMetaData = yield select(getUserMetaData);

  try {
    yield put(
      authenticateInsuranceCooperationUserRequest({
        token: onetimePassword,
        insuranceNumber: currentUser.insuranceNumber
      })
    );
    const action = yield take([AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS, AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE]);

    if (action.type !== AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS) {
      yield put(attendInsuranceCooperationUserDataFailure(action.error));
      yield put(signInUserOtpFailure(action.error));
      return;
    }

    const { token: bearerToken } = action.payload;

    const { data: attendInsuranceCooperationResponse } = yield attendInsuranceCooperationApiRequest(
      {
        ...attendUserData,
        userMetaData
      },
      bearerToken
    );

    if (attendInsuranceCooperationResponse.errors) {
      const errors = attendInsuranceCooperationResponse.errors.map((error) => error?.message ?? error);
      if (errors.includes('alreadyAppliedError')) {
        yield put(
          attendInsuranceCooperationUserDataSuccess({
            // keep it empty to take the user object from success authentication
            data: { attendInsuranceCooperation: { user: {} }, alreadyApplied: true }
          })
        );
        return;
      }
      yield put(attendInsuranceCooperationUserDataFailure(attendInsuranceCooperationResponse.errors));
      return;
    }

    yield put(actions.setPending('entities.forms.login', false));
    yield put(actions.setPending('entities.forms.user', false));
    yield put(actions.reset('entities.forms.user'));
    yield put(actions.reset('entities.forms.login'));
    yield put(attendInsuranceCooperationUserDataSuccess(attendInsuranceCooperationResponse));
    yield put(setUserAsAuthenticated());
  } catch (error) {
    yield put(attendInsuranceCooperationUserDataFailure(error));
  }
}
