import { put, all } from 'redux-saga/effects';
import { registeringUser, authenticateWithGISFailure, authenticateWithGISSuccess } from 'redux/actions';
import { authenticateWithGISApiRequest } from 'api/balloon';
import { handleAxiosError } from 'utils';

export function* authenticateWithGISSaga({ payload }) {
  const { token } = payload;
  try {
    yield all([yield put(registeringUser())]);

    const { data } = yield authenticateWithGISApiRequest(token);

    if (data.errors) {
      return yield put(authenticateWithGISFailure(data.errors));
    }

    yield all([yield put(authenticateWithGISSuccess({ result: data }))]);
  } catch (error) {
    yield new Promise((resolve) => {
      handleAxiosError(error, resolve);
    });
    yield put(authenticateWithGISFailure(error));
  }
}
