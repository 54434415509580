import styled from 'styled-components';
import { FormGroup } from 'reactstrap';
import { asRem } from 'utils';
import { colors } from 'Theme';

export default styled(FormGroup)`
  label {
    margin-bottom: 0;
    font-size: ${asRem(11)};
    width: 100%;
    height: ${asRem(10)};
    line-height: ${asRem(14.4)};
    hyphens: auto;
  }

  input,
  .input {
    background-color: ${colors.grey.c100} !important;

    &.disabled {
      background-color: ${colors.grey.c200} !important;
    }
  }
`;
