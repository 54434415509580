import React from 'react';
import { TEXT } from 'locales/translations';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

/** @deprecated refactor them with a tab component for general use */
export const FormSwitchDakBgm = ({ handleDakClick, handleOtherClick, isDakActive }) => {
  return (
    <div className="form-switch-dak-bgm">
      <Button onClick={handleDakClick} className={`form-switch-dak-bgm__button ${!isDakActive ? 'dak-inactive' : 'text-active'}`}>
        <p>{TEXT.dakBgm.formSection.leftButton}</p>
      </Button>

      <Button onClick={handleOtherClick} className={`form-switch-dak-bgm__button ${isDakActive ? 'others-inactive' : 'text-active'}`}>
        <p>{TEXT.dakBgm.formSection.rightButton}</p>
      </Button>
    </div>
  );
};

FormSwitchDakBgm.propTypes = {
  handleDakClick: PropTypes.func.isRequired,
  handleOtherClick: PropTypes.func.isRequired,
  isDakActive: PropTypes.bool.isRequired
};

export default FormSwitchDakBgm;
