import React, { useEffect, useRef } from 'react';
import { InquiryForm } from './InquiryForm';
import { TEXT } from 'locales/translations';
import { BackgroundImage, Col, Headline, List, Paragraph, Row } from 'Common';
import { RoundedBox } from 'Component';
import { InquirySectionStyle } from './InquirySection.style';

import { illuInquiryForm, illuParkbench } from 'images/landingpages/b2b_landingpage';
import { useDispatch, useSelector } from 'react-redux';
import { getB2bApiRequest } from 'redux/query/getB2bContact';
import { resetB2bContact } from 'redux/actions';

export default () => {
  const { success } = useSelector(getB2bApiRequest);
  const dispatch = useDispatch();

  const succesBoxRef = useRef(null);

  useEffect(() => {
    dispatch(resetB2bContact());
  }, []);

  useEffect(() => {
    if (success && succesBoxRef.current) {
      try {
        const elementRect = succesBoxRef.current.getBoundingClientRect();

        const absoluteElementTop = elementRect.top + window.scrollY;

        const middlePosition = absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

        window.scrollTo({
          top: middlePosition,
          behavior: 'smooth'
        });
      } catch (error) {
        console.error('Scrolling failed:', error);
      }
    }
  }, [success]);

  return (
    <InquirySectionStyle className="text-start">
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
          <Headline level={2}>{TEXT.healthAdvisor.inquiry.headline}</Headline>
          <Headline level={5} className="text-start subtitle">
            {TEXT.healthAdvisor.inquiry.subtitle}
          </Headline>
          <List>
            {[
              TEXT.healthAdvisor.inquiry.li_1,
              TEXT.healthAdvisor.inquiry.li_2,
              TEXT.healthAdvisor.inquiry.li_3,
              TEXT.healthAdvisor.inquiry.li_4
            ]}
          </List>
          <Paragraph>{TEXT.healthAdvisor.inquiry.body}</Paragraph>
          <div className="image-wrapper">
            <BackgroundImage image={illuInquiryForm} className="content-image" />
          </div>
        </Col>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }} id="inquiryForm" className="anchor">
          {success ? (
            <div ref={succesBoxRef}>
              <RoundedBox className="text-center p-0">
                <BackgroundImage image={illuParkbench} className="success-image" />
                <Headline level={5} className="w-100 ps-3 pe-3">
                  {TEXT.healthAdvisor.inquiry.success.headline}
                </Headline>
                <Paragraph className="w-100 ps-3 pe-3 mb-5">{TEXT.healthAdvisor.inquiry.success.body}</Paragraph>
              </RoundedBox>
            </div>
          ) : (
            <RoundedBox>
              <Headline className="text-start" level={5}>
                {TEXT.healthAdvisor.inquiry.form}
              </Headline>
              <InquiryForm />
            </RoundedBox>
          )}
        </Col>
      </Row>
    </InquirySectionStyle>
  );
};
