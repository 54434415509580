import React from 'react';
import { Button, Col, Container, Row } from 'Common';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import { themeFeeling } from 'Theme';
import { ThemeContainer } from 'Container';

export const MissingMonthlySubscription = () => {
  return (
    <ThemeContainer theme={themeFeeling}>
      <Container className="upgrade-subscription-wrong-abo">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 8, offset: 2 }}>
            <h1 className="plans__heading">{TEXT.upgrade.title}</h1>
            <h1 className="plans__subheading">Oh, du erfüllst die Voraussetzungen für ein Abo-Upgrade nicht</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 7, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <div className="card-stoerer card-info mt-5 mt-sm-6 mt-md-5 mb-md-5">
              <div className="card-body text-center mt-sm-2 mt-md-6 mt-lg-5">
                <div className="plans-btn__info">
                  <p className="plans-btn__title">Es tut uns leid …</p>

                  <p className="plans__subheading">… aber ein Upgrade ist für Dich leider nicht möglich!</p>
                  <p className="plans__subheading">Voraussetzung für ein Upgrade ist ein aktives Monatsabo.</p>

                  <Button to={ROUTES.plans}>Zu den Abonnements</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ThemeContainer>
  );
};
