import React from 'react';
import Style from './PaymentCollapse.style';
import { Row, Col } from 'Common';

export const PaymentCollapse = ({ headline, children, className = '' }) => {
  return (
    <Style className={className}>
      <Row>
        <Col xs={12}>{headline}</Col>
      </Row>
      {children}
    </Style>
  );
};
