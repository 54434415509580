import React, { useEffect, useState } from 'react';
import { BorderedSectionContainer, InsuranceContainer, InsuranceLandingPage } from 'Container';
import {
  HealthRequirementsHint,
  ImageParagraph,
  ImageTextRow,
  LogoChain,
  PartnerLogo,
  PrivacyPolicyHint,
  InsuranceHeaderSection,
  InsuranceNumberAlreadyExistsButton,
  Section,
  PostalCodeHint
} from 'Component';
import { Col, Headline, Icon, Image, Input, Link, List, Paragraph, Row, ScrollButton, Toggle, SelectBox, Typeahead } from 'Common';
import { LogoBrigitte, LogoFlow, LogoGesundLeben } from 'images/trust_band';
import { flower, heart, thumb } from 'images/landingpages/b2b_clients';
import { logoHeaderImage, mobilePhoneImage, successPhoneImage } from 'images/landingpages/dak';
import { colors } from 'Theme';
import { TEXT } from 'locales/translations';
import FormSwitchDakBgm from './DakBgm/components/FormSwitchDakBgm';
import { getBgmCompaniesRequest } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBgmCompanies } from 'redux/reducers';

export const DakBgmLandingPage = () => {
  const dispatch = useDispatch();
  const [isDakActive, setIsDakActive] = useState(true);

  const { formSection: formText, header: headerText, checkmarkSection: checkmarkText, ballooniSection: infoText } = TEXT.dakBgm;
  const brandText = TEXT.dak.trustBandSection;
  const bgmCompanies = useSelector(getBgmCompanies);

  useEffect(() => {
    dispatch(getBgmCompaniesRequest());
  }, []);

  // noinspection DuplicatedCode
  return (
    <InsuranceLandingPage>
      <InsuranceHeaderSection className="header-section lg-p-4">
        <Headline level={1}>{headerText.headline} </Headline>
        <Paragraph className="intro">{headerText.subline}</Paragraph>
        <ScrollButton type="inverted" color="blue" to="form-section" offset={-50}>
          {headerText.button}
        </ScrollButton>
        <PartnerLogo imageSrc={logoHeaderImage} altText={headerText.altText} logoLink={headerText.logoLink} />
        <Paragraph className="add-on-text">{headerText.addOnText}</Paragraph>
      </InsuranceHeaderSection>
      <Section backgroundColor="white" className="checkmark-section">
        <Row>
          <Col xs="12" md="7" lg="6">
            <Headline level={2}>{checkmarkText.headline}</Headline>
            <Paragraph>{checkmarkText.subline_1}</Paragraph>
            <Paragraph>{checkmarkText.subline_2}</Paragraph>
            <List icon={<Icon color={[colors.blue.c200, colors.blue.c600]} size={{ sm: 32, md: 32, lg: 40 }} name="Checkmark" />}>
              {[checkmarkText.checkmark_1, checkmarkText.checkmark_2, checkmarkText.checkmark_3]}
            </List>
          </Col>
          <Col xs="12" md="5" lg="6" className="image">
            <Image src={mobilePhoneImage} alt="Kursbeispiel Mobil" className="image-course-phone" />
          </Col>
        </Row>
      </Section>
      <BorderedSectionContainer topBorder className="text-center">
        <Headline level={2}>{infoText.headline}</Headline>
        <ImageTextRow>
          <ImageParagraph image={thumb}>{infoText.ballooni_1}</ImageParagraph>
          <ImageParagraph image={flower}>{infoText.ballooni_2}</ImageParagraph>
          <ImageParagraph image={heart}>{infoText.ballooni_3}</ImageParagraph>
        </ImageTextRow>
      </BorderedSectionContainer>
      <Section backgroundColor="white" verticalPadding="small" className="brands-section text-centerd-none d-md-block">
        <LogoChain
          headline={<Headline level={5}>{brandText.headline}</Headline>}
          logos={[
            <LogoBrigitte primaryColor={colors.blueGrey.c500} />,
            <LogoFlow primaryColor={colors.blueGrey.c500} />,
            <LogoGesundLeben primaryColor={colors.blueGrey.c500} secondaryColor={colors.white.c500} />
          ]}
        />
      </Section>
      <Section backgroundColor="white" verticalPadding="small" className="form-section">
        <InsuranceContainer b2bClientKey={'DAK_BGM'}>
          <InsuranceContainer.FormView>
            <div className="header">
              <Headline level={2}>{formText.headline}</Headline>
              <Paragraph className="small">
                {formText.infoText}
                <Link href={`mailto:${formText.contactMail}`}>{formText.contactMail}</Link>
              </Paragraph>
            </div>
            <FormSwitchDakBgm
              isDakActive={isDakActive}
              handleDakClick={() => setIsDakActive(true)}
              handleOtherClick={() => setIsDakActive(false)}
            />
            {isDakActive ? (
              <InsuranceContainer.Form
                withoutInsuranceNumber
                defaultValues={{ gender: 'UNKNOWN', company: '' }}
                optionalFields={{ insuranceNumber: true, gender: true, age: true }}
              >
                {({ register }) => (
                  <>
                    <Input {...register('name')} placeholder={formText.placeholderName} />
                    <Input {...register('email')} placeholder={formText.placeholderMail} type="email" />
                    <Typeahead {...register('company')} options={bgmCompanies} placeholder={formText.placeholderCompanyRequired} />
                    <Input {...register('postalCode')} placeholder={formText.postalCode} />
                    <Paragraph className="text-center extra-small mt-4 mb-4">{formText.additionalInfoText}</Paragraph>
                    <Input {...register('insuranceNumber')} placeholder={formText.placeholderNum} />
                    <Paragraph className="extra-small">{TEXT.formsGeneral.insuranceNumberInfo}</Paragraph>
                    <SelectBox {...register('gender')} placeholder={TEXT.formsGeneral.placeholderGender} />
                    <Input {...register('age')} placeholder={TEXT.formsGeneral.placeholderAge} />
                    <Toggle {...register('healthCheck')} className="mb-3" id="health-terms-toggle">
                      <HealthRequirementsHint />
                    </Toggle>
                    <Toggle {...register('termsCheck')} className="mb-3" id="privacy-toggle">
                      <PrivacyPolicyHint />
                    </Toggle>
                    <Toggle {...register('postalCodeCheck')} className="mb-3" id="privacy-toggle">
                      <PostalCodeHint />
                    </Toggle>
                  </>
                )}
              </InsuranceContainer.Form>
            ) : (
              <InsuranceContainer.Form
                withoutInsuranceNumber
                defaultValues={{ gender: 'UNKNOWN' }}
                optionalFields={{ gender: true, age: true }}
              >
                {({ register }) => (
                  <>
                    <Input {...register('name')} placeholder={formText.placeholderName} />
                    <Input {...register('email')} placeholder={formText.placeholderMail} type="email" />
                    <Typeahead {...register('company')} options={bgmCompanies} placeholder={formText.placeholderCompanyRequired} />
                    <Input {...register('postalCode')} placeholder={formText.postalCode} />
                    <Paragraph className="text-center extra-small mt-4 mb-4">{formText.additionalInfoText}</Paragraph>
                    <SelectBox {...register('gender')} placeholder={TEXT.formsGeneral.placeholderGender} />
                    <Input {...register('age')} placeholder={TEXT.formsGeneral.placeholderAge} />
                    <Toggle {...register('healthCheck')} className="mb-3" id="health-terms-toggle">
                      <HealthRequirementsHint />
                    </Toggle>
                    <Toggle {...register('termsCheck')} className="mb-3" id="privacy-toggle">
                      <PrivacyPolicyHint />
                    </Toggle>
                    <Toggle {...register('postalCodeCheck')} className="mb-3" id="privacy-toggle">
                      <PostalCodeHint />
                    </Toggle>
                  </>
                )}
              </InsuranceContainer.Form>
            )}
          </InsuranceContainer.FormView>
          <InsuranceContainer.OtpView />
          <InsuranceContainer.SuccessView>
            <Image src={successPhoneImage} alt="Antistress-Coaching-Kurs" />
          </InsuranceContainer.SuccessView>
        </InsuranceContainer>
      </Section>
    </InsuranceLandingPage>
  );
};
