import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NegativeAmount } from 'components';
import { Amount, DatePresenter, PeriodPresenter } from 'Common';
import { coupon as couponPropType } from 'utils/propTypes';

const lineItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  })
);

const invoicePropType = PropTypes.shape({
  lines: PropTypes.shape({
    data: lineItemsPropType
  }),
  discount: PropTypes.shape({
    coupon: couponPropType
  })
});

const InvoiceHeader = ({ invoice }) => (
  <table className="invoice__header">
    <tbody>
      <tr>
        <td>
          <DatePresenter date={invoice.created} />
        </td>
        <td className="text-right">#{invoice.number}</td>
      </tr>
      {invoice.dueDate && (
        <tr>
          <td>Fällig am</td>
          <td>
            <DatePresenter date={invoice.dueDate} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

InvoiceHeader.propTypes = {
  invoice: PropTypes.shape({
    date: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired
  })
};

const InvoiceLineItems = ({ lines }) => {
  return (
    <table className="invoice__line-items">
      <thead>
        <tr>
          <th>Beschreibung</th>
          <th className="text-right">Betrag</th>
        </tr>
      </thead>
      <tbody>
        {lines.map((item, idx) => (
          <Fragment key={idx}>
            <tr>
              <td>
                <PeriodPresenter start={item.period.start} end={item.period.end} />
              </td>
              <td />
            </tr>
            <tr key={idx}>
              <td>{item.description}</td>
              <td className="text-right">
                <Amount value={item.amount} currency={item.currency} />
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

InvoiceLineItems.propTypes = {
  lines: lineItemsPropType
};

const InvoiceGlobalItems = ({ invoice }) => (
  <table className="invoice__global-items">
    <tbody>
      <tr>
        <td>Zwischensumme</td>
        <td className="text-right">
          <Amount value={invoice.subtotal} currency={invoice.currency} />
        </td>
      </tr>
      {invoice.discount ? (
        <tr>
          <td>{invoice.discount.coupon.name}</td>
          <td className="text-right">
            {invoice.discount.coupon.amountOff ? (
              <NegativeAmount value={invoice.discount.coupon.amountOff} currency={invoice.discount.coupon.currency} />
            ) : (
              <NegativeAmount value={(invoice.subtotal * invoice.discount.coupon.percentOff) / 100} currency={invoice.currency} />
            )}
          </td>
        </tr>
      ) : null}
      <tr>
        <td>Steuer ({invoice.taxPercent} %)</td>
        <td className="text-right">
          <Amount value={invoice.tax} currency={invoice.currency} />
        </td>
      </tr>
      <tr>
        <td>Gesamtsumme</td>
        <td className="text-right">
          <Amount value={invoice.amountDue} currency={invoice.currency} />
        </td>
      </tr>
      <tr>
        <td>
          <strong>Bezahlt</strong>
        </td>
        <td className="text-right">
          <strong>
            <Amount value={invoice.amountPaid} currency={invoice.currency} />
          </strong>
        </td>
      </tr>
    </tbody>
  </table>
);

InvoiceGlobalItems.propTypes = {
  invoice: invoicePropType
};

InvoiceTable.propTypes = {
  invoice: invoicePropType
};

export default function InvoiceTable({ invoice }) {
  return (
    <Fragment>
      <InvoiceHeader invoice={invoice} />
      {invoice.lines && invoice.lines.totalCount > 0 ? <InvoiceLineItems lines={invoice.lines.data} /> : null}
      <InvoiceGlobalItems invoice={invoice} />
    </Fragment>
  );
}
