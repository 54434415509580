import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as routerPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { BooleanPresenter } from 'components';
import { Amount, DatePresenter } from 'Common';
import { invoiceRowPropType } from './invoiceRowPropType';
import { Table } from 'reactstrap';
import { Button } from 'Common';

class InvoiceRow extends Component {
  handleClick = (e, param) => {
    e.preventDefault();
    window.open(param.invoicePdf, '_blank');
  };
  render() {
    const { invoice } = this.props;
    return (
      <tr>
        <td className="ps-0">
          <div className="invoice-name">{invoice.number}</div>
          <DatePresenter date={invoice.created} />
        </td>
        <td className="align-middle">
          <Amount value={invoice.amountDue} currency={invoice.currency} />
        </td>
        <td className="align-middle">
          <BooleanPresenter value={invoice.paid} />
        </td>
        <td className="align-middle pe-0">
          <Button onClick={(e) => this.handleClick(e, invoice)} className="pull-right">
            Download
          </Button>
        </td>
      </tr>
    );
  }
}

InvoiceRow.propTypes = {
  invoice: invoiceRowPropType,
  history: routerPropTypes.history,
  match: routerPropTypes.match.isRequired
};

const InvoiceTable = ({ invoices, match, history }) => (
  <Table className="invoices-table table-borderless" responsive>
    <tbody>
      {invoices.map((invoice) => (
        <InvoiceRow history={history} match={match} key={invoice.id} invoice={invoice} />
      ))}
    </tbody>
  </Table>
);

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(invoiceRowPropType),
  match: routerPropTypes.match.isRequired,
  history: routerPropTypes.history
};

const InvoiceList = ({ invoices, match, history }) => {
  return (
    invoices.length > 0 && (
      <div className="invoices-list">
        <InvoiceTable history={history} match={match} invoices={invoices} />
      </div>
    )
  );
};

InvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(invoiceRowPropType),
  match: routerPropTypes.match.isRequired,
  history: routerPropTypes.history
};

export default InvoiceList;
