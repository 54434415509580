import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NegativeAmount, CouponExplainer, IntervalText } from 'components';
import { Amount } from 'Common';
import { getSelectedSubscriptionPlan } from 'redux/query';

class SubscriptionPanel extends Component {
  render() {
    const { plan } = this.props;

    const taxAmount = plan.chargeAmount * (plan.taxRatePercentage / 100);
    return (
      <Fragment>
        <div className="confirmation-panel__subscription-summary  flex-column">
          <p className="mb-4 d-block subscription-summary__section-headline" dangerouslySetInnerHTML={{ __html: plan.title }} />
          <table className="subscription-summary">
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>Preis</td>
                <td>
                  <Amount value={plan.crossedOutPrice ? plan.crossedOutPrice : plan.chargeAmount} currency={plan.currency} />
                </td>
              </tr>
              {plan.crossedOutPrice ? (
                <tr>
                  <td>&nbsp;</td>
                  <td className="subscription-summary__coupon-text">
                    {plan.coupon.name} <br />
                    <CouponExplainer plan={plan} />
                  </td>
                  <td>{plan.crossedOutPrice ? <NegativeAmount value={plan.chargeAmount} currency={plan.currency} /> : null}</td>
                </tr>
              ) : null}
              <tr>
                <td>&nbsp;</td>
                <td>MwSt. inkl.</td>
                <td>
                  <Amount value={taxAmount} currency={plan.currency} />
                </td>
              </tr>
              <tr className="subscription-summary__total-amount">
                <td>&nbsp;</td>
                <td>
                  Gesamt
                  <br className="br--mobile" /> {plan?.priceIntervalInfo ?? ''}
                </td>
                <td>
                  <Amount value={plan.chargeAmount} currency={plan.currency} />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

SubscriptionPanel.propTypes = {
  plan: PropTypes.shape({
    chargeAmount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  plan: getSelectedSubscriptionPlan(state)
});

export default connect(mapStateToProps)(SubscriptionPanel);
