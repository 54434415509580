import { InsuranceCooperationAuthenticationContext } from '../InsuranceCooperationAuthenticationContext';
import { useContext } from 'react';

export const GetTokenContainer = ({ children }) => {
  const { allowFormSubmit, showPopupError, authenticationError, handleOnOpenAuthenticationWindow } = useContext(
    InsuranceCooperationAuthenticationContext
  );
  if (allowFormSubmit || showPopupError || authenticationError) {
    return null;
  }

  return children({
    openWindow: handleOnOpenAuthenticationWindow
  });
};
