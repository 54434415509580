import React, { Fragment } from 'react';
import { Col, Button, Row } from 'Common';
import { MainPage } from 'Container';
import { ADDRESSES } from 'utils/constants';

const EmailConfirmationError = () => (
  <Fragment>
    <MainPage bodyClassName="background-green-c500" className="thanks" title="Es ist etwas schief gegangen">
      <div className="container hero">
        <Row>
          <Col>
            <h2>Es ist etwas schief gegangen.</h2>
            <br />
            <p>Bitte versuche es noch einmal oder wende dich an den Support.</p>
            <Button type="inverted" href={'mailto:' + ADDRESSES.supportEmail}>
              Support kontaktieren
            </Button>
          </Col>
        </Row>
      </div>
    </MainPage>
  </Fragment>
);

export default EmailConfirmationError;
