import styled from 'styled-components';
import { breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const BrandsStyle = styled(Section)`
  .logo-chain {
    max-width: ${asRem(150)};

    @media (min-width: ${breakpoints.md}) {
      max-width: ${asRem(450)};
      margin-bottom: ${asRem(60)};
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: ${asRem(660)};
    }
  }
`;
