import { requestApi } from 'utils';

export const upgradeSubscriptionWithCouponApiRequest = ({ subscriptionPlanId, couponCode }) =>
  requestApi.mutate(`
  mutation UpgradeSubscriptionWithCoupon {
    switchingSubscriptionPlan(newPlanId: "${subscriptionPlanId}", couponCode: "${couponCode}") {
      id
      state
      isSandbox
      type
      userId
      validTil
      subscriptionPlanId
      subscriptionPlan {
        id
        chargeAmount
        provider
        interval
        title
        isPromo
      }
      createdAt
    }
  }
`);
