import React from 'react';
import TrustRow from './TrustRow';
import TrustCarousel from './TrustCarousel';
import { FormattedMessage } from 'react-intl';

const TrustBand = () => (
  <section className="trust-band">
    <FormattedMessage id="frontpage.testimonials.heading">
      {(heading) => <h2 className="trust-band__heading">{heading}</h2>}
    </FormattedMessage>
    <TrustRow />
    <TrustCarousel />
  </section>
);

export default TrustBand;
