export const SUBSCRIPTION_PLAN_GROUPS = {
  DRAFT: 'draft',
  GIFT: 'gift',
  INSURANCE: 'insurance',
  LEGACY: 'legacy',
  NULL: null,
  VOUCHERS: 'vouchers',
  GOTHAER: 'gothaer',
  BKK: 'bkk'
};
