import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Row, Col } from 'Common';
import { TextBlockImg } from 'Component';
import { BenefitStyle } from './Benefit.style';

import { chair, feather, finger } from 'images/landingpages/b2b_landingpage';

export const Benefit = () => {
  return (
    <BenefitStyle className="text-center" backgroundColor="white">
      <Headline level={2}>{TEXT.company.benefit.headline}</Headline>
      <Row className="text-start text-md-center">
        <Col xs="12" md="4">
          <TextBlockImg image={chair} smRow>
            <Headline level={5}>{TEXT.company.benefit.meditation}</Headline>
          </TextBlockImg>
        </Col>
        <Col xs="12" md="4">
          <TextBlockImg image={feather} smRow>
            <Headline level={5}>{TEXT.company.benefit.happynes}</Headline>
          </TextBlockImg>
        </Col>
        <Col xs="12" md="4">
          <TextBlockImg image={finger} smRow>
            <Headline level={5}>{TEXT.company.benefit.health}</Headline>
          </TextBlockImg>
        </Col>
      </Row>
    </BenefitStyle>
  );
};
