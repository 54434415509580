import { requestApi } from 'utils';

export const authenticateWithGISApiRequest = (token) =>
  requestApi.mutate(
    `
  mutation AuthenticateWithGIS($token: String!) {
    authenticateWithGIS(token: $token) {
      token
      user {
        id
        email
        firstName
        lastName
        isGuest
      }
    }
  }
`,
    {
      token: token
    }
  );
