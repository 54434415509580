import React, { useState } from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Card, BackgroundImage, Paragraph, Span } from 'Common';
import { PartnershipsStyle } from './Partnerships.style';
import { CarouselMindful } from './CarouselMindful';

import { logoXingColor, logoHansefitColor, logoSwissLifeColor } from 'images/landingpages/b2b_landingpage';

import { CarouselIndicators } from 'reactstrap';

const items = [
  {
    component: (
      <div className="card-wrapper">
        <Card className="partner-card">
          <BackgroundImage image={logoSwissLifeColor} />
          <Paragraph className="small">
            <Span className="small-bold">{TEXT.company.partnerships.card_swiss.bold}</Span>
            {TEXT.company.partnerships.card_swiss.body}
          </Paragraph>

          <Paragraph className="extra-small">
            <Span className="extra-small-bold">{TEXT.company.partnerships.card_swiss.name}</Span>
            <br />
            {TEXT.company.partnerships.card_swiss.position}
            <br />
            <Span className="extra-small-bold">{TEXT.company.partnerships.card_swiss.company}</Span>
          </Paragraph>
        </Card>
      </div>
    ),
    key: 1
  },
  {
    component: (
      <div className="card-wrapper">
        <Card className="partner-card">
          <BackgroundImage image={logoXingColor} />
          <Paragraph className="small">
            <Span className="small-bold">{TEXT.company.partnerships.card_xing.bold}</Span>
            {TEXT.company.partnerships.card_xing.body}
          </Paragraph>

          <Paragraph className="extra-small">
            <Span className="extra-small-bold">{TEXT.company.partnerships.card_xing.name}</Span>
            <br />
            {TEXT.company.partnerships.card_xing.position}
            <br />
            <Span className="extra-small-bold"> {TEXT.company.partnerships.card_xing.company}</Span>
          </Paragraph>
        </Card>
      </div>
    ),
    key: 2
  },
  {
    component: (
      <div className="card-wrapper">
        <Card className="partner-card">
          <BackgroundImage image={logoHansefitColor} />
          <Paragraph className="small">
            <Span className="small-bold">{TEXT.company.partnerships.card_hansefit.bold}</Span>
            {TEXT.company.partnerships.card_hansefit.body}
          </Paragraph>

          <Paragraph className="extra-small">
            <Span className="extra-small-bold">{TEXT.company.partnerships.card_hansefit.name}</Span>
            <br />
            {TEXT.company.partnerships.card_hansefit.position}
            <br />
            <Span className="extra-small-bold">{TEXT.company.partnerships.card_hansefit.company}</Span>
          </Paragraph>
        </Card>
      </div>
    ),
    key: 3
  }
];

export const Partnerships = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const [animating, setAnimating] = useState(false);

  const [touchStart, setTouchStart] = useState(0);

  const [touchEnd, setTouchEnd] = useState(0);

  const goToIndex = (index) => {
    setActiveIndex(index);
  };

  const handleNext = () => {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const handlePrevious = () => {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const handleOnStartAnimation = () => {
    setAnimating(true);
  };

  const handleOnEndAnimation = () => {
    setAnimating(false);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 0) {
      handleNext();
    }

    if (touchStart - touchEnd < 0) {
      handlePrevious();
    }
  };

  return (
    <PartnershipsStyle activeIndex={activeIndex} className="text-center" type="full-width" backgroundColor="lightGrey">
      <Headline level={2}>{TEXT.company.partnerships.headline}</Headline>
      <Paragraph>{TEXT.company.partnerships.subline}</Paragraph>
      <div className="carousel-wrapper">
        <CarouselMindful
          items={items}
          activeIndex={activeIndex ?? 0}
          next={handleNext}
          previous={handlePrevious}
          goToIndex={goToIndex}
          onStartAnimation={handleOnStartAnimation}
          onEndAnimation={handleOnEndAnimation}
          onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
          onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => handleTouchEnd()}
        />
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex ?? 0}
          onClickHandler={goToIndex}
          className="mime-elements-carousel-indicators"
        />
      </div>
    </PartnershipsStyle>
  );
};
