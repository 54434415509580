import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';

import IlluMindfulness from 'images/home/mindfulness@2x.png';
import IlluScience from 'images/home/science@2x.png';
import IlluEverydayLife from 'images/home/everydayLife@2x.png';
import AdvantageItem from './AdvantageItem';

class AdvantagesSection extends Component {
  render() {
    return (
      <section className="advantages-section">
        <Container>
          <Row>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 12, offset: 0 }}>
              <TextBlock
                heading={TEXT.advantagesSection.heading}
                extraStyleHeading="margin-bottom-heading"
                body={TEXT.advantagesSection.body}
                extraStyleBody="margin-bottom-body"
                textColor="greyBlue"
                textAlign="center"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 0 }}>
              <AdvantageItem
                image={IlluMindfulness}
                classNameImg="advantage-item__illu"
                alt={TEXT.advantageItem1.alt}
                heading={TEXT.advantageItem1.heading}
                extraStyleHeading="uppercase"
                body={TEXT.advantageItem1.body}
              />
            </Col>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 4, offset: 0 }}>
              <AdvantageItem
                image={IlluScience}
                classNameImg="advantage-item__illu"
                alt={TEXT.advantageItem2.alt}
                heading={TEXT.advantageItem2.heading}
                extraStyleHeading="uppercase"
                body={TEXT.advantageItem2.body}
              />
            </Col>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 0 }}>
              <AdvantageItem
                image={IlluEverydayLife}
                classNameImg="advantage-item__illu"
                alt={TEXT.advantageItem3.alt}
                heading={TEXT.advantageItem3.heading}
                extraStyleHeading="uppercase"
                body={TEXT.advantageItem3.body}
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default AdvantagesSection;
