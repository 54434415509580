import Style from './CreditCard.style';
import { ucFirst } from 'utils';
import creditCardImage from 'images/checkout/img-credit-card.svg';
import masterCardImage from 'images/checkout/img-mastercard.svg';

export const CreditCard = ({ card }) => {
  const renderCardImage = () => {
    switch (card?.brand) {
      case 'mastercard':
        return masterCardImage;
      default:
        return creditCardImage;
    }
  };

  return (
    <Style data-cy-name="user-payment-method-card">
      <img src={renderCardImage()} alt="Logo Kreditkarte" className="logo" />
      {card?.cardHolder && (
        <div className="card-row">
          <div className="name">{card?.cardHolder}</div>
        </div>
      )}
      {card?.brand && card?.last4 && (
        <div className="card-row">
          <div className="brand">{ucFirst(`${card?.brand}`)}</div>
          <div className="last4">****{card?.last4}</div>
        </div>
      )}
      {card?.expMonth && card?.expYear && (
        <div className="card-row">
          <div className="expires-at-label">gültig bis:</div>
          <div className="expires-at">
            {card?.expMonth}/{card?.expYear}
          </div>
        </div>
      )}
    </Style>
  );
};
