import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isFetchingCurrentEntryContent, isFetchingCategories, isFetchingNewContent } from 'redux/reducers';
import { getCategoriesTitle, getEntryContent, getNewContent } from 'redux/actions';
import { getToken } from 'redux/query';

function withLibrary(WrappedComponent) {
  class withLibrary extends Component {
    componentDidMount() {
      const { token, getEntryContent, getCategoriesTitle, getNewContent } = this.props;
      if (token) {
        getEntryContent();
        getCategoriesTitle();
        getNewContent();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  withLibrary.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    token: PropTypes.string,
    getEntryContent: PropTypes.func.isRequired,
    getCategoriesTitle: PropTypes.func.isRequired,
    getNewContent: PropTypes.func.isRequired
  };

  return withLibrary;
}

const mapStateToProps = (state) => ({
  isFetching: isFetchingCategories(state) || isFetchingCurrentEntryContent(state) || isFetchingNewContent(state),
  token: getToken(state)
});

export default compose(
  connect(mapStateToProps, {
    getEntryContent,
    getCategoriesTitle,
    getNewContent
  }),
  withLibrary
);
