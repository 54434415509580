import { requestApi } from 'utils';

export const sendOtpByInsuranceNumberApiRequest = ({ insuranceNumber, sendDeepLinkOTP }) =>
  requestApi.mutate(
    `
  mutation RequestOnetimePasswordWithInsuranceNumber($insuranceNumber: String!, $mailLinks: Boolean, $origin: AppOrigin!, $asterisksCount: Int) {
    requestOnetimePasswordWithInsuranceNumber(insuranceNumber: $insuranceNumber, mailLinks: $mailLinks, origin: $origin, asterisksCount: $asterisksCount) {
      emailHint
    }
  }
`,
    {
      insuranceNumber,
      mailLinks: sendDeepLinkOTP,
      origin: 'WEBAPP',
      asterisksCount: 8
    }
  );
