import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'Common';
import { TEXT } from 'locales/translations';
import { redirectToStoreDownload } from '../../../../utils';

const Episode = ({ episode, headline, shortDescription, description, linkSpotify, linkITunes }) => {
  const [isExtended, setIsExtended] = useState(false);

  return (
    <article className={`episode-container ${isExtended ? 'episode-container__highlight' : ''}`}>
      <Button type="transparent" className="btn-arrow-container" onClick={() => setIsExtended(!isExtended)}>
        <div className={`btn-arrow ${isExtended ? 'btn-arrow__flipped' : ''}`}></div>
      </Button>
      <h4>{episode}</h4>
      <h3>{headline}</h3>
      {!isExtended && <p>{shortDescription}</p>}
      {isExtended && <p>{description}</p>}
      {!isExtended && (
        <Button className="btn-episode" onClick={() => setIsExtended(!isExtended)}>
          {TEXT.podcast.episode_section.button}
        </Button>
      )}
      {isExtended && (
        <Fragment>
          <Button className="btn-episode" onClick={redirectToStoreDownload}>
            {TEXT.podcast.links_section.link_balloon_download}
          </Button>
          <Button href={linkSpotify} color="blue" className="btn-episode">
            {TEXT.podcast.episode_section.link_spotify}
          </Button>

          <Button href={linkITunes} color="green" className="btn-episode">
            {TEXT.podcast.episode_section.link_itunes}
          </Button>
        </Fragment>
      )}
    </article>
  );
};

Episode.propTypes = {
  episode: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkBalloon: PropTypes.string.isRequired,
  linkAudioNow: PropTypes.string.isRequired,
  linkSpotify: PropTypes.string.isRequired,
  linkITunes: PropTypes.string.isRequired
};

export default Episode;
