import React, { useMemo, useState } from 'react';
// ToDO: Btn austauschen
import { Button } from 'reactstrap';
import CardHeader from 'components/Plans/CardHeader';
import { Amount, Col, Row } from 'Common';
import IntervalText from 'components/Plans/IntervalText';
import { TEXT } from 'locales/translations';
import { subscriptionPlan } from 'utils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { colors } from 'components/Theme';
import insuranceImage from 'images/home/krankenkasse-erstattungsfaehig.svg';
import couponImage from 'images/plans/discount-ballooni.jpg';
import discountImage from 'images/plans/discount-decoration.svg';
import DecorativeImage from '../DecorativeImage';

const SubscriptionCard = ({ plan, onClick, hasCoupon }) => {
  const [hovered, setHovered] = useState(false);

  const { reduced, originalPrice, price, badgeColor, badgeTitle, buttonColor, buttonBorderColor, buttonTextColor, reducedPriceColor } =
    useMemo(() => {
      const { crossedOutPrice, chargeAmount, zppCertified, badgeColor: planBadgeColor, badgeTitle: planBadgeTitle } = plan;

      const reducedAmount = crossedOutPrice ? chargeAmount : undefined;
      const originalPrice = crossedOutPrice ? crossedOutPrice : chargeAmount;
      const reduced = typeof reducedAmount !== 'undefined';
      const price = reduced ? reducedAmount : originalPrice;
      const badgeColor = zppCertified || hasCoupon ? colors.pink.c500 : planBadgeColor;
      const badgeTitle = zppCertified
        ? TEXT.subscriptionCard.badgeTitleZpp
        : planBadgeTitle && hasCoupon
        ? TEXT.subscriptionCard.badgeTitleSlogan
        : planBadgeTitle;

      const getColor = (defaultColor) => {
        if (badgeTitle || hovered) {
          if (hasCoupon) {
            return hovered ? colors.pink.c600 : colors.pink.c500;
          } else {
            if (hovered) {
              return plan.zppCertified ? colors.pink.c600 : colors.green.c600;
            } else {
              return plan.zppCertified ? colors.pink.c500 : colors.green.c500;
            }
          }
        } else {
          return defaultColor;
        }
      };

      const buttonColor = getColor('transparent');

      const buttonBorderColor = getColor(colors.blueGrey.c500);
      const buttonTextColor = badgeTitle || hovered ? colors.white.c500 : colors.blueGrey.c500;
      const reducedPriceColor = reduced ? (hasCoupon ? colors.pink.c600 : colors.green.c600) : colors.blueGrey.c500;

      return {
        reduced,
        reducedAmount,
        originalPrice,
        price,
        badgeColor,
        badgeTitle,
        buttonColor,
        buttonBorderColor,
        buttonTextColor,
        reducedPriceColor
      };
    }, [plan, hovered]);

  const PriceBlock = () => (
    <>
      <p className="card-price" style={{ color: reducedPriceColor }}>
        <Amount value={price} currency={plan.currency} />
      </p>
      {reduced && (
        <p className="card-crossed-out-price">
          <Amount value={originalPrice} currency={plan.currency} />
        </p>
      )}
    </>
  );

  const PlanTitle = () => <p className="card-plan-title" dangerouslySetInnerHTML={{ __html: plan.title }} />;

  return (
    <div
      data-cy-name={`subscription-card-${`${plan.title}`.toLowerCase()}`}
      className={`card border-${badgeColor.toString().replace('var(--', '').replace(')', '')}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {badgeTitle && <CardHeader title={badgeTitle} color={badgeColor} />}
      {hasCoupon && !badgeTitle && (
        <DecorativeImage alt={TEXT.subscriptionCard.discount} src={discountImage} className="card-decoration-image" />
      )}
      <Row className="align-items-center flex-grow-1 m-0 py-4">
        <Col
          className={classNames(
            'col-12 p-0 d-flex flex-column align-items-center',
            plan.zppCertified || (hasCoupon && plan.badgeTitle) ? 'col-md-12' : 'col-md-6 ps-md-4 align-items-md-start'
          )}
        >
          {plan.zppCertified && <DecorativeImage alt={TEXT.healthPageSection.alt} src={insuranceImage} className="card-insurance-image" />}
          {plan.zppCertified || (hasCoupon && plan.badgeTitle) ? <PlanTitle /> : <PriceBlock />}
          {!(hasCoupon && plan.badgeTitle) && (
            <span className="card-interval">
              <IntervalText plan={plan} withAsterisk value={price} withPriceExplainer />
            </span>
          )}
        </Col>
        <Col
          className={classNames(
            'col-12 p-0 d-flex flex-column align-items-center',
            plan.zppCertified || (hasCoupon && plan.badgeTitle) ? 'col-md-12' : 'col-md-6 pe-sm-4'
          )}
        >
          {plan.zppCertified || (hasCoupon && plan.badgeTitle) ? <PriceBlock /> : <PlanTitle />}
          {hasCoupon && plan.badgeTitle && (
            <div className="card-interval mb-4">
              <IntervalText plan={plan} withAsterisk value={price} withPriceExplainer />
            </div>
          )}
          <Button
            className="card-call-to-action-btn"
            onClick={onClick}
            style={{
              backgroundColor: buttonColor,
              borderColor: buttonBorderColor,
              color: buttonTextColor
            }}
          >
            {!plan.crossedOutPrice && (plan.interval === 'ONETIME' || plan.zppCertified)
              ? TEXT.subscriptionCard.oneTimePayment_btn
              : TEXT.subscriptionCard.aboPlan_btn}
          </Button>
          {plan.zppCertified && <p className="card-caption">{TEXT.subscriptionCard.caption}</p>}
          {hasCoupon && plan.badgeTitle && (
            <DecorativeImage alt={TEXT.subscriptionCard.discount} src={couponImage} className="card-discount-image" />
          )}
        </Col>
      </Row>
    </div>
  );
};

SubscriptionCard.propTypes = {
  plan: subscriptionPlan.isRequired,
  onClick: PropTypes.func.isRequired,
  hasCoupon: PropTypes.bool
};

export default SubscriptionCard;
