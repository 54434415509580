import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Card, BackgroundImage, List, Paragraph, Button, Badge } from 'Common';
import { CardContainer } from 'Container';
import { OfferStyle } from './Offer.style';
import { HashLink as Link } from 'react-router-hash-link';
import {
  illuBulb,
  illuBoat,
  illuBook,
  illuMobile,
  illuBrain,
  illuPartner,
  landscapeRight,
  landscapeLeft
} from 'images/landingpages/b2b_landingpage';

export const Offer = () => {
  return (
    <>
      <div id="yourBalloon" />
      <OfferStyle className="text-center">
        <Headline level={2}>{TEXT.company.offer.headline}</Headline>
        <Paragraph>{TEXT.company.offer.subline}</Paragraph>
        <CardContainer
          cards={[
            <Card>
              <BackgroundImage image={illuBoat} />
              <Headline level={5} className="text-start">
                {TEXT.company.offer.card_1.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_1.li_1, TEXT.company.offer.card_1.li_2, TEXT.company.offer.card_1.li_3]}
              </List>
            </Card>,
            <Card>
              <BackgroundImage image={illuPartner} />
              <Headline level={5} className="text-start">
                {TEXT.company.offer.card_2.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_2.li_1, TEXT.company.offer.card_2.li_2, TEXT.company.offer.card_2.li_3]}
              </List>
            </Card>,
            <Card>
              <BackgroundImage image={illuMobile} />
              <Headline level={5} className="text-start">
                {TEXT.company.offer.card_3.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_3.li_1, TEXT.company.offer.card_3.li_2, TEXT.company.offer.card_3.li_3]}
              </List>
            </Card>,
            <Card>
              <BackgroundImage image={illuBook} />
              <Headline level={5} className="text-start">
                {TEXT.company.offer.card_4.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_4.li_1, TEXT.company.offer.card_4.li_2, TEXT.company.offer.card_4.li_3]}
              </List>
            </Card>,
            <Card>
              <BackgroundImage image={illuBrain} />
              <Headline level={5} className="text-start">
                {TEXT.company.offer.card_5.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_5.li_1, TEXT.company.offer.card_5.li_2, TEXT.company.offer.card_5.li_3]}
              </List>
            </Card>,
            <Card>
              <BackgroundImage image={illuBulb} />
              <Badge>auf Anfrage</Badge>
              <Headline level={5} className="bold text-start">
                {TEXT.company.offer.card_6.headline}
              </Headline>
              <List className="small">
                {[TEXT.company.offer.card_6.li_1, TEXT.company.offer.card_6.li_2, TEXT.company.offer.card_6.li_3]}
              </List>
            </Card>
          ]}
          cols={{ xs: 12, md: 6, lg: 4 }}
        />
        <Link to="#b2bContactForm">
          <Button type="inverted">{TEXT.company.contactBtn}</Button>
        </Link>

        <BackgroundImage image={landscapeLeft} className="landscape landscape-left" />
        <BackgroundImage image={landscapeRight} className="landscape landscape-right" />
      </OfferStyle>
    </>
  );
};
