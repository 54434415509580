import { all, put, take } from 'redux-saga/effects';

import {
  VALIDATE_INSURANCE_NUMBER_SUCCESS,
  validateInsuranceNumberRequest,
  registerUserWithInsuranceNumberFailure,
  registerHealthInsuranceUserRequest,
  registerUserWithInsuranceNumberSuccess
} from 'redux/actions';

export function* registerUserWithInsuranceNumberSaga({ payload }) {
  const { user, sendDeepLinkOTP, optionalInsuranceNumber = false } = payload;
  yield user;
  try {
    if (!optionalInsuranceNumber || `${user.insuranceNumber}`.length > 0) {
      yield put(
        validateInsuranceNumberRequest({
          insuranceNumber: payload.user.insuranceNumber,
          email: payload.user.email
        })
      );
      yield take(VALIDATE_INSURANCE_NUMBER_SUCCESS);
    }
    yield all([put(registerHealthInsuranceUserRequest({ user, sendDeepLinkOTP })), put(registerUserWithInsuranceNumberSuccess())]);
  } catch (error) {
    yield put(registerUserWithInsuranceNumberFailure(error));
  }
}
