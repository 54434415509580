import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import {
  RETRIEVE_ALERTS_PENDING,
  RETRIEVE_ALERTS_FAILURE,
  RETRIEVE_ALERTS_SUCCESS,
  ALERT_TOGGLE_VISIBILITY
} from 'redux/actions/actionTypes';

import * as api from 'api/balloon/alerts';

export const retrievingAlerts = () => ({
  type: RETRIEVE_ALERTS_PENDING
});

export const retrieveAlertsFailure = (error) => ({
  type: RETRIEVE_ALERTS_FAILURE,
  error
});

export const retrieveAlertsSuccess = (payload) => ({
  type: RETRIEVE_ALERTS_SUCCESS,
  payload
});

export const toggleAlert = (id) => ({
  type: ALERT_TOGGLE_VISIBILITY,
  id
});

export const retrieveAlerts = () => (dispatch) => {
  dispatch(retrievingAlerts());
  return api.getAlerts().then(
    (response) => {
      if (typeof response === 'undefined' || response === null) {
        dispatch(retrieveAlertsFailure({}));
      } else {
        dispatch(retrieveAlertsSuccess(normalize(response.data.data.alerts || [], [schema.alert])));
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(retrieveAlertsFailure(error));
      });
      throw error;
    }
  );
};
