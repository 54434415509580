import React, { useContext } from 'react';
import { useInsuranceFormType } from 'hookApi';
import { InsuranceContainerContext } from 'Container/InsuranceContainer/InsuranceContainerContext';

export const FormView = ({ children }) => {
  const { b2bClientKey } = useContext(InsuranceContainerContext);
  const formType = useInsuranceFormType({ b2bClientKey });

  if (formType !== 'RegisterFormView') {
    return null;
  }

  return children;
};
