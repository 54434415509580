import React, { Component } from 'react';
import daylightProduct from 'images/landingpages/beurer/daylightProduct.png';
import { isAuthed, isGuest } from 'redux/query';
import { connect } from 'react-redux';
import BeurerPage from './BeurerPage';
import PropTypes from 'prop-types';

class Licht extends Component {
  render() {
    const { isGuest, isAuthed } = this.props;
    return (
      <BeurerPage
        code="LICHT"
        yourProduct="deiner Tageslichtlampe TL 100"
        description="Der mehrteilige Kurs ist so konzipiert, dass er dich zusammen
                    mit der BEURER Tageslichtlampe beim leichteren Aufwachen und zur
                    Aufhellung der Stimmung unterstützt. Zudem findest du in dem
                    Kurs Übungen zur Entspannung, zur Stärkung der geistigen
                    Klarheit sowie zur Anregung emotionaler Qualitäten wie
                    Dankbarkeit und Mitgefühl."
        productImage={daylightProduct}
        productName="Tageslichtlampe TL 100"
        backgroundImageClass="licht-header"
        isAuthed={isAuthed}
        isGuest={isGuest}
      />
    );
  }
}

Licht.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired
};

export const mapStateToProps = (state) => {
  return {
    isAuthed: isAuthed(state),
    isGuest: isGuest(state)
  };
};

export default connect(mapStateToProps, {})(Licht);
