import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';
import { uniq } from 'lodash';

const initialState = {
  byId: {},
  byUser: {},
  isFetching: false
};

export default function invoices(state = initialState, action) {
  switch (action.type) {
    case types.GET_INVOICES_PENDING:
      return {
        ...state,
        isFetching: true
      };

    case types.GET_INVOICES_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.entities.stripeInvoices
        },
        byUser: {
          ...state.byUser,
          // Appending the result at the end is, for the moment, irrelevant,
          // since only the first 12 invoices are ever loaded. Once paging
          // were introduced, we might have to check this.
          // Keep in mind that the array in [action.userId] should keep
          // the order in which invoices are presented, that is, reverse
          // chronological order. The API calls must be made accordingly for
          // this to work.
          [action.userId]: state.byUser[action.userId]
            ? uniq([...state.byUser[action.userId], ...action.payload.result])
            : action.payload.result
        },
        isFetching: false,
        error: undefined
      };
    }

    case types.GET_INVOICES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state && state.isFetching;
export const getForUser = (state, uid) => {
  return (state.byUser[uid] || []).map((invoiceId) => state.byId[invoiceId]);
};
export const getByNumber = (state, number) => Object.values(state.byId).filter((invoice) => invoice.number === number)[0];
