import React, { useEffect, useRef } from 'react';
import { FormB2BContact } from './FormB2BContact';
import { TEXT } from 'locales/translations';
import { BackgroundImage, Col, Headline, Paragraph, Row } from 'Common';
import { RoundedBox } from 'Component';
import { WhitepaperStyle } from './Whitepaper.style';

import { chairBackground, illuMagazine } from 'images/landingpages/b2b_landingpage';
import { useDispatch, useSelector } from 'react-redux';
import { getB2bApiRequest } from 'redux/query/getB2bContact';
import { resetB2bContact } from 'redux/actions';

export const Whitepaper = () => {
  const { success } = useSelector(getB2bApiRequest);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetB2bContact());
  }, []);

  const succesBoxRef = useRef(null);

  useEffect(() => {
    if (success && succesBoxRef.current) {
      try {
        const elementRect = succesBoxRef.current.getBoundingClientRect();

        const absoluteElementTop = elementRect.top + window.scrollY;

        const middlePosition = absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

        window.scrollTo({
          top: middlePosition,
          behavior: 'smooth'
        });
      } catch (error) {
        console.error('Scrolling failed:', error);
      }
    }
  }, [success]);

  return (
    <WhitepaperStyle className="text-start text-md-center text-lg-start">
      <Row>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 5, offset: 0 }}>
          <Headline level={2}>{TEXT.company.whitepaper.headline}</Headline>
          <Paragraph>{TEXT.company.whitepaper.body}</Paragraph>
          <div className="image-wrapper">
            <BackgroundImage image={chairBackground} className="whitepaper-image" />
          </div>
        </Col>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 1 }} className="anchor" id="b2bContactForm">
          {success ? (
            <div ref={succesBoxRef}>
              <RoundedBox className="text-center success-box">
                <BackgroundImage image={illuMagazine} />
                <Headline level={5}>{TEXT.company.whitepaper.success.headline}</Headline>
                <Paragraph>{TEXT.company.whitepaper.success.body_1}</Paragraph>
                <Paragraph>{TEXT.company.whitepaper.success.body_2}</Paragraph>
              </RoundedBox>
            </div>
          ) : (
            <RoundedBox className="form-wrapper">
              <Headline className="text-start" level={5}>
                {TEXT.company.whitepaper.form}
              </Headline>
              <FormB2BContact />
            </RoundedBox>
          )}
        </Col>
      </Row>
    </WhitepaperStyle>
  );
};
