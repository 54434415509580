import React from 'react';
import PropTypes from 'prop-types';

import Stern1x from 'images/trust_band/stern.png';
import Stern2x from 'images/trust_band/stern@2x.png';
import Stern3x from 'images/trust_band/stern@3x.png';

import SternSw1x from 'images/home/stern.png';
import SternSw2x from 'images/home/stern@2x.png';
import SternSw3x from 'images/home/stern@3x.png';

import DecorativeImage from '../../components/DecorativeImage';

SternLogo.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  isColored: PropTypes.bool
};

export default function SternLogo({ className, sizes, isColored }) {
  return (
    <DecorativeImage
      className={className}
      srcSet={`${isColored ? Stern1x : SternSw1x} 127w, ${isColored ? Stern2x : SternSw2x} 254w, ${isColored ? Stern3x : SternSw3x} 381w`}
      sizes={sizes}
      src={Stern1x}
    />
  );
}
