import { requestApi } from 'utils';

export const signInUserWithOnetimePasswordApiRequest = ({ email, token }) =>
  requestApi.mutate(
    `
  mutation SignInUserWithOnetimePassword($email: String!, $token: String!) {
    signInUserWithOnetimePassword(email: $email, token: $token) {
      user {
        id
        email
        firstName
        lastName
        isGuest
        unconfirmedEmail
        b2bClientKeys
      }
      token
    }
  }
`,
    {
      email: email,
      token: token
    }
  );
