import React, { useEffect, useMemo, useState } from 'react';
import { TEXT } from 'locales/translations';
import { Amount, Col, Container, Form, Row, Spinner } from 'Common';
import IntervalText from 'components/Plans/IntervalText';
import { FlashListContainer } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubscriptionPlanByInterval,
  getUserUpgradeProration,
  isSubscriptionPlansLoading,
  isUpgradeSubscriptionFailed
} from 'redux/query';
import { CheckboxField } from 'Component';
import { ROUTES } from 'utils';
import { Redirect } from 'react-router-dom';
import { postCheckoutSubscriptionUpgradeRequest } from 'redux/actions';
// ToDo: refactor this component for the correct folder structure
import TermsOfServiceLink from 'components/TermsOfServiceLink';

export const UserSubscriptionUpgrade = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSubscriptionPlansLoading);
  const monthlySubscriptionPlan = useSelector(getSubscriptionPlanByInterval('MONTHLY'));
  const yearlySubscriptionPlan = useSelector(getSubscriptionPlanByInterval('YEARLY'));
  const { reducedPrice, lastUpdateDate } = useSelector(getUserUpgradeProration);
  const hasError = useSelector(isUpgradeSubscriptionFailed);

  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [redirectToErrorPage, setRedirectToErrorPage] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const { coupon, reducedAmount, originalPrice } = useMemo(
    () => ({
      coupon: yearlySubscriptionPlan.crossedOutPrice ? yearlySubscriptionPlan.coupon : undefined,
      reducedAmount: yearlySubscriptionPlan.crossedOutPrice ? yearlySubscriptionPlan.chargeAmount : undefined,
      originalPrice: yearlySubscriptionPlan.crossedOutPrice ? yearlySubscriptionPlan.crossedOutPrice : yearlySubscriptionPlan.chargeAmount
    }),
    [yearlySubscriptionPlan, monthlySubscriptionPlan]
  );

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (hasError && initialized) {
      setRedirectToErrorPage(true);
    }
  }, [hasError, initialized]);

  const unusedTimeValue = originalPrice - reducedPrice;
  const savingsMonthly = monthlySubscriptionPlan.chargeAmount * 12 - (reducedAmount ? reducedAmount : reducedPrice);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (!termsConfirmed) {
      return setShowError(true);
    }
    const dMinutes = Math.floor(Math.abs((new Date() - lastUpdateDate) / (1000 * 60)));
    if (dMinutes >= 10) {
      return setRedirectToErrorPage(true);
    }
    dispatch(postCheckoutSubscriptionUpgradeRequest());
  };

  const handleOnChangeCheckbox = (terms) => {
    setShowError(false);
    setTermsConfirmed(terms);
  };

  if (redirectToErrorPage) {
    return <Redirect to={ROUTES.upgradeerror} />;
  }

  return (
    <Container>
      <Row>
        <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 8, offset: 2 }}>
          <h1 className="plans__heading">{TEXT.upgrade.title}</h1>
          <p className="plans__subheading">
            {TEXT.upgrade.saving}
            <Amount value={savingsMonthly} currency={yearlySubscriptionPlan.currency} />
            {TEXT.upgrade.infoText}
            <Amount value={unusedTimeValue} currency={yearlySubscriptionPlan.currency} />
            {TEXT.upgrade.credited}
          </p>
          <FlashListContainer />
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 7, offset: 3 }} lg={{ size: 4, offset: 4 }}>
          <div className="card-stoerer card-info mt-5 mt-sm-6 mt-md-5 mb-md-5">
            <div className="card-body text-center mt-sm-2 mt-md-6 mt-lg-5">
              <div className="plans-btn__info">
                <p
                  className="plans-btn__title"
                  dangerouslySetInnerHTML={{
                    __html: yearlySubscriptionPlan.title
                  }}
                />
                <div className="plans-btn__pricing">
                  <>
                    {isLoading ? (
                      <div className="fetching-spinner">
                        <Spinner className="upgrade-spinner" />
                      </div>
                    ) : (
                      <p className="plans-btn__amount">
                        <Amount
                          value={reducedAmount ? reducedAmount - unusedTimeValue : reducedPrice}
                          currency={yearlySubscriptionPlan.currency}
                        />
                      </p>
                    )}
                  </>

                  <div className="amount_content">
                    <p className="plans-btn__interval">
                      <IntervalText plan={yearlySubscriptionPlan} withAsterisk={true} />
                    </p>
                    <p className="plans-btn__original-amount">
                      &nbsp; {TEXT.subscriptionCard.preText_discount}
                      &nbsp;
                      <Amount value={originalPrice} currency={yearlySubscriptionPlan.currency} />
                    </p>
                  </div>
                  <p className="plans__subheading">
                    <strong>
                      <Amount value={savingsMonthly / 12} currency={yearlySubscriptionPlan.currency} />
                      {TEXT.upgrade.savingPerMonth}
                    </strong>
                    {TEXT.upgrade.savingCurrentMonth}
                  </p>
                </div>
              </div>
              <Form onSubmit={handleOnSubmit}>
                <CheckboxField
                  name="terms"
                  label={
                    <>
                      Ich stimme den <TermsOfServiceLink /> zu.
                    </>
                  }
                  errorMessage={showError && 'Du musst den AGB zustimmen.'}
                  onChange={handleOnChangeCheckbox}
                />

                <div>
                  {isLoading ? (
                    <Spinner className="upgrade-spinner" />
                  ) : (
                    <button className="btn btn__promo btn btn-secondary btn-white-space-pre">
                      {TEXT.upgrade.btnTitle_1}
                      <Amount
                        className="font-weight-bold"
                        value={reducedAmount ? reducedAmount - unusedTimeValue : reducedPrice}
                        currency={yearlySubscriptionPlan.currency}
                      />
                      {TEXT.upgrade.btnTitle_2}
                    </button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }}>
          <p className="small text-center subscription-disclaimer mt-3 mt-md-0">
            {coupon ? TEXT.upgrade.codeSubscriptionDisclaimer : TEXT.upgrade.subscriptionDisclaimer}
          </p>
        </Col>
      </Row>
    </Container>
  );
};
