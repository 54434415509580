import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Webplayer } from 'components';
import { getSingleBySlug } from 'redux/actions';
import { getSingle, getPlayerStatus } from 'redux/reducers';
import { getToken, isGuest } from 'redux/query';
import PropTypes from 'prop-types';
import WithSingle from './WithSingle';
import withSpinner from 'components/withSpinner';

class SinglePlayerContainer extends Component {
  componentDidUpdate(prevProps) {
    const oldSlug = prevProps.match.params.slug;

    const newSlug = this.props.match.params.slug;

    const { getSingleBySlug, playerStatus, token } = this.props;
    if (newSlug !== oldSlug) {
      getSingleBySlug(newSlug);
      window.scrollTo(0, 0);
    }
    if (prevProps.playerStatus !== playerStatus && playerStatus === 'end') {
      getSingleBySlug(newSlug);
    }
    if (prevProps.token !== token) {
      getSingleBySlug(newSlug);
    }
  }

  render() {
    return <Webplayer {...this.props} />;
  }
}

SinglePlayerContainer.propTypes = {
  getSingleBySlug: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  playerStatus: PropTypes.string,
  token: PropTypes.string,
  isGuest: PropTypes.bool.isRequired
};

SinglePlayerContainer.defaultProps = {
  content: {}
};

const mapStateToProps = (state) => ({
  content: getSingle(state),
  contentType: 'single',
  playerStatus: getPlayerStatus(state),
  token: getToken(state),
  isGuest: isGuest(state)
});

export default WithSingle(
  withSpinner(
    connect(mapStateToProps, {
      getSingleBySlug
    })(SinglePlayerContainer)
  )
);
