import React, { useMemo } from 'react';
import Style from './GiftSubscriptionPlan.style';
import illuBlue from 'images/gift/gutschein-karte-blue@2x.png';
import illuGreen from 'images/gift/gutschein-karte-green@2x.png';
import illuPink from 'images/gift/gutschein-karte-pink@2x.png';
import { TEXT } from 'locales/translations';
import { Amount, Paragraph, Button } from 'Common';
import { selectSubscriptionPlan } from 'redux/actions';
import { ROUTES } from 'utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const GiftSubscriptionPlan = ({ className = '', plan }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const text = useMemo(() => {
    switch (`${plan.backendIntervalNaming}`.toLowerCase()) {
      case 'yearly':
        return {
          label: TEXT.gift.intervalYearly,
          alt: 'balloon 1 jahr verschenken'
        };
      case 'monthly':
        return {
          label: TEXT.gift.intervalMonthly,
          alt: 'balloon 1 monat verschenken'
        };
      case 'quarterly':
        return {
          label: TEXT.gift.intervalQuarterly,
          alt: 'balloon 3 monate verschenken'
        };
      default:
        return {
          label: TEXT.gift.intervalYearly,
          alt: 'balloon 1 jahr verschenken'
        };
    }
  }, [plan]);

  const color = useMemo(() => {
    switch (`${plan.backendIntervalNaming}`.toLowerCase()) {
      case 'yearly':
        return 'blue';
      case 'monthly':
        return 'pink';
      case 'quarterly':
        return 'green';
      default:
        return 'blue';
    }
  }, [plan]);

  const image = useMemo(() => {
    switch (`${plan.backendIntervalNaming}`.toLowerCase()) {
      case 'yearly':
        return illuBlue;
      case 'monthly':
        return illuPink;
      case 'quarterly':
        return illuGreen;
      default:
        return illuBlue;
    }
  }, [plan]);

  const subscriptionName = useMemo(() => {
    switch (`${plan.backendIntervalNaming}`.toLowerCase()) {
      case 'yearly':
        return 'one-year';
      case 'monthly':
        return 'one-month';
      case 'quarterly':
        return 'three-month';
      default:
        return 'unknown';
    }
  }, [plan]);

  const handleOnSelectSubscription = () => {
    dispatch(selectSubscriptionPlan(plan.id));
    history.push(ROUTES.checkout);
  };

  return (
    <Style className={`color-${color} ${className}`}>
      <img className="subscription-image" src={image} alt={text.alt} />
      <Paragraph className="subscription-name">
        {text.label}
        <Amount value={plan.chargeAmount} currency={plan.currency} />
      </Paragraph>
      <Button
        id={`gift-btn-ot-payment-${subscriptionName}`}
        className="subscription-button"
        dataCyName={`gift-btn-${subscriptionName}`}
        onClick={handleOnSelectSubscription}
        type="inverted"
        color={color}
      >
        {TEXT.gift.buyBtn}
      </Button>
    </Style>
  );
};
