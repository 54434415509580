import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Webplayer } from 'components';
import withSpinner from 'components/withSpinner';
import { getCourseBySlug } from 'redux/actions';
import WithCourse from './WithCourse';
import { getCourse, getPlayerStatus } from 'redux/reducers';
import { isGuest } from 'redux/query';
import PropTypes from 'prop-types';

class CoursePlayerContainer extends Component {
  componentDidUpdate(prevProps) {
    const oldSlug = prevProps.match.params.slug;

    const { getCourseBySlug, playerStatus, match } = this.props;

    const newSlug = match.params.slug;
    if (newSlug !== oldSlug) {
      getCourseBySlug(newSlug);
      window.scrollTo(0, 0);
    }
    if (prevProps.playerStatus !== playerStatus && playerStatus === 'end') {
      getCourseBySlug(newSlug);
    }
  }

  render() {
    return <Webplayer {...this.props} />;
  }
}

CoursePlayerContainer.propTypes = {
  getCourseBySlug: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  playerStatus: PropTypes.string,
  token: PropTypes.string,
  isGuest: PropTypes.bool.isRequired
};

CoursePlayerContainer.defaultProps = {
  content: {}
};

const mapStateToProps = (state) => ({
  content: getCourse(state),
  contentType: 'course',
  playerStatus: getPlayerStatus(state),
  isGuest: isGuest(state)
});

export default WithCourse(withSpinner(connect(mapStateToProps, { getCourseBySlug })(CoursePlayerContainer)));
