import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

// See https://github.com/reactstrap/reactstrap/issues/83#issue-168537815
// on how to connect NavLink with react-router-dom's link
export const BrandLink = () => (
  <NavLink className="navbar-brand p-0" tag={Link} to={ROUTES.home}>
    Balloon Home
  </NavLink>
);

export const LogoutLink = ({ onLogout }) => (
  <DropdownItem tag={Link} onClick={onLogout} to={ROUTES.home}>
    Logout
  </DropdownItem>
);

LogoutLink.propTypes = {
  onLogout: PropTypes.func.isRequired
};

export const ProfileLink = ({ onClick }) => (
  <DropdownItem tag={Link} to={ROUTES.profilePersonalData} onClick={onClick} data-cy-name="profile-link">
    Mein Profil
  </DropdownItem>
);

export const RegistrationLink = () => (
  <NavItem>
    <NavLink tag={Link} to={ROUTES.signup}>
      Registrieren
    </NavLink>
  </NavItem>
);

export const LoginLink = () => (
  <NavItem>
    <NavLink tag={Link} to={ROUTES.login}>
      Anmelden
    </NavLink>
  </NavItem>
);

export const SubscribeLink = ({ onSubscribe }) => (
  <NavItem>
    <NavLink tag={Link} onClick={onSubscribe} to={ROUTES.plans}>
      Preise
    </NavLink>
  </NavItem>
);

SubscribeLink.propTypes = {
  onSubscribe: PropTypes.func.isRequired
};
