import styled from 'styled-components';
import { breakpoints, spacing, colors } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';
// Card wrapper gives the cards a gap left and right, so they are centered in the carousel.
// To change the gap between the elements use the width of the consts below

const ItemWidthSm = 305;

const ItemWidthMd = 520;

export const PartnershipsStyle = styled(Section)`
  .card-wrapper {
    width: ${asRem(ItemWidthSm)};
    display: flex;
    justify-content: center;

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(ItemWidthMd)};
    }
  }

  .partner-card {
    flex: 0 0 auto;
    width: ${asRem(280)};
    height: ${asRem(510)};
    margin: ${asRem(spacing.four)} auto ${asRem(spacing.two)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(490)};
      height: ${asRem(434)};
    }

    .image {
      aspect-ratio: 150 / 75;
      width: ${asRem(150)};
      align-self: center;
      margin-bottom: ${asRem(spacing.two)};
      @media (min-width: ${breakpoints.md}) {
        margin-bottom: ${asRem(spacing.three)};
      }
    }

    p.extra-small {
      margin-top: auto;
      @media (min-width: ${breakpoints.md}) {
        height: ${asRem(70)};
      }
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .mime-elements-carousel-indicators {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    margin-bottom: ${asRem(-spacing.five)};

    button {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 0 6px;
    }
  }

  .carousel-indicators [data-bs-target] {
    opacity: 1;
    background-color: ${colors.grey.c500};
    transition: background-color 0.3s ease;
  }

  .carousel-indicators .active {
    background-color: ${colors.purple.c500};
  }

  .carousel-wrapper {
    width: ${asRem(ItemWidthSm)};
    margin: 0 auto;

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(ItemWidthMd)};
    }

    .carousel-inner {
      display: flex;
      flex-direction: row;
      overflow: visible;
      transition: left 1s ease;

      left: -${({ activeIndex }) => activeIndex * 100}%;

      .carousel-item {
        display: block !important;
        float: none;
        margin: 0 auto;
        transform: translateX(0) !important;
      }
    }
  }
`;
