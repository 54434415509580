export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const registerUserRequest = ({ firstname, email, sendDeepLinkOTP = true }) => ({
  type: REGISTER_USER_REQUEST,
  payload: {
    firstname,
    email,
    sendDeepLinkOTP
  }
});

export const registerUserSuccess = (payload) => ({
  type: REGISTER_USER_SUCCESS,
  // ToDo: splice payload for pre reducing
  payload
});

export const registerUserFailure = (error) => ({
  type: REGISTER_USER_FAILURE,
  error
});
