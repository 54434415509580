import React from 'react';
import PropTypes from 'prop-types';
import { TermsModal } from 'components';
import { Markdown } from 'Common';
import markdownContent from '!!raw-loader!static/privacy.md';

PrivacyPolicyLink.propTypes = {
  classNames: PropTypes.string,
  linkClassName: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.string
};

export default function PrivacyPolicyLink({ className, linkClassName, buttonText = 'Datenschutzhinweise', color }) {
  return (
    <TermsModal
      buttonColor="link"
      buttonClasses={className}
      linkClasses={linkClassName}
      modalHeaderText="Datenschutzhinweise"
      buttonText={buttonText}
      color={color}
      renderAs="link"
    >
      <Markdown className="popup" containerId="modal-body" scrollOffset={0}>{markdownContent}</Markdown>
    </TermsModal>
  );
}
