import React from 'react';
import { Link as RouterDomLink } from 'react-router-dom';
import { Link as MMLink } from '@missionme/mime_elements';

export const Link = ({ to, href, className, color, children, onClick, dataCyName }) => {
  return (
    <MMLink
      component={to && RouterDomLink}
      to={to}
      href={href}
      className={className}
      color={color}
      onClick={onClick}
      dataCyName={dataCyName}
    >
      {children}
    </MMLink>
  );
};
