import LibraryNavItem from './LibraryNavItem';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Navbar, NavbarToggler, Nav, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';

const CategoryList = ({ categories }) => {
  const [activeSlug, setActiveSlug] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname;

    const currentSlug = pathname.split('/').pop();
    setActiveSlug(currentSlug);
  }, []);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const activeCategory = useMemo(() => {
    return (
      categories.find((category) => category.slug === activeSlug) || {
        title: TEXT.library.current
      }
    );
  }, [categories, activeSlug]);

  return (
    <Navbar expand="lg" className="library-nav-wrapper" onClick={toggle}>
      <div className="mr-auto d-lg-none library-nav-item library-nav-item--active">{activeCategory.title}</div>
      <NavbarToggler className={!isOpen ? 'navbar-toggler' : 'navbar-toggler--collapsed'} onClick={toggle} />

      <Collapse isOpen={isOpen} navbar className="library-nav-wrapper--collapse">
        <Nav className="library-nav" navbar>
          <LibraryNavItem key="newBalloon" type={activeSlug === '' ? 'active' : 'default'} title={TEXT.library.current} slug="" />

          {categories.map((category, idx) => {
            return (
              <LibraryNavItem
                key={idx}
                type={activeSlug === category.slug ? 'active' : 'default'}
                title={category.title || TEXT.library.current}
                slug={category.slug}
              />
            );
          })}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired
};

export default CategoryList;
