import React, { useMemo } from 'react';
import bkkLogo from 'images/landingpages/bkk/bertelsmann-bkk-logo.svg';
import gothaerLogo from 'images/landingpages/gothaer/gothaer-logo.svg';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const cooperations = {
  gothaer: {
    logo: gothaerLogo,
    url: 'https://www.gothaer.de/',
    altText: 'gothaer-logo'
  },
  bkk: {
    logo: bkkLogo,
    url: 'https://www.bertelsmann-bkk.de/balloon',
    altText: 'bertelsmann-bkk-logo'
  }
};

export const CooperationLogo = () => {
  const location = useLocation();
  const cooperation = useMemo(
    () => cooperations[Object.keys(cooperations).find((key) => location.pathname.toLowerCase().startsWith(ROUTES[key]))],
    [location]
  );

  if (!cooperation) {
    return null;
  }

  return (
    <a className="brand-logo" target="_blank" rel="noopener noreferrer" href={cooperation.url}>
      <img src={cooperation.logo} alt={cooperation.altText} />
    </a>
  );
};
