import React, { useEffect } from 'react';
import { Button, Form, Headline, Input, Paragraph, Link } from 'Common';
import Style from './LoginForm.style';
import { GoogleLoginButton } from 'components';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { isGoogleMailAlreadyExistError } from 'redux/query';
import { requestOnetimePassword } from 'redux/actions';
import { isLoginError } from 'redux/query';
import AppleLoginButton from '../../AppleLoginButton';
import LoginRegisterSocialErrors from '../../LoginRegisterSocialErrors';

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const hasLoginError = useSelector(isLoginError);
  const errorGoogleMailAlreadyExist = useSelector(isGoogleMailAlreadyExistError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasLoginError) {
      setError('email', {
        type: 'manual',
        message: 'Diese E-Mail-Adresse kann nicht verwendet werden, bitte überprüfe deine Eingabe.'
      });
    }
  }, [hasLoginError, setError]);

  useEffect(() => {
    if (errorGoogleMailAlreadyExist) {
      setError('email', {
        type: 'manual',
        message: `${TEXT.login.google_mail_hint}\n${TEXT.login.cta_google_login}`
      });
    }
  }, [errorGoogleMailAlreadyExist, setError]);

  const onSubmit = (user) => dispatch(requestOnetimePassword(user.email, true, true));

  return (
    <Style>
      <Headline level={2} className="text-center">
        {TEXT.login.title}
      </Headline>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('email', {
            required: 'Bitte gib deine E-Mail ein.',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Keine valide E-Mail-Adresse.'
            },
            onChange: () => {
              if (errors.email) {
                // needed to remove hasRegisterError message
                clearErrors('email');
              }
            }
          })}
          dataCyName="login-mail-input"
          placeholder="E-Mail"
          type="email"
          feedbackMessage={errors.email?.message}
          valid={!!errors.email ? false : undefined}
        />
        <Button dataCyName="submit-login-button">{TEXT.login.login_btn}</Button>
      </Form>
      <GoogleLoginButton textButton={TEXT.login.login_google_btn} />
      <AppleLoginButton black />
      <LoginRegisterSocialErrors isRegister={false} />
      <Paragraph className="small text-center">
        {TEXT.login.hint}
        <Link className="bold" to={ROUTES.signup}>
          {TEXT.login.registrationHint}
        </Link>
      </Paragraph>
    </Style>
  );
};
