import { requestApi } from 'utils';

export const registerGuestUserApiRequest = () => {
  return requestApi.mutate(`
    mutation RegisterGuestUser {
      createUserGuest {
        token
        user {
          id
          email
          firstName
          lastName
          isGuest
          unconfirmedEmail
        }
      }
    }
  `);
};
