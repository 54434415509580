import React from 'react';
import PropTypes from 'prop-types';
import { getFirstName } from 'utils';

export const ProfileNavigationHeader = ({ userName, subscriptionTitle }) => (
  <div className="profile-navigation-header">
    <div className="profile-navigation-header__user-name" data-cy-name="greeting-header">
      Hallo {getFirstName(userName)}!
    </div>
    <span
      data-cy-name="subscription-status"
      className={
        'profile-navigation-header__subscription-status profile-navigation-header__subscription-status' +
        (subscriptionTitle ? '--active' : '--inactive')
      }
    >
      {subscriptionTitle ? subscriptionTitle : 'kein abo aktiv'}
    </span>
  </div>
);

ProfileNavigationHeader.propTypes = {
  userName: PropTypes.string,
  subscriptionTitle: PropTypes.string
};

export default ProfileNavigationHeader;
