export const AUTHENTICATE_WITH_GIS_REQUEST = 'AUTHENTICATE_WITH_GIS_REQUEST';
export const AUTHENTICATE_WITH_GIS_SUCCESS = 'AUTHENTICATE_WITH_GIS_SUCCESS';
export const AUTHENTICATE_WITH_GIS_FAILURE = 'AUTHENTICATE_WITH_GIS_FAILURE';

export const authenticateWithGISRequest = (token) => ({
  type: AUTHENTICATE_WITH_GIS_REQUEST,
  payload: {
    token
  }
});

export const authenticateWithGISSuccess = (payload) => ({
  type: AUTHENTICATE_WITH_GIS_SUCCESS,
  payload
});

export const authenticateWithGISFailure = (error) => ({
  type: AUTHENTICATE_WITH_GIS_FAILURE,
  error
});
