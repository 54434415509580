import React, { Component, Fragment } from 'react';
import CodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import { Button, Col, Link, Paragraph, Row } from 'Common';
import { withRouter } from 'react-router-dom';
import * as reactRouterPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { connect } from 'react-redux';
import { TEXT } from 'locales/translations';
import { getUnconfirmedEmail, hasUnconfirmedEmail } from 'redux/query';
import { addFlash, clearFlashes, confirmEmailAddressChange, getUser, updateUserEmail } from 'redux/actions';

import { withUser } from 'containers/withUser';
import withSpinner from 'components/withSpinner';

class CodeEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.match.params.code
    };
  }

  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;

    const { confirmEmailAddressChange } = this.props;
    confirmEmailAddressChange(code);
  }

  setCode(code) {
    this.setState({ code: code });
  }

  handleResendCode = (e) => {
    e.preventDefault();
    const { updateUserEmail, unconfirmedEmail, addFlash } = this.props;
    updateUserEmail(unconfirmedEmail);
    addFlash(`Der Code wurde an die E-Mail ${unconfirmedEmail} geschickt`, 'success');
  };

  render() {
    const { unconfirmedEmail } = this.props;

    const { code } = this.state;
    return (
      <div className="code-entry">
        <Row>
          <Col>
            <h1 className="profile-subheading">{TEXT.codeEntry.heading}</h1>
            <div className="pb-4">
              Um deine E-Mail-Adresse zu speichern, gib bitte den 6-stelligen Code ein, den wir dir per E-Mail an {unconfirmedEmail}{' '}
              geschickt haben.
            </div>
          </Col>
        </Row>
        <Fragment>
          <form className="d-flex flex-column justify-content-center align-items-center" onSubmit={this.handleSubmit.bind(this)}>
            <CodeInput
              className="code-field"
              type="number"
              fields={6}
              value={code}
              onChange={this.setCode.bind(this)}
              inputStyle={{
                margin: '4px',
                width: '40px',
                borderRadius: '6px',
                fontSize: '20px',
                height: '53px',
                color: '#717171',
                textAlign: 'center',
                border: '1px solid #ededed',
                backgroundColor: '#ededed'
              }}
            />
            <Button className="mt-4">Speichern</Button>
          </form>
          <Paragraph className="small-bold text-center mt-4">
            <Link className="text-uppercase" color="green" onClick={this.handleResendCode}>
              Neuen Code anfordern
            </Link>
          </Paragraph>
        </Fragment>
      </div>
    );
  }
}

CodeEntry.propTypes = {
  unconfirmedEmail: PropTypes.string.isRequired,
  hasUnconfirmedEmail: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: reactRouterPropTypes.history,
  confirmEmailAddressChange: PropTypes.func.isRequired,
  updateUserEmail: PropTypes.func.isRequired,
  addFlash: PropTypes.func.isRequired,
  clearFlashes: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const hasConfirmedEmail = hasUnconfirmedEmail(state);

  return {
    unconfirmedEmail: hasConfirmedEmail ? getUnconfirmedEmail(state) : '',
    hasUnconfirmedEmail: hasConfirmedEmail
  };
};

export default withRouter(
  withUser(
    withSpinner(
      connect(mapStateToProps, {
        clearFlashes,
        addFlash,
        getUser,
        confirmEmailAddressChange,
        // eslint-disable-next-line no-restricted-globals
        history,
        updateUserEmail
      })(CodeEntry)
    )
  )
);
