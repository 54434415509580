import styled from 'styled-components';
import balloonDustyPink from 'images/landingpages/health_insurance/balloon_dusty_pink.svg';

export default styled.div`
  .balloon-top-right {
    width: 100vw;
    background: url(${balloonDustyPink}) no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 30%;

    @include media-breakpoint-down(lg) {
      background-size: 30%;
      background-position-y: 15%;
    }

    @include media-breakpoint-down(sm) {
      background: none;
      background-size: 20%;
    }

    @media (max-width: 575.98px) {
      background-size: 32%;
      background: none;
    }
  }
`;
