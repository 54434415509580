import styled from 'styled-components';
import { breakpoints, spacing } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const WhitepaperStyle = styled(Section)`
  position: relative;

  .rounded-box {
    width: 100%;
    display: flex;
    z-index: 1;

    .image {
      aspect-ratio: 1 / 1;
      width: ${asRem(200)};
      align-self: center;
      margin-top: ${asRem(-spacing.three)};
      margin-bottom: ${asRem(spacing.two)};
    }
  }

  .image-wrapper {
    z-index: -1 !important;
    position: relative;
  }

  .whitepaper-image {
    aspect-ratio: 794 / 395;
    width: ${asRem(290)};
    height: auto;
    left: 0;
    transform: translateX(-20%);
    margin-bottom: ${asRem(spacing.two)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(455)};
      transform: translateX(-40%);
    }

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(1000)};
      left: 0;
      transform: translateX(-30%);
    }

    @media (min-width: ${breakpoints.xl}) {
      width: ${asRem(1300)};
      left: 0;
      transform: translateX(-60%);
    }
  }
  .anchor {
    scroll-margin-top: ${asRem(100)};
  }
`;
