import { ADD_FLASH, REMOVE_FLASH, CLEAR_FLASHES } from 'redux/actions';

const initialState = [];

export default function flashes(state = initialState, action) {
  switch (action.type) {
    case ADD_FLASH:
      return [
        ...state,
        {
          message: action.payload.message,
          alertType: action.payload.alertType,
          errorCode: action.payload.errorCode
        }
      ];

    case CLEAR_FLASHES:
      return [];

    case REMOVE_FLASH:
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];

    default:
      return [...state];
  }
}

// TODO: Return array of all flash message objects
export const getAll = (state) => state;
