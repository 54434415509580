import { getUserPaymentInfosRequest } from 'redux/actions';
import { put, select } from 'redux-saga/effects';
import { isRegisteredUser } from 'redux/query';

export function* loadUserInformationSaga() {
  const state = yield select();
  const isRegistered = isRegisteredUser(state);
  if (!isRegistered) {
    return;
  }
  yield put(getUserPaymentInfosRequest({ renewable: false }));
}
