import { useEffect, useState } from 'react';
import { InsuranceCooperationAuthenticationContext } from './InsuranceCooperationAuthenticationContext';
import { SuccessContainer } from './Components/SuccessContainer';
import { GetTokenContainer } from './Components/GetTokenContainer';
import { ErrorContainer } from './Components/ErrorContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getOAuthTokenExpiresDate, isInsuranceCooperationOAuthFailed } from 'redux/query';
import { FormBlockerContainer } from './Components/FormBlockerContainer';
import Style from './InsuranceCooperationAuthenticationContainer.style';
import { platform } from 'config';
import { ROUTES } from 'utils';
import { TEXT } from 'locales/translations';
import { setInsuranceTokenExpirationDate } from 'redux/actions';
import { isMobile } from 'react-device-detect';

export const InsuranceCooperationAuthenticationContainer = ({ children, provider }) => {
  const dispatch = useDispatch();
  const oAuthTokenExpiresDate = useSelector(getOAuthTokenExpiresDate);
  const isOAuthFailed = useSelector(isInsuranceCooperationOAuthFailed);

  const [showPopupError, setShowPopupError] = useState(false);
  const [allowFormSubmit, setAllowFormSubmit] = useState(!!(oAuthTokenExpiresDate && new Date() < oAuthTokenExpiresDate));
  const [authenticationError, setAuthenticationError] = useState(null);

  let interval = null;
  const setOAuthTokenExpiresInterval = () => {
    if (interval) {
      clearTimeout(interval);
    }
    if (new Date() < oAuthTokenExpiresDate) {
      interval = setTimeout(() => {
        setOAuthTokenExpiresInterval();
      }, 1000);
    } else {
      setAllowFormSubmit(false);
    }
  };

  const handleOnOpenAuthenticationWindow = () => {
    if (isMobile) {
      window.location.href = `${ROUTES.insuranceCooperationAuthentication}?provider=${provider}`;
      return;
    }

    const authWindow = window.open(
      `${ROUTES.insuranceCooperationAuthentication}?provider=${provider}`,
      'authWindow',
      'toolbar=no,menubar=no,width=600,height=700,left=100,top=100'
    );

    if (authWindow) {
      authWindow.focus();
    } else {
      setShowPopupError(true);

      alert(TEXT.insuranceCooperation.popupNotOpened);
    }
  };

  useEffect(() => {
    if (!oAuthTokenExpiresDate || new Date() > oAuthTokenExpiresDate) {
      if (allowFormSubmit) {
        setAllowFormSubmit(false);
      }
      return () => {
        if (interval) {
          clearTimeout(interval);
        }
      };
    }
    if (oAuthTokenExpiresDate && new Date() < oAuthTokenExpiresDate) {
      setAllowFormSubmit(true);
    }
    setOAuthTokenExpiresInterval();
    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [oAuthTokenExpiresDate]);

  useEffect(() => {
    if (isOAuthFailed) {
      setAuthenticationError('transferFailed');
    }
  }, [isOAuthFailed]);

  useEffect(() => {
    window.addEventListener('message', handlePostMessages, false);

    return () => {
      window.removeEventListener('message', handlePostMessages, false);
    };
  }, []);

  const handlePostMessages = (event) => {
    if (event.origin !== window.location.origin) {
      console.warn('Origin not allowed', event.origin);
      return;
    }
    const { status = 'pending', code, tokenExpirationDate } = event.data;

    if (status === 'pending') {
      return;
    }
    switch (true) {
      case status === '1' && !!code:
        dispatch(setInsuranceTokenExpirationDate({ oAuthTokenExpiresAt: tokenExpirationDate }));
        setAuthenticationError(null);
        break;
      case status === '0':
        setAuthenticationError('NOT_ALLOWED');
        break;
      default:
        console.warn('Unknown response', event.data);
        setAuthenticationError('defaultError');
        break;
    }
    !platform.isProduction && console.log('Nachricht vom Popup erhalten:', event.data);
  };

  return (
    <Style id="anmeldung">
      <InsuranceCooperationAuthenticationContext.Provider
        value={{
          showPopupError,
          allowFormSubmit,
          authenticationError,
          setShowPopupError,
          provider,
          oAuthTokenExpiresDate,
          handleOnOpenAuthenticationWindow
        }}
      >
        {children({
          showPopupError,
          allowFormSubmit,
          authenticationError,
          setShowPopupError,
          provider,
          oAuthTokenExpiresDate,
          handleOnOpenAuthenticationWindow
        })}
      </InsuranceCooperationAuthenticationContext.Provider>
    </Style>
  );
};

InsuranceCooperationAuthenticationContainer.SuccessContainer = SuccessContainer;
InsuranceCooperationAuthenticationContainer.GetTokenContainer = GetTokenContainer;
InsuranceCooperationAuthenticationContainer.FormBlockerContainer = FormBlockerContainer;
InsuranceCooperationAuthenticationContainer.ErrorContainer = ErrorContainer;
