import { put, take } from 'redux-saga/effects';
import {
  REGISTER_USER_FAILURE,
  REGISTER_USER_SUCCESS,
  persistHealthInsuranceUserData,
  registerHealthInsuranceUserFailure,
  registerUserRequest,
  requestOnetimePassword,
  registerHealthInsuranceUserSuccess
} from 'redux/actions';

export function* registerHealthInsuranceUserSaga({ payload }) {
  const { user, sendDeepLinkOTP } = payload;
  yield user;

  try {
    yield put(
      registerUserRequest({
        firstname: user.name,
        email: user.email,
        sendDeepLinkOTP: false
      })
    );
    const action = yield take([REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE]);

    switch (action.type) {
      case REGISTER_USER_SUCCESS:
        yield put(persistHealthInsuranceUserData(user));
        yield put(registerHealthInsuranceUserSuccess());
        break;

      case REGISTER_USER_FAILURE:
        const { error } = action;
        if (error[0].extensions.user) {
          const userError = error[0].extensions.user;

          const emailHasAlreadyBeenTaken = userError.email?.indexOf('emailHasAlreadyBeenTaken') >= 0;

          if (emailHasAlreadyBeenTaken) {
            yield put(persistHealthInsuranceUserData(user));
            yield put(requestOnetimePassword(user.email, false));
            yield put(registerHealthInsuranceUserSuccess());
          }
        }
        break;

      default:
        console.warn(`${action.type} not registered`);
        break;
    }
  } catch (error) {
    yield put(registerHealthInsuranceUserFailure(error));
  }
}
