import { requestApi } from 'utils';

export const confirmStripePaymentApiRequest = ({ subscriptionPlanId, paymentMethodId, couponCode }) =>
  requestApi.mutate(`
  mutation ConfirmStripePayment {
    confirmStripePayment (
      subscriptionPlanId: "${subscriptionPlanId}",
      couponCode: "${couponCode}", 
      paymentMethodId: "${paymentMethodId}"
    ) {
      userAutoAssigned
    }
  }
`);
