import React from 'react';
import PropTypes from 'prop-types';

const Review = ({ name, meta, headline, copy }) => {
  return (
    <div className="item">
      <div className="top-container">
        <div className="stars" />
        <h4>{name}</h4>
        <p>{meta}</p>
      </div>
      <div className="bottom-container">
        <h4>{headline}</h4>
        <p>{copy}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired
};

export default Review;
