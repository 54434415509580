import { put, select } from 'redux-saga/effects';
import { updateUserPaymentInfosFailure, updateUserPaymentInfosSuccess } from 'redux/actions';
import { createBillingInfoApiRequest } from 'api/balloon';
import { getCurrentUser } from 'redux/query';

export function* updateUserPaymentInfosSaga({ payload }) {
  const { firstName, lastName, street, zipCode, city, country } = payload;
  try {
    const state = yield select();
    const { email } = getCurrentUser(state);

    const { data: response } = yield createBillingInfoApiRequest({
      billingInfo: {
        firstName,
        lastName,
        street,
        zipCode,
        city,
        country,
        email
      }
    });

    yield put(
      updateUserPaymentInfosSuccess({
        renewalInfo: {
          address: {
            city: response.data.createBillingInfo.city,
            country: response.data.createBillingInfo.countryCode,
            name: `${response.data.createBillingInfo.firstName} ${response.data.createBillingInfo.lastName}`,
            street: response.data.createBillingInfo.addressLine1,
            zipCode: response.data.createBillingInfo.zipCode
          }
        }
      })
    );
  } catch (error) {
    yield put(updateUserPaymentInfosFailure(error));
  }
}
