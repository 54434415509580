import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isFetchingCourse } from 'redux/reducers';
import { isAuthed, getToken } from 'redux/query';
import { getCourseBySlug } from 'redux/actions';

function WithCourse(WrappedComponent) {
  class WithCourse extends Component {
    componentDidMount() {
      const { getCourseBySlug, token, isAuthed, match } = this.props;
      if (isAuthed && token) {
        getCourseBySlug(match.params.slug);
      }
    }

    componentDidUpdate(prevProps) {
      const { getCourseBySlug, token, isAuthed, match } = this.props;
      if (isAuthed && token && prevProps.token !== token) {
        getCourseBySlug(match.params.slug);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithCourse.propTypes = {
    isAuthed: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    token: PropTypes.string,
    getCourseBySlug: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  return WithCourse;
}

const mapStateToProps = (state) => ({
  isFetching: isAuthed(state) && isFetchingCourse(state),
  isAuthed: isAuthed(state),
  token: getToken(state)
});

export default compose(connect(mapStateToProps, { getCourseBySlug }), WithCourse);
