import { InsuranceCooperationAuthenticationContext } from '../InsuranceCooperationAuthenticationContext';
import { useContext } from 'react';
import { TEXT } from 'locales/translations';

export const ErrorContainer = ({ children }) => {
  const { authSection } = TEXT.insuranceCooperation;

  const { showPopupError, authenticationError, handleOnOpenAuthenticationWindow } = useContext(InsuranceCooperationAuthenticationContext);

  if (showPopupError || authenticationError) {
    return children({
      showPopupError,
      authenticationError,
      openWindow: handleOnOpenAuthenticationWindow,
      errorMessage: authSection.errors?.[authenticationError] || authSection.errors.defaultError
    });
  }

  return null;
};
