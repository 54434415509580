import React, { Component } from 'react';
import DecorativeImage from 'components/DecorativeImage';
import { Col, Container, Button, Row } from 'Common';
import { ROUTES } from 'utils/constants';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';

import imgPodcastDesktopMockup from 'images/home/podcastMockupDesktop@2x.png';
import imgPodcastMobileMockup from 'images/home/podcastMockupMobile@2x.png';
import imgPodcastBubble from 'images/home/podcastBubbleCropped@2x.png';
import { ThemeContainer } from 'Container';
import { themePodcast } from 'Theme';

class PodcastSection extends Component {
  render() {
    return (
      <ThemeContainer theme={themePodcast}>
        <section className="podcast-section">
          <Container>
            <Row>
              <Col xs={{ size: 12, offset: 0 }} md={{ size: 6, offset: 0 }}>
                <DecorativeImage
                  src={imgPodcastDesktopMockup}
                  alt={TEXT.podcastSection.altMobileMD}
                  className="podcast-section__mockup-img--desktop"
                />
                <DecorativeImage
                  src={imgPodcastMobileMockup}
                  alt={TEXT.podcastSection.altMobileXS}
                  className="podcast-section__mockup-img--mobile"
                />
              </Col>
              <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} className="podcast-section__content">
                <DecorativeImage src={imgPodcastBubble} alt={TEXT.podcastSection.altBubble} className="podcast-section__bubble-img" />
                <div>
                  <TextBlock heading={TEXT.podcastSection.heading} body={TEXT.podcastSection.body} textColor="white" />
                  <Button type="inverted" className="width-100" to={ROUTES.podcast}>
                    {TEXT.podcastSection.btn}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </ThemeContainer>
    );
  }
}

export default PodcastSection;
