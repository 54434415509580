import * as types from 'redux/actions/actionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  alerts: {}
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_ALERTS_PENDING:
      return {
        ...state,
        isFetching: true
      };

    case types.RETRIEVE_ALERTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        success: false,
        error: true
      };

    case types.RETRIEVE_ALERTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true,
        error: false,
        alerts: action.payload.entities.alerts
      };

    case types.ALERT_TOGGLE_VISIBILITY: {
      const { alerts } = state;

      const { id } = action;
      alerts[id].dismissed = !alerts[id].dismissed;

      return {
        ...state,
        isFetching: false,
        success: true,
        error: false,
        alerts: { ...alerts }
      };
    }
    default:
      return state;
  }
}

export const getAlerts = (state) => {
  if (state.alerts) {
    return state.alerts;
  }

  return {};
};
