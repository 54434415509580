import React from 'react';
import { Carousel, CarouselItem } from 'reactstrap';

export const CarouselMindful = ({
  items,
  children,
  goToIndex = (index) => {},
  onStartAnimation = () => {},
  onEndAnimation = () => {},
  ...args
}) => {
  return (
    <Carousel interval={false} next={() => {}} previous={() => {}} {...args}>
      {items.map((item, index) => (
        <CarouselItem onExiting={onStartAnimation} onExited={onEndAnimation} key={index}>
          {item.component}
        </CarouselItem>
      ))}
      {children}
    </Carousel>
  );
};
