import * as types from 'redux/actions/actionTypes';

const initialState = {
  prorationDate: '',
  isFetching: false,
  reducedPrice: 0,
  coupon: '',
  error: false
};

export default function upgradeSubscription(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
export const isFetchingUpgrade = (state) => state && state.isFetching;
export const getProration = (state) => state && state;
export const isUpgradeError = (state) => state && state.error;
