import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PrimaryLink = ({ to, children, className = '', ...rest }) => (
  <Link className={`btn btn-primary ${className}`} to={to} {...rest}>
    {children}
  </Link>
);

PrimaryLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default PrimaryLink;
