import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';
import { REDEEM_GIFT_COUPON_FAILURE, REDEEM_GIFT_COUPON_PENDING, REDEEM_GIFT_COUPON_SUCCESS, SET_REDEEM_GIFT_COUPON } from 'redux/actions';

const initialState = {
  isFetching: false,
  redeemedVoucher: false,
  redeemedEntryVoucher: '',
  hasError: false,
  alreadyApplied: false,
  codeExpired: false
};

export default function giftCoupons(state = initialState, action) {
  switch (action.type) {
    case REDEEM_GIFT_COUPON_PENDING:
      return {
        ...state,
        isFetching: true,
        redeemedVoucher: false,
        hasError: false,
        alreadyApplied: false,
        codeExpired: false
      };
    case REDEEM_GIFT_COUPON_FAILURE: {
      let alreadyApplied = false;
      let codeExpired = false;
      if (action.error.length > 0) {
        alreadyApplied = action.error[0].extensions.code.includes('codeAlreadyApplied');
        codeExpired = action.error[0].extensions.code.includes('codeExpired');
      }

      return {
        ...state,
        isFetching: false,
        redeemedVoucher: false,
        redeemedEntryVoucher: '',
        hasError: true,
        alreadyApplied: alreadyApplied,
        codeExpired: codeExpired,
        error: axiosError(state.error, action)
      };
    }
    case SET_REDEEM_GIFT_COUPON: {
      return {
        ...state,
        redeemedEntryVoucher: action.code
      };
    }
    case REDEEM_GIFT_COUPON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        redeemedVoucher: true,
        hasError: false,
        alreadyApplied: false,
        codeExpired: false
      };
    default:
      return state;
  }
}
//only for Flashmessage usaged
export const getErrorMessage = (state) => {
  const messages = {
    giftCouponAlreadyApplied: 'Dieser Code wurde bereits eingelöst',
    giftCouponNotValid: 'Die Code-Eingabe hat nicht geklappt. Bitte überprüfe deinen Code und gib ihn nochmal ein.',
    voucherCodeNotValid: 'Die Code-Eingabe hat nicht geklappt. Bitte überprüfe deinen Code und gib ihn nochmal ein.',
    userAlreadyHasASubscription: 'Der Gutschein kann nicht eingelöst werden, da du bereits ein laufendes Balloon-Abo hast',
    giftCouponNotFound: 'Die Code-Eingabe hat nicht geklappt. Bitte überprüfe deinen Code und gib ihn nochmal ein.',
    voucherCodeNotFound: 'Die Code-Eingabe hat nicht geklappt. Bitte überprüfe deinen Code und gib ihn nochmal ein.',
    codeExpired: 'Dieser Code ist leider nicht mehr gültig! Bitte überprüfe deine Eingabe.',
    codeNotFound: 'Leider können wir diesen Code nicht finden.'
  };

  const fallback = 'Hmm, dies hätte nicht passieren sollen. Probier es bitte nochmal';

  let msg = fallback;
  if (state.error) {
    msg = messages[state.error.data];
  }

  return msg;
};

export const isFetching = (state) => state.isFetching;
export const redeemedVoucher = (state) => state.redeemedVoucher;
export const redeemedEntryVoucher = (state) => state.redeemedEntryVoucher;
export const hasError = (state) => state && state.hasError;
export const isAlreadyApplied = (state) => state && state.alreadyApplied;
export const isCodeExpired = (state) => state && state.codeExpired;
