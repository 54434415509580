import React from 'react';
import { ROUTES } from 'utils/constants';
import DecorativeImage from 'components/DecorativeImage';
import { TEXT } from 'locales/translations';
import { Col, Container, Button, Row } from 'Common';

import bench from 'images/webplayer/bench.png';

export default function SubscriptionTeaser(props) {
  return (
    <section id="section-appstores" className="subscription-teaser">
      <Container>
        <Row>
          <Col
            className="content mb-4 mb-sm-0"
            xs={{ size: 10, offset: 1, order: 2 }}
            md={{ size: 5, offset: 1, order: 1 }}
            lg={{ size: 4, offset: 2 }}
          >
            <section className="button-section">
              <h2 className="text-block__heading">{TEXT.subscriptionTeaser.title}</h2>
              <p className="text-block__body">{TEXT.subscriptionTeaser.message}</p>
              <Button to={ROUTES.plans} className="mt-1">
                {TEXT.appAdTile.button}
              </Button>
            </section>
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} lg={{ size: 4 }} className="image-wrapper">
            <DecorativeImage alt={TEXT.ctaInfoSection.alt} src={bench} className="bench-image mt-3 mt-md-0" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
