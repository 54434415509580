import { schema } from 'normalizr';
import { changeCaseObject } from 'utils/change-case-object';
import {} from 'utils/subscriptionPlanGroups';

export const reminder = new schema.Entity('reminders');

export const alert = new schema.Entity(
  'alerts',
  {},
  {
    processStrategy: (entity) => {
      return { ...entity, dismissed: false };
    }
  }
);

export const stripeInvoice = new schema.Entity(
  'stripeInvoices',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const stripeCoupon = new schema.Entity(
  'stripeCoupons',
  {},
  {
    processStrategy: (entity) => {
      return changeCaseObject.camelCase(entity);
    }
  }
);

export const subscription = new schema.Entity(
  'subscriptions',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const user = new schema.Entity('users', {
  reminder: reminder,
  subscriptions: [subscription]
});

export const category = new schema.Entity(
  'categories',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const entryContent = new schema.Entity(
  'entryContent',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const course = new schema.Entity(
  'courses',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const single = new schema.Entity(
  'singles',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const newContent = new schema.Entity(
  'newContent',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const requestCancellation = new schema.Entity(
  'requestCancellation',
  {},
  {
    processStrategy: (entity) => changeCaseObject.camelCase(entity)
  }
);

export const payableInfos = new schema.Entity(
  'payableInfos',
  {},
  {
    // idAttribute: 'payableId', this only works with this.
    processStrategy: (entity) => {
      return {
        name: entity.renewalInfo.address.name,
        street: entity.renewalInfo.address.street,
        city: entity.renewalInfo.address.city,
        country: entity.renewalInfo.address.country,
        zipCode: entity.renewalInfo.address.zip_code,
        provider: entity.provider
      };
    }
  }
);
