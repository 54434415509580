import { hasSubscription, getCurrentUser, getSubscriptionPlanById } from 'redux/query';

export const getCurrentUserSubscriptionPlan = (state) => {
  const hasUserSubscription = hasSubscription(state);
  if (!hasUserSubscription) {
    return;
  }
  const user = getCurrentUser(state);
  const subscriptionId = (user?.subscriptions || [])[0]?.subscriptionPlanId;

  return getSubscriptionPlanById(state)(subscriptionId);
};
