export const GET_BGM_COMPANIES_REQUEST = 'GET_BGM_COMPANIES_REQUEST';
export const GET_BGM_COMPANIES_SUCCESS = 'GET_BGM_COMPANIES_SUCCESS';
export const GET_BGM_COMPANIES_FAILURE = 'GET_BGM_COMPANIES_FAILURE';

export const getBgmCompaniesRequest = () => ({
  type: GET_BGM_COMPANIES_REQUEST
});

export const getBgmCompaniesSuccess = (payload) => ({
  type: GET_BGM_COMPANIES_SUCCESS,
  payload
});

export const getBgmCompaniesFailure = (error) => ({
  type: GET_BGM_COMPANIES_FAILURE,
  error
});
