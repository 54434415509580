import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthed, isGuest } from 'redux/query';
import { getGiftCouponRedeemedVoucher } from 'redux/reducers';
import { logoutUser } from 'redux/actions';
import { FlashListContainer } from 'containers';
import SignInUserWithOnetimePasswordVoucherBox from '../../Bkk/components/SignInUserWithOnetimePasswordVoucherBox';
import VoucherCodeEntry from '../../Bkk/components/VoucherCodeEntry';
import RegisterFormBox from '../../Bkk/components/RegisterFormBox';
import RequestOnetimeFormBox from '../../Bkk/components/RequestOnetimeFormBox';
import SuccessContentXing from './SuccessContentXing';
import TrustBandSectionB2b from 'components/Landingpages/B2B/TrustBandSectionB2b';

class XingFormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegisterForm: true
    };
  }

  showLoginForm = () => {
    this.setState({ showRegisterForm: !this.state.showRegisterForm });
  };

  resetOtpLoginState = () => {
    this.setState({ showRegisterForm: true });
    this.props.onLogout();
  };

  render() {
    const {
      isAuthed,
      isGuest,
      hasRedeemedVoucher,
      partnerName,
      duration,
      successPhone,
      successMediumPhone,
      successLargePhone,
      universalLink
    } = this.props;

    const { showRegisterForm } = this.state;
    return (
      <section id="form-section-container">
        <TrustBandSectionB2b />
        <div id="form-box-container">
          <div className={`form-box ${hasRedeemedVoucher ? 'reset-bottom success-content' : ''}`}>
            {!isAuthed && !isGuest ? (
              <Fragment>
                <FlashListContainer />
                <SignInUserWithOnetimePasswordVoucherBox handleOtpState={this.resetOtpLoginState} />
              </Fragment>
            ) : (
              <Fragment>
                {isAuthed && !isGuest ? (
                  <Fragment>
                    {hasRedeemedVoucher ? (
                      <SuccessContentXing
                        partnerName={partnerName}
                        successPhone={successPhone}
                        successMediumPhone={successMediumPhone}
                        successLargePhone={successLargePhone}
                        universalLink={universalLink}
                      />
                    ) : (
                      <VoucherCodeEntry
                        headline={`Du hast einen Zugangs-Code für das ${partnerName} Kurs-Paket bekommen. Bitte gib diesen Code hier ein.`}
                        requiredTerms={false}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <FlashListContainer />
                    {showRegisterForm ? (
                      <RegisterFormBox
                        headline={`Registrierung für Mitglieder von ${partnerName}`}
                        subline={`Nutze den Zugangs-Code aus dem ${partnerName} Vorteilsprogramm, um dich hier zu registrieren und das Kurs-Paket für ${duration} Monate freizuschalten.`}
                        showLoginForm={this.showLoginForm}
                        requiredTerms={false}
                      />
                    ) : (
                      <RequestOnetimeFormBox
                        headline={`Anmeldung für Mitglieder von ${partnerName}`}
                        subline={`Nutze den Zugangs-Code aus dem ${partnerName} Vorteilsprogramm, um dich hier zu registrieren und das Kurs-Paket für ${duration} Monate freizuschalten.`}
                        showLoginForm={this.showLoginForm}
                        requiredTerms={false}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </section>
    );
  }
}

XingFormSection.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  partnerName: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  isGuest: PropTypes.bool.isRequired,
  hasRedeemedVoucher: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  successPhone: PropTypes.node.isRequired,
  successMediumPhone: PropTypes.node.isRequired,
  successLargePhone: PropTypes.node.isRequired,
  universalLink: PropTypes.string.isRequired
};

XingFormSection.defaultProps = {
  isAuthed: false,
  isGuest: true,
  hasRedeemedVoucher: false
};

const mapStateToProps = (state) => ({
  isAuthed: isAuthed(state),
  isGuest: isGuest(state),
  hasRedeemedVoucher: getGiftCouponRedeemedVoucher(state)
});

export default connect(mapStateToProps, { onLogout: logoutUser })(XingFormSection);
