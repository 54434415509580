import React from 'react';
import Badge from 'images/apple-badge-en-alternate.svg';
import { ROUTES } from 'utils/constants';

export default function AppStoreBadge(props) {
  const AppStoreLink = ROUTES['apple'];
  return (
    <a
      href={AppStoreLink}
      rel="noreferrer"
      target="_blank"
      data-cy-name="app-store-badge"
      style={{
        display: 'block',
        overflow: 'hidden',
        background: `url(${Badge}) no-repeat`,
        width: '120px',
        height: '40px',
        backgroundSize: 'contain'
      }}
    >
      {' '}
    </a>
  );
}
