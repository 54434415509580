import React from 'react';
import PropTypes from 'prop-types';
import { TermsModal } from 'components';
import markdownContent from '!!raw-loader!static/resilience_health_benefit.md';
import { Markdown } from 'Common';

export function ResilienceHealthBenefitLink({ classNames, renderAs = 'button' }) {
  return (
    <TermsModal
      buttonClasses={classNames}
      modalHeaderText="Gesundheitlicher Nutzen"
      buttonText="Gesundheitlicher Nutzen"
      renderAs={renderAs}
    >
      <Markdown className="popup" containerId="modal-body" scrollOffset={0}>{markdownContent}</Markdown>
    </TermsModal>
  );
}

ResilienceHealthBenefitLink.propTypes = {
  classNames: PropTypes.string,
  renderAs: PropTypes.string
};
