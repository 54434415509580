import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Container } from 'reactstrap';

PageLayout.propTypes = {
  image1x: PropTypes.string.isRequired,
  image2x: PropTypes.string.isRequired,
  image3x: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default function PageLayout(props) {
  const { image1x, image2x, image3x, title, text } = props;

  return (
    <div className="section">
      <Container>
        <Row>
          <Col xs={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }}>
            <img
              className="img-fluid mt-auto"
              srcSet={`${image1x} 165w, ${image2x} 330w, ${image3x} 495w`}
              src={image1x}
              alt="Survey Health"
            />
            <h2
              className="h2_bold_kk text-blueGrey-c500 mb-4 d-block text-center"
              style={{
                paddingTop: '100px'
              }}
            >
              {title}
            </h2>
            <p className="p_lg_kk text-center">{text}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
