export const SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST = 'SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST';
export const SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS = 'SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS';
export const SEND_OTP_WITH_INSURANCE_NUMBER_FAILURE = 'SEND_OTP_WITH_INSURANCE_NUMBER_FAILURE';

export const sendOtpWithInsuranceNumberRequest = ({ user, sendDeepLinkOTP }) => ({
  type: SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST,
  payload: {
    user,
    sendDeepLinkOTP
  }
});

export const sendOtpWithInsuranceNumberSuccess = ({ emailHint, insuranceNumber }) => ({
  type: SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS,
  payload: {
    emailHint,
    insuranceNumber
  }
});

export const sendOtpWithInsuranceNumberFailure = (error) => ({
  type: SEND_OTP_WITH_INSURANCE_NUMBER_FAILURE,
  error
});
