import styled from 'styled-components';
import { spacing, breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const HeaderStyle = styled(Section)`
  padding-top: ${asRem(spacing.twelve)} !important;
  padding-bottom: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${asRem(spacing.eight * 2)} !important;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: ${asRem(spacing.nine * 2)} !important;
  }

  .btn-contact {
    margin-bottom: ${asRem(spacing.two)};
    margin-right: ${asRem(spacing.two)};

    border: solid red 1px @media (min-width: ${breakpoints.md}) {
      margin: ${asRem(spacing.four)} ${asRem(spacing.two)} ${asRem(spacing.three)} 0;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: ${asRem(spacing.eight * 2)};
    }
  }

  .btn-offer {
    margin-bottom: ${asRem(spacing.four)};
  }

  .header-image {
    aspect-ratio: 600 / 524;
    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(600)};
    }
  }
`;
