import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Row, Col, Button, Paragraph, BackgroundImage } from 'Common';
import { HashLink as Link } from 'react-router-hash-link';

import { headerIllu } from 'images/landingpages/b2b_landingpage';
import { HeaderStyle } from './Header.style';

export const Header = () => {
  return (
    <HeaderStyle className="header">
      <Row>
        <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 0 }} className="text-center text-lg-start">
          <Headline level={1}>{TEXT.company.header.headline}</Headline>
          <Paragraph className="intro">{TEXT.company.header.body}</Paragraph>
          <Link to="#b2bContactForm">
            <Button type="inverted" className="btn-contact">
              {TEXT.company.contactBtn}
            </Button>
          </Link>
          <Link to="#yourBalloon">
            <Button type="outline-inverted" className="btn-offer">
              {TEXT.company.header.offerBtn}
            </Button>
          </Link>
        </Col>
        <Col xs={{ size: 12, offset: 0 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
          <BackgroundImage image={headerIllu} className="header-image" />
        </Col>
      </Row>
    </HeaderStyle>
  );
};
