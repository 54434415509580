import React from 'react';
import { TEXT } from 'locales/translations';
import DecorativeImage from 'components/DecorativeImage';
import illuBallooni from 'images/profile/balloon-geschenk-hell@2x.png';
import { ROUTES } from 'utils';
import { Col, Container, Button, Row } from 'Common';
import { ThemeContainer } from 'Container';
import { themeCalm } from 'Theme';

const GiftCouponBanner = () => (
  <div className="gift-coupon-banner mb-5">
    <ThemeContainer theme={themeCalm}>
      <Container>
        <Row className="align-items-end">
          <Col
            xs={{ size: 12, offset: 0, order: 1 }}
            md={{ size: 10, offset: 0, order: 1 }}
            xl={{ size: 10, offset: 0 }}
            className="gift-coupon-banner__content-wrapper-mobile"
          >
            <div className="gift-coupon-banner__content-wrapper">
              <h2 className="gift-coupon-banner__headline">{TEXT.giftCouponBanner.headline}</h2>
              <p className="gift-coupon-banner__text">{TEXT.giftCouponBanner.subline}</p>
            </div>
            <div className="d-lg-none gift-coupon-banner__illustration-wrapper">
              <DecorativeImage className="gift-coupon-banner__illustration" src={illuBallooni} alt={TEXT.giftCouponBanner.altText} />
            </div>
            <Button className="gift-coupon-banner__button" type="inverted" to={ROUTES.buyGift}>
              {TEXT.giftCouponBanner.button}
            </Button>
          </Col>
          <Col
            xs={{ size: 12, offset: 0, order: 2 }}
            md={{ size: 2, offset: 0, order: 2 }}
            xl={{ size: 2, offset: 0 }}
            className="gift-coupon-banner__illustration-wrapper d-none d-lg-block"
          >
            <DecorativeImage className="gift-coupon-banner__illustration" src={illuBallooni} alt={TEXT.giftCouponBanner.altText} />
          </Col>
        </Row>
      </Container>
    </ThemeContainer>
  </div>
);

export default GiftCouponBanner;
