import { requestApi } from 'utils';

export const createBillingInfoApiRequest = ({ billingInfo }) =>
  requestApi.mutate(`
  mutation CreateBillingInfo {
    createBillingInfo(
      firstName: "${billingInfo.firstName}",
      lastName: "${billingInfo.lastName}",
      addressLine1: "${billingInfo.street}",
      city: "${billingInfo.city}",
      countryCode: "${billingInfo.country}",
      zipCode: "${billingInfo.zipCode}",
      email: "${billingInfo.email}",
      provider: "stripe",
      providerPaymentMethod: "${billingInfo.providerPaymentMethod}"
    ) {
        firstName,
        lastName,
        addressLine1,
        city,
        countryCode,
        zipCode,
        email,
        provider
    }
  }
`);
