import { useDispatch, useSelector } from 'react-redux';
import { insuranceNumberExists } from 'redux/query';
import { Button, Paragraph } from 'Common';
import { TEXT } from 'locales/translations';
import { sendOtpWithInsuranceNumberRequest } from 'redux/actions';
import Style from './InsuranceNumberAlreadyExistsButton.style';

export const InsuranceNumberAlreadyExistsButton = ({ values }) => {
  const dispatch = useDispatch();
  const insuranceNumberAlreadyExists = useSelector(insuranceNumberExists);

  const handleOnSendOtpByInsuranceNumber = () => {
    const { name = '', email = '', insuranceNumber = '' } = values;
    dispatch(
      sendOtpWithInsuranceNumberRequest({
        user: { name, email, insuranceNumber },
        sendDeepLinkOTP: false
      })
    );
  };

  if (!insuranceNumberAlreadyExists) {
    return null;
  }

  return (
    <Style data-cy-name="existing-insurance-number-info">
      <Paragraph className="small">{TEXT.formsGeneral.infoTextLoginInsuranceNumber}</Paragraph>
      <Button color="pink" onClick={handleOnSendOtpByInsuranceNumber} dataCyName="existing-insurance-number-submit-button">
        {TEXT.login.login_with_insurance_number}
      </Button>
    </Style>
  );
};
