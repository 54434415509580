import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';

import { GET_INVOICES_PENDING, GET_INVOICES_FAILURE, GET_INVOICES_SUCCESS } from 'redux/actions/actionTypes';
import { getCurrentUser } from '../../query';

export const gettingInvoices = () => ({
  type: GET_INVOICES_PENDING
});

export const getInvoicesFailure = (error) => ({
  type: GET_INVOICES_FAILURE,
  error
});

export const getInvoicesSuccess = ({ userId, payload }) => ({
  type: GET_INVOICES_SUCCESS,
  payload,
  userId
});

export const getInvoices =
  () =>
  (dispatch, getState, { api }) => {
    dispatch(gettingInvoices());
    const userId = getCurrentUser(getState())?.id;

    return api.getInvoices().then(
      (response) => {
        if (response.data.errors) {
          dispatch(getInvoicesFailure(response.data.errors));
        } else {
          dispatch(
            getInvoicesSuccess({
              userId,
              payload: normalize(response.data.data.stripeInvoices, [schema.stripeInvoice])
            })
          );
        }
      },
      (error) => {
        handleAxiosError(error, () => {
          dispatch(getInvoicesFailure(error));
        });
      }
    );
  };
