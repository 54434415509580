import formsReducer, * as fromForms from './forms';
import playerReducer, * as fromPlayer from './player';
import flashesReducer, * as fromFlashes from './flashes';
import entitiesReducer, * as fromEntities from './entities';
import upgradeSubscriptionReducer, * as fromUpgradeSubscription from './upgradeSubscription';
import bgmCompaniesReducer, * as bgmCompanies from './bgmCompanies';
import withdrawCancelationSubscriptionReducer, * as fromWithdrawCancelationSubscription from './withdrawCancelationSubscription';
import storeInfos from './storeInfos';
import insuranceCompanies from './insuranceCompanies';
import { paymentReducer } from './paymentReducer/paymentReducer';
import { persistAppReducer } from './persistAppReducer';
import { getCurrentUser } from 'redux/query';

export default {
  forms: formsReducer,
  entities: entitiesReducer,
  flashes: flashesReducer,
  player: playerReducer,
  payment: paymentReducer,
  persistApp: persistAppReducer,
  // @deprecated use payment reducer instead
  upgradeSubscription: upgradeSubscriptionReducer,
  bgmCompanies: bgmCompaniesReducer,
  withdrawCancelationSubscription: withdrawCancelationSubscriptionReducer,
  storeInfos,
  insuranceCompanies
};

export const getGiftCouponCode = (state) => fromForms.getGiftCouponCode(state.forms);

// flashes selectors;
/** @deprecated use query method instead */
export const getFlashes = (state) => fromFlashes.getAll(state.flashes);

// invoices selectors
/** @deprecated use query method instead */
export const isFetchingInvoices = (state) => fromEntities.isFetchingInvoices(state.entities);

/** @deprecated use query method instead */
export const getInvoicesForUser = (state) => {
  const userId = getCurrentUser(state)?.id;
  return fromEntities.getInvoicesForUser(state.entities, userId);
};

export const getInvoiceByNumber = (state, number) => fromEntities.getInvoiceByNumber(state.entities, number);

// giftCouponSelectors
/** @deprecated - use the redux query method */
export const isFetchingGiftCoupon = (state) => fromEntities.isFetchingGiftCoupon(state.entities);
export const getGiftCouponRedeemedVoucher = (state) => fromEntities.getGiftCouponRedeemedVoucher(state.entities);
export const getGiftCouponCodeVoucher = (state) => fromEntities.getGiftCouponCodeVoucher(state.entities);
export const getGiftCouponErrorMessage = (state) => fromEntities.getGiftCouponErrorMessage(state.entities);
export const hasGiftCouponError = (state) => fromEntities.hasGiftCouponError(state.entities);
export const isGiftCouponAlreadyApplied = (state) => fromEntities.isGiftCouponAlreadyApplied(state.entities);
export const isGiftCouponCodeExpired = (state) => fromEntities.isGiftCouponCodeExpired(state.entities);

//categories selectors
export const getAllCategories = (state) => fromEntities.getAllCategories(state.entities);
export const isFetchingCategories = (state) => fromEntities.isFetchingCategories(state.entities);
export const getCategoryById = (state, id) => fromEntities.getCategoryById(state.entities, id);

export const getCurrentEntryContent = (state) => fromEntities.getCurrentEntryContent(state.entities);
export const isFetchingCurrentEntryContent = (state) => fromEntities.isFetchingCurrentEntryContent(state.entities);
export const getEntryContentButtonText = (state) => fromEntities.getEntryContentButtonText(state.entities);

// course selectors
export const getCourse = (state) => fromEntities.getCourse(state.entities);
export const isFetchingCourse = (state) => fromEntities.isFetchingCourse(state.entities);

// course selectors
export const getSingle = (state) => fromEntities.getSingle(state.entities);
export const isFetchingSingle = (state) => fromEntities.isFetchingSingle(state.entities);

// players selectors;
export const getCurrentPlayerUnit = (state) => fromPlayer.getCurrentUnit(state.player);
export const getCurrentPlayerProcess = (state) => fromPlayer.getProcess(state.player);
export const getPlayerStatus = (state) => fromPlayer.getStatus(state.player);
export const getCreatingProcessStatus = (state) => fromPlayer.getCreatingProcessStatus(state.player);
export const getProtectedFileUrl = (state) => fromPlayer.getProtectedFileUrl(state.player);

// giftCouponSelectors
export const isFetchingUnlockCode = (state) => fromEntities.isFetchingUnlockCode(state.entities);
export const getSpecialUnlocked = (state) => fromEntities.getSpecialUnlocked(state.entities);

// alerts selectors
export const getAlerts = (state) => fromEntities.getAlerts(state.entities);

// stripePayment

/**
 * @deprecated use query method instead
 */
export const getCurrentUserEmail = (state) => {
  const currentUser = getCurrentUser(state);
  return currentUser?.email || null;
};

//upgrade subscription
export const isFetchingUpgrade = (state) => fromUpgradeSubscription.isFetchingUpgrade(state.upgradeSubscription);
export const getProration = (state) => fromUpgradeSubscription.getProration(state.upgradeSubscription);
export const isUpgradeError = (state) => fromUpgradeSubscription.isUpgradeError(state.upgradeSubscription);

// newContentSelectors
export const isFetchingNewContent = (state) => fromEntities.isFetchingNewContent(state.entities);
export const getNewContent = (state) => fromEntities.getNewContent(state.entities);

//category selectors
export const getCurrentCategory = (state) => fromEntities.getCurrentCategory(state.entities);
export const isFetchingCategory = (state) => fromEntities.isFetchingCategory(state.entities);

// createRequestCancellation selectors
export const isFetchingRequestCancellation = (state) => fromEntities.isFetchingRequestCancellation(state.entities);
export const getRequestCancellation = (state) => fromEntities.getRequestCancellation(state.entities);

// DAK BGM
export const getBgmCompanies = (state) => bgmCompanies.getCompanies(state.bgmCompanies);

//withdraw Cancelation Subscription
export const isFetchingWithdrawCancelation = (state) =>
  fromWithdrawCancelationSubscription.isFetchingWithdrawCancelation(state.withdrawCancelationSubscription);
