import {
  CONFIRM_PAYMENT_METHOD_FAILURE,
  GET_SUBSCRIPTION_PLANS_FAILURE,
  GET_USER_PAYMENT_INFOS_FAILURE,
  GET_USER_PRORATION_PRICE_FAILURE,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE,
  STRIPE_SETUP_INTENT_FAILURE,
  SUBMIT_CHECKOUT_FAILURE,
  UPDATE_USER_PAYMENT_INFOS_FAILURE
} from 'redux/actions';
import { arrayContains } from 'utils';

export const isPurchaseSubscriptionFailed = (state) =>
  arrayContains(state.payment.failedRequests, [
    GET_USER_PRORATION_PRICE_FAILURE,
    POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE,
    CONFIRM_PAYMENT_METHOD_FAILURE,
    SUBMIT_CHECKOUT_FAILURE,
    GET_SUBSCRIPTION_PLANS_FAILURE,
    GET_USER_PAYMENT_INFOS_FAILURE,
    STRIPE_SETUP_INTENT_FAILURE,
    UPDATE_USER_PAYMENT_INFOS_FAILURE
  ]);
