import React from 'react';
import RNCodeInput from 'react-code-input';
import { asRem } from 'utils';

export const CodeInput = ({ value, onChange, error, ...props }) => {
  return (
    <RNCodeInput
      inputMode="numeric"
      name="otp-token"
      className="code-field"
      type="number"
      fields={6}
      value={value}
      onChange={onChange}
      inputStyle={{
        margin: asRem(4),
        width: asRem(40),
        borderRadius: asRem(6),
        fontSize: asRem(20),
        height: asRem(53),
        color: '#717171',
        textAlign: 'center',
        border: error ? '1px solid red' : '1px solid #ededed',
        backgroundColor: '#ededed'
      }}
    />
  );
};
