export const UPDATE_USER_PAYMENT_INFOS_REQUEST = 'UPDATE_USER_PAYMENT_INFOS_REQUEST';
export const UPDATE_USER_PAYMENT_INFOS_SUCCESS = 'UPDATE_USER_PAYMENT_INFOS_SUCCESS';
export const UPDATE_USER_PAYMENT_INFOS_FAILURE = 'UPDATE_USER_PAYMENT_INFOS_FAILURE';

export const updateUserPaymentInfosRequest = ({ firstName, lastName, street, zipCode, city, country }) => ({
  type: UPDATE_USER_PAYMENT_INFOS_REQUEST,
  payload: {
    firstName,
    lastName,
    street,
    zipCode,
    city,
    country
  }
});

export const updateUserPaymentInfosSuccess = (payload) => ({
  type: UPDATE_USER_PAYMENT_INFOS_SUCCESS,
  payload
});

export const updateUserPaymentInfosFailure = (error) => ({
  type: UPDATE_USER_PAYMENT_INFOS_FAILURE,
  error
});
