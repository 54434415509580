import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false
};

export default function single(state = initialState, action) {
  switch (action.type) {
    case types.GET_SINGLE_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_SINGLE_SUCCESS: {
      return {
        ...state,
        ...action.payload.result,
        isFetching: false,
        error: undefined
      };
    }

    case types.GET_SINGLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state && state.single && state.single.isFetching;

export const getSingle = (state) => state && state.single;
