import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/categories';
import { GET_CATEGORY_PENDING, GET_CATEGORY_FAILURE, GET_CATEGORY_SUCCESS } from 'redux/actions/actionTypes';

export const gettingCategory = () => ({
  type: GET_CATEGORY_PENDING
});

export const getCategoryFailure = (error) => ({
  type: GET_CATEGORY_FAILURE,
  error
});

export const getCategorySuccess = (data) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: data
});

export const getCategoryBySlug = (slug) => (dispatch) => {
  dispatch(gettingCategory());
  return api.getCategoryBySlug(slug).then(
    (response) => {
      dispatch(getCategorySuccess(normalize(response.data.data.categories, [schema.category])));
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(getCategoryFailure(error));
      });
    }
  );
};
