export const GET_USER_PRORATION_PRICE_REQUEST = 'GET_USER_PRORATION_PRICE_REQUEST';
export const GET_USER_PRORATION_PRICE_SUCCESS = 'GET_USER_PRORATION_PRICE_SUCCESS';
export const GET_USER_PRORATION_PRICE_FAILURE = 'GET_USER_PRORATION_PRICE_FAILURE';

export const getUserProrationPriceRequest = ({ subscriptionPlanId }) => ({
  type: GET_USER_PRORATION_PRICE_REQUEST,
  payload: {
    subscriptionPlanId
  }
});

export const getUserProrationPriceSuccess = ({ reducedPrice }) => ({
  type: GET_USER_PRORATION_PRICE_SUCCESS,
  payload: {
    reducedPrice
  }
});

export const getUserProrationPriceFailure = (error) => ({
  type: GET_USER_PRORATION_PRICE_FAILURE,
  error
});
