import { requestApi } from 'utils';

export const upgradeSubscriptionApiRequest = ({ subscriptionPlanId }) =>
  requestApi.mutate(`
  mutation UpgradeSubscription {
    switchingSubscriptionPlan(newPlanId: "${subscriptionPlanId}") {
      id
      state
      isSandbox
      type
      userId
      validTil
      subscriptionPlanId
      subscriptionPlan {
        id
        chargeAmount
        provider
        interval
        title
        isPromo
      }
      createdAt
    }
  }
`);
