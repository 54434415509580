export const GET_USER_PAYMENT_INFOS_REQUEST = 'GET_USER_PAYMENT_INFOS_REQUEST';
export const GET_USER_PAYMENT_INFOS_SUCCESS = 'GET_USER_PAYMENT_INFOS_SUCCESS';
export const GET_USER_PAYMENT_INFOS_FAILURE = 'GET_USER_PAYMENT_INFOS_FAILURE';

export const getUserPaymentInfosRequest = ({ renewable = false }) => ({
  type: GET_USER_PAYMENT_INFOS_REQUEST,
  payload: {
    renewable
  }
});

export const getUserPaymentInfosSuccess = ({ paymentInfos }) => ({
  type: GET_USER_PAYMENT_INFOS_SUCCESS,
  payload: {
    paymentInfos
  }
});

export const getUserPaymentInfosFailure = (error) => ({
  type: GET_USER_PAYMENT_INFOS_FAILURE,
  error
});
