export const GET_STORE_INFOS_REQUEST = 'GET_STORE_INFOS_REQUEST';
export const GET_STORE_INFOS_SUCCESS = 'GET_STORE_INFOS_SUCCESS';
export const GET_STORE_INFOS_FAILURE = 'GET_STORE_INFOS_FAILURE';

export const getStoreInfosRequest = () => ({
  type: GET_STORE_INFOS_REQUEST
});

export const getStoreInfosSuccess = (payload) => ({
  type: GET_STORE_INFOS_SUCCESS,
  payload
});

export const getStoreInfosFailure = (error) => ({
  type: GET_STORE_INFOS_FAILURE,
  error
});
