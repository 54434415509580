import React, { useState } from 'react';
import { TEXT } from 'locales/translations';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row } from 'reactstrap';
import { Link, ScrollLink } from 'Common';

export const FaqSection = () => {
  const [open, setOpen] = useState('');

  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq-marketing">
      <Container>
        <Row>
          <Col xs={12} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <h2>{TEXT.healthMarketingPage.faqSection.headline}</h2>
            <Accordion flush open={open} toggle={toggle} className="faq-marketing__accordion">
              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="1">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_1}</p>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <p>{TEXT.healthMarketingPage.faqSection.copy_1}</p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="2">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_2}</p>
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <p>{TEXT.healthMarketingPage.faqSection.copy_2}</p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="3">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_3}</p>
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  <p>{TEXT.healthMarketingPage.faqSection.copy_3}</p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="4">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_4}</p>
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  <p>
                    {TEXT.healthMarketingPage.faqSection.copy_4a}
                    <ScrollLink offset={-110} to={'cooperations-slider'}>
                      {TEXT.healthMarketingPage.faqSection.linkText_4}
                    </ScrollLink>

                    {TEXT.healthMarketingPage.faqSection.copy_4b}
                  </p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="5">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_5}</p>
                </AccordionHeader>
                <AccordionBody accordionId="5">
                  <p>{TEXT.healthMarketingPage.faqSection.copy_5}</p>
                  <p className="accordion-body__strong">{TEXT.healthMarketingPage.faqSection.subline_5}</p>
                  <p>{TEXT.healthMarketingPage.faqSection.body_5}</p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="faq-marketing__accordion-item">
                <AccordionHeader targetId="6">
                  <p className="accordion-header__heading">{TEXT.healthMarketingPage.faqSection.headline_6}</p>
                </AccordionHeader>
                <AccordionBody accordionId="6">
                  <p>
                    {TEXT.healthMarketingPage.faqSection.copy_6a}
                    <Link className="accordion-body__strong" href={TEXT.healthMarketingPage.faqSection.urlBuy}>
                      Hier
                    </Link>
                    {TEXT.healthMarketingPage.faqSection.copy_6b}
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
