import React from 'react';
import PropTypes from 'prop-types';
import { Control, Errors } from 'react-redux-form';
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import { labelText } from 'utils/labelHelper';
import { selectOptions } from 'utils/propTypes';

SelectField.propTypes = {
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  fieldId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  messages: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredHint: PropTypes.bool,
  showLabel: PropTypes.bool,
  hasFormError: PropTypes.bool,
  type: PropTypes.string,
  validators: PropTypes.object,
  options: selectOptions.isRequired,
  handleChange: PropTypes.func,
  cyName: PropTypes.string,
  className: PropTypes.string
};

SelectField.defaultProps = {
  disabled: false,
  handleChange: () => null,
  messages: {
    valueMissing: 'Bitte wähle aus, welches Abo du kündigen möchtest.'
  }
};

export default function SelectField({
  fieldId,
  defaultValue,
  disabled,
  label,
  showLabel,
  messages,
  model,
  placeholder,
  required,
  validators,
  showRequiredHint = false,
  hasFormError,
  handleChange,
  options,
  cyName,
  className
}) {
  const additionalProps = {};
  if (cyName) {
    additionalProps['data-cy-name'] = cyName;
  }
  if (className) {
    additionalProps['className'] = className;
  }

  return options.length > 0 ? (
    <FormGroup>
      {showLabel && label && (
        <Label className="label--small" for={fieldId}>
          {showRequiredHint ? labelText(label, required) : label}
        </Label>
      )}
      <Control.select
        {...additionalProps}
        component={Input}
        type="select"
        id={fieldId}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        model={model}
        validateOn={['blur', 'change']}
        required={required}
        validators={validators}
        mapProps={{
          valid: ({ fieldValue: { touched, valid }, id }) => {
            if ((touched && !valid) || hasFormError) {
              const element = document.getElementById(id);
              element && element.classList.add('is-invalid');
              return false;
            } else {
              const element = document.getElementById(id);
              if (element && element.classList.contains('is-invalid')) {
                return true;
              }
              return undefined;
            }
          }
        }}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {options.map((option, idx) => (
          <option key={idx} value={option.value} selected={option.selected} disabled={option.disabled}>
            {option.name}
          </option>
        ))}
      </Control.select>
      <FormFeedback>
        <Errors model={model} show={(field) => !field.pristine || field.touched} messages={messages} />
      </FormFeedback>
    </FormGroup>
  ) : (
    <FormGroup>
      {label && (
        <Label className="label--small" for={fieldId}>
          {showRequiredHint ? labelText(label, required) : label}
        </Label>
      )}
    </FormGroup>
  );
}
