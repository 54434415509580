import React from 'react';
import PropTypes from 'prop-types';

const PlayerDescription = ({ title, subtitle, description }) => (
  <div className="player-description">
    <h2 className="player-description__title text-start">{title}</h2>
    <h4 className="mt-0 mb-2 text-start">{subtitle}</h4>
    <div className="text-start mb-4 mb-lg-0">{description}</div>
  </div>
);

PlayerDescription.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

PlayerDescription.defaultProps = {
  title: '',
  subtitle: '',
  description: ''
};

export default PlayerDescription;
