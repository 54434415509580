import React, { useMemo } from 'react';
import ThankYouComponent from './ThankYouComponent';
import { TEXT } from 'locales/translations';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import { getUserPaymentDetails } from 'redux/query';

export const ThankYouPurchaseAssociated = () => {
  const userSubscription = useSelector(getUserPaymentDetails);
  const userEmail = useMemo(() => {
    return userSubscription?.address?.email;
  }, [userSubscription]);

  return (
    <ThankYouComponent
      body={`${TEXT.thankYou.copyPurchaseAssociated_1} ${userEmail ?? ''} ${TEXT.thankYou.copyPurchaseAssociated_2}`}
      showFooter
    >
      <Link to={ROUTES.login} className="btn btn-grey-c100 d-sm-inline-block">
        {TEXT.thankYou.btnLogin}
      </Link>
    </ThankYouComponent>
  );
};
