import React from 'react';
import { TEXT } from 'locales/translations';
import DecorativeImage from 'components/DecorativeImage';
import AboCardIllu from 'images/library/abo-card-illu.png';
import { ROUTES } from 'utils';
import { Col, Container, Button, Row } from 'Common';

const AppAdTile = () => (
  <Container className="library-app-ad-tile mb-5">
    <Row>
      <Col className="library-app-ad-tile__wrapper" xs={{ size: 12, offset: 0, order: 2 }} md={{ size: 6, offset: 0, order: 1 }} xl={{ size: 6, offset: 0 }}>
        <DecorativeImage className="library-app-ad-tile__illustration" src={AboCardIllu} alt={TEXT.appAdTile.altText} />
      </Col>
      <Col xs={{ size: 12, offset: 0, order: 1 }} md={{ size: 6, offset: 0, order: 2 }} xl={{ size: 5, offset: 0 }}>
        <div className="library-app-ad-tile__content-wrapper">
          <h2 className="library-app-ad-tile__headline">{TEXT.appAdTile.headline}</h2>
          <div className="library-app-ad-tile__btn-wrapper">
            <Button type="inverted" to={ROUTES.plans}>
              {TEXT.appAdTile.button}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default AppAdTile;
