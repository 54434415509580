import React, { useMemo } from 'react';
import { TEXT } from 'locales/translations';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils';
import { DateTimePresenter, PeriodPresenter } from 'Common';
import { useSelector } from 'react-redux';
import { getUserPaymentDetails, getUserPaymentMethod } from 'redux/query';

export const SubscriptionDetailTable = () => {
  const { provider, product, active, startsAt, endsAt, canceled, cancelationWithdrawable } = useSelector(getUserPaymentDetails);
  const userPaymentMethod = useSelector(getUserPaymentMethod);

  const paymentMethodType = useMemo(() => {
    if (!userPaymentMethod) {
      return '';
    }
    return userPaymentMethod.type;
  }, [userPaymentMethod]);

  return (
    <table>
      <tbody>
        <tr>
          <td className="subscription-status-title">{TEXT.subscriptionStatusDetail.headline}</td>
          <td>
            <span
              data-cy-name="profile-subscription-status"
              className="subscription-status__details__title"
              dangerouslySetInnerHTML={{ __html: product }}
            />
          </td>
        </tr>
        {provider === 'STRIPE' && product === 'Monats-Abo' && active && (
          <tr>
            <td className="upgrade-subscription-title">{TEXT.subscriptionStatusDetail.upgradeTitle}</td>
            <td>
              <Link to={ROUTES.upgrade} className="btn btn-sm btn-secondary">
                {TEXT.subscriptionStatusDetail.upgradeButton}
              </Link>
            </td>
          </tr>
        )}
        <tr>
          <td className="subscription-status-title subscription-status__details__subscribedSince-title">
            {active ? <>{TEXT.subscriptionStatusDetail.runtimeCurrentPeriode}</> : <>{TEXT.subscriptionStatusDetail.runtimeLastPeriode}</>}
          </td>
          <td className="subscription-status-detail subscription-status__details__subscribedSince">
            {active && endsAt ? <PeriodPresenter start={startsAt} end={endsAt} /> : <>{TEXT.subscriptionStatusDetail.noEnded}</>}
          </td>
        </tr>
        <tr>
          <td className="subscription-status-title subscription-status__details__subscribedSince-title">
            {canceled ? 'Gekündigt und endet am' : 'Status'}
          </td>
          <td className="subscription-status-detail subscription-status__details__subscribedSince">
            {canceled ? (
              <>
                <DateTimePresenter date={endsAt} />
              </>
            ) : active ? (
              <>{TEXT.subscriptionStatusDetail.subscriptionStateActive}</>
            ) : (
              <>{TEXT.subscriptionStatusDetail.subscriptionStateEnded}</>
            )}
          </td>
        </tr>
        {provider === 'STRIPE' && canceled && cancelationWithdrawable && (
          <tr>
            <td className="upgrade-subscription-title"></td>
            <td>
              <Link to={ROUTES.withdrawCancelation} className="btn btn-sm btn-secondary">
                {TEXT.subscriptionStatusDetail.linkAboRenew}
              </Link>
            </td>
          </tr>
        )}
        {active && !canceled && !product.includes('Geschenk-Gutschein') && (
          <tr>
            <td className="subscription-status-title subscription-status__details__subscribedWith-title">
              {TEXT.subscriptionStatusDetail.payWith}
            </td>
            <td className="subscription-status__details__subscribedWith">
              {provider === 'itunes' && <>{TEXT.subscriptionStatusDetail.payWithApple}</>}
              {provider === 'STRIPE' && paymentMethodType === 'card' && <>{TEXT.subscriptionStatusDetail.payWithStripeCard}</>}
              {provider === 'STRIPE' && paymentMethodType === 'sepa_debit' && <>{TEXT.subscriptionStatusDetail.payWithStripeDebit}</>}
              {provider === 'STRIPE' && paymentMethodType === 'paypal' && <>{TEXT.subscriptionStatusDetail.payWithStripePayPal}</>}

              {(provider === 'GOOGLE_PLAY_STORE' || provider === 'GOOGLE_PAYMENT') && <>{TEXT.subscriptionStatusDetail.payWithGoogle}</>}
              {provider === 'BALLOON_VOUCHERS' && <>{TEXT.subscriptionStatusDetail.payWithVoucer}</>}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
