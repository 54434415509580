import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFlashes, removeFlash } from 'redux/actions';
import { getFlashes } from 'redux/query';
import { Alert } from 'reactstrap';

export const FlashListContainer = () => {
  const dispatch = useDispatch();
  const flashes = useSelector(getFlashes);

  useEffect(() => {
    return () => {
      clearFlashes();
    };
  }, [flashes]);

  const handleOnRemoveFlash = (index) => () => {
    dispatch(removeFlash(index));
  };

  if (!(flashes || []).length) {
    return null;
  }

  return (
    <div>
      {flashes.map((flash, index) => (
        <Alert key={`flashAlert${index}`} color={flash.alertType} toggle={handleOnRemoveFlash(index)}>
          {flash.message}
        </Alert>
      ))}
    </div>
  );
};
