import styled from 'styled-components';
import { asRem } from 'utils';
import { colors } from 'Theme';

export default styled.div`
  text-align: center;
  padding: ${asRem(100)} ${asRem(20)};

  h2 {
    color: ${colors.pink.c500}!important;
`;
