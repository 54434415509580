import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { TEXT } from 'locales/translations';
import CallToActionKaifu from './CallToActionKaifu';

class KaifuHero extends Component {
  render() {
    return (
      <div className="hero hero-kaifu">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
              <h1 className="plans__heading">{TEXT.kaifuHero.title}</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 12 }} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
              <div className="card card-offer">
                <div className="card-header">
                  <small>{TEXT.kaifuHeroCard.subheadline}</small>
                  <h2 className="mt-1">{TEXT.kaifuHeroCard.duration}</h2>
                </div>
                <div className="card-body pt-0">
                  <p className="mb-5">{TEXT.kaifuHeroCard.explainer}</p>
                  <div className="section-cta">
                    <CallToActionKaifu />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default KaifuHero;
