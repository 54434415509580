import { useDispatch, useSelector } from 'react-redux';
import {
  isRegisteredUser,
  getConfirmedPurchaseInformation,
  getSelectedSubscriptionPlan,
  isPurchaseSubscriptionFailed,
  hasSubscription
} from 'redux/query';
import { useEffect, useMemo } from 'react';
import { ROUTES } from 'utils';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { addFlash } from 'redux/actions';

export const useCheckoutRefererRoute = () => {
  const dispatch = useDispatch();
  const userRegistered = useSelector(isRegisteredUser);
  const isSubscript = useSelector(hasSubscription);
  const selectedSubscriptionPlan = useSelector(getSelectedSubscriptionPlan);
  const { isGiftCoupon, confirmedPurchase, autoAssigned } = useSelector(getConfirmedPurchaseInformation);
  const hasError = useSelector(isPurchaseSubscriptionFailed);
  const isSelectedPlanGiftCoupon =
    !!selectedSubscriptionPlan && selectedSubscriptionPlan.subscriptionPlanGroup === SUBSCRIPTION_PLAN_GROUPS.GIFT;

  useEffect(() => {
    if (!confirmedPurchase && isSubscript && !isSelectedPlanGiftCoupon) {
      dispatch(addFlash('Du hast bereits ein Abo!', 'success'));
    }
  }, [isSelectedPlanGiftCoupon, isSubscript, confirmedPurchase]);

  return useMemo(() => {
    if (hasError) {
      return ROUTES.paymenterror;
    }

    if (confirmedPurchase) {
      if (isGiftCoupon) {
        return ROUTES.thankyougift;
      }
      if (userRegistered) {
        return ROUTES.thankyou;
      }
      if (autoAssigned) {
        return ROUTES.thankyoupurchaseassociated;
      }
      return ROUTES.thankyouloggedout;
    }
    if (isSubscript && !isSelectedPlanGiftCoupon) {
      return ROUTES.profilePersonalData;
    }
    if (!selectedSubscriptionPlan) {
      return ROUTES.plans;
    }

    return null;
  }, [
    hasError,
    userRegistered,
    selectedSubscriptionPlan,
    isGiftCoupon,
    confirmedPurchase,
    isSubscript,
    isSelectedPlanGiftCoupon,
    autoAssigned
  ]);
};
