import React from 'react';
import { TEXT } from 'locales/translations';
import { Container, Row, Col } from 'reactstrap';
import { ROUTES } from 'utils/constants';
import { HashLink as Link } from 'react-router-hash-link';
import iphoneStoerer from 'images/landingpages/health_insurance_marketing/iphone-stoerer@2x.png';

export const CourseSection = () => {
  return (
    <section className="course-marketing">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            xs={{ size: '12', offset: '0' }}
            sm={{ size: '10', offset: '1' }}
            md={{ size: '6', offset: '2' }}
            lg={{ size: '6', offset: '3' }}
          >
            <h2>{TEXT.healthMarketingPage.courseSection.headline}</h2>
            <div className="course-marketing__btn-container">
              <Link className="btn btn-dark-green" to={ROUTES.krankenkasse + '#section-checkout'}>
                {TEXT.healthMarketingPage.courseSection.ctaBtn}
              </Link>
            </div>
          </Col>
          <Col
            xs={{ size: '5', offset: '0' }}
            sm={{ size: '5', offset: '0' }}
            md={{ size: '4', offset: '0' }}
            lg={{ size: '3', offset: '0' }}
            className="mt-5 mt-md-0"
          >
            <img className="course-marketing__stoerer-img" src={iphoneStoerer} alt="inkl. 12 Monate Balloon-App" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
