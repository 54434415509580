import styled from 'styled-components';
import { Section } from 'components/Container';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-wrapper {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 ${asRem(20)};
  }

  .app-badge-wrapper {
    justify-content: space-evenly;
    display: flex;
    gap: ${asRem(32)};
    margin: ${asRem(32)} 0;

    @media (min-width: ${breakpoints.md}) {
      gap: ${asRem(64)};
    }
  }

  img {
    width: 130%;
    margin: ${asRem(32)} 0 0;

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      margin: 0 0 ${asRem(-48)};
    }

    @media (min-width: ${breakpoints.lg}) {
      width: 130%;
      margin: 0 0 ${asRem(-48)};
    }
  }
`;
