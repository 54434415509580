import {
  CREATE_B2B_CONTACT_FAILURE,
  CREATE_B2B_CONTACT_REQUEST,
  CREATE_B2B_CONTACT_SUCCESS,
  RESET_B2B_CONTACT
} from '../../actions/b2bContact';

const initialState = {
  success: false,
  error: null
};

export const b2bContact = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_B2B_CONTACT_REQUEST:
      return {
        ...state,
        success: false,
        error: null
      };
    case CREATE_B2B_CONTACT_SUCCESS:
      return {
        ...state,
        success: true,
        error: null
      };
    case CREATE_B2B_CONTACT_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload
      };
    case RESET_B2B_CONTACT:
      return {
        ...state,
        success: false,
        error: null
      };
    default:
      return state;
  }
};
