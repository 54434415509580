/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isAuthed, isGuest } from 'redux/query';
import { VoucherRoute, PrivateRoute, VoucherManual } from 'components';
import { ROUTES } from 'utils/constants';

class VoucherRouteContainer extends Component {
  render() {
    const customRouting = location.href.includes(ROUTES.voucher);

    const voucherManualRouting = location.href.includes(ROUTES.voucher_manual);

    if (!customRouting) {
      return <PrivateRoute {...this.props} />;
    }
    if (!voucherManualRouting) {
      return <VoucherRoute {...this.props} />;
    }

    return <VoucherManual />;
  }
}

const mapStateToProps = (state) => ({
  isAuthed: isAuthed(state),
  isGuest: isGuest(state)
});

export default connect(mapStateToProps)(VoucherRouteContainer);
