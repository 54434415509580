import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearStripePaymentInfos, clearUserPaymentInfos, getSubscriptionPlansRequest, selectSubscriptionPlan } from 'redux/actions';
import { getFilteredSubscriptionPlans, hasSubscription, isCouponNotFound, isSubscriptionPlansLoading } from 'redux/query';
import { ROUTES } from 'utils';
import qs from 'query-string';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import Style from './PurchasePage.style';
import { Col, Container, Row, Spinner } from 'Common';
import { FlashListContainer } from 'containers';
import { TEXT } from 'locales/translations';
import SubscriptionCard from '../../Plans/SubscriptionCard';
import { CheckoutCouponForm } from 'Component';

export const PurchasePage = ({ history, location }) => {
  const couponNotFound = useSelector(isCouponNotFound);
  const coupon = useMemo(() => {
    let { coupon } = qs.parse(location.search);
    if (couponNotFound) {
      return null;
    }
    return coupon;
  }, [location.search, couponNotFound]);

  const dispatch = useDispatch();
  const hasUserSubscription = useSelector(hasSubscription);
  const isLoading = useSelector(isSubscriptionPlansLoading);
  const subscriptionPlans = useSelector(getFilteredSubscriptionPlans(coupon));

  const header = !!coupon ? TEXT.subscriptionPlans.reducedHeading : TEXT.subscriptionPlans.finalHeading;
  const subheader = !!coupon ? TEXT.plans.reducedSubheading : TEXT.plans.subheading;

  useEffect(() => {
    if (hasUserSubscription) {
      history.replace(ROUTES.profilePersonalData);
      return;
    }
    dispatch(clearUserPaymentInfos());
    dispatch(clearStripePaymentInfos());
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL, coupon));
  }, []);

  const handleOnSelectPlan = (plan) => () => {
    if (plan.zppCertified) {
      history.push(ROUTES.krankenkasse + '#section-checkout');
      return;
    }

    dispatch(selectSubscriptionPlan(plan.id));
    history.push(ROUTES.checkout);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Style>
      <Container>
        <Row>
          <Col xs={{ size: 12 }} lg={{ size: 10, offset: 1 }} className="mt-4 mt-sm-0">
            <h1 className="plans__heading">{header}</h1>
            <p className="plans__subheading">{subheader}</p>
            <FlashListContainer />
          </Col>
        </Row>
      </Container>
      {subscriptionPlans.standard || subscriptionPlans.badged ? (
        <Fragment>
          <Row>
            <Col lg={{ size: 10, offset: 1 }}>
              <Row>
                <Col className="subscriptions" xs="12" md="6">
                  {subscriptionPlans.badged.map((plan) => (
                    <SubscriptionCard key={plan.id} plan={plan} onClick={handleOnSelectPlan(plan)} hasCoupon={!!coupon} />
                  ))}
                </Col>
                <Col className="subscriptions spac" xs="12" md="6">
                  {subscriptionPlans.standard.map((plan) => (
                    <SubscriptionCard key={plan.id} plan={plan} onClick={handleOnSelectPlan(plan)} hasCoupon={!!coupon} />
                  ))}
                </Col>
              </Row>
              <Col className="mt-4">
                <CheckoutCouponForm />
              </Col>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col xs={{ size: 12, offset: 0 }} md={{ size: 9, offset: 1 }}>
                <p className="small text-center subscription-disclaimer">{TEXT.checkout.subscriptionDisclaimer}</p>
              </Col>
            </Row>
          </Container>
        </Fragment>
      ) : (
        <p className="text-center">{TEXT.checkout.noAbos}</p>
      )}
    </Style>
  );
};
