import React, { useEffect } from 'react';
import Style from './SubscriptionStatusPage.style';
import { Headline, Spinner } from 'Common';
import { TEXT } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { getUserPaymentInfosRequest } from 'redux/actions';
import { getUserPaymentDetails, hasSubscription, isUserPaymentInformationLoading } from 'redux/query';
import { NoSubscriptionContainer } from 'containers';
import { SubscriptionDetailTable, SubscriptionHint, UserBillingAddress } from 'Component';
import { UserPaymentMethodForm } from 'Component/UserPaymentMethodForm/UserPaymentMethodForm';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils';
import { Col, Row } from 'reactstrap';

export const SubscriptionStatusPage = () => {
  const dispatch = useDispatch();
  const { provider, product, active, canceled } = useSelector(getUserPaymentDetails);
  const isLoading = useSelector(isUserPaymentInformationLoading);
  const isSubscripte = useSelector(hasSubscription);

  useEffect(() => {
    dispatch(getUserPaymentInfosRequest({ renewable: true }));
  }, []);

  const isSubscriptionOngoing = active && !canceled;

  return (
    <Style className="subscription-status">
      <Headline level={1} className="headline">
        {TEXT.subscriptionStatus.heading}
      </Headline>
      {isLoading ? (
        <Spinner />
      ) : isSubscripte ? (
        <>
          <SubscriptionHint />
          <SubscriptionDetailTable />
          {provider === 'STRIPE' && (
            <>
              {isSubscriptionOngoing && !product.includes('Geschenk-Gutschein') && (
                <>
                  <UserPaymentMethodForm />
                  <UserBillingAddress />
                  <Row className="mt-5">
                    <Col xs={12} sm={6} className="subscription-status-title">
                      {TEXT.subscriptionStatusDetail.currentContract}
                    </Col>
                    <Col xs={12} sm={6}>
                      <Link
                        to={ROUTES.terminate}
                        className="btn-link-underlined p-0 m-0 btn btn-secondary float-sm-right float-none mt-sm-2"
                      >
                        {TEXT.subscriptionStatusDetail.cancelContract}
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <NoSubscriptionContainer />
      )}
    </Style>
  );
};
