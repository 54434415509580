import { requestApi } from 'utils';

export const createB2bContactApiRequest = ({ name, email, companyName, phone, website, companySize, jobTitle, message, contactType }) =>
  requestApi.mutate(
    `
  mutation CreateB2bContact(
    $name: String!
    $email: String!
    $companyName: String
    $phone: String
    $website: String
    $companySize: String
    $jobTitle: String
    $message: String
    $contactType: ContactType!
  ) {
    createB2bContact(
      name: $name
      email: $email
      companyName: $companyName
      phone: $phone
      website: $website
      companySize: $companySize
      jobTitle: $jobTitle
      message: $message
      contactType: $contactType
    ) {
      name
      email
      companyName
      phone
      website
      companySize
      jobTitle
      message
      contactType
      id
    }
  }
`,
    {
      name,
      email,
      companyName,
      phone,
      website,
      companySize,
      jobTitle,
      message,
      contactType
    }
  );
