import { call, put, takeLatest } from 'redux-saga/effects';
import { createB2bContactApiRequest } from 'api/balloon/createB2bContactApiRequest';
import { CREATE_B2B_CONTACT_REQUEST, createB2bContactSuccess, createB2bContactFailure } from 'redux/actions/b2bContact';

function* createB2bContactSaga(action) {
  try {
    const response = yield call(createB2bContactApiRequest, action.payload);
    yield put(createB2bContactSuccess(response.data.data.createB2bContact));
  } catch (error) {
    yield put(createB2bContactFailure(error.message));
  }
}

export function* watchCreateB2bContact() {
  yield takeLatest(CREATE_B2B_CONTACT_REQUEST, createB2bContactSaga);
}
