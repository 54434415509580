import React from 'react';
import { TEXT } from 'locales/translations';
import phone from 'images/landingpages/bkk/kurs-image.jpg';
import phoneMedium from 'images/landingpages/bkk/kurs-image@2x.jpg';
import phoneLarge from 'images/landingpages/bkk/kurs-image@3x.jpg';
import checkmark from 'images/landingpages/b2b_clients/checkmark.svg';

const CheckmarkBoxSection = () => {
  return (
    <section id="checkmark-section-container">
      <div id="content-container">
        <div id="bottom-container">
          <div id="text-container">
            <h2>{TEXT.bkk.checkmark_section.headline}</h2>
            <p>{TEXT.bkk.checkmark_section.subline_1}</p>
            <p>{TEXT.bkk.checkmark_section.subline_2}</p>
            <div className="checkmark-container">
              <div className="checkmark-content">
                <img src={checkmark} alt="" className="img-checkmark" />
                <p>{TEXT.bkk.checkmark_section.checkmark_1}</p>
              </div>
              <div className="checkmark-content">
                <img src={checkmark} alt="" className="img-checkmark" />
                <p>{TEXT.bkk.checkmark_section.checkmark_2}</p>
              </div>
              <div className="checkmark-content">
                <img src={checkmark} alt="" className="img-checkmark" />
                <p>{TEXT.bkk.checkmark_section.checkmark_3}</p>
              </div>
            </div>
          </div>
          <div id="link-container">
            <img
              className="img-course-phone"
              src={phone}
              srcSet={`${phone} 300w, ${phoneMedium} 768w, ${phoneLarge} 1280w`}
              alt="Kursbeispiel Mobil"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default CheckmarkBoxSection;
