import { requestApi } from 'utils';

export const getInvoices = () =>
  requestApi.query(`
  query GetInvoices {
    stripeInvoices {
      id
      created
      amountPaid
      amountDue
      number
      currency
      periodStart
      periodEnd
      invoicePdf
      paid
    }
  }
`);
