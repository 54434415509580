import React from 'react';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import TermsOfServiceLink from 'components/TermsOfServiceLink';
import { Paragraph } from 'Common';

export const ImplicitTermsAcceptance = ({ linkColor }) => {
  return (
    <Paragraph className="extra-small implicit-acceptance card-link text-center">
      Es gelten unsere <TermsOfServiceLink color={linkColor} /> und <PrivacyPolicyLink buttonText="hier" color={linkColor} /> findest du
      unsere Datenschutzhinweise.
    </Paragraph>
  );
};
