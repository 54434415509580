import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { TEXT } from 'locales/translations';
import Confetti from 'react-confetti';

import balloonieImg from 'images/thank_you/balloony_gift.png';
import mobileImg from 'images/thank_you/mobile_thank_you.png';

class ThankYouComponent extends Component {
  render() {
    const { showFooter, title, body, children } = this.props;

    return (
      <>
        <section className="main-decorated thank-you">
          <Container>
            <Row>
              <Col
                xs={{ size: '12', offset: '0' }}
                sm={{ size: '10', offset: '1' }}
                md={{ size: '8', offset: '2' }}
                lg={{ size: '6', offset: '3' }}
                className="card thank-you__card d-block text-center mt-6 mb-0 mb-sm-7 my-md-8 background-green-c500"
              >
                <Confetti
                  drawShape={(ctx) => {
                    ctx.beginPath();
                    ctx.arc(0, 0, 10, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.closePath();
                    ctx.restore();
                  }}
                  colors={[
                    '#335d72',
                    '#455a64',
                    '#4a9183',
                    '#598096',
                    '#598196',
                    '#5e5e5e',
                    '#62a89a',
                    '#aaaaaa',
                    '#b0c3cd',
                    '#bd5a61',
                    '#d4d4d4',
                    '#db9598',
                    '#dc3545',
                    '#e78088',
                    '#e9c85f',
                    '#f4f5f5',
                    '#fda886'
                  ]}
                  width="570"
                  height="620"
                />

                <h2 className="text-white-c500 mb-3 mb-sm-2 mb-0">{title}</h2>
                <>
                  <p className="text-white-c500 display-linebreak px-2 px-md-5">{body}</p>
                </>
                <img className="mx-auto balloonie-img d-block" src={balloonieImg} alt="balloonie" />
                {children}
              </Col>
            </Row>
          </Container>
          {showFooter && (
            <Container fluid className="bottom-section">
              <Container className="mt-6 mt-sm-5 mb-0 pb-sm-0">
                <Row className="flex-column-reverse flex-sm-row">
                  <Col
                    xs={{ size: 12, offset: 0 }}
                    sm={{ size: 6, offset: 0 }}
                    lg={{ size: 4, offset: 2 }}
                    className="image text-center p-0"
                  >
                    <div className="inner">
                      <img className="d-block mx-auto" src={mobileImg} alt="Mobiles Telefon mit der Anzeige der Profilseite" />
                    </div>
                  </Col>
                  <Col
                    xs={{ size: 12, offset: 0 }}
                    sm={{ size: 6, offset: 0 }}
                    lg={{ size: 5, offset: 0 }}
                    xl={{ size: 4, offset: 0 }}
                    className="description text-center"
                  >
                    <h3 className="card-title mt-4 mt-md-5 text-blueGrey-c500">{TEXT.thankYou.titleBottomSection}</h3>
                    <p className="mb-3 mb-sm-4 mb-md-5 text-blueGrey-c500">{TEXT.thankYou.copyBottomSection}</p>
                  </Col>
                </Row>
              </Container>
            </Container>
          )}
        </section>
      </>
    );
  }
}

ThankYouComponent.propTypes = {
  showFooter: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node
};

ThankYouComponent.defaultProps = {
  showFooter: true,
  title: TEXT.thankYou.title,
  body: TEXT.thankYou.copyRegularPurchase
};

export default ThankYouComponent;
