import { put, select } from 'redux-saga/effects';
import { getCurrentUser, getPurchaseUserId, isRegisteredUser } from 'redux/query';
import { transferSubscriptionApiRequest } from 'api/balloon';
import { clearPayerUserId, getUserPaymentInfosRequest } from 'redux/actions';

export function* migrateSubscriptionSaga() {
  const state = yield select();

  const payerUserId = getPurchaseUserId(state);
  const user = getCurrentUser(state);
  const isRegistered = isRegisteredUser(state);

  if (payerUserId && isRegistered && user.id !== payerUserId) {
    yield transferSubscriptionApiRequest({ guestUserId: payerUserId });
    yield put(clearPayerUserId());
  }
  yield put(getUserPaymentInfosRequest({ renewable: false }));
}
