import React from 'react';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';
import { currencySymbol } from 'Common';
import { localizedAmount } from 'utils/localeHelpers';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { useHistory } from 'react-router-dom';

const Header = ({ plan, totalAmount }) => {
  const history = useHistory();
  const previousPath = history.location.state?.from;

  const { interval, subscriptionPlanGroup, title, currency, zppCertified } = plan;

  const startTitleWord = interval === 'ONETIME' && subscriptionPlanGroup !== SUBSCRIPTION_PLAN_GROUPS.GIFT ? 'Deine' : 'Dein';

  const headline = `${startTitleWord} ${title} für ${localizedAmount(totalAmount / 100)} ${currencySymbol(currency)}`;

  let subTitle;
  switch (true) {
    case zppCertified && previousPath === '/krankenkasse/resilienz':
      subTitle = TEXT.resiliencePage.checkout.headerSubTitlePraeventionskursResilienz;
      break;
    case zppCertified:
      subTitle = TEXT.checkout.headerSubTitlePraeventionskurs;
      break;
    case subscriptionPlanGroup === 'gift':
      subTitle = TEXT.checkout.headerSubTitleGift;
      break;
    default:
      subTitle = TEXT.checkout.headerSubTitle;
  }

  return (
    <div className="card-header pt-6 px-3">
      <h2 className="card-title">
        <div dangerouslySetInnerHTML={{ __html: headline }} />
      </h2>
      <p className="card-copy px-sm-5">{subTitle}</p>
    </div>
  );
};

Header.propTypes = {
  plan: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subscriptionPlanGroup: PropTypes.string,
    zppCertified: PropTypes.bool
  }).isRequired,
  totalAmount: PropTypes.number.isRequired
};

export default Header;
