import React from 'react';

const TeamIllu = () => {
  return (
    <div className="illu-container">
      <div className="illu" />
    </div>
  );
};

export default TeamIllu;
