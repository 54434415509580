import { HealthCooperations } from 'components/Component';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { hasSubscription } from 'redux/query';
import {
  AdvantagesSection,
  BenefitInfoSection,
  BorisSection,
  CtaInfoSection,
  HealthLandingPageSection,
  MobileShowcaseSection,
  PodcastSection,
  Stage,
  TrustBandSection,
  VideoSection
} from './index';

class Home extends React.PureComponent {
  render() {
    const { hasSubscription } = this.props;
    return (
      <main className="home">
        <FormattedMessage id="meta.title">
          {(title) => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <Stage hasSubscription={hasSubscription} />
        <AdvantagesSection />
        <HealthLandingPageSection />
        <HealthCooperations id="cooperations-slider" />
        <VideoSection />
        <BorisSection />
        <PodcastSection />
        <BenefitInfoSection hasSubscription={hasSubscription} />
        <MobileShowcaseSection />
        <TrustBandSection />
        <CtaInfoSection />
      </main>
    );
  }
}

Home.propTypes = {
  hasSubscription: PropTypes.bool
};

Home.defaultProps = {
  hasSubscription: false
};

const mapStateToProps = (state) => ({
  hasSubscription: hasSubscription(state)
});

export default connect(mapStateToProps, null)(Home);
