import React from 'react';
import { BorderedSectionContainer, InsuranceContainer, InsuranceLandingPage } from 'Container';
import {
  PartnerLogo,
  ImageParagraph,
  ImageTextRow,
  LogoChain,
  Section,
  PrivacyPolicyHint,
  InsuranceHeaderSection,
  HealthRequirementsHint,
  InsuranceNumberAlreadyExistsButton
} from 'Component';
import { Col, Headline, Icon, Link, List, Paragraph, Row, ScrollButton, Input, Toggle, Image } from 'Common';
import { LogoBrigitte, LogoFlow, LogoGesundLeben } from 'images/trust_band';
import { thumb, flower, heart } from 'images/landingpages/b2b_clients';
import { successPhoneImage, mobilePhoneImage, logoHeaderImage } from 'images/landingpages/vividaBkk';
import { colors } from 'components/Theme';
import { TEXT } from 'locales/translations';

export const VividaBkkLandingPage = () => {
  const text = TEXT.vividaBkk;

  return (
    <InsuranceLandingPage>
      <InsuranceHeaderSection>
        <Headline level={1}>{text.header.headline} </Headline>
        <Paragraph className="intro">{text.header.subline}</Paragraph>
        <ScrollButton type="inverted" color="blue" to="form-section" offset={-50}>
          {text.header.button}
        </ScrollButton>
        <PartnerLogo imageSrc={logoHeaderImage} altText={text.header.altText} logoLink={text.header.logoLink} />
        <Paragraph className="add-on-text">{text.header.addOnText}</Paragraph>
      </InsuranceHeaderSection>
      <Section backgroundColor="white" className="checkmark-section" dataCyName="checkmark-section">
        <Row>
          <Col xs="12" md="7" lg="6">
            <Headline level={2}>{text.checkmarkSection.headline}</Headline>
            <Paragraph>{text.checkmarkSection.subline_1}</Paragraph>
            <Paragraph>{text.checkmarkSection.subline_2}</Paragraph>
            <List icon={<Icon color={[colors.blue.c200, colors.blue.c600]} size={{ sm: 32, md: 32, lg: 40 }} name="Checkmark" />}>
              {[text.checkmarkSection.checkmark_1, text.checkmarkSection.checkmark_2, text.checkmarkSection.checkmark_3]}
            </List>
          </Col>
          <Col xs="12" md="5" lg="6" className="image">
            <Image src={mobilePhoneImage} alt="Kursbeispiel Mobil" className="image-course-phone" />
          </Col>
        </Row>
      </Section>
      <BorderedSectionContainer topBorder className="text-center" dataCyName="image-text-row-section">
        <Headline level={2}>{text.ballooniSection.headline}</Headline>
        <ImageTextRow>
          <ImageParagraph dataCyName="paragraph-image-1" image={thumb}>
            {text.ballooniSection.ballooni_1}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-2" image={flower}>
            {text.ballooniSection.ballooni_2}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-3" image={heart}>
            {text.ballooniSection.ballooni_3}
          </ImageParagraph>
        </ImageTextRow>
      </BorderedSectionContainer>
      <Section backgroundColor="white" verticalPadding="small" className="brands-section text-centerd-none d-md-block">
        <LogoChain
          headline={<Headline level={5}>{text.trustBandSection.headline}</Headline>}
          logos={[
            <LogoBrigitte primaryColor={colors.blueGrey.c500} />,
            <LogoFlow primaryColor={colors.blueGrey.c500} />,
            <LogoGesundLeben primaryColor={colors.blueGrey.c500} secondaryColor={colors.white.c500} />
          ]}
        />
      </Section>
      <Section backgroundColor="white" verticalPadding="small" className="form-section" id="anmeldung-vivida-bkk" dataCyName="form-section">
        <InsuranceContainer b2bClientKey={'VIVIDA_BKK'} defaultValues={{ personalDataSync: false }}>
          <InsuranceContainer.FormView>
            <div className="header">
              <Headline level={2}>{text.formSection.headline}</Headline>
              <Paragraph className="small">
                {text.formSection.infoText}
                <Link href={`mailto:${text.formSection.contactMail}`}>{text.formSection.contactMail}</Link>
              </Paragraph>
            </div>
            <InsuranceContainer.Form defaultValues={{ personalDataSync: false }}>
              {({ register }) => (
                <>
                  <Input {...register('name')} placeholder={text.formSection.placeholderName} />
                  <Input {...register('email')} placeholder={text.formSection.placeholderMail} type="email" />
                  <Input {...register('insuranceNumber')} placeholder={text.formSection.placeholderNum} />
                  <InsuranceNumberAlreadyExistsButton />
                  <Paragraph className="extra-small">{text.formSection.insuranceNumberInfo}</Paragraph>
                  <Input {...register('dateOfBirth')} placeholder={text.formSection.placeholderDateOfBirth} />
                  <Toggle {...register('healthCheck')} className="mb-3" id="health-terms-toggle">
                    <HealthRequirementsHint />
                  </Toggle>
                  <Toggle {...register('termsCheck')} className="mb-3" id="privacy-toggle">
                    <PrivacyPolicyHint />
                  </Toggle>
                  <Toggle {...register('personalDataSync')} className="mb-3" id="personal-data-sync-toggle" dataCyName="personal-data-sync">
                    <Paragraph className="extra-small personal-data-text">{text.formSection.personalDataSync}</Paragraph>
                  </Toggle>
                </>
              )}
            </InsuranceContainer.Form>
          </InsuranceContainer.FormView>
          <InsuranceContainer.OtpView />
          <InsuranceContainer.SuccessView>
            <Image src={successPhoneImage} alt="Antistress-Coaching-Kurs" />
          </InsuranceContainer.SuccessView>
        </InsuranceContainer>
      </Section>
    </InsuranceLandingPage>
  );
};
