import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TEXT } from 'locales/translations';
import { getColorSetByPrimaryColor } from 'utils';
import TruncatedText from './TruncatedText';

const ListItem = ({
  categoryBadge,
  newBadge,
  specialBadge,
  type,
  color,
  title,
  time,
  unitsCount,
  illustration,
  icon,
  categoryIcon,
  slug
}) => {
  if (!color) return null;
  const { darkColor } = getColorSetByPrimaryColor(color);

  return (
    <Link
      to={`/play/${type}/${slug}`}
      className={`library-list-item library-list-item--${type}`}
      style={
        type === 'course'
          ? {
              background: color,
              backgroundImage: `url(${illustration})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom'
            }
          : { backgroundColor: color }
      }
    >
      <div className={`library-list-item__${newBadge}`} style={{ backgroundColor: darkColor }}>
        {TEXT.libraryBadges.new}
      </div>
      <div className={`library-list-item__${specialBadge}`} style={{ backgroundColor: darkColor }}>
        {TEXT.libraryBadges.special}
      </div>
      <div className="library-list-item__content">
        <div className={`library-list-item__${icon}`} />
        <div className="library-list-item__double-line">
          <div className="library-list-item__headline">
            <TruncatedText>{title}</TruncatedText>
          </div>
          <div className="library-list-item__sub-headline">
            {type === 'course' ? `${TEXT.contentTypes.course} | ${unitsCount} | ${time}` : `${TEXT.contentTypes.single} | ${time}`}
          </div>
        </div>
      </div>
      {type === 'single' && categoryIcon ? <img className="library-list-item__category-icon" src={categoryIcon} alt="icon" /> : null}
    </Link>
  );
};

ListItem.propTypes = {
  categoryBadge: PropTypes.string,
  newBadge: PropTypes.string,
  specialBadge: PropTypes.string,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  unitsCount: PropTypes.string,
  illustration: PropTypes.string,
  icon: PropTypes.string.isRequired,
  slug: PropTypes.string,
  categoryIcon: PropTypes.string
};

export default ListItem;
