import React, { useState } from 'react';
import { Image, Link } from 'Common';
import PropTypes from 'prop-types';

export const HoverImageLink = ({ image, hoverImage = image, to, className, alt, href }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOverOut = () => setIsHovered((isHovered) => !isHovered);

  const displayImage = isHovered ? hoverImage : image;

  return (
    <Link to={to} href={href}>
      <Image className={className} src={displayImage} alt={alt} onMouseEnter={handleMouseOverOut} onMouseLeave={handleMouseOverOut} />
    </Link>
  );
};

HoverImageLink.propTypes = {
  image: PropTypes.string.isRequired,
  hoverImage: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string
};
