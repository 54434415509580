import React from 'react';
import { MainPage } from 'Container';
import { Col, Row } from 'reactstrap';
import markdownContent from '!!raw-loader!static/imprint.md';
import { MarkdownPanel } from 'Common';

export default function Imprint() {
  return (
    <MainPage bodyClassName="background-blue-c500" title="Impressum">
      <Row>
        <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
          <div className="panel">
            <MarkdownPanel>{markdownContent}</MarkdownPanel>
          </div>
        </Col>
      </Row>
    </MainPage>
  );
}
