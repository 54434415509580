import styled from 'styled-components';
import { breakpoints, colors } from 'components/Theme';
import { asRem } from 'utils';

export default styled.footer`
  align-self: flex-end;
  width: 100%;
  margin-top: auto;
  background-color: ${colors.blue.c600};
  color: ${colors.white.c500};
  padding: 0;
  z-index: 1;
  text-align: center;

  .nav-col {
    padding-left: 4vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: ${breakpoints.md}) {
      padding-left: ${asRem(32)};
      align-items: center;
    }
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: ${breakpoints.md}) {
      min-width: ${asRem(190)};
    }
  }

  .bottom-nav-col {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
      padding-left: ${asRem(32)};
    }
  }

  .bottom-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
      align-items: flex-start;
      min-width: ${asRem(190)};
    }
  }

  .bottom-footer-end {
    @media (min-width: ${breakpoints.md}) {
      display: flex;
      justify-content: flex-end;
      padding-right: 4vw;
    }
  }

  .social-icon {
    display: inline-block;
    margin-right: ${asRem(16)};

    @media (min-width: ${breakpoints.sm}) {
      margin-right: ${asRem(32)};
    }

    > svg {
      height: 2em;
      fill: ${colors.white.c500};
    }

    &:hover svg {
      fill: ${colors.blueGrey.c200};
      transition: color 0.15s ease-in-out;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }

  .bottom-footer {
    background-color: ${colors.white.c500};
    color: ${colors.blueGrey.c500};

    .row {
      align-items: center;

      @media (min-width: ${breakpoints.md}) {
        min-height: ${asRem(68)};
      }

      @media (min-width: ${breakpoints.xl}) {
        min-height: ${asRem(54)};
      }
    }
  }
`;
