import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components';
import { isValidEmail } from 'utils/validators';

export default function EmailTextField({
  showLabel,
  defaultValue,
  disabled,
  label,
  showRequiredHint,
  handleChange,
  fieldId,
  model,
  placeholder
}) {
  return (
    <TextField
      id={fieldId}
      label={showLabel ? label || 'E-Mail-Adresse' : null}
      autocomplete="email"
      messages={{
        valueMissing: 'Bitte gib deine E-Mail ein.',
        isEmail: 'Keine valide E-Mail-Adresse.',
        badInput: 'Diese E-Mail wurde nicht gefunden.',
        customError: 'Diese E-Mail-Adresse kann nicht verwendet werden, bitte überprüfe deine Eingabe.'
      }}
      showRequiredHint={showRequiredHint}
      defaultValue={defaultValue}
      disabled={disabled}
      model={model}
      placeholder={placeholder}
      required
      validators={{
        isEmail: (val) => isValidEmail(val)
      }}
      cyName="mail-input"
      handleChange={handleChange}
    />
  );
}

EmailTextField.defaultProps = {
  showLabel: false,
  defaultValue: '',
  disabled: false,
  label: null,
  handleChange: () => null,
  fieldId: 'user.email',
  model: '.email',
  placeholder: 'E-Mail-Adresse'
};

EmailTextField.propTypes = {
  showLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  showRequiredHint: PropTypes.bool,
  handleChange: PropTypes.func,
  fieldId: PropTypes.string,
  placeholder: PropTypes.string,
  model: PropTypes.string
};
