import { LOGOUT_USER, REQUEST_OTP_SUCCESS } from 'redux/actions/actionTypes';
import {
  REGISTER_USER_SUCCESS,
  REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS,
  SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS,
  SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS,
  RESET_USER_GUEST_STATE,
  REGISTER_HEALTH_INSURANCE_USER_SUCCESS
} from 'redux/actions';

const initialState = {
  currentOtpUrl: null,
  updatedAt: 0
};

export const persistAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        ...initialState
      };
    }

    case REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS:
    case SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS:
    case REGISTER_HEALTH_INSURANCE_USER_SUCCESS:
    case REQUEST_OTP_SUCCESS:
    case SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS:
    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        currentOtpUrl: window.location.pathname
      };
    }

    case RESET_USER_GUEST_STATE: {
      return {
        ...state,
        currentOtpUrl: null
      };
    }

    default:
      return state;
  }
};
