import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
// eslint-disable-next-line no-unused-vars
import * as api from 'api/balloon/course';

import { GET_COURSE_PENDING, GET_COURSE_FAILURE, GET_COURSE_SUCCESS } from 'redux/actions/actionTypes';

export const gettingCourse = () => ({
  type: GET_COURSE_PENDING
});

export const getCourseFailure = (error) => ({
  type: GET_COURSE_FAILURE,
  error
});

export const getCourseSuccess = (payload) => ({
  type: GET_COURSE_SUCCESS,
  payload
});

export const getCourseBySlug =
  (slug) =>
  (dispatch, _, { api }) => {
    dispatch(gettingCourse());
    return api.getCourse(slug.trim()).then(
      (response) => {
        if (response.data.errors) {
          dispatch(getCourseFailure(response.data.errors));
        } else {
          dispatch(
            getCourseSuccess(
              normalize(response.data.data.courses[0], {
                course: schema.course
              })
            )
          );
        }
      },
      (error) => {
        handleAxiosError(error, () => {
          dispatch(getCourseFailure(error));
        });
      }
    );
  };
