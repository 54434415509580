/// this file contains multiply query methods, this must be refactored with a central error handling

/** @deprecated refactor with central error handling */
export const getConfirmCodeErrorMessage = (messageCode) => {
  const messages = {
    INVALID_CONFIRMATION_CODE:
      'Der eingegebene Code ist leider falsch. Bitte versuche es noch mal oder lasse dir einen neuen Code zusenden',
    TOKEN_EXPIRED: 'Der eingegebene Code ist leider abgelaufen. Lasse dir einen neuen Code zusenden'
  };

  const fallback = 'Hmm, dies hätte nicht passieren sollen. Probier es bitte nochmal';

  return messages[messageCode] || fallback;
};

/** @deprecated refactor with central error handling */
export const getUpdateUserEmailErrorMessage = (error) => {
  const userError = error.extensions.user;

  if ('unconfirmed_email' in userError) {
    return userError.unconfirmed_email[0];
  }

  return 'Hmm, dies hätte nicht passieren sollen. Probier es bitte nochmal';
};
