import * as React from 'react';
import Style from './B2BLandingPage.style';

import {
  AudioSamples,
  Author,
  Benefit,
  Brands,
  CompanyTrust,
  Faq,
  Header,
  HealthAdvisor,
  Offer,
  Partnerships,
  Whitepaper
} from './components';
import '@missionme/mime_elements/dist/public/Css/Global.css';
import '@missionme/mime_elements/dist/public/Css/GlobalColors.css';
import '@missionme/mime_elements/dist/public/Css/BootstrapUtils.css';

const B2BLandingPage = () => {
  return (
    <Style>
      <Header />
      <CompanyTrust />
      <Benefit />
      <AudioSamples />
      <Author />
      <Offer />
      <HealthAdvisor />
      <Partnerships />
      <Whitepaper />
      <Faq />
      <Brands />
    </Style>
  );
};

export default B2BLandingPage;
