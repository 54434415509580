import React from 'react';
import { BackgroundImage, Paragraph, Link } from 'Common';
import { HealthAdvisorStyle } from './HealthAdvisor.style';
import ballooniePoints from 'images/landingpages/b2b_landingpage/balloonie-points.png';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils/constants';

export const HealthAdvisor = () => {
  return (
    <HealthAdvisorStyle>
      <BackgroundImage image={ballooniePoints} className="img" />
      <Paragraph className="claim">
        {TEXT.company.health_advisor.claim}
        <Link to={ROUTES.healthAdvisor}>{TEXT.company.health_advisor.link}</Link>
      </Paragraph>
    </HealthAdvisorStyle>
  );
};
