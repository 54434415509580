import React from 'react';
import { TEXT } from 'locales/translations';
import { Markdown, Paragraph } from 'Common';
import { TermsModal } from 'components/index';
import { colors } from 'Theme';
import markdownContent from '!!raw-loader!static/health_requirements.md';

export const HealthRequirementsHint = ({ paragraphClassName = 'extra-small' }) => (
  <Paragraph className={paragraphClassName}>
    {TEXT.formsGeneral.acceptText}
    <TermsModal
      buttonColor="link"
      modalHeaderText="Gesundheitsvoraussetzungen"
      buttonText="Gesundheitsvoraussetzungen"
      renderAs="link"
      color={colors.green.c500}
    >
      <Markdown className="popup" containerId="modal-body" scrollOffset={0}>
        {markdownContent}
      </Markdown>
      ;
    </TermsModal>
    {TEXT.formsGeneral.confirmHealthTermsText}
  </Paragraph>
);
