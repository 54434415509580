import React from 'react';

export const CheckoutHint = () => (
  <div className="panel panel__checkout-hint">
    <h1 className="mt-3">Nur noch wenige Klicks...</h1>
    <p>Schön, dass du dich für ein Abo entschieden hast! Bitte logge dich ein, um mit der Bezahlung fortzufahren.</p>
  </div>
);

export default CheckoutHint;
