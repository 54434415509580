import isEmail from 'validator/lib/isEmail';
import trim from 'validator/lib/trim';
import isLength from 'validator/lib/isLength';
import isURL from 'validator/es/lib/isURL';

// Use "!val ||" to avoid displaying both
// the valueMissing message and the actual error message
// at the same time
export const isValidEmail = (val) => !val || isEmail(val);
export const isValidUrl = (val) => !val || isURL(val);
export const isBirthday = (val) => !val || RegExp(/[/0-9]{2}\.[0-9]{2}\.[0-9]{4}/).test(val);
export const isTrimmed = (val) => !val || trim(val) === val;
export const couponValidLength = (val) => !val || isLength(val, { max: 100 });
export const zipCodeMinValidLength = (val) => !val || isLength(val, { min: 5 });
export const zipCodeMaxValidLength = (val) => !val || isLength(val, { max: 5 });
export const isValidPostalCode = (val) => !val || isLength(val, { min: 5, max: 5 });
export const isValidInsuranceNumber = (val) => new RegExp('[a-zA-Z]{1}\\d{9}$', 'i').test(val);
export const validOptionalInsuranceNumbersLength = (val) => !val || `${val}` === '' || isValidInsuranceNumber(val);
export const isValidPhoneNumber = (phone) => /^[\d\s()-]+$/.test(phone);
