import React from 'react';
import HeaderLogo from './HeaderLogo';
import { TEXT } from 'locales/translations';

const Header = () => {
  return (
    <section className="header-container">
      <HeaderLogo />
      <h1 className="plans__heading">{TEXT.podcast.header.headline}</h1>
      <h3>{TEXT.podcast.header.subline}</h3>
    </section>
  );
};
export default Header;
