import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  receivedDate: '',
  email: '',
  pdfUrl: ''
};

export default function requestCancellation(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_REQUEST_CANCELLATION_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.CREATE_REQUEST_CANCELLATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        receivedDate: action.payload.result.receivedDate,
        email: action.payload.result.email,
        pdfUrl: action.payload.result.confirmationPdfUrl,
        error: undefined
      };
    }

    case types.CREATE_REQUEST_CANCELLATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state && state.isFetching;

export const getRequestCancellation = (state) => state;
