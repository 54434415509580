import React, { Component } from 'react';
import { NoSubscription } from 'components/SubscriptionStatus';

class NoSubscriptionContainer extends Component {
  render() {
    return <NoSubscription />;
  }
}

export default NoSubscriptionContainer;
