import React from 'react';
import { Button, Col, Container, Link, Row } from 'Common';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import { ThemeContainer } from 'Container';
import { themeFeeling } from 'Theme';

export const UpgradeUnknownUser = () => {
  return (
    <ThemeContainer theme={themeFeeling}>
      <Container className="upgrade-subscription-user-unkown">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 8, offset: 2 }}>
            <h1 className="plans__heading">{TEXT.upgrade.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 7, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <div className="card-stoerer card-info mt-5 mt-sm-6 mt-md-5 mb-md-5">
              <div className="card-body text-center mt-sm-2 mt-md-6 mt-lg-5">
                <div className="plans-btn__info">
                  <p className="plans-btn__title">Kennen wir uns?</p>

                  <p className="plans__subheading">Um das Angebot nutzen zu können musst du dich zuerst anmelden.</p>
                  <p className="plans__subheading">Voraussetzung für ein Upgrade ist ein aktives Monatsabo.</p>

                  <Button to={ROUTES.login}>anmelden</Button>
                </div>
              </div>
              <div className="card-footer">
                <p className="text-center card-link">
                  {TEXT.login.cta_check}&nbsp;
                  <Link to={ROUTES.signup}>{TEXT.login.cta_register}</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ThemeContainer>
  );
};
