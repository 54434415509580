import {
  STRIPE_SETUP_INTENT_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  CONFIRM_PAYMENT_METHOD_REQUEST,
  SUBMIT_CHECKOUT_REQUEST,
  GET_USER_PAYMENT_INFOS_REQUEST,
  UPDATE_USER_PAYMENT_INFOS_REQUEST,
  GET_USER_PRORATION_PRICE_REQUEST,
  UPDATE_USER_PAYMENT_METHOD_REQUEST,
  APPLICATION_BOOTSTRAPPED,
  USER_SUCCESSFULLY_AUTHENTICATED
} from 'redux/actions';
import { takeLatest, all } from 'redux-saga/effects';
import { stripeSetupIntentSaga } from './stripeSetupIntentSaga/stripeSetupIntentSaga';
import { confirmPaymentMethodSaga } from './confirmPaymentMethodSaga/confirmPaymentMethodSaga';
import { getSubscriptionPlansSaga } from './getSubscriptionPlansSaga/getSubscriptionPlansSaga';
import { submitCheckoutSaga } from './submitCheckoutSaga/submitCheckoutSaga';
import { getUserPaymentInfosSaga } from './getUserPaymentInfosSaga/getUserPaymentInfosSaga';
import { updateUserPaymentInfosSaga } from './updateUserPaymentInfosSaga/updateUserPaymentInfosSaga';
import { getUserProrationPriceSaga } from './getUserProrationPriceSaga/getUserProrationPriceSaga';
import { updateUserPaymentMethodSaga } from './updateUserPaymentMethodSaga/updateUserPaymentMethodSaga';
import { migrateSubscriptionSaga } from './migrateSubscriptionSaga/migrateSubscriptionSaga';

export function* paymentWatcher() {
  yield all([
    yield takeLatest(STRIPE_SETUP_INTENT_REQUEST, stripeSetupIntentSaga),
    yield takeLatest(GET_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlansSaga),
    yield takeLatest(CONFIRM_PAYMENT_METHOD_REQUEST, confirmPaymentMethodSaga),
    yield takeLatest(SUBMIT_CHECKOUT_REQUEST, submitCheckoutSaga),
    yield takeLatest(GET_USER_PAYMENT_INFOS_REQUEST, getUserPaymentInfosSaga),
    yield takeLatest(GET_USER_PRORATION_PRICE_REQUEST, getUserProrationPriceSaga),
    yield takeLatest(UPDATE_USER_PAYMENT_METHOD_REQUEST, updateUserPaymentMethodSaga),
    yield takeLatest(UPDATE_USER_PAYMENT_INFOS_REQUEST, updateUserPaymentInfosSaga),
    yield takeLatest([USER_SUCCESSFULLY_AUTHENTICATED, APPLICATION_BOOTSTRAPPED], migrateSubscriptionSaga)
  ]);
}
