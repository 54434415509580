import React from 'react';
import { TEXT } from 'locales/translations';
import DecorativeImage from 'components/DecorativeImage';
import CardIllu from 'images/library/ballooni-geschenk@2x.png';
import CardIlluMobil from 'images/library/balloon-geschenk-hell@2x.png';
import { ROUTES } from 'utils';
import { Col, Container, Button, Row } from 'Common';

const CouponAdTile = () => (
  <div className="library-coupon-ad-tile mb-5">
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col xs={{ size: 12, offset: 0, order: 1 }} md={{ size: 8, offset: 0, order: 1 }} xl={{ size: 6, offset: 0 }}>
          <div className="library-coupon-ad-tile__content-wrapper pt-3">
            <h2 className="library-coupon-ad-tile__headline">{TEXT.couponAdTile.headline}</h2>
            <p className="library-coupon-ad-tile__text">{TEXT.couponAdTile.subline}</p>
          </div>
        </Col>
        <Col
          xs={{ size: 12, offset: 0, order: 2 }}
          md={{ size: 4, offset: 0, order: 2 }}
          xl={{ size: 2, offset: 0 }}
          className="library-coupon-ad-tile__illustration-wrapper"
        >
          <DecorativeImage
            className="library-coupon-ad-tile__illustration d-md-none mb-3"
            src={CardIlluMobil}
            alt={TEXT.couponAdTile.altText}
          />
          <DecorativeImage
            className="library-coupon-ad-tile__illustration d-none d-md-block"
            src={CardIllu}
            alt={TEXT.couponAdTile.altText}
          />
        </Col>
        <Col xs={{ size: 12, offset: 0, order: 3 }} md={{ size: 12, offset: 0, order: 3 }} xl={{ size: 4, offset: 0 }}>
          <div className="library-coupon-ad-tile__content-wrapper">
            <div className="library-coupon-ad-tile__btn-wrapper">
              <Button className="w-100 library-coupon-ad-tile__button" color="blue" type="inverted" to={ROUTES.buyGift}>
                {TEXT.couponAdTile.button}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default CouponAdTile;
