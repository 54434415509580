import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';
import { localizedAmount, subscriptionPlan } from 'utils';
import { currencySymbol } from 'Common';

IntervalText.propTypes = {
  plan: subscriptionPlan.isRequired,
  withAsterisk: PropTypes.bool,
  withPriceExplainer: PropTypes.bool,
  value: PropTypes.number
};

function relate(plan, value) {
  if (!value || plan.zppCertified === true) {
    return null;
  }
  switch (plan.interval) {
    case 'YEARLY': {
      return `(${localizedAmount(value / 100)} ${currencySymbol(plan.currency)} /${TEXT.subscriptionCard.Month})`;
    }
    default: {
      return null;
    }
  }
}

const intervalText = (plan, withAsterisk, withPriceExplainer, value) => {
  if (withPriceExplainer && plan.priceExplainer && plan.priceExplainer.length > 0) {
    return plan.priceExplainer;
  }

  return `${intervalTextForRecurring(plan.interval, plan.coupon, withAsterisk)} ${relate(plan, Math.round(value / 12.0)) ?? ''}`;
};

const addAsterisk = (text, withAsterisk) => {
  return withAsterisk ? `${text} *` : text;
};

const intervalTextForRecurring = (interval, coupon, withAsterisk) => {
  switch (interval) {
    case 'YEARLY':
      return addAsterisk(coupon?.duration === 'ONCE' ? TEXT.intervalText.firstYear : TEXT.intervalText.yearly, withAsterisk);
    case 'QUARTERLY':
      return addAsterisk(TEXT.intervalText.quarterly, withAsterisk);
    case 'MONTHLY':
      if (coupon?.duration === 'ONCE') {
        return `(${addAsterisk(TEXT.intervalText.firstMonth, withAsterisk)})`;
      } else if (coupon?.duration === 'REPEATING') {
        return `(${addAsterisk(`${TEXT.intervalText.firstMonths} ${coupon.durationInMonths} ${TEXT.intervalText.months}`, withAsterisk)})`;
      } else {
        return addAsterisk(TEXT.intervalText.monthly, withAsterisk);
      }
    case 'ONETIME':
      return addAsterisk(TEXT.intervalText.onetime, withAsterisk);
  }
  return addAsterisk('pro ?', withAsterisk);
};

export default function IntervalText({ plan, withAsterisk, withPriceExplainer, value }) {
  return <Fragment>{intervalText(plan, withAsterisk, withPriceExplainer, value)}</Fragment>;
}
