import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as routerPropTypes from 'utils/propTypes/reactRouterPropTypes';
import withSpinner from 'components/withSpinner';
import InvoiceList from './InvoiceList';
import { InvoiceContainer } from 'containers';
import { invoiceRowPropType } from './invoiceRowPropType';
import { useUserError } from 'hookApi';

const InvoicesContent = ({ invoices, match, history }) => {
  const error = useUserError();
  if (error) {
    return error;
  }

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={(props) => (
          <Fragment>
            <InvoiceList history={history} match={match} invoices={invoices} />
          </Fragment>
        )}
      />
      <Route exact path={`${match.path}/:id`} component={InvoiceContainer} />
    </Switch>
  );
};

InvoicesContent.propTypes = {
  invoices: PropTypes.arrayOf(invoiceRowPropType),
  history: routerPropTypes.history,
  match: routerPropTypes.match.isRequired
};

export default withSpinner(InvoicesContent);
