export const REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST = 'REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST';
export const REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS = 'REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS';
export const REGISTER_USER_WITH_INSURANCE_NUMBER_FAILURE = 'REGISTER_USER_WITH_INSURANCE_NUMBER_FAILURE';

export const registerUserWithInsuranceNumberRequest = ({ user, userMetaData = {}, sendDeepLinkOTP, optionalInsuranceNumber = false }) => ({
  type: REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST,
  payload: {
    user,
    userMetaData,
    sendDeepLinkOTP,
    optionalInsuranceNumber
  }
});

export const registerUserWithInsuranceNumberSuccess = () => ({
  type: REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS
});

export const registerUserWithInsuranceNumberFailure = (error) => ({
  type: REGISTER_USER_WITH_INSURANCE_NUMBER_FAILURE,
  error
});
