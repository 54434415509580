import React from 'react';
import { Link } from 'react-router-dom';
import { Button as MMButton } from '@missionme/mime_elements';

export const Button = ({ children, className, id, type, dataCyName, color, disabled, to, href, onClick, ...props }) => {
  return to ? (
    <Link to={to}>
      <MMButton className={className} id={id} type={type} color={color} disabled={disabled} dataCyName={dataCyName} {...props}>
        {children}
      </MMButton>
    </Link>
  ) : (
    <MMButton
      className={className}
      id={id}
      type={type}
      color={color}
      disabled={disabled}
      dataCyName={dataCyName}
      href={href}
      onClick={onClick}
      {...props}
    >
      {children}
    </MMButton>
  );
};
