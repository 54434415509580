import React from 'react';
import PageLayout from './PageLayout';

import survey_maybe1x from 'images/landingpages/health_insurance/survey_maybe.png';
import survey_maybe2x from 'images/landingpages/health_insurance/survey_maybe@2x.png';
import survey_maybe3x from 'images/landingpages/health_insurance/survey_maybe@3x.png';

import { TEXT } from 'locales/translations';

export default function Maybe() {
  return (
    <PageLayout
      image1x={survey_maybe1x}
      image2x={survey_maybe2x}
      image3x={survey_maybe3x}
      title={TEXT.healthSurvey.maybe.title}
      text={TEXT.healthSurvey.maybe.text}
    />
  );
}
