import { requestApi } from 'utils';

export const getUnitProtectedFileUrl = (id) =>
  requestApi.query(`
  query GetUnitProtectedFileUrl {
    units(ids: "${id}") {
      protectedFile
    }
  }
`);
