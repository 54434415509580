import React, { Component } from 'react';
import CodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser, isLoginError } from 'redux/query';
import { addFlash, clearFlashes, getUser, requestOnetimePassword, signInUserWithOnetimePasswordAndRedeemGiftCoupon } from 'redux/actions';
import { Button, Col, Link, Paragraph, Row } from 'Common';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';

class SignInUserWithOnetimePasswordVoucherBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: 0
    };
  }

  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;

    const { email, voucherCode } = this.props;
    this.props.signInUserWithOnetimePasswordAndRedeemGiftCoupon({
      email,
      token: code,
      voucherCode
    });
  }

  setCode(code) {
    this.setState({ code: code });
  }

  handleResendCode(e) {
    e.preventDefault();
    const { requestOnetimePassword, email, addFlash } = this.props;

    requestOnetimePassword(email, false);

    addFlash(`Der Code wurde an die E-Mail ${email} geschickt`, 'success');
  }

  render() {
    const { email, handleOtpState } = this.props;

    const { code } = this.state;
    return (
      <div className="code-entry">
        <ThemeContainer theme={themeUniversal}>
          <Row>
            <Col>
              <h2 className="profile-subheading">E-Mail Anmeldung bestätigen</h2>
              <div className="pb-4 text-center">
                Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an {email} verschickt, wenn du bereits
                registriert bist. Andernfalls registriere dich bitte <a onClick={handleOtpState.bind(this)}>hier.</a>
              </div>
            </Col>
          </Row>
          <form className="sign-in-user-with-onetime-password text-center" onSubmit={this.handleSubmit.bind(this)}>
            <CodeInput
              className="code-field"
              type="number"
              fields={6}
              value={code}
              onChange={this.setCode.bind(this)}
              inputStyle={{
                margin: '4px',
                width: '40px',
                borderRadius: '6px',
                fontSize: '20px',
                height: '53px',
                color: '#717171',
                textAlign: 'center',
                border: this.props.loginError ? '1px solid red' : '1px solid #ededed',
                backgroundColor: '#ededed'
              }}
            />
            {this.props.loginError && <span className="fb-error">Code ist nicht gültig</span>}
            <Button className="sign-in-user-otp-btn mt-4" dataCyName="otp-submit-button">
              Speichern
            </Button>
          </form>
          <Paragraph className="mt-5 small-bold text-center">
            <Link className="text-uppercase" color="green" onClick={this.handleResendCode.bind(this)}>
              Neuen Code anfordern
            </Link>
          </Paragraph>
        </ThemeContainer>
      </div>
    );
  }
}

SignInUserWithOnetimePasswordVoucherBox.propTypes = {
  signInUserWithOnetimePasswordAndRedeemGiftCoupon: PropTypes.func.isRequired,
  requestOnetimePassword: PropTypes.func.isRequired,
  addFlash: PropTypes.func.isRequired,
  clearFlashes: PropTypes.func.isRequired,
  voucherCode: PropTypes.string,
  email: PropTypes.string.isRequired,
  handleOtpState: PropTypes.func.isRequired,
  loginError: PropTypes.bool.isRequired
};

SignInUserWithOnetimePasswordVoucherBox.defaultProps = {
  loginError: false
};

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);

  return {
    email: currentUser.email,
    loginError: isLoginError(state),
    voucherCode: state.entities.forms.user.code
  };
};

export default connect(mapStateToProps, {
  clearFlashes,
  addFlash,
  getUser,
  signInUserWithOnetimePasswordAndRedeemGiftCoupon,
  requestOnetimePassword
})(SignInUserWithOnetimePasswordVoucherBox);
