import React from 'react';
import { ADDRESSES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import { Button, Headline } from 'Common';

const Help = () => (
  <div className="help">
    <Headline level={1} className="profile-subheading">
      {TEXT.help.heading}
    </Headline>
    <div className="pb-4">{TEXT.help.text}</div>
    <Button className="w-100" href={'mailto:' + ADDRESSES.supportEmail}>
      {TEXT.help.buttonText}
    </Button>
  </div>
);

export default Help;
