import styled from 'styled-components';
import { Section } from 'Container';
import { asRem } from 'utils';
import { breakpoints } from 'components/Theme';

export default styled(Section)`
  padding: ${asRem(20)} 0;
  z-index: 5;
  position: relative;

  .mime-elements-headline-2 {
    margin-bottom: ${asRem(16)};
    margin-top: ${asRem(40)};

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: ${asRem(27)};
      align-self: flex-start;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: ${asRem(27)};
      align-self: flex-start;
    }
  }

  .top-line {
    position: absolute;
    left: ${asRem(-4)};
    right: ${asRem(-4)};
    top: ${asRem(-3)};
    object-fit: cover;
    z-index: -1;

    @media (min-width: ${breakpoints.md}) {
      top: ${asRem(-3)};
    }

    @media (min-width: ${breakpoints.lg}) {
      top: ${asRem(-5)};
    }
  }

  .bottom-line {
    height: ${asRem(4)};
    width: 170%;
    position: absolute;
    left: 0;
    bottom: ${asRem(-2)};

    @media (min-width: ${breakpoints.md}) {
      bottom: ${asRem(-3)};
      width: 100%;
    }

    @media (min-width: ${breakpoints.lg}) {
      bottom: ${asRem(-4)};
    }
  }
`;
