import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { DownloadAd, PlayerDescription } from 'components/Webplayer';
import PropTypes from 'prop-types';
import { pushDataLayerPlayerEvent, pushDataLayerPlayerStartEvent } from 'components/GtmHelper';
import { TEXT } from 'locales/translations';
import { asRem, ROUTES } from 'utils';
import { browserHistory } from 'redux/configureStore';

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      pause: true,
      currentTime: '0:00',
      process: 0,
      openMobilModal: false
    };
    this.audio = new Audio(props.content.protectedFile);
    this.createProcessTime = 0;
    this.audio.ontimeupdate = () => {
      let minutes = Math.floor(this.audio.currentTime / 60);
      if (minutes > 59) {
        minutes = Math.floor(this.audio.currentTime / 60) - 60;
      }

      let seconds = Math.round(this.audio.currentTime - minutes * 60);
      if (seconds > 3599) {
        seconds = Math.round(this.audio.currentTime - minutes * 60) - 3600;
      }

      let time = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
      let process = (this.audio.currentTime / this.audio.duration) * 100;
      if (!isNaN(process)) {
        props.setProcess(process);
        this.setState({ currentTime: time, process: process }); // in seconds
        if (Math.round(process) >= 85 && this.createProcessTime === 0) {
          this.createProcess();
        }
      }
    };
    this.audio.oncanplay = () => {
      let audio = this.audio;
      if (this.state.play && props.playerStatus !== 'load') {
        let playPromise = audio.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              props.setStatus('playing');
            })
            .catch((error) => {
              audio.pause();
              props.setStatus('pausing');
              this.setState({
                play: false,
                pause: true,
                currentTime: '0:00',
                process: 0
              });
            });
        }
      }
    };

    this.audio.onended = () => {
      this.audio.pause();
      if (window.innerWidth <= 760) {
        this.setState({ openMobilModal: true });
      } else {
        props.setStatus('end');
      }
    };
  }

  createProcess = () => {
    const { createProcessForUnit, currentlyPlayedUnit, content, contentType } = this.props;

    const roundedTimeInSeconds = Math.round(this.audio.currentTime);

    if (this.createProcessTime !== roundedTimeInSeconds) {
      this.createProcessTime = roundedTimeInSeconds;
      const recordedAt = new Date().toISOString();
      createProcessForUnit(currentlyPlayedUnit.id, recordedAt, roundedTimeInSeconds, roundedTimeInSeconds);
      pushDataLayerPlayerEvent(
        content.id === currentlyPlayedUnit.id && contentType === 'course' ? 'intro' : contentType,
        roundedTimeInSeconds
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentlyPlayedUnit, setStatus, playerStatus, protectedFileUrl, content } = this.props;

    if (
      prevProps.currentlyPlayedUnit &&
      currentlyPlayedUnit &&
      prevProps.currentlyPlayedUnit.id === currentlyPlayedUnit.id &&
      playerStatus === 'resume'
    ) {
      this.audio.play();
      setStatus('playing');
      this.setState({
        play: true,
        pause: false
      });
    }

    if (
      prevProps.currentlyPlayedUnit &&
      currentlyPlayedUnit &&
      prevProps.currentlyPlayedUnit.id === currentlyPlayedUnit.id &&
      prevState.play &&
      playerStatus === 'pausing' &&
      prevProps.playerStatus !== 'end'
    ) {
      this.audio.pause();
      this.setState({
        play: false,
        pause: true
      });

      this.createProcess();
    }

    if (protectedFileUrl !== prevProps.protectedFileUrl && protectedFileUrl !== null) {
      this.audio.pause();
      if (protectedFileUrl?.length === 0) {
        if (content && content.protectedFile && content.protectedFile.length > 0) {
          this.audio.src = content.protectedFile;
        }
      } else {
        this.audio.src = protectedFileUrl;
      }
      this.audio.load();
    }

    if (
      prevProps.currentlyPlayedUnit &&
      currentlyPlayedUnit &&
      prevProps.currentlyPlayedUnit.id !== currentlyPlayedUnit.id &&
      typeof prevProps.currentlyPlayedUnit.id !== 'undefined' &&
      prevProps.content.id === content.id
    ) {
      this.audio.play();
      setStatus('playing');
      this.setState({
        play: true,
        pause: false
      });
    }
  }

  componentWillUnmount() {
    this.audio.pause();
    const { stopPlayer } = this.props;
    this.createProcess();
    this.audio.src = '';
    stopPlayer();
  }

  toggle = () => {
    const { play, pause } = this.state;

    const { setStatus, content, contentType } = this.props;

    if (content.locked && contentType === 'single') {
      browserHistory.push(ROUTES.plans);
      return;
    }

    const roundedTimeInSeconds = Math.round(this.audio.currentTime);

    if (pause && roundedTimeInSeconds === 0) {
      pushDataLayerPlayerStartEvent();
    }

    this.setState({
      play: !play,
      pause: !pause
    });

    if (pause) {
      this.audio.play();
      setStatus('playing');
    } else {
      this.audio.pause();
      setStatus('pausing');
    }
  };

  skipBackward = (e, time) => {
    e.preventDefault();
    const { content, contentType } = this.props;
    if (content.locked && contentType === 'single') {
      return;
    }
    if (this.audio.currentTime >= time) {
      this.audio.currentTime = this.audio.currentTime - time;
    } else {
      this.audio.currentTime = 0;
    }
  };

  skipForward = (e, time) => {
    e.preventDefault();
    const { content, contentType } = this.props;
    if (content.locked && contentType === 'single') {
      return;
    }
    let calTime = this.audio.currentTime + time;
    if (calTime >= this.audio.duration) {
      this.audio.currentTime = this.audio.duration - 3;
    } else {
      this.audio.currentTime = this.audio.currentTime + time;
    }
  };

  findDist = (start, end) => {
    let dx = end.x;
    let dy = end.y;

    dx -= start.x;
    dx *= dx;

    dy -= start.y;
    dy *= dy;

    return Math.sqrt(dx + dy);
  };

  findAngleUsingSides = (a, b, c) => {
    let radians = (a * a + b * b - c * c) / (2 * (a * b));
    return (Math.acos(radians) / Math.PI) * 180;
  };

  changeProcess = (e) => {
    e.preventDefault();
    const { content } = this.props;
    if (!content.isNotFastForwardable) {
      let dim = e.target.getBoundingClientRect();
      let posX = dim.left,
        posY = window.scrollY + dim.top,
        radius = 55,
        x = e.pageX - posX,
        y = e.pageY - posY,
        coords = {
          x: x,
          y: y
        };

      let sideA = this.findDist(coords, {
        x: radius,
        y: 0
      });
      let sideB = this.findDist(coords, {
        x: radius,
        y: radius
      });
      let sideC = radius;

      let A = this.findAngleUsingSides(sideB, sideC, sideA);

      if (x < radius) {
        A = 360 - A;
      }

      let percentClicked = A / 360;
      const process = Number(percentClicked.toFixed(2)) * 100;
      let time = this.audio.duration * (process / 100);
      this.audio.currentTime = time;
      this.setState({ process: process });
    }
  };

  render() {
    const { content, contentType, children, setStatus } = this.props;

    const { pause, process, openMobilModal } = this.state;
    let single = true;
    let runtime = 'Übung | ' + Math.round(content.length / 60) + ' Min';
    let locked = content.locked;
    if (contentType === 'course') {
      runtime = 'Kurs | ' + Math.round(content.length / 60) + ' Min';
      single = false;
      locked = false;
    }
    const image = content.pictureV2 || content.category?.icon;
    const isMobile = window.innerWidth < 768;
    const numberOfChildren = React.Children.count(children);
    const itemHeight = asRem(4.5);
    const maxItemsDisplayed = 14;

    const listHeight = numberOfChildren > maxItemsDisplayed ? maxItemsDisplayed * itemHeight : numberOfChildren * itemHeight;

    const listStyles = single
      ? { overflow: 'hidden', height: 0 }
      : isMobile
      ? {
          overflow: 'visible',
          height: `${listHeight}rem`,
          WebkitOverflowScrolling: 'touch',
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }
      : {
          overflow: numberOfChildren > maxItemsDisplayed ? 'auto' : 'visible',
          height: numberOfChildren > maxItemsDisplayed ? `${48.2}rem` : `${listHeight}rem`
        };

    return (
      <div>
        <div className={'player player--' + (locked ? 'locked' : pause ? 'pausing' : 'playing')}>
          {image && (
            <img
              src={image}
              alt={TEXT.webplayer.rightDecorativeImage}
              style={{ backgroundColor: content.backgroundColor }}
              className={`${contentType === 'course' ? 'w-100 h-auto' : 'image'} d-block d-lg-none`}
            />
          )}
          <Container>
            <Row>
              <Col xs={{ size: 12, offset: 0 }} md={{ size: 4, offset: 0 }} lg={{ size: 4, offset: 0 }}>
                <div className="player-elements mt-4 mt-lg-0">
                  <div className="player-elements__play-button-progress">
                    <div className="player-elements__play-button-progress-bar">
                      <div className="single-chart">
                        <svg viewBox="0 0 36 36" className="circular-chart" onClick={this.changeProcess}>
                          <path
                            stroke={content.backgroundColor}
                            className="circle"
                            strokeDasharray={process + ', 100'}
                            d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                        </svg>
                        <svg
                          viewBox="0 0 100 100"
                          className="circle-bg"
                          style={{ fill: content.backgroundColor }}
                          onClick={this.changeProcess}
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                      </div>

                      <div
                        className="player-elements__play-button"
                        style={{ backgroundColor: content.backgroundColor }}
                        onClick={this.toggle}
                      >
                        <svg
                          id="pause-icon"
                          width="46px"
                          height="53px"
                          viewBox="0 0 46 53"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect id="Rectangle" x="0" y="0" width="18" height="53" rx="3" fill="#FFFFFF" fillRule="nonzero" />
                            <rect id="Rectangle" x="28" y="0" width="18" height="53" rx="3" fill="#FFFFFF" fillRule="nonzero" />
                          </g>
                        </svg>

                        <svg id="play-icon" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="single-icon" fill="#FFFFFF">
                              <path
                                d="M10.9699497,9.7321234 C12.3520359,8.8237977 12.3469582,7.347551 10.9699497,6.4422902 L2.50248842,0.8756858 C1.12040223,-0.0329133 1.8189894e-12,0.5762773 1.8189894e-12,2.2349855 L1.8189894e-12,13.9372587 C1.8189894e-12,15.5965782 1.12547995,16.2020419 2.50248842,15.2970533 L10.9699497,9.7321234 Z"
                                id="Path"
                              />
                            </g>
                          </g>
                        </svg>
                        <svg
                          id="locked-icon"
                          width="111px"
                          height="137px"
                          viewBox="0 0 111 137"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="single-icon" fill="#FFFFFF">
                              <path
                                d="M95.7085568,50.9262264 L95.7085568,36.7140796 C95.7085568,16.4706957 77.6297142,0 55.4102171,0 C33.1907201,0 15.1118774,16.4706957 15.1118774,36.7140796 L15.1118774,50.9262264 C6.54596156,52.4099816 0,59.9148459 0,68.9439774 L0,118.424429 C0,128.517003 8.16796973,136.728296 18.2072936,136.728296 L92.6131406,136.728296 C102.652464,136.728296 110.820434,128.517003 110.820434,118.424429 L110.820434,68.9439774 C110.820434,59.9148459 104.274473,52.4099816 95.7085568,50.9262264 L95.7085568,50.9262264 Z M20.1491699,36.7140796 C20.1491699,19.2609657 35.9662682,5.06401098 55.4102171,5.06401098 C74.854166,5.06401098 90.6712644,19.2609657 90.6712644,36.7140796 L90.6712644,50.6401098 L20.1491699,50.6401098 L20.1491699,36.7140796 L20.1491699,36.7140796 Z M65.484802,96.2162085 C65.484802,101.801813 60.9663507,106.34423 55.4102171,106.34423 C49.8540835,106.34423 45.3356322,101.801813 45.3356322,96.2162085 L45.3356322,81.0241756 C45.3356322,75.4385715 49.8540835,70.8961537 55.4102171,70.8961537 C60.9663507,70.8961537 65.484802,75.4385715 65.484802,81.0241756 L65.484802,96.2162085 L65.484802,96.2162085 Z"
                                id="Fill-813"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="player-elements-skip-buttons">
                    <div
                      className="player-elements-skip-buttons__skip player-elements-skip-buttons__skip-backward"
                      onClick={(e) => this.skipBackward(e, 10)}
                    >
                      <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="backward_10sec-icon" transform="translate(1.000000, 0.000000)" fill={content.backgroundColor}>
                          <path
                            d="M11.1208687,24.2686949 C10.866511,24.2693908 10.7393321,23.9555031 10.7393321,23.3270318 C10.7393321,22.6985605 10.866511,22.3733178 11.1208687,22.3513036 C16.1130425,22.3513036 20.159999,18.3043471 20.159999,13.3121733 C20.159999,8.31999946 16.1130425,4.27304297 11.1208687,4.27304297 C8.74095684,4.27304297 6.50882452,5.19552527 4.83330625,6.81813124 C3.0854741,8.51076744 2.0817384,10.8304009 2.0817384,13.3121733 L0.164347123,13.3121733 C0.164347123,10.3058117 1.38237068,7.49095897 3.49942883,5.4407568 C5.52909836,3.47518356 8.23803191,2.35565169 11.1208687,2.35565169 C17.1719885,2.35565169 22.0773903,7.2610535 22.0773903,13.3121733 C22.0773903,19.3632931 17.1719885,24.2686949 11.1208687,24.2686949 Z"
                            id="Oval"
                            stroke={content.backgroundColor}
                            strokeWidth="0.876521774"
                            fillRule="nonzero"
                            transform="translate(11.120869, 13.312174) rotate(-270.000000) translate(-11.120869, -13.312174) "
                          />
                          <path
                            d="M7.38911288,12.1226787 L7.38911288,10.96567 L9.61547819,10.96567 L9.61547819,17.1539137 L8.31822596,17.1539137 L8.31822596,12.1226787 L7.38911288,12.1226787 Z M10.5884174,14.0072005 C10.5884174,11.9152248 11.3772791,10.8692526 12.9550261,10.8692526 C13.7906477,10.8692526 14.39252,11.1497367 14.760661,11.7107135 C15.128802,12.2716902 15.3128697,13.0371783 15.3128697,14.0072005 C15.3128697,14.9830663 15.128802,15.7514761 14.760661,16.3124528 C14.39252,16.8734295 13.7906477,17.1539137 12.9550261,17.1539137 C11.3772791,17.1539137 10.5884174,16.1050198 10.5884174,14.0072005 Z M14.0945045,14.0072005 C14.0945045,13.3761017 14.0170791,12.889637 13.8622262,12.5477918 C13.7073732,12.2059466 13.4049762,12.0350265 12.9550261,12.0350265 C12.4992325,12.0350265 12.1939138,12.2059466 12.0390609,12.5477918 C11.8842079,12.889637 11.8067826,13.3761017 11.8067826,14.0072005 C11.8067826,14.6441429 11.8842079,15.1335293 12.0390609,15.4753745 C12.1939138,15.8172197 12.4992325,15.9881397 12.9550261,15.9881397 C13.4049762,15.9881397 13.7073732,15.8172197 13.8622262,15.4753745 C14.0170791,15.1335293 14.0945045,14.6441429 14.0945045,14.0072005 Z"
                            id="10"
                          />
                          <path
                            d="M12.8855942,3.95554737 C13.5311211,3.61253812 13.5402582,3.06126539 12.8855942,2.713401 L7.7427431,-0.0193209983 C7.09721623,-0.362330246 6.5739133,-0.0428718553 6.5739133,0.672343156 L6.5739133,5.99660522 C6.5739133,6.72161003 7.0880791,7.03613376 7.7427431,6.68826937 L12.8855942,3.95554737 Z"
                            id="Arrow-head-22-Copy-6"
                            transform="translate(9.860870, 3.335366) rotate(-180.000000) translate(-9.860870, -3.335366) "
                          />
                          <path
                            d="M8.50298532,3.95554737 C9.14851219,3.61253812 9.15764932,3.06126539 8.50298532,2.713401 L3.36013423,-0.0193209983 C2.71460736,-0.362330246 2.19130443,-0.0428718553 2.19130443,0.672343156 L2.19130443,5.99660522 C2.19130443,6.72161003 2.70547023,7.03613376 3.36013423,6.68826937 L8.50298532,3.95554737 Z"
                            id="Arrow-head-22-Copy-8"
                            transform="translate(5.478261, 3.335366) rotate(-180.000000) translate(-5.478261, -3.335366) "
                          />
                        </g>
                      </svg>
                    </div>

                    <div className="player-elements__progressTime" style={{ color: content.backgroundColor }}>
                      {this.state.currentTime}
                    </div>
                    {content.isNotFastForwardable ? (
                      <div className="player-elements-skip-buttons__disabled player-elements-skip-buttons__disabled-forward" />
                    ) : (
                      <div
                        className="player-elements-skip-buttons__skip player-elements-skip-buttons__skip-forward"
                        onClick={(e) => this.skipForward(e, 10)}
                      >
                        <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="forward_10sec-icon" transform="translate(1.000000, 0.000000)" fill={content.backgroundColor}>
                            <g id="Group" transform="translate(11.500000, 12.500000) scale(-1, 1) translate(-11.500000, -12.500000) ">
                              <path
                                d="M11.1208687,24.2686949 C10.866511,24.2693908 10.7393321,23.9555031 10.7393321,23.3270318 C10.7393321,22.6985605 10.866511,22.3733178 11.1208687,22.3513036 C16.1130425,22.3513036 20.159999,18.3043471 20.159999,13.3121733 C20.159999,8.31999946 16.1130425,4.27304297 11.1208687,4.27304297 C8.74095684,4.27304297 6.50882452,5.19552527 4.83330625,6.81813124 C3.0854741,8.51076744 2.0817384,10.8304009 2.0817384,13.3121733 L0.164347123,13.3121733 C0.164347123,10.3058117 1.38237068,7.49095897 3.49942883,5.4407568 C5.52909836,3.47518356 8.23803191,2.35565169 11.1208687,2.35565169 C17.1719885,2.35565169 22.0773903,7.2610535 22.0773903,13.3121733 C22.0773903,19.3632931 17.1719885,24.2686949 11.1208687,24.2686949 Z"
                                id="Oval"
                                stroke={content.backgroundColor}
                                strokeWidth="0.876521774"
                                fillRule="nonzero"
                                transform="translate(11.120869, 13.312174) rotate(-270.000000) translate(-11.120869, -13.312174) "
                              />
                              <path
                                d="M12.8855942,3.95554737 C13.5311211,3.61253812 13.5402582,3.06126539 12.8855942,2.713401 L7.7427431,-0.0193209983 C7.09721623,-0.362330246 6.5739133,-0.0428718553 6.5739133,0.672343156 L6.5739133,5.99660522 C6.5739133,6.72161003 7.0880791,7.03613376 7.7427431,6.68826937 L12.8855942,3.95554737 Z"
                                id="Arrow-head-22-Copy-6"
                                transform="translate(9.860870, 3.335366) rotate(-180.000000) translate(-9.860870, -3.335366) "
                              />
                              <path
                                d="M8.50298532,3.95554737 C9.14851219,3.61253812 9.15764932,3.06126539 8.50298532,2.713401 L3.36013423,-0.0193209983 C2.71460736,-0.362330246 2.19130443,-0.0428718553 2.19130443,0.672343156 L2.19130443,5.99660522 C2.19130443,6.72161003 2.70547023,7.03613376 3.36013423,6.68826937 L8.50298532,3.95554737 Z"
                                id="Arrow-head-22-Copy-8"
                                transform="translate(5.478261, 3.335366) rotate(-180.000000) translate(-5.478261, -3.335366) "
                              />
                            </g>
                            <path
                              d="M8.38911288,12.1226787 L8.38911288,10.96567 L10.6154782,10.96567 L10.6154782,17.1539137 L9.31822596,17.1539137 L9.31822596,12.1226787 L8.38911288,12.1226787 Z M11.5884174,14.0072005 C11.5884174,11.9152248 12.3772791,10.8692526 13.9550261,10.8692526 C14.7906477,10.8692526 15.39252,11.1497367 15.760661,11.7107135 C16.128802,12.2716902 16.3128697,13.0371783 16.3128697,14.0072005 C16.3128697,14.9830663 16.128802,15.7514761 15.760661,16.3124528 C15.39252,16.8734295 14.7906477,17.1539137 13.9550261,17.1539137 C12.3772791,17.1539137 11.5884174,16.1050198 11.5884174,14.0072005 Z M15.0945045,14.0072005 C15.0945045,13.3761017 15.0170791,12.889637 14.8622262,12.5477918 C14.7073732,12.2059466 14.4049762,12.0350265 13.9550261,12.0350265 C13.4992325,12.0350265 13.1939138,12.2059466 13.0390609,12.5477918 C12.8842079,12.889637 12.8067826,13.3761017 12.8067826,14.0072005 C12.8067826,14.6441429 12.8842079,15.1335293 13.0390609,15.4753745 C13.1939138,15.8172197 13.4992325,15.9881397 13.9550261,15.9881397 C14.4049762,15.9881397 14.7073732,15.8172197 14.8622262,15.4753745 C15.0170791,15.1335293 15.0945045,14.6441429 15.0945045,14.0072005 Z"
                              id="10"
                            />
                          </g>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={{ size: 12, offset: 0 }} md={{ size: 8, offset: 0 }} className="d-flex justify-content-center">
                <PlayerDescription title={content.title} subtitle={runtime} description={content.description} />
              </Col>
            </Row>
          </Container>

          <DownloadAd openModal={openMobilModal} contentType={contentType} content={content} setStatus={setStatus} />
          <div className={'player-episode-list ' + (single ? 'single' : '')} style={listStyles}>
            {single ? null : children}
          </div>
        </div>
      </div>
    );
  }
}

Player.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.object.isRequired,
  currentlyPlayedUnit: PropTypes.object,
  contentType: PropTypes.string.isRequired,
  setProcess: PropTypes.func.isRequired,
  currentlyProcess: PropTypes.number,
  setStatus: PropTypes.func.isRequired,
  stopPlayer: PropTypes.func.isRequired,
  createProcessForUnit: PropTypes.func.isRequired,
  creatingProcessStatus: PropTypes.bool,
  playerStatus: PropTypes.string,
  protectedFileUrl: PropTypes.string,
  match: PropTypes.object.isRequired
};

Player.defaultProps = {
  protectedFileUrl: ''
};

export default Player;
