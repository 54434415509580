import { put } from 'redux-saga/effects';
import { confirmPaymentMethodFailure, confirmPaymentMethodSuccess } from 'redux/actions';

export function* confirmPaymentMethodSaga({ payload: { confirmPayment } }) {
  try {
    const response = yield confirmPayment();
    if (response.error) {
      if (response.error.code === 'setup_intent_unexpected_state') {
        return yield put(confirmPaymentMethodSuccess({ setupIntent: response.error.setup_intent }));
      }
      return yield put(confirmPaymentMethodFailure(response.error));
    }

    yield put(confirmPaymentMethodSuccess(response));
  } catch (error) {
    yield put(confirmPaymentMethodFailure(error));
  }
}
