import React from 'react';
import { TEXT } from 'locales/translations';
import { ScrollButton } from 'Common';
import PropTypes from 'prop-types';

const XingHeader = ({ headline, subline, route }) => {
  return (
    <section className="header-container">
      <div className="header">
        <h1>{headline}</h1>
        <h3>{subline}</h3>
        <ScrollButton type="inverted" color="blue" className="btn-xing" to="form-section-container" offset={-50}>
          {TEXT.xing.header.button}
        </ScrollButton>
      </div>
    </section>
  );
};

XingHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};

export default XingHeader;
