import PropTypes from 'prop-types';

export const billingAddress = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  addressLine1: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  email: PropTypes.string
});
