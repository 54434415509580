import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

import AppCarousel from './AppCarousel';
import MobileScreenMeditation from 'components/MobileScreenMeditation';
import MobileScreenExercise from 'components/MobileScreenExercise';
import MobileScreenCourse from 'components/MobileScreenCourse';

class MobileShowcaseSection extends Component {
  render() {
    return (
      <section className="mobile-showcase-section">
        <Container className="mobile-showcase-section__image-row">
          <Row>
            <Col xs={10} md={4} lg={4}>
              <MobileScreenMeditation className="mobile-showcase-section__mockup-img" />
            </Col>
            <Col xs={10} md={4} lg={4}>
              <MobileScreenExercise className="mobile-showcase-section__mockup-img" />
            </Col>
            <Col xs={10} md={4} lg={4}>
              <MobileScreenCourse className="mobile-showcase-section__mockup-img" />
            </Col>
          </Row>
        </Container>
        <div className="mobile-showcase-section__image-carousel">
          <AppCarousel />
        </div>
      </section>
    );
  }
}

export default MobileShowcaseSection;
