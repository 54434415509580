import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// ToDo: Button austauschen
import { Button } from 'reactstrap';
import { TEXT } from 'locales/translations';
import { UserBillingAddressForm, SectionHeader } from 'Component';
import { getCurrentUser, getUserPaymentDetails, isUpdateUserPaymentLoading } from 'redux/query';
import { updateUserPaymentInfosRequest } from 'redux/actions';
import { Spinner, Row, Col } from 'Common';

export const UserBillingAddress = () => {
  const dispatch = useDispatch();
  const { active, canceled, renewalInfo } = useSelector(getUserPaymentDetails);
  const isLoading = useSelector(isUpdateUserPaymentLoading);
  const user = useSelector(getCurrentUser);
  const [editable, setEditable] = useState(false);

  const { name = '', city = '', country = '', street = '', zipCode = '' } = renewalInfo?.address || {};
  const [firstName = '', lastName = ''] = name.split(' ');

  useEffect(() => {
    if (!isLoading) {
      setEditable(false);
    }
  }, [isLoading]);

  const handleOnSubmit = (billingAddress) => {
    dispatch(
      updateUserPaymentInfosRequest({
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        street: billingAddress.street,
        city: billingAddress.city,
        zipCode: billingAddress.zipCode,
        country: billingAddress.country,
        email: user.email
      })
    );
  };

  return (
    <div>
      <Row>
        <Col xs="12">
          <SectionHeader number={false} dataCyName="user-profile-billing-address">
            {active && !canceled
              ? TEXT.subscriptionStatus.billingAddressHeadline
              : TEXT.subscriptionStatus.billingAddressHeadlineOneTimePurchase}
          </SectionHeader>
        </Col>
      </Row>
      {editable ? (
        isLoading ? (
          <Spinner />
        ) : (
          <UserBillingAddressForm onSubmit={handleOnSubmit} submitButtonLabel={'Speichern'} />
        )
      ) : (
        <Fragment>
          <Row>
            <Col className="checkout-text m-0" xs="12">
              <p className="checkout-text mt-3 mb-0" data-cy-name="user-profile-billing-address-content">
                {firstName} {lastName}
                <br />
                {street}
                <br />
                {zipCode}&nbsp;{city}
                <br />
                {country}
              </p>
            </Col>
          </Row>
          {active && !canceled && (
            <Row>
              <Col className="d-flex justify-content-sm-end justify-content-start mt-sm-2" xs="12">
                <Button
                  data-cy-name="user-profile-edit-billing-address"
                  className="btn-link-underlined p-0 m-0"
                  onClick={() => setEditable(true)}
                >
                  {TEXT.checkout.btnEdit}
                </Button>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </div>
  );
};
