import React from 'react';
import { TEXT } from 'locales/translations';
import { Form } from 'react-redux-form';
import { NameField } from 'components/form';
import { useDispatch } from 'react-redux';
import { EmailTextField, PrivacyPolicyLink, SelectField, TextField } from 'components';
import { createB2bContactRequest } from 'redux/actions/b2bContact';
import { Button, Paragraph } from 'Common';
import { FormB2BContactStyle } from './FormB2BContact.style';
import { isValidPhoneNumber } from 'utils/validators';

export const FormB2BContact = () => {
  const dispatch = useDispatch();

  const createB2bContact = (formValues) => {
    const submissionData = {
      ...formValues,
      contactType: 'GENERIC'
    };
    dispatch(createB2bContactRequest(submissionData));
  };

  const companySizeOptions = [
    { value: '', name: `${TEXT.formsGeneral.companySize}` },
    { value: '<10', name: '<10' },
    { value: '10-20', name: '10-20' },
    { value: '21 - 50', name: '21 - 50' },
    { value: '51 - 200', name: '51 - 200' },
    { value: '201 - 500', name: '201 - 500' },
    { value: '501 - 1.000', name: '501 - 1.000' },
    { value: '1.000+', name: '1.000+' }
  ];

  return (
    <FormB2BContactStyle>
      <Form
        className="whitepaper-form"
        model="entities.forms.b2bContact"
        onSubmit={createB2bContact}
        hideNativeErrors
        data-cy-name="b2b-contact-form"
      >
        <NameField defaultValue="" />

        <EmailTextField showRequiredHint={true} placeholder={TEXT.company.form.placeholderMail} cyName="mail-input" />

        <TextField
          type="string"
          placeholder={TEXT.formsGeneral.phone}
          model={'.phone'}
          showRequiredHint={true}
          required
          cyName="phone"
          messages={{
            valueMissing: TEXT.company.form.valMissingPhone
          }}
          validators={{
            isValidPhoneNumber: (val) => isValidPhoneNumber(val)
          }}
          id={'wp-1'}
        />

        <TextField
          type="text"
          placeholder={TEXT.formsGeneral.companyName}
          model={'.companyName'}
          showRequiredHint={true}
          required
          cyName="company-name"
          messages={{
            valueMissing: TEXT.company.form.valMissingCompanyName
          }}
          id={'wp-2'}
        />

        <SelectField
          showRequiredHint={true}
          required
          fieldId={'companySize'}
          model={'.companySize'}
          placeholder={TEXT.formsGeneral.companySize}
          options={companySizeOptions}
          cyName="employees-count-input"
          messages={{
            valueMissing: TEXT.company.form.valMissingCompanySize
          }}
        />

        <TextField
          type="text"
          placeholder={TEXT.formsGeneral.jobTitle}
          model={'.jobTitle'}
          showRequiredHint={true}
          required
          cyName="job_title"
          messages={{
            valueMissing: TEXT.company.form.valMissingJobTitle
          }}
          id={'wp-3'}
        />

        <TextField
          type={'textarea'}
          placeholder={TEXT.formsGeneral.message}
          model={'.message'}
          showRequiredHint={true}
          cyName="message"
          id={'wp-4'}
        />

        <Paragraph className="small">
          {TEXT.company.whitepaper.asterixText}
          <PrivacyPolicyLink buttonText={TEXT.company.whitepaper.privacyPolicyLinkText} className="privacy-link" />
          {TEXT.company.whitepaper.zu}
        </Paragraph>
        <Button className={'darkActionEffect w-100'} dataCyName="submit-button">
          {TEXT.formsGeneral.sendBtn}
        </Button>
      </Form>
    </FormB2BContactStyle>
  );
};
