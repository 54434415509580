import { all, put, take, select } from 'redux-saga/effects';
import {
  submitCheckoutSuccess,
  submitCheckoutFailure,
  getUserPaymentInfosRequest,
  clearStripePaymentInfos,
  storePurchaseUserId,
  GET_USER_PAYMENT_INFOS_FAILURE,
  GET_USER_PAYMENT_INFOS_SUCCESS
} from 'redux/actions';
import { getCurrentUserId, getSelectedSubscriptionPlan, getSetupIntent, isRegisteredUser } from 'redux/query';
import { confirmStripePaymentApiRequest } from 'api/balloon/Payment';
import { pushDataLayerThankYouEvent } from 'components/GtmHelper';

export function* submitCheckoutSaga() {
  try {
    const state = yield select();
    const setupIntent = getSetupIntent(state);
    const subscriptionPlan = getSelectedSubscriptionPlan(state);
    const userId = getCurrentUserId(state);
    const isRegistered = isRegisteredUser(state);

    const response = (yield confirmStripePaymentApiRequest({
      subscriptionPlanId: subscriptionPlan.id,
      paymentMethodId: setupIntent.payment_method,
      couponCode: subscriptionPlan?.coupon?.code || null
    })).data;

    if (response.errors) {
      yield put(submitCheckoutFailure(response.errors));
      return;
    }
    pushDataLayerThankYouEvent(subscriptionPlan.title, 'stripe');
    yield put(getUserPaymentInfosRequest({ renewable: false }));
    const action = yield take([GET_USER_PAYMENT_INFOS_SUCCESS, GET_USER_PAYMENT_INFOS_FAILURE]);

    switch (action.type) {
      case GET_USER_PAYMENT_INFOS_SUCCESS:
        yield all([
          put(
            submitCheckoutSuccess({
              userAutoAssigned: response.data.confirmStripePayment.userAutoAssigned,
              selectedSubscriptionPlan: subscriptionPlan
            })
          ),
          put(clearStripePaymentInfos()),
          // store the guest user id if the user is not registered to transfer the subscription after registration
          put(storePurchaseUserId({ userId: isRegistered ? null : userId }))
        ]);

        break;
      case GET_USER_PAYMENT_INFOS_FAILURE:
        yield put(submitCheckoutFailure(action.error));
        break;
    }
  } catch (error) {
    yield put(submitCheckoutFailure(error));
  }
}
