import styled from 'styled-components';
import { colors } from 'Theme';
import { asRem } from 'utils';

export default styled.div.attrs({
  className: 'existing-insurance-number'
})`
  background-color: ${colors.grey.c100};
  border-radius: ${asRem(8)};
  padding: ${asRem(16)};
  margin-bottom: ${asRem(16)};

  button.mime-elements-button {
    margin-bottom: 0;
  }
`;
