import styled from 'styled-components';
import { Section } from 'Container';
import { asRem } from 'utils';
import { breakpoints } from 'components/Theme';
import skyline from 'images/landingpages/health_insurance/hintergrund-illu-skyline.jpg';

export default styled(Section)`
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;
  background-image: url(${skyline});
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: bottom ${asRem(25)} left;
  min-height: ${asRem(650)};

  @media (min-width: 425px) {
    min-height: ${asRem(570)};
  }

  @media (min-width: ${breakpoints.md}) {
    background-position: bottom ${asRem(-10)} left;
    background-size: 100% 100%;
    justify-content: center;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: ${asRem(600)};
  }

  .content {
    max-width: ${asRem(640)};
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${breakpoints.md}) {
      max-width: ${asRem(560)};
    }

    .mime-elements-headline-1 {
      margin: ${asRem(96)} 0 ${asRem(12.8)};
      padding: 0 ${asRem(20)};
      z-index: 1;

      @media (min-width: ${breakpoints.md}) {
        margin: ${asRem(-16)} 0 ${asRem(16)};
      }

      @media (min-width: ${breakpoints.lg}) {
        margin: 0 0 ${asRem(20.8)};
      }
    }

    .scroll-button {
      margin-top: ${asRem(24)};
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cloud {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    aspect-ratio: 1/0.4;
    z-index: -1;

    &-left {
      top: ${asRem(412.5)};
      left: 0;
      width: ${asRem(130)};

      @media (min-width: ${breakpoints.md}) {
        top: ${asRem(272)};
        width: ${asRem(200)};
        left: ${asRem(-48)};
      }

      @media (min-width: ${breakpoints.lg}) {
        top: ${asRem(168)};
        left: ${asRem(-90)};
        width: ${asRem(300)};
      }
    }

    &-right {
      display: none;

      @media (min-width: ${breakpoints.md}) {
        display: block;
        top: ${asRem(260)};
        right: ${asRem(-32)};
        width: ${asRem(170)};
      }

      @media (min-width: ${breakpoints.lg}) {
        top: ${asRem(190)};
        right: ${asRem(-16)};
        width: ${asRem(200)};
      }
    }
  }

  .add-on-text {
    font-weight: 600;
    position: absolute;
    left: ${asRem(32)};
    bottom: ${asRem(-10)};
    font-size: ${asRem(14)};
    width: 44%;
    text-align: left;
    line-height: ${asRem(15)};

    @media (min-width: 555px) {
      bottom: ${asRem(3.2)};
    }

    @media (min-width: ${breakpoints.md}) {
      width: auto;
      left: ${asRem(30)};
      font-size: ${asRem(16)};
      bottom: ${asRem(-6)};
    }

    @media (min-width: ${breakpoints.lg}) {
      left: ${asRem(160)};
    }
  }

  .partner-logo-bubble {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    a {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &__image {
      width: 70%;

      @media (min-width: ${breakpoints.md}) {
        width: 100%;
      }
    }
  }

  .blue-bottom-line {
    width: 170%;
    position: absolute;
    left: 0;
    bottom: ${asRem(-2)};

    @media (min-width: ${breakpoints.md}) {
      bottom: ${asRem(-3)};
      width: 100%;
    }

    @media (min-width: ${breakpoints.lg}) {
      bottom: ${asRem(-4)};
    }
  }
`;
