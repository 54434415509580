import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

import { getAlerts } from 'redux/reducers';

import { toggleAlert } from 'redux/actions/alerts';
import { bindActionCreators } from 'redux';

export const SubscriptionAlerts = (props) => {
  const { alerts } = props;
  return (
    <div className={'subscriptionAlerts'}>
      {alerts &&
        Object.values(alerts).map((alert) => {
          const { id, severity, webUrl, webUrlTitle, webMessage, dismissed } = alert;
          return (
            <Alert
              isOpen={!dismissed}
              color={severity}
              toggle={() => props.toggleAlert(id)}
              key={`alert-${id}`}
              className={`subscriptionAlerts__item subscriptionAlerts__item--${severity}`}
            >
              {webMessage}{' '}
              {webUrl && (
                <Link to={webUrl} className="subscriptionAlerts__item--link">
                  {webUrlTitle}
                </Link>
              )}
            </Alert>
          );
        })}
    </div>
  );
};

SubscriptionAlerts.propTypes = {
  alerts: PropTypes.object.isRequired,
  toggleAlert: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  toggleAlert: bindActionCreators(toggleAlert, dispatch)
});

const mapStateToProps = (state) => ({
  alerts: getAlerts(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAlerts);
