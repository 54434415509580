import React, { Component } from 'react';
import { TEXT } from 'locales/translations';
import PropTypes from 'prop-types';
import { IntervalText } from 'components';
import { subscriptionPlan } from 'utils/propTypes';
import { Form } from 'react-redux-form';
import { FlashListContainer } from 'containers';
import TermsAndConditionsField from 'Page/Checkout/TermsAndConditionsField';
import { Amount, Spinner, DateTimePresenter, Container, Row, Col, Button } from 'Common';

class SubscriptionWithdrawCancelation extends Component {
  render() {
    const { onClick, subscriptionTitle, currentPlan, isFetching, validTil } = this.props;
    return (
      <Container>
        <Row>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 8, offset: 2 }}>
            <h1 className="plans__heading">{TEXT.withdrawCacelation.title}</h1>
            <p className="plans__subheading">
              {TEXT.withdrawCacelation.subline_1}
              <>
                <DateTimePresenter date={validTil} /> Uhr.
              </>
              {TEXT.withdrawCacelation.subline_2}
            </p>
            <FlashListContainer />
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 7, offset: 3 }} lg={{ size: 4, offset: 4 }} className="mt-4">
            <div className="card-stoerer card-info mb-md-5">
              <div className="card-body text-center">
                <div className="plans-btn__info">
                  <p className="plans-btn__title" dangerouslySetInnerHTML={{ __html: subscriptionTitle }} />
                  <div className="plans-btn__pricing">
                    <p className="plans-btn__amount">
                      <Amount value={currentPlan.chargeAmount} currency={currentPlan.currency} />
                    </p>
                    <p className="plans-btn__interval">
                      <IntervalText plan={currentPlan} withAsterisk={true} withPriceExplainer />
                    </p>
                  </div>
                </div>
                <Form model="entities.forms.termsAndConditions" onSubmit={onClick} hideNativeErrors>
                  <TermsAndConditionsField />

                  <div>
                    {isFetching ? (
                      <Spinner className="upgrade-spinner" />
                    ) : (
                      <Button dataCyName="submit-withdraw-cancellation" className="btn-promo">
                        {TEXT.withdrawCacelation.btn_text}
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }}>
            <p className="small text-center subscription-disclaimer mt-3 mt-md-0">{TEXT.withdrawCacelation.subscriptionDisclaimer}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}
SubscriptionWithdrawCancelation.propTypes = {
  onClick: PropTypes.func.isRequired,
  subscriptionTitle: PropTypes.string,
  validTil: PropTypes.string,
  isFetching: PropTypes.bool,
  currentPlan: subscriptionPlan
};

export default SubscriptionWithdrawCancelation;
