import { useState, useEffect } from 'react';
import { pushDataLayerB2BAudioSampleType } from 'components/GtmHelper';

export const useAudioPlayerModal = (audioId, trackingKey) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    if (!isOpen) {
      pushDataLayerB2BAudioSampleType(trackingKey);
    }
    setIsOpen(!isOpen);
  };

  const closeModalAfterAudioPlayed = () => {
    const audio = document.getElementById(audioId);
    if (audio) {
      audio.addEventListener('ended', () => {
        setIsOpen(false);
      });
    }
  };

  useEffect(() => {
    closeModalAfterAudioPlayed();
  }, []);

  return {
    isOpen,
    toggle,
    closeModalAfterAudioPlayed
  };
};
