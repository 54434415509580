import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Row, Col, Paragraph, Button, BackgroundImage } from 'Common';
import { HeadlineContainer } from 'Component';
import { HashLink as Link } from 'react-router-hash-link';
import { AuthorStyle } from './Author.style';
import { experts } from 'images/landingpages/b2b_landingpage';

export const Author = () => {
  return (
    <AuthorStyle backgroundColor="white" className="text-center text-lg-start bubbles">
      <Row>
        <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 0 }}>
          <HeadlineContainer>
            <Headline level={2}>{TEXT.company.author.headline_1}</Headline>
            <Headline level={3}>{TEXT.company.author.headline_2}</Headline>
          </HeadlineContainer>
          <Paragraph>{TEXT.company.author.body_1}</Paragraph>
          <Paragraph>{TEXT.company.author.body_2}</Paragraph>
          <Link to="#b2bContactForm">
            <Button>{TEXT.company.contactBtn}</Button>
          </Link>
        </Col>
        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }}>
          <BackgroundImage image={experts} className="experts-image" />
        </Col>
      </Row>
    </AuthorStyle>
  );
};
