import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BrigitteTestimonial, FlowTestimonial, SternTestimonial } from 'components/Testimonial';

const TrustRow = () => (
  <Container className="trust-row">
    <Row>
      <Col sm={4}>
        <BrigitteTestimonial isColored />
      </Col>
      <Col sm={4}>
        <FlowTestimonial isColored />
      </Col>
      <Col sm={4}>
        <SternTestimonial isColored />
      </Col>
    </Row>
  </Container>
);

export default TrustRow;
