import PropTypes from 'prop-types';

export const coupon = PropTypes.shape({
  id: PropTypes.string.isRequired,
  duration: PropTypes.oneOf(['forever', 'once', 'repeating']),
  created: PropTypes.number.isRequired,
  durationInMonths: PropTypes.number, // for duration = repeating, otherwise null
  livemode: PropTypes.bool.isRequired,
  maxRedemptions: PropTypes.number,
  metadata: PropTypes.object.isRequired,
  redeemBy: PropTypes.number,
  timesRedeemed: PropTypes.number.isRequired,
  valid: PropTypes.bool.isRequired,
  // reduce either by amount ...
  amountOff: PropTypes.number,
  currency: PropTypes.string,
  // ... or by percentage
  percentOff: PropTypes.number
});

export const onetimeCoupon = PropTypes.shape({
  id: PropTypes.string.isRequired,
  // code: PropTypes.string,
  // reduce either by amount ...
  amountOff: PropTypes.number,
  // ... or by percentage
  percentOff: PropTypes.number,
  description: PropTypes.string,
  expiresAt: PropTypes.string
});
