import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  specialUnlocked: false
};

export default function redeemUnlockCode(state = initialState, action) {
  switch (action.type) {
    case types.REDEEM_CODE_PENDING:
      return {
        ...state,
        isFetching: true,
        specialUnlocked: false
      };
    case types.REDEEM_CODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        specialUnlocked: false,
        error: axiosError(state.error, action)
      };
    case types.CLEAR_UNLOCKCODE_MESSAGES:
      return {
        ...state,
        isFetching: false,
        specialUnlocked: false
      };
    case types.REDEEM_CODE_SUCCESS:
      return {
        ...state,
        specialUnlocked: true
      };
    default:
      return state;
  }
}

export const isFetching = (state) => state && state.isFetching;
export const getSpecialUnlocked = (state) => state && state.specialUnlocked;
