import React from 'react';
import Style from './BorderedSectionContainer.style';
import { blueBorderContainerBottomImage } from 'images/landingpages/health_insurance';
import blueBorderTop from 'images/landingpages/health_insurance/blue-rand-oben.svg';
import { useTheme } from 'components/Theme';

export const BorderedSectionContainer = ({ children, className = '', topBorder = false, bottomBorder = true, style, dataCyName }) => {
  const theme = useTheme();
  let backgroundColor = 'green';
  let bottomLine = 'green';
  let topLine = 'green';

  switch (theme.id) {
    case 'theme_work':
      backgroundColor = 'red';
      bottomLine = 'red';
      topLine = 'red';
      break;
    case 'theme_calm':
      backgroundColor = 'blue';
      bottomLine = blueBorderContainerBottomImage;
      topLine = blueBorderTop;
      break;
  }

  return (
    <Style backgroundColor={backgroundColor} className={className} style={style} dataCyName={dataCyName}>
      {topBorder && <img src={topLine} alt="" className="top-line" />}
      {children}
      {bottomBorder && <img src={bottomLine} alt="" className="bottom-line" />}
    </Style>
  );
};
