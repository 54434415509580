import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { TEXT } from 'locales/translations';
import InvoiceTable from './InvoiceTable';
import * as routerPropTypes from 'utils/propTypes/reactRouterPropTypes';
import { usePageTitle } from '../../containers/PageTitleContainer/PageTitleContainer';

export default function Invoice({ invoice, match }) {
  usePageTitle(TEXT.invoice.title);

  return (
    <Row className="invoice">
      <Col xs={12}>
        <Row>
          <Col xs={12} className="pb-4">
            <Link to="/profile/invoices" className="btn-link">
              &lt; Zur&uuml;ck zur Rechnungsübersicht
            </Link>
          </Col>
        </Row>
        {invoice ? (
          <Fragment>
            <h1 className="invoice__heading">
              {TEXT.invoice.heading} {invoice.number}
            </h1>
            <InvoiceTable invoice={invoice} />
          </Fragment>
        ) : (
          <Fragment>
            <h1 className="invoice__heading">Rechnungsansicht </h1>
            <div>{`Eine Rechnung mit der Nummer ${match.params.id} wurde nicht gefunden!`}</div>
          </Fragment>
        )}
      </Col>
    </Row>
  );
}

Invoice.propTypes = {
  invoice: PropTypes.object,
  match: routerPropTypes.match.isRequired
};
