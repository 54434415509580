import React from 'react';
import ReactDOM from 'react-dom/client';
import Bootstrap from 'redux/bootstrap';
import { App } from './App';
import 'react-notifications-component/dist/theme.css';
import './styles.scss';

const Application = () => {
  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('react-root'));

Bootstrap()
  .then(() => root.render(<Application />))
  .catch((error) => console.error(error));
