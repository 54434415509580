import React from 'react';
import PageLayout from './PageLayout';

import survey_right1x from 'images/landingpages/health_insurance/survey_right.png';
import survey_right2x from 'images/landingpages/health_insurance/survey_right@2x.png';
import survey_right3x from 'images/landingpages/health_insurance/survey_right@3x.png';

import { TEXT } from 'locales/translations';

export default function Rigth() {
  return (
    <PageLayout
      image1x={survey_right1x}
      image2x={survey_right2x}
      image3x={survey_right3x}
      title={TEXT.healthSurvey.right.title}
      text={TEXT.healthSurvey.right.text}
    />
  );
}
