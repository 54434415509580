import styled from 'styled-components';
import { Modal } from 'reactstrap';
import { colors } from 'components/Theme';

const getColor = (color) => {
  switch (color) {
    case 'work':
      return colors.purple.c500;
    case 'calm':
      return colors.blue.c500;
    default:
      return colors.pink.c500;
  }
};

export const StyledModal = styled(Modal)`
  .audio-player-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: auto;
  }
  .modal-content {
    display: flex;
    align-self: center;
    border: 1px solid ${(props) => getColor(props.color)};
    margin: auto;
    background-color: ${(props) => getColor(props.color)} !important;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10%;
  position: relative;

  .close-button {
    text-decoration: none;
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.8rem;
    background-color: transparent;
    color: ${colors.white.c500};
    border: none;
    font-size: 2.5rem;
    width: 2.5rem;

    &:hover,
    &:focus,
    &:active {
      color: ${colors.grey.c500}!important;
      background-color: transparent !important;
    }
  }

  .modal-image {
    width: 100%;
    aspect-ratio: 750/1000;
  }

  .audio-player {
    background-color: ${(props) => getColor(props.color)};
    border-radius: 8px;
    border: 1px solid ${(props) => getColor(props.color)};
    color: ${colors.white.c500};
  }
`;
