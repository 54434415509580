import React from 'react';
import PropTypes from 'prop-types';

const Author = ({ imageClass, name, description }) => {
  return (
    <section className="author-container">
      <div className={imageClass} />
      <h4>{name}</h4>
      <p>{description}</p>
    </section>
  );
};

Author.propTypes = {
  imageClass: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default Author;
