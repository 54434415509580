import React, { useEffect } from 'react';
import Style from './SubscriptionUpgradePage.style';
import { useDispatch, useSelector } from 'react-redux';
import { getUserPaymentDetails, getSubscriptionPlanByInterval, hasSubscription, isGuest } from 'redux/query';
import { MissingMonthlySubscription, UpgradeUnknownUser, UserSubscriptionUpgrade } from 'Component';
import { getSubscriptionPlansRequest, getUserProrationPriceRequest } from 'redux/actions';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';

export const SubscriptionUpgradePage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isGuestUser = useSelector(isGuest);
  const yearlySubscriptionPlan = useSelector(getSubscriptionPlanByInterval('YEARLY'));
  const userHasSubscription = useSelector(hasSubscription);
  const { product } = useSelector(getUserPaymentDetails);

  const { coupon } = qs.parse(search);

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL, coupon));
  }, [coupon]);

  useEffect(() => {
    if (isGuestUser || !yearlySubscriptionPlan?.id) {
      return;
    }
    dispatch(getUserProrationPriceRequest({ subscriptionPlanId: yearlySubscriptionPlan.id }));
  }, [yearlySubscriptionPlan.id]);

  return (
    <Style>
      {isGuestUser ? (
        <UpgradeUnknownUser />
      ) : (
        <>{userHasSubscription && product === 'Monats-Abo' ? <UserSubscriptionUpgrade /> : <MissingMonthlySubscription />}</>
      )}
    </Style>
  );
};
