import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';

import count from 'images/landingpages/health_insurance/count.svg';

export default styled.div`
  .header {
    display: flex;
    flex-direction: row;
  }

  .count {
    width: ${asRem(58)};
    height: ${asRem(56)};
    object-fit: contain;
    background: url(${count}) no-repeat;

    .index {
      color: ${colors.white.c500};
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: ${asRem(12)};

      @media (min-width: ${breakpoints.md}) {
        padding-top: ${asRem(8)};
        width: ${asRem(58)};
      }
    }
  }
`;
