import { Button, Col, Headline, Icon, Image, Link, List, Paragraph, Row } from 'Common';
import { Card } from 'Component';
import { Section, ThemeProvider } from 'Container';
import { colors, themeCalm } from 'Theme';
import { MixedCooperations } from 'components/Component';
import {
  descriptionImage,
  euSign,
  headerImage,
  isoSign,
  preventionSign,
  symbolChat,
  symbolJudge,
  symbolLock,
  symbolShield
} from 'images/trustPortal';
import { TEXT } from 'locales/translations';
import Style from './TrustPortalPage.style';

export const TrustPortalPage = () => {
  const { headerSection, signetSection, descriptionSection, contactSection } = TEXT.trustPortal;

  const handleOnOpenMail = () => {
    const a = document.createElement('a');
    a.href = 'mailto:security@balloonapp.de';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Style>
      <Section verticalPadding="none" className="header-section">
        <Image src={headerImage} />
        <Headline level={1}>{headerSection.headline}</Headline>
        <Paragraph className="intro">{headerSection.description}</Paragraph>
        <Button href={'mailto:security@balloonapp.de'} type="inverted">
          {headerSection.buttonLabel}
        </Button>
      </Section>
      <Section className="text-center signet-section" backgroundColor="white">
        <Headline level={4}>{signetSection.headline}</Headline>
        <Row>
          <Col md="4">
            <Image src={preventionSign} alt="Deutscher Standard Präventionen - Prüfsiegel" />
            <Paragraph className="dsgvo-box">{signetSection.signPreventionLabel}</Paragraph>
          </Col>
          <Col md="4">
            <Image src={euSign} alt="EU Datenschutz - Prüfsiegel" />
            <Paragraph className="dsgvo-box">{signetSection.signDsgvoLabel}</Paragraph>
          </Col>
          <Col md="4">
            <Image src={isoSign} alt="ISO/IEC27001 Zertifiziert - Prüfsiegel" />
            <Paragraph className="dsgvo-box">
              <Link href={signetSection.mimeUrl} target="_blank">
              {signetSection.mime}
              </Link>
              {''}
              {signetSection.signIsoLabel}
            </Paragraph>
          </Col>
        </Row>
      </Section>
      <Section backgroundColor="lightGrey" className="description-section">
        <div className="description-image-wrapper">
          <Image src={descriptionImage} className="description-image" />
          <Headline level={2}>{descriptionSection.headline}</Headline>
          <List icon={<Image src={symbolLock} className="list-icon" />} className="main-list">
            <Headline level={4}>{descriptionSection.list1.headline}</Headline>
          </List>
          <List
            className="description-list"
            icon={<Icon color={[colors.green.c200, colors.green.c600]} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
          >
            <Paragraph className="image-row image-row-1">
              <strong>{descriptionSection.list1.entry1[0]}</strong> {descriptionSection.list1.entry1[1]}
            </Paragraph>
            <Paragraph className="image-row image-row-2">
              <strong>{descriptionSection.list1.entry2[0]}</strong> {descriptionSection.list1.entry2[1]}
            </Paragraph>
            <Paragraph>
              <strong>{descriptionSection.list1.entry3[0]}</strong> {descriptionSection.list1.entry3[1]}
            </Paragraph>
            <Paragraph>
              <strong>{descriptionSection.list1.entry4[0]}</strong> {descriptionSection.list1.entry4[1]}
            </Paragraph>
          </List>
        </div>
        <List icon={<Image src={symbolJudge} className="list-icon" />} className="main-list">
          <Headline level={4}>{descriptionSection.list2.headline}</Headline>
        </List>
        <List
          className="description-list"
          icon={<Icon color={[colors.green.c200, colors.green.c600]} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
        >
          <Paragraph>
            <strong>{descriptionSection.list2.entry1[0]}</strong> {descriptionSection.list2.entry1[1]}
          </Paragraph>
        </List>
        <List icon={<Image src={symbolShield} className="list-icon" />} className="main-list">
          <Headline level={4}>{descriptionSection.list3.headline}</Headline>
        </List>
        <List
          className="description-list"
          icon={<Icon color={[colors.green.c200, colors.green.c600]} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
        >
          <Paragraph>
            <strong>{descriptionSection.list3.entry1[0]}</strong> {descriptionSection.list3.entry1[1]}
          </Paragraph>
          <Paragraph>
            <strong>{descriptionSection.list3.entry2[0]}</strong> {descriptionSection.list3.entry2[1]}
          </Paragraph>
        </List>
        <List icon={<Image src={symbolChat} className="list-icon" />} className="main-list">
          <Headline level={4}>{descriptionSection.list4.headline}</Headline>
        </List>
        <List
          className="description-list"
          icon={<Icon color={[colors.green.c200, colors.green.c600]} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
        >
          <Paragraph>
            <strong>{descriptionSection.list4.entry1[0]}</strong> {descriptionSection.list4.entry1[1]}
          </Paragraph>
          <Paragraph>
            <strong>{descriptionSection.list4.entry2[0]}</strong> {descriptionSection.list4.entry2[1]}
          </Paragraph>
          <Paragraph>
            <strong>{descriptionSection.list4.entry3[0]}</strong> {descriptionSection.list4.entry3[1]}
          </Paragraph>
        </List>
      </Section>
      <MixedCooperations id="cooperations-slider" />
      <ThemeProvider theme={themeCalm}>
        <Section className="contact-section">
          <Row>
            <Col md={6}>
              <Headline level={2}>{contactSection.headline}</Headline>
              <Paragraph>{contactSection.description_1}</Paragraph>
              <Paragraph>{contactSection.description_2}</Paragraph>
              <List className="contact-list">
                {contactSection.list.map((label, index) => (
                  <Paragraph key={`paragraph-contact-section-list-${index}`}>{label}</Paragraph>
                ))}
              </List>
              <Paragraph>{contactSection.description_3}</Paragraph>
            </Col>
            <Col md={6}>
              <Card className="contact-card">
                <Headline level={2}>{contactSection.contactFormHeadline}</Headline>
                <Paragraph>{contactSection.contactFormDescription1}</Paragraph>
                <Link color={colors.blue.c500} href="mailto:security@balloonapp.de">
                  <Paragraph>security@balloonapp.de</Paragraph>
                </Link>
                <br />
                <Paragraph>{contactSection.contactFormDescription2}</Paragraph>
                <br />
                <Button onClick={handleOnOpenMail}>{contactSection.buttonLabel}</Button>
              </Card>
            </Col>
          </Row>
        </Section>
      </ThemeProvider>
    </Style>
  );
};
