import React from 'react';
import { getStripeErrorMessage } from 'redux/reducers/entities';
import { getUsersError } from 'redux/query';
import { useSelector } from 'react-redux';

const errorMessage = `Hmm, das hätte nicht passieren sollen.
Bitte probiere die Seite erneut zu laden.`;

function errMsg(error) {
  let stripeMsg = getStripeErrorMessage({ error: error });
  return error ? `${error.status}, ${error.statusText}${stripeMsg ? ', ' + stripeMsg : ''}` : undefined;
}

export const useUserError = () => {
  const error = useSelector(getUsersError);
  if (!!error) {
    return (
      <>
        <p className="display-linebreak">{errorMessage}</p>
        <p>({errMsg(error)})</p>
      </>
    );
  }

  return null;
};
