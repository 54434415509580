import * as types from 'redux/actions/actionTypes';
import { REGISTER_USER_FAILURE, GET_SUBSCRIPTION_PLANS_FAILURE, REDEEM_GIFT_COUPON_FAILURE } from 'redux/actions';

const initialState = undefined;

export default function axiosError(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_FAILURE:
    case types.GET_USER_FAILURE:
    case types.LOGIN_USER_FAILURE:
    case types.REGISTER_USER_OTP_FAILURE:
    case REGISTER_USER_FAILURE:
    case REDEEM_GIFT_COUPON_FAILURE: {
      return action && typeof action.error[0] !== 'undefined'
        ? action.error[0].message
          ? {
              ...state,
              timeout: undefined,
              status: 422,
              statusText: action.error[0].message,
              data: action.error[0].extensions.code
            }
          : {
              timeout: true
              // we have action.error.request, but there's no point in
              // storing it in the state
            }
        : state;
    }
    case types.GET_SINGLE_FAILURE:
    case types.GET_COURSE_FAILURE: {
      return action.error
        ? action.error[0].message
          ? {
              ...state,
              timeout: undefined,
              status: 404
            }
          : {
              timeout: true
              // we have action.error.request, but there's no point in
              // storing it in the state
            }
        : state;
    }
    case types.REDEEM_CODE_FAILURE: {
      return action.error
        ? action.error[0].message
          ? {
              ...state,
              timeout: undefined,
              status: 422,
              statusText: action.error[0].message,
              data: action.error[0].extensions.unlocked
            }
          : {
              timeout: true
              // we have action.error.request, but there's no point in
              // storing it in the state
            }
        : state;
    }
    default:
      return state;
  }
}
