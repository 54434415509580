import React from 'react';
import PropTypes from 'prop-types';

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default function CardHeader({ color, title }) {
  return (
    <>
      <div className="subscriptions card-header" style={color ? { backgroundColor: color } : null}>
        {title}
      </div>
      <div className="card-header-spacer" />
    </>
  );
}
