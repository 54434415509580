import { requestApi } from 'utils';

export const createStripeSetupIntentApiRequest = () =>
  requestApi.mutate(
    `
  mutation CreateStripeSetupIntent {
    createStripeSetupIntent {
      id,
      clientSecret
    }
  }
`
  );
