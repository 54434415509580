import React from 'react';
import { Col, Container, Headline, Image, Row, ScrollButton } from 'Common';
import { TEXT } from 'locales/translations';
import Style from './ResilienceExpert.style';
import { Paragraph } from '@missionme/mime_elements';

import borisBalloonie from 'images/home/BallonieBoris@2x.png';

export function ResilienceExpert() {
  return (
    <Style type="full-width" backgroundColor="white">
      <Container>
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 4 }}>
            <Image src={borisBalloonie} alt={TEXT.borisSection.alt} className="image text-center d-none d-md-block mx-auto mb-4" />
          </Col>
          <Col xs={{ size: 12, offset: 0 }} md={{ size: 7, offset: 1 }}>
            <Headline level={3} className="text-center text-md-left mb-0">
              {TEXT.resiliencePage.expert.headline_1}
            </Headline>
            <Headline level={2} className="text-center text-md-left mb-0 mb-md-5">
              {TEXT.resiliencePage.expert.headline_2}
            </Headline>
            <Paragraph className="text-center text-md-left">{TEXT.resiliencePage.expert.text}</Paragraph>
            <div className="mt-0 mt-md-5 d-flex justify-content-center">
              <ScrollButton className="btn-w-auto" color="pink" to="section-checkout">
                {TEXT.resiliencePage.cta}
              </ScrollButton>
            </div>
            <Image
              src={borisBalloonie}
              alt="Balloon präsentiert Achtsamkeitsexperte und Neurowissenschaftler Dr. Boris Bornemann"
              className="image text-center d-block d-md-none mx-auto mt-5"
            />
          </Col>
        </Row>
      </Container>
    </Style>
  );
}
