import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const id = `${window.location?.hash || ''}`.replace('#', '');
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        //scrollIntoView -> https://github.com/facebook/react/issues/23396
        //chrome -> element.scrollTo() not working any more
        return element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
    } else {
      return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  return null;
};
