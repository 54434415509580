export const formatDate = (date, format) => {
  if (date === null) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const map = {
    dd: `${date.getDate()}`.padStart(2, '0'),
    mm: `${date.getMonth() + 1}`.padStart(2, '0'),
    yyyy: date.getFullYear(),
    HH: `${date.getHours()}`.padStart(2, '0'),
    ii: `${date.getMinutes()}`.padStart(2, '0'),
    ss: `${date.getSeconds()}`.padStart(2, '0')
  };

  return format.replace(/dd|mm|yyyy|HH|ii|ss/gi, (matched) => map[matched]);
};
