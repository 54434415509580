import React from 'react';

const GradientLine = () => {
  return (
    <div className="gradient-line-container">
      <div className="gradient-line gradient-line-10"></div>
      <div className="gradient-line gradient-line-5"></div>
      <div className="gradient-line gradient-line-2"></div>
    </div>
  );
};
export default GradientLine;
