import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPanel from './SubscriptionPanel';
import { Amount, Button, Form, Spinner } from 'Common';
import { TEXT } from 'locales/translations';
import { ACTIVE_SECTION, CheckoutContext } from '../Checkout';
import { getSelectedSubscriptionPlan, getUserPaymentInformation, isSubmitCheckoutLoading } from 'redux/query';
import { CheckboxField } from 'Component';
import { submitCheckoutRequest } from 'redux/actions';
import TermsOfServiceLink from 'components/TermsOfServiceLink';

export const ConfirmationSection = () => {
  const dispatch = useDispatch();
  const { activeCollapse } = useContext(CheckoutContext);

  const plan = useSelector(getSelectedSubscriptionPlan);
  const { paymentMethod } = useSelector(getUserPaymentInformation);
  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [showError, setShowError] = useState(false);
  const isLoading = useSelector(isSubmitCheckoutLoading);

  const showCreditCardBubble = plan.interval !== 'ONETIME' && paymentMethod === 'card';

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (!termsConfirmed) {
      return setShowError(true);
    }
    dispatch(submitCheckoutRequest());
  };

  const handleOnChangeCheckbox = (terms) => {
    setShowError(false);
    setTermsConfirmed(terms);
  };

  if (ACTIVE_SECTION.CONFIRMATION !== activeCollapse) {
    return null;
  }

  return (
    <div className="section-container">
      <SubscriptionPanel />
      <Form onSubmit={handleOnSubmit}>
        <CheckboxField
          dataCyName="terms"
          name="terms"
          label={
            <>
              Ich stimme den <TermsOfServiceLink /> zu.
            </>
          }
          errorMessage={showError && 'Du musst den AGB zustimmen.'}
          onChange={handleOnChangeCheckbox}
        />
        <div className="card-footer mb-3">
          {showCreditCardBubble && (
            <div className="credit-card-hint">
              <p>
                {plan.zppCertified ? TEXT.checkoutConfirm.bubble_1 : TEXT.checkoutConfirm.bubbleAbo_1}
                <strong>{TEXT.checkoutConfirm.bubble_2}</strong>
                {TEXT.checkoutConfirm.bubble_3}
              </p>
            </div>
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <Button color="pink" disabled={isLoading} dataCyName="submit-checkout-purchase">
              <span>
                {TEXT.checkoutConfirm.btnText_1}
                <Amount className="font-weight-bold" value={plan.chargeAmount} currency={plan.currency} />
                {TEXT.checkoutConfirm.btnText_2}
              </span>
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
