import React, { useMemo } from 'react';
import { Label } from 'reactstrap';
import Style from './TextField.style';
import { createUniqKey } from 'utils';
import { Input } from 'Common';

export const TextField = ({
  label,
  id,
  name,
  className,
  type = 'text',
  placeholder,
  dataCyName,
  disabled,
  required,
  register,
  errorMessage,
  validationPattern,
  onChange = (evt) => {},
  clearError = () => {},
  customField
}) => {
  const handleOnChange = (evt) => {
    onChange(evt.target.value);
    clearError();
  };

  const _id = useMemo(() => id || createUniqKey(), [id]);
  const _register = useMemo(() => {
    if (!register) {
      return {
        name,
        onChange: handleOnChange
      };
    }
    return register(name, {
      required,
      pattern: validationPattern,
      onChange: handleOnChange
    });
  }, [register]);

  return (
    <Style>
      {label && (
        <Label for={_id}>
          {label}
          {required && '*'}
        </Label>
      )}
      {customField && typeof customField === 'function' ? (
        customField(_id)
      ) : (
        <Input
          {..._register}
          className={className}
          placeholder={placeholder}
          id={_id}
          type={type}
          dataCyName={dataCyName}
          disabled={disabled}
          feedbackMessage={errorMessage}
          valid={!!errorMessage ? false : undefined}
        />
      )}
    </Style>
  );
};
