import React, { Component } from 'react';
import { ROUTES } from 'utils/constants';
import XingHeader from './components/XingHeader';
import XingCheckmarkBoxSection from './components/XingCheckmarkBoxSection';
import BallooniSectionXing from './components/BallooniSectionXing';
import { TEXT } from 'locales/translations';
import XingFormSection from './components/XingFormSection';
import BottomHeaderLine from './components/BottomHeaderLine';
import xingLogoBottom from 'images/landingpages/xing/xing_bottom_2021.svg';
import xingLogoHeader from 'images/landingpages/xing/xing_header_2021.svg';
import phoneLarge from 'images/landingpages/xing/app-xing@3x.png';
import phoneMobil from 'images/landingpages/xing/app-xing-mobil.png';
import phoneMobilMedium from 'images/landingpages/xing/app-xing-mobil@2x.png';
import phoneMobilLarge from 'images/landingpages/xing/app-xing-mobil@3x.png';
import successPhone from 'images/landingpages/xing/success-phone.png';
import successMediumPhone from 'images/landingpages/xing/success-phone@2x.png';
import successLargePhone from 'images/landingpages/xing/success-phone@3x.png';
import { Link } from 'Common';

class XingLandingPage extends Component {
  render() {
    return (
      <main className="xing">
        <XingHeader headline={TEXT.xing.header.headline} subline={TEXT.xing.header.subline} route={ROUTES.xing} />
        <BottomHeaderLine imageSrc={xingLogoHeader} />
        <XingCheckmarkBoxSection
          headline={TEXT.xing.checkmark_section.headline}
          subline={TEXT.xing.checkmark_section.subline_1}
          checkmark_1={TEXT.xing.checkmark_section.checkmark_1}
          checkmark_2={TEXT.xing.checkmark_section.checkmark_2}
          checkmark_3={TEXT.xing.checkmark_section.checkmark_3}
          hasAdditionalCheckmarks={false}
          phoneMobil={phoneMobil}
          phoneMobil_300={phoneMobil}
          phoneMobil_768={phoneMobilMedium}
          phoneMobil_1280={phoneMobilLarge}
          phoneLarge={phoneLarge}
        />
        <BallooniSectionXing
          headline={TEXT.xing.ballooni_section.headline}
          partnerName="XING Premium"
          duration={18}
          ballooni_first={
            <>
              Deinen persönlichen Zugangs-Code zum Kurs-Paket erhältst du im{' '}
              <Link href="https://www.xing.com/premium/partners/balloon" color="white">
                XING Premium Vorteilsbereich
              </Link>
            </>
          }
          ballooni_last={
            <>
              Das XING Premium Kurs Paket findest du in der Balloon App über den “Entdecken” Tab. <br /> Der kostenlose Zugriff umfasst 4
              Kurse und 10 Einzelübungen.
            </>
          }
          bookmark={ROUTES.xing}
        />
        <XingFormSection
          partnerName="XING Premium"
          duration={18}
          successPhone={successPhone}
          successMediumPhone={successMediumPhone}
          successLargePhone={successLargePhone}
          universalLink="https://www.balloonapp.de/app/category/2C4CCA94B046CE2D0033458376796553EF27BB214524484A1B5E00AB7C6A66AD/"
        />
        <div className="xing-footer justify-content-end">
          <a target="_blank" rel="noopener noreferrer" href="https://www.xing.com/premium/partners/balloon">
            <img src={xingLogoBottom} alt="balloon-xing" />
          </a>
        </div>
      </main>
    );
  }
}

export default XingLandingPage;
