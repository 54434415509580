import { logoGJ, logoHansefit, logoRTL, logoSwissLife, logoXing } from 'images/landingpages/b2b_landingpage';

export const businessCooperations = [
  {
    name: 'G+J',
    cooperationLogo: logoGJ
  },
  {
    name: 'Xing',
    cooperationLogo: logoXing
  },
  {
    name: 'Hansefit',
    cooperationLogo: logoHansefit
  },
  {
    name: 'SwissLife',
    cooperationLogo: logoSwissLife
  },
  {
    name: 'RTL',
    cooperationLogo: logoRTL
  }
];