import { requestApi } from 'utils';

export const requestOnetimePasswordApiRequest = (email) =>
  requestApi.mutate(
    `
  mutation RequestOnetimePassword($email: String!) {
    requestOnetimePassword(email: $email, origin: WEBAPP)
  }
`,
    {
      email: email
    }
  );
