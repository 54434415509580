import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'Common';

/**
 *@deprecated handle loading in the component itself, to prevent property drilling
 */
export default function withSpinner(WrappedComponent) {
  class WithSpinner extends Component {
    render() {
      const { isFetching, ...rest } = this.props;
      return isFetching ? <Spinner /> : <WrappedComponent {...rest} />;
    }
  }

  WithSpinner.propTypes = {
    isFetching: PropTypes.bool.isRequired
  };

  return WithSpinner;
}
