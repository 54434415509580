import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Container, Col, Row } from 'reactstrap';
import { BrigitteTestimonial, FlowTestimonial, SternTestimonial } from 'components/Testimonial';

const items = [
  {
    key: 1,
    component: <BrigitteTestimonial isColored />
  },
  {
    key: 2,
    component: <FlowTestimonial isColored />
  },
  {
    component: <SternTestimonial isColored />,
    key: 3
  }
];

class TrustCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.key}>
          <Container>
            <Row>
              <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                {item.component}
              </Col>
            </Row>
          </Container>
        </CarouselItem>
      );
    });

    return (
      <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} className="trust-carousel">
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}

export default TrustCarousel;
