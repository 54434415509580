import { IMG_ALT_TEXTS } from './constants';

export const altAttribute = (src) => {
  let alt = '';

  let applicableRule = IMG_ALT_TEXTS.find((rule) => {
    return src.match(rule.pattern);
  });

  if (applicableRule) {
    alt = applicableRule.substitution;
  }

  return alt;
};
