export const User = (data) =>
  Object.assign(
    { ...data },
    data.id ? { id: data.id } : {},
    data.email ? { email: data.email } : {},
    data.firstname ? { firstname: data.firstname } : {},
    data.insuranceNumber ? { insuranceNumber: data.insuranceNumber } : {},
    data.b2bClientKeys ? { b2bClientKeys: data.b2bClientKeys } : {},
    data.subscriptions ? { subscriptions: data.subscriptions } : {}
  );
