import { Image, Paragraph, Spinner } from 'Common';
import { Slider } from 'Component';
import { useSliderSettings } from 'hookApi/useSliderSettings';
import { TEXT } from 'locales/translations';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCompaniesRequest } from 'redux/actions';
import { getActiveCooperationPartners } from 'redux/query/insuranceCompanies/getActiveCooperationPartners';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { businessCooperations } from 'utils/constants/businessCooperations';
import { CooperationStyle } from './Cooperations.style';

export const MixedCooperations = ({ id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInsuranceCompaniesRequest(true));
  }, [dispatch]);

  const activeCooperationPartners = useSelector(getActiveCooperationPartners);
  const combinedCoopPartners = useMemo(() => [...activeCooperationPartners, ...businessCooperations], [activeCooperationPartners]);

  const settings = useSliderSettings(combinedCoopPartners.length);

  if (!activeCooperationPartners?.length) {
    return <Spinner />;
  }

  return (
    <CooperationStyle
      id={id}
      className="text-center"
      verticalPadding={{ sm: 40, md: 30, lg: 40 }}
      backgroundColor="white"
      type="full-width"
    >
      <Paragraph className="bold">{TEXT.mixedCooperations.headline}</Paragraph>
      <Slider key={settings.initialSlide} settings={settings} className="pink-dots">
        {combinedCoopPartners.map((cooperation) =>
          cooperation.cooperationLogo ? <Image key={cooperation.name} src={cooperation.cooperationLogo} className="logo" /> : null
        )}
      </Slider>
    </CooperationStyle>
  );
};
