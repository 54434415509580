import React from 'react';
import { TEXT } from 'locales/translations';
import { Col, Row } from 'reactstrap';
import { ROUTES } from 'utils/constants';
import { Button } from 'Common';

export const RefundSection = () => {
  return (
    <section className="refund-marketing" id="refund-section">
      <Row>
        <Col
          xs={{ size: 10, offset: 1 }}
          lg={{ size: 6, offset: 1 }}
          xl={{ size: 5, offset: 2 }}
          xxl={{ size: 4, offset: 3 }}
          className="refund-marketing__content-container"
        >
          <h2 className="refund-marketing__headline">{TEXT.healthMarketingPage.refundSection.headline}</h2>
          <p>{TEXT.healthMarketingPage.refundSection.copy_1}</p>
          <ul className="refund-marketing__list">
            <p className="refund-marketing__list-element">{TEXT.healthMarketingPage.refundSection.checkmark_1}</p>
            <p className="refund-marketing__list-element">{TEXT.healthMarketingPage.refundSection.checkmark_2}</p>
          </ul>
          <p>{TEXT.healthMarketingPage.refundSection.copy_2}</p>
          <div className="refund-marketing__btn-container">
            <Button type="inverted" to={ROUTES.krankenkasse + '#section-checkout'}>
              {TEXT.healthMarketingPage.refundSection.ctaBtn}
            </Button>
          </div>
        </Col>
        <Col xs={12} lg={6} className="refund-marketing__img-container"></Col>
      </Row>
    </section>
  );
};
