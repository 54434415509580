import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  course: {}
};

export default function entryContent(state = initialState, action) {
  switch (action.type) {
    case types.GET_ENTRY_CONTENT_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_ENTRY_CONTENT_SUCCESS: {
      return {
        ...state,
        course: action.payload.entities.entryContent,
        isFetching: false,
        headingKey: action.payload.result[0],
        error: undefined
      };
    }

    case types.GET_ENTRY_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state && state.entryContent.isFetching;

export const getCurrentEntryContent = (state) => {
  const values = Object.values(state.entryContent.course);
  if (values[0]) {
    return values[0];
  } else {
    return state.entryContent;
  }
};

export const getEntryContentButtonText = (state) => {
  if (state.entryContent.headingKey === 'LAST_LISTENED') {
    return 'Kurs fortsetzen';
  } else {
    return 'Kurs starten';
  }
};
