import { requestApi } from 'utils';

export const createRequestCancellation = ({
  cancellationType,
  product,
  cancellationDateType,
  requestedCancellationAt,
  email,
  cancellationReason
}) =>
  requestApi.mutate(
    `
  mutation RequestCancellation($cancellationType: Cancellation!, $product: Product!, $cancellationDateType: CancellationDate, $requestedCancellationAt: ISO8601DateTime, $email: String!, $cancellationReason: String) {
    requestCancellation(
      cancellationType: $cancellationType,
      product: $product,
      cancellationDateType: $cancellationDateType,
      requestedCancellationAt: $requestedCancellationAt,
      email: $email,
      cancellationReason: $cancellationReason
    ) {
      receivedDate
      email
      confirmationPdfUrl
    }
  }
`,
    {
      cancellationType: cancellationType,
      product: product,
      cancellationDateType: cancellationDateType,
      requestedCancellationAt: requestedCancellationAt,
      email: email,
      cancellationReason: cancellationReason
    }
  );
