import React from 'react';
import Style from './InsuranceContainer.SpinnerView.style';
import { Spinner } from 'Common';

export const SpinnerView = () => {
  return (
    <Style>
      <Spinner />
    </Style>
  );
};
