import React from 'react';
import LandingpageBallooni from './../../LandingpageBallooni';
import { TEXT } from 'locales/translations';
import LandingpageTopBlueLine from './../../LandingpageTopBlueLine';
import LandingpageBottomBlueLine from './../../LandingpageBottomBlueLine';

const BallooniSectionGothaer = () => {
  return (
    <section id="ballooni-section-container">
      <LandingpageTopBlueLine severalClass="ballooni-top" />
      <h2>{TEXT.gothaer.ballooni_section.headline}</h2>
      <div id="bottom-container">
        <section className="ballooni-container">
          <div className="ballooni3-image" />
          <p>
            Kaufe auf dieser Seite den Jahreszugang von Balloon und entdecke über 200 Meditationen sowie Zusatzinhalte zum Thema
            Achtsamkeit.
          </p>
        </section>
        <LandingpageBallooni imageClass="ballooni1-image" description={TEXT.gothaer.ballooni_section.ballooni_2} />
        <LandingpageBallooni imageClass="ballooni2-image" description={TEXT.gothaer.ballooni_section.ballooni_3} />
      </div>
      <LandingpageBottomBlueLine severalClass="ballooni-bottom" />
    </section>
  );
};

export default BallooniSectionGothaer;
