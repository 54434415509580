import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { ROUTES } from 'utils/constants';

const PrivateRoute = ({ component: Component, isAuthed, isGuest, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthed && !isGuest ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: ROUTES.login,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired, //VoucherRouteContainer is switching to obj or function and self injection...but it's fixed the warning
  location: PropTypes.object
};

export default PrivateRoute;
