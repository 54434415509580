import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { TEXT } from 'locales/translations';
import { PersonalDataContentContainer } from 'containers';
import { hasUnconfirmedEmail } from 'redux/query';
import { Headline } from 'Common';
import { useSelector } from 'react-redux';

const PersonalData = () => {
  const hasNotConfirmedEmail = useSelector(hasUnconfirmedEmail);

  return (
    <div className={`personal-data${hasNotConfirmedEmail ? ' personal-data-has-unconfirme-email' : ''}`}>
      <Row>
        <Col>
          <Headline level={1} className="profile-subheading">
            {TEXT.personalData.heading}
          </Headline>
        </Col>
      </Row>
      <PersonalDataContentContainer />
    </div>
  );
};

export default PersonalData;
