import React from 'react';
import PropTypes from 'prop-types';

import Brigitte1x from 'images/trust_band/brigitte.png';
import Brigitte2x from 'images/trust_band/brigitte@2x.png';
import Brigitte3x from 'images/trust_band/brigitte@3x.png';

import BrigitteSw1x from 'images/home/brigitte.png';
import BrigitteSw2x from 'images/home/brigitte@2x.png';
import BrigitteSw3x from 'images/home/brigitte@3x.png';

import DecorativeImage from '../../components/DecorativeImage';

BrigitteLogo.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.string.isRequired,
  isColored: PropTypes.bool
};

export default function BrigitteLogo({ className, sizes, isColored }) {
  return (
    <DecorativeImage
      className={className}
      srcSet={`${isColored ? Brigitte1x : BrigitteSw1x} 127w, ${isColored ? Brigitte2x : BrigitteSw2x} 254w, ${
        isColored ? Brigitte3x : BrigitteSw3x
      } 381w`}
      sizes={sizes}
      src={Brigitte1x}
    />
  );
}
