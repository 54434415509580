import React from 'react';
import { Button } from 'Common';
import { StyledModal, ModalBody } from './AudioPlayerModal.style';

const AudioPlayerModal = ({ toggle, isOpen, courseImage, courseAudio, onClose, color, audioId }) => {
  return (
    <StyledModal color={color} className="audio-player-modal" isOpen={isOpen} toggle={toggle} onOpened={onClose}>
      <ModalBody color={color}>
        <Button className="close-button" onClick={toggle}>
          <span aria-hidden="true">×</span>
        </Button>
        <img className="modal-image" color={color} src={courseImage} />
        <audio color={color} src={courseAudio} id={audioId} className="audio-player" controls={true} autoPlay />
      </ModalBody>
    </StyledModal>
  );
};

export default AudioPlayerModal;
