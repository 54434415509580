import React from 'react';
import PageLayout from './PageLayout';

import survey_wrong1x from 'images/landingpages/health_insurance/survey_wrong.png';
import survey_wrong2x from 'images/landingpages/health_insurance/survey_wrong@2x.png';
import survey_wrong3x from 'images/landingpages/health_insurance/survey_wrong@3x.png';

import { TEXT } from 'locales/translations';

export default function Wrong() {
  return (
    <PageLayout
      image1x={survey_wrong1x}
      image2x={survey_wrong2x}
      image3x={survey_wrong3x}
      title={TEXT.healthSurvey.wrong.title}
      text={TEXT.healthSurvey.wrong.text}
    />
  );
}
