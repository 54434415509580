import React from 'react';
import PropTypes from 'prop-types';
import { TermsModal } from 'components';
import { ResilienceRequirements } from './';
import { colors } from 'Theme';

export function ResilienceRequirementsLink({
  classNames,
  renderAs = 'link'
}) {
  return (
    <TermsModal
      buttonColor="link"
      buttonClasses={classNames}
      modalHeaderText="Gesundheitsvoraussetzungen"
      buttonText="Gesundheitsvoraussetzungen"
      renderAs={renderAs}
      color={colors.green.c500}
    >
      <ResilienceRequirements />
    </TermsModal>
  );
}

ResilienceRequirementsLink.propTypes = {
  classNames: PropTypes.string,
  renderAs: PropTypes.string
};
