import { requestApi } from 'utils';

export const getCourse = (slug) =>
  requestApi.query(`
  query GetCourse {
    courses(slug: "${slug}") {
      id
      title
      description
      isFree
      isSpecial
      isNotFastForwardable
      completed
      locked
      pictureV2
      backgroundColor
      isNew
      protectedFile
      completed
      specialLogo
      slug
      length
      units {
        id
        position
        length
        title
        completed
        protectedFile
      }
      latestProgressInCourse {
        id
      }
      category {
        id
        title
        courses {
          id
          title
          pictureV2
          isFree
          isSpecial
          locked
          backgroundColor
          isNew
          length
          slug
          unitsCount
        }
      }
    }
  }
`);
