import { TEXT } from 'locales/translations';

const retrieveCustomErrorMessage = (type, code, message) => {
  const messagesForType = TEXT.stripe_errors[type];
  let msg;

  // All those ifs are rather ugly. It could be made better (safer)
  // by building custom objects for each of Stripe's error types
  // and polymorphism.
  if (type === 'invalid_request_error') {
    if (message.startsWith('No such coupon: ')) {
      const couponCode = message.substr(16);
      msg = `Den Promotion-Code "${couponCode}" konnten wir leider nicht finden.`;
    }
  }

  if (typeof messagesForType === 'string') {
    msg = messagesForType;
  } else if (typeof messagesForType === 'object') {
    if (code) {
      msg = messagesForType[code];
    } else {
      msg = `No code provided, cannot resolve message for ${messagesForType}.`;
    }
  }

  return msg;
};

export const getStripeErrorMsg = (err, stripeStatus) => {
  const customErrMsg = retrieveCustomErrorMessage(err.type, err.code, err.message);

  // Use a custom error message, otherwise, if available, Stripe's message,
  // otherwise a default message
  return customErrMsg ? customErrMsg : err.message ? err.message : `An unhandled error of type ${err.type} occurred (${err.code})`;
};
