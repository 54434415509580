// importing the package.json exposes all the data to the bundle,
// so we use build-time to do the job
const VERSION = process.env.REACT_APP_PACKAGE_VERSION;

const window = global.window || {
  location: { hostname: 'localhost' }
};

const platform = {
  isProduction: window.location.host === 'www.balloonapp.de',
  isStaging: window.location.host === 'staging.balloonapp.de',
  isDevelopment: window.location.hostname !== 'www.balloonapp.de' && window.location.hostname !== 'staging.balloonapp.de'
};

const environment = (() => {
  if (platform.isProduction) return 'production';
  if (platform.isStaging) return 'staging';
  if (platform.isDevelopment) return 'development';
  return 'test';
})();

const ENVIRONMENT_CONFIG = {
  development: {
    APPLE_CLIENT_ID_WEBAPP: 'com.greenhouse.balloon.webapp',
    FACEBOOK_APP_ID: '985338358299470',
    GOOGLE_SIGN_IN_ID: '825749605042-ar8hkno3votp1om9djo3mhjfbodv7lr6.apps.googleusercontent.com',
    STRIPE_PUBLIC_API_KEY: 'pk_test_CvVYJKs8Ge2a0DymiXXG5zgB',
    BUGSNAG_API_KEY: '1e02f80c21c19240187bfc24e2ce3071',
    disableReduxLogger: true
  },
  staging: {
    APPLE_CLIENT_ID_WEBAPP: 'com.greenhouse.balloon.webapp',
    FACEBOOK_APP_ID: '985338358299470',
    GOOGLE_SIGN_IN_ID: '825749605042-ar8hkno3votp1om9djo3mhjfbodv7lr6.apps.googleusercontent.com',
    STRIPE_PUBLIC_API_KEY: 'pk_test_CvVYJKs8Ge2a0DymiXXG5zgB',
    BUGSNAG_API_KEY: '1e02f80c21c19240187bfc24e2ce3071',
    disableReduxLogger: true
  },
  production: {
    APPLE_CLIENT_ID_WEBAPP: 'com.greenhouse.balloon.webapp',
    FACEBOOK_APP_ID: '985338358299470',
    GOOGLE_SIGN_IN_ID: '825749605042-ar8hkno3votp1om9djo3mhjfbodv7lr6.apps.googleusercontent.com',
    STRIPE_PUBLIC_API_KEY: 'pk_live_I8jW30ErG9wyEdxjept2ZqMK',
    BUGSNAG_API_KEY: '1e02f80c21c19240187bfc24e2ce3071',
    disableReduxLogger: true
  }
};

const CONFIG = ENVIRONMENT_CONFIG[environment];

module.exports = { VERSION, platform, environment, CONFIG, ENVIRONMENT_CONFIG };
