import styled from 'styled-components';
import { spacing, breakpoints } from 'components/Theme';
import { asRem } from 'utils';

export const AudioSamplesFilterStyle = styled.div`
  .scrollable-row {
    margin: ${asRem(spacing.four)} 0;

    @media (min-width: ${breakpoints.md}) {
      margin: ${asRem(spacing.five)} 0;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin: ${asRem(spacing.six)} 0;
    }

    button {
      min-width: ${asRem(250)};
      letter-spacing: ${asRem(0.5)};
      @media (min-width: ${breakpoints.md}) {
        padding-top: ${asRem(spacing.two)};
      }
    }
  }
`;
