import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavbarToggler, Navbar, Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ProfileNavigationHeaderContainer } from 'containers';
import { ROUTES } from 'utils/constants';

const ProfileNavItem = ({ id, to, children, onClick }) => (
  <NavItem>
    <NavLink to={to} className="profile-nav-link" activeClassName="active" id={id} onClick={onClick}>
      <span>{children}</span>
    </NavLink>
  </NavItem>
);

ProfileNavItem.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

class ProfileNavigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    if (isMobile) {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }

  render() {
    const { profileRoutes } = this.props;

    const { isOpen } = this.state;

    return (
      <div className="profile-navigation">
        <ProfileNavigationHeaderContainer />
        <Navbar expand="md" className="submenu m-0 p-0">
          <NavbarToggler className="d-flex d-md-none justify-content-end me-2 me-sm-3" onClick={this.toggle}>
            <div className={'submenu-toggler ' + (isOpen ? 'rotate' : '')} />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav>
              <ProfileNavItem onClick={this.toggle} to={profileRoutes.personalData}>
                Persönliche Daten
              </ProfileNavItem>
              <ProfileNavItem onClick={this.toggle} to={profileRoutes.subscriptionStatus}>
                Deine Freischaltung
              </ProfileNavItem>
              <ProfileNavItem onClick={this.toggle} to={profileRoutes.invoices}>
                Deine Rechnungen
              </ProfileNavItem>
              <ProfileNavItem onClick={this.toggle} to={profileRoutes.specialFeature}>
                Specials freischalten
              </ProfileNavItem>
              <ProfileNavItem onClick={this.toggle} to={ROUTES.voucher}>
                Gutschein einlösen
              </ProfileNavItem>
              <ProfileNavItem onClick={this.toggle} to={profileRoutes.help}>
                Hilfe
              </ProfileNavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

ProfileNavigation.propTypes = {
  profileRoutes: PropTypes.shape({
    subscriptionStatus: PropTypes.string.isRequired,
    personalData: PropTypes.string.isRequired,
    invoices: PropTypes.string.isRequired,
    paymentOptions: PropTypes.string.isRequired,
    specialFeature: PropTypes.string.isRequired
  }).isRequired
};

export default ProfileNavigation;
