import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import { Section } from 'Container';

export default styled(Section)`
  color: ${colors.blueGrey.c500};
  
  .info-box {
    @media (min-width: ${breakpoints.md}) {
      min-height: 21rem;
    }
  }

  .info-content {
    @media (min-width: ${breakpoints.md}) {
      min-height: 21rem;
    }
  }

  .dropdown-toggle {
    .form-control {
      background-color: ${colors.white.c500};
      border-radius: ${asRem(8)};
      border: solid 0.5px ${colors.grey.c600};
      &:focus {
        box-shadow: none;
      }
    }
    &::after {
      display: inline-block;
      margin-left: ${asRem(4)};
      vertical-align: ${asRem(4)};
      content: '';
      border-top: ${asRem(4)} solid;
      border-right: ${asRem(4)} solid transparent;
      border-bottom: 0;
      border-left: ${asRem(4)} solid transparent;
      position: absolute;
      right: ${asRem(16)};
      top: ${asRem(30)};
    }
    .dropdown-item {
      color: ${colors.grey.c800};
      &:hover,
      &:focus {
        background-color: ${colors.green.c500};
      }
      &.cooperation-partner {
        border-bottom: ${colors.grey.c100} solid 2px;
      }
    }
    .dropdown-menu {
      &.show {
        border: solid 0.5px ${colors.grey.c600};
        border-top: 0;
        margin-top: -${asRem(4)};
      }
    }
  }

  .content-table-check {
    table,
    th,
    td {
      border-collapse: collapse;
    }
    th {
      padding: 0 ${asRem(7)};
    }
    td {
      padding: ${asRem(5)};
      font-size: ${asRem(12)};
      color: ${colors.grey.c800};

      @media (min-width: ${breakpoints.sm}) {
        font-size: ${asRem(14)};
      }

      @media (min-width: ${breakpoints.md}) {
        font-size: ${asRem(11)};
      }

      @media (min-width: ${breakpoints.lg}) {
        font-size: ${asRem(16)};
      }
    }
    table {
      border-radius: ${asRem(12)};
      border: solid ${asRem(2)} ${colors.green.c500};
      background-color: ${colors.white.c500};
      thead {
        font-size: ${asRem(14)};
        background-color: ${colors.green.c500};
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: ${colors.white.c500};
      }
      tfoot {
        border-top: solid ${asRem(1)} ${colors.green.c500};
        padding: 0 ${asRem(2)};
      }
      .text-right {
        text-align: right;
      }
    }
  }
`;
