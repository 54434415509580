/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiftCouponRetriever } from 'components';
import { isFetchingGiftCoupon, getGiftCouponRedeemedVoucher, getGiftCouponCodeVoucher, getFlashes } from 'redux/reducers';
import { hasSubscription } from 'redux/query';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import qs from 'query-string';
import { redeemGiftCoupon, addFlash } from 'redux/actions';
import { withRouter } from 'react-router-dom';
import * as reactRouterPropTypes from 'utils/propTypes/reactRouterPropTypes';
import * as flashPropTypes from 'utils/propTypes/flashes';
import GiftCouponBanner from './GiftCouponBanner';
import { Headline } from 'Common';

class GiftCouponRetrieverContainer extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.hasSubscription !== this.props.hasSubscription && this.props.hasSubscription) {
      this.props.history.push(ROUTES.profileSubscriptionStatus);
    }
    if (prevProps.hasRedeemedVoucher !== this.props.hasRedeemedVoucher && this.props.hasRedeemedVoucher && !this.props.hasSubscription) {
      const { addFlash } = this.props;
      addFlash(
        'Dein Gutschein wurde erfolgreich eingelöst. Die enthaltenen Meditationen sind jetzt in der Balloon App für dich freigeschaltet.',
        'success'
      );
    }
  }

  render() {
    const { isFetching, hasSubscription, hasRedeemedVoucher, entryVoucher, flashes } = this.props;

    const { voucher } = qs.parse(location.search);
    return (
      <>
        <div>
          <Headline level={1} className="profile-subheading">
            {TEXT.subscriptionStatus.redeemGiftCouponHeadline}
          </Headline>
        </div>
        {hasSubscription ? (
          <>
            <div className="pb-3">{TEXT.subscriptionStatus.redeemGiftCouponTextHasAbo}</div>
            <GiftCouponBanner />
          </>
        ) : (
          <>
            <div className="mb-2">
              <div className="pb-3">{TEXT.subscriptionStatus.redeemGiftCouponText}</div>
              <GiftCouponRetriever isFetching={isFetching} defaultValue={voucher} />
              {hasRedeemedVoucher && entryVoucher.length > 0 && flashes.length > 0 && (
                <div className="pb-3 mt-5 font-weight-bold">Dein Gutschein {entryVoucher} wurde erfolgreich eingelöst.</div>
              )}
            </div>
            <GiftCouponBanner />
          </>
        )}
      </>
    );
  }
}

GiftCouponRetrieverContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  hasRedeemedVoucher: PropTypes.bool.isRequired,
  redeemGiftCoupon: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  addFlash: PropTypes.func.isRequired,
  history: reactRouterPropTypes.history,
  entryVoucher: PropTypes.string,
  flashes: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      alertType: flashPropTypes.alertType
    })
  )
};
GiftCouponRetrieverContainer.defaultProps = {
  hasSubscription: false,
  hasRedeemedVoucher: false,
  flashes: []
};

const mapStateToProps = (state) => ({
  isFetching: isFetchingGiftCoupon(state),
  hasSubscription: hasSubscription(state),
  hasRedeemedVoucher: getGiftCouponRedeemedVoucher(state),
  flashes: getFlashes(state),
  entryVoucher: getGiftCouponCodeVoucher(state)
});

export default withRouter(
  connect(mapStateToProps, {
    history,
    redeemGiftCoupon,
    addFlash
  })(GiftCouponRetrieverContainer)
);
