import styled from 'styled-components';
import { asRem } from 'utils';

export default styled.div`
  section.auth-section {
    .icon-clock {
      width: ${asRem(32)};
      margin-top: ${asRem(-4)};
    }
  }

  section.form-section {
    position: relative;

    .insurance-form-blocker {
      position: absolute;
      top: ${asRem(-24)};
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100;
    }
  }
`;
