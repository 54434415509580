import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HealthInstructionCard extends Component {
  render() {
    const { num, headlineLight, headlineBold, cardBody } = this.props;

    return (
      <div className="instruction-card mb-5 mb-md-6">
        <div className="instruction-card-header mb-3">
          <div className="instruction-card-count me-3 me-md-4">
            <span>{num}</span>
          </div>
          <h3 className="instruction-card-headline text-blueGrey-c500">
            {headlineLight}
            <span>{headlineBold}</span>
          </h3>
        </div>
        <p className="p_lg_kk instruction-card-body">{cardBody}</p>
      </div>
    );
  }
}

HealthInstructionCard.propTypes = {
  num: PropTypes.number.isRequired,
  headlineLight: PropTypes.string.isRequired,
  headlineBold: PropTypes.string.isRequired,
  cardBody: PropTypes.string.isRequired
};

export default HealthInstructionCard;
