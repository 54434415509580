function pushDataLayerEvent(eventObj) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObj);
}

export function pushDataLayerPageView(pagePath) {
  pushDataLayerEvent({
    event: 'pageView',
    pagePath: pagePath,
    pageURL: window.location.href
  });
}

export function pushDataLayerPlayerEvent(type, time) {
  const pathArray = window.location.pathname.split('/');
  pushDataLayerEvent({
    event: type + 'PlayWebplayer',
    courseSlug: pathArray[pathArray.length - 1],
    playTimeInSeconds: time
  });
}

export function pushDataLayerPlayerStartEvent() {
  const pathArray = window.location.pathname.split('/');
  pushDataLayerEvent({
    event: 'playerStart',
    courseSlug: pathArray[pathArray.length - 1]
  });
}

export function pushDataLayerThankYouEvent(abotyp, paymentreferrer) {
  pushDataLayerEvent({
    event: 'thankYouPage',
    aboTyp: abotyp,
    paymentReferrer: paymentreferrer
  });
}

export function pushDataLayerExternalClickToCourseEvent(slug) {
  pushDataLayerEvent({
    event: 'fremdSeitenZumKurs',
    courseSlug: slug,
    externalHost: document.referrer
  });
}

export function pushDataLayerInitExterneKachel(slug) {
  pushDataLayerEvent({
    event: 'initExterneKachel',
    courseSlug: slug,
    externalHost: document.referrer
  });
}

export function pushDataLayerUserType(eventTyp, userTyp) {
  pushDataLayerEvent({
    event: eventTyp, //signin or register
    userTyp: userTyp
  });
}

export function pushDataLayerB2BAudioSampleType(businessPageSubCategory) {
  pushDataLayerEvent({
    event: 'B2bAudioSample',
    business_page_sub_category: businessPageSubCategory
  });
}
