import React from 'react';

const HeaderLogo = () => {
  return (
    <div className="header-logo-container">
      <div className="header-logo" />
    </div>
  );
};
export default HeaderLogo;
