import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';

import { GET_USER_PENDING, GET_USER_FAILURE, GET_USER_SUCCESS } from 'redux/actions/actionTypes';

export const gettingUser = () => ({
  type: GET_USER_PENDING
});

export const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  error
});

export const getUserSuccess = (payload) => ({
  type: GET_USER_SUCCESS,
  payload
});

export const getUser =
  () =>
  (dispatch, getState, { api }) => {
    dispatch(gettingUser());

    return api.getUser().then(
      (response) => {
        dispatch(getUserSuccess(normalize(response.data, { user: schema.user })));
      },
      (error) => {
        handleAxiosError(error, () => {
          dispatch(getUserFailure(error));
        });
      }
    );
  };
