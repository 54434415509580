import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/progress';

import { CREATE_PROCESS_PENDING, CREATE_PROCESS_FAILURE, CREATE_PROCESS_SUCCESS } from 'redux/actions/actionTypes';

export const createProcessPending = () => ({
  type: CREATE_PROCESS_PENDING
});

export const createProcessFailure = (error) => ({
  type: CREATE_PROCESS_FAILURE,
  error
});

export const createProcessSuccess = () => ({
  type: CREATE_PROCESS_SUCCESS
});

export const createProcessForUnit = (id, recordedAt, timeInSeconds, completenessInSeconds) => (dispatch) => {
  dispatch(createProcessPending());
  return api.createProgress(id, recordedAt, timeInSeconds, completenessInSeconds).then(
    (response) => {
      dispatch(createProcessSuccess());
    },
    (error) => {
      handleAxiosError(error, () => {});
    }
  );
};
