import { requestApi } from 'utils';

export const checkUserTokenApiRequest = ({ token }) =>
  requestApi.request({
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
      'X-Authorization': `Bearer ${token}`
    },
    data: {
      query: `
      query CheckUserToken {
        me {
          id
        }
      }
    `
    }
  });
