import React from 'react';
import { Row, Col } from 'Common';
import { Switch } from 'react-router-dom';
import { SubscriptionStatusPage } from 'Page';
import balloonProfile1x from 'images/profile/balloon_with_clouds.png';
import balloonProfile2x from 'images/profile/balloon_with_clouds@2x.png';
import balloonProfile3x from 'images/profile/balloon_with_clouds@3x.png';

import {
  FlashListContainer,
  InvoicesContainer,
  PrivateRouteContainer,
  VoucherRouteContainer,
  GiftCouponRetrieverContainer
} from 'containers';
import { PersonalData, ProfileNavigation, Help, SpecialFeature, SubscriptionAlerts, CodeEntry } from 'components';
import { MainPage } from 'Container';
import { ROUTES } from 'utils/constants';
import PaymentOptions from '../PaymentOptions';

export const Profile = () => {
  const PROFILE_ROUTES = {
    invoices: '/profile/invoices',
    paymentOptions: '/profile/payment-options',
    personalData: '/profile/personal-data',
    subscriptionStatus: '/profile/subscription-status',
    help: '/profile/help',
    specialFeature: '/profile/special-feature',
    voucher: '/gutschein',
    voucherManuel: '/gutschein/anleitung'
  };

  return (
    <MainPage bodyClassName="main-decorated" className="profile">
      <Row>
        <FlashListContainer />
        <SubscriptionAlerts />
      </Row>
      <Row>
        <Col>
          <div className="card-profile">
            <Row>
              <Col className="col-12 col-sm-12 col-md-4 px-sm-0">
                <ProfileNavigation profileRoutes={PROFILE_ROUTES} />
              </Col>
              <Col className="col-12 col-sm-12 col-md-8 px-sm-0">
                <div className="profile-content">
                  <Switch>
                    <VoucherRouteContainer path={PROFILE_ROUTES.subscriptionStatus} component={SubscriptionStatusPage} />
                    <PrivateRouteContainer path={ROUTES.deeplinkConfirmMail} component={CodeEntry} />
                    <PrivateRouteContainer path={PROFILE_ROUTES.personalData} component={PersonalData} />
                    <PrivateRouteContainer path={PROFILE_ROUTES.invoices} component={InvoicesContainer} />
                    <PrivateRouteContainer path={PROFILE_ROUTES.paymentOptions} component={PaymentOptions} />
                    <PrivateRouteContainer path={PROFILE_ROUTES.help} component={Help} />
                    <PrivateRouteContainer path={PROFILE_ROUTES.specialFeature} component={SpecialFeature} />
                    <VoucherRouteContainer path={PROFILE_ROUTES.voucher} component={GiftCouponRetrieverContainer} />
                  </Switch>
                </div>
              </Col>
            </Row>
            <img
              className="profile__balloon"
              srcSet={`${balloonProfile1x} 165w, ${balloonProfile2x} 330w, ${balloonProfile3x} 495w`}
              src={balloonProfile1x}
              alt="Balloon über den Wolken"
            />
          </div>
        </Col>
      </Row>
    </MainPage>
  );
};
