import { ROUTES } from 'utils/constants/routes';

export const cooperationRoutes = [
  ROUTES.xing,
  ROUTES.xingProJobs,
  ROUTES.gothaer,
  ROUTES.dak,
  ROUTES.bkk,
  ROUTES.mkk,
  ROUTES.dakBgm,
  ROUTES.stressreduktion,
  ROUTES.viactiv
];

export const cooperationRoutesGreen = [ROUTES.stressreduktion, ROUTES.xingProJobs];

export const tintedRoutes = [
  ROUTES.terminate,
  `${ROUTES.terminate}/*`,
  ROUTES.login,
  ROUTES.signup,
  ROUTES.voucher,
  `${ROUTES.voucher}/*`,
  ROUTES.profile,
  `${ROUTES.profile}/*`,
  ROUTES.category,
  ROUTES.library,
  ROUTES.checkout,
  ROUTES.krankenkasse,
  ROUTES.buyGift,
  ROUTES.play,
  `${ROUTES.play}/*`,
  ROUTES.upgrade,
  ROUTES.fallbackApp,
  ROUTES.confirmMail,
  ROUTES.confirmMailCode,
  ROUTES.plans,
  ROUTES.kaifu,
  ROUTES.podcast,
  ROUTES.thankyou,
  ROUTES.thankyouloggedout,
  ROUTES.thankyougift,
  ROUTES.thankyoupurchaseassociated,
  ROUTES.thankyouupgrade,
  ROUTES.paymenterror,
  ROUTES.email_confirmation,
  `${ROUTES.upgrade}/*`,
  `${ROUTES.legal}/*`,
  `${ROUTES.webappResources}/*`,
  ROUTES.externalLegacyFacebookPrivacy,
  ROUTES.externalLegacyHealth,
  ROUTES.externalLegacyPrivacyPolicy,
  ROUTES.externalLegacyTerms
];

export const hiddenMenuRoutes = [`${ROUTES.legalApp}/*`, `${ROUTES.appResources}/*`, ROUTES.insuranceCooperationAuthentication];

export const hiddenMenuLinksRoutes = [
  ROUTES.krankenkasse,
  `${ROUTES.krankenkasse}/*`,
  `${ROUTES.appResources}/*`,
  `${ROUTES.legal}/*`,
  `${ROUTES.webappResources}/*`,
  ROUTES.externalLegacyFacebookPrivacy,
  ROUTES.externalLegacyHealth,
  ROUTES.externalLegacyPrivacyPolicy,
  ROUTES.externalLegacyTerms
];

export const hiddenFooterRoutes = [
  `${ROUTES.appResources}/*`,
  `${ROUTES.legal}/*`,
  `${ROUTES.webappResources}/*`,
  ROUTES.insuranceCooperationAuthentication,
  ROUTES.externalLegacyFacebookPrivacy,
  ROUTES.externalLegacyHealth,
  ROUTES.externalLegacyPrivacyPolicy,
  ROUTES.externalLegacyTerms
];
