import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { GiftCouponFormContainer } from 'containers';
import { Spinner } from 'Common';

class GiftCouponRetriever extends Component {
  render() {
    const { isFetching, defaultValue } = this.props;

    const className = 'giftCoupon-row';

    return isFetching ? (
      <Spinner />
    ) : (
      <Row className={className}>
        <Col xs={{ size: 12, offset: 0 }}>
          <GiftCouponFormContainer defaultValue={defaultValue} />
        </Col>
      </Row>
    );
  }
}

GiftCouponRetriever.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string
};

export default GiftCouponRetriever;
