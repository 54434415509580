import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearFlashes } from 'redux/actions';

import { AppStoreBadge, GooglePlayBadge } from 'components';

class SuccessContentXing extends Component {
  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  render() {
    const { successPhone, successMediumPhone, successLargePhone, partnerName, universalLink } = this.props;
    return (
      <div className="bkk-success-content">
        <Row>
          <Col>
            <h2 className="profile-subheading">Super, das hat geklappt!</h2>
            <div className="content">
              Öffne jetzt die Balloon-App und starte mit deinem {partnerName}
              &nbsp; Kurs-Paket.
            </div>
          </Col>
        </Row>
        <Row className="content-app-btn-xing">
          <Col>
            <a href={universalLink} className="btn btn-secondary btn-open-app-xing">
              APP öffnen
            </a>
          </Col>
        </Row>
        <Row className="content-app-badge app-badge pt-5 pb-5">
          <GooglePlayBadge />
          <AppStoreBadge />
        </Row>
        <Row>
          <Col>
            <img
              className="course-phone-balloon"
              src={successPhone}
              srcSet={`${successPhone} 300w, ${successMediumPhone} 768w, ${successLargePhone} 1280w`}
              alt="Kursbeispiel Mobil"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

SuccessContentXing.propTypes = {
  clearFlashes: PropTypes.func.isRequired,
  partnerName: PropTypes.string.isRequired,
  successPhone: PropTypes.node.isRequired,
  successMediumPhone: PropTypes.node.isRequired,
  successLargePhone: PropTypes.node.isRequired,
  universalLink: PropTypes.string.isRequired
};

export default connect(null, {
  clearFlashes
})(SuccessContentXing);
