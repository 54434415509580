import { all, put, take, select } from 'redux-saga/effects';
import {
  updateUserPaymentMethodFailure,
  updateUserPaymentMethodSuccess,
  getUserPaymentInfosRequest,
  clearStripePaymentInfos,
  GET_USER_PAYMENT_INFOS_FAILURE,
  GET_USER_PAYMENT_INFOS_SUCCESS
} from 'redux/actions';
import { getSetupIntent } from 'redux/query';
import { updateCurrentPaymentMethodApiRequest } from 'api/balloon/Payment';

export function* updateUserPaymentMethodSaga() {
  try {
    const state = yield select();
    const setupIntent = getSetupIntent(state);

    const response = (yield updateCurrentPaymentMethodApiRequest(setupIntent.payment_method)).data;

    if (response.errors) {
      yield put(updateUserPaymentMethodFailure(response.errors));
      return;
    }
    yield put(getUserPaymentInfosRequest({ renewable: true }));
    const action = yield take([GET_USER_PAYMENT_INFOS_SUCCESS, GET_USER_PAYMENT_INFOS_FAILURE]);

    switch (action.type) {
      case GET_USER_PAYMENT_INFOS_SUCCESS:
        yield all([put(updateUserPaymentMethodSuccess()), put(clearStripePaymentInfos())]);

        break;
      case GET_USER_PAYMENT_INFOS_FAILURE:
        yield put(updateUserPaymentMethodFailure(action.error));
        break;
    }
  } catch (error) {
    yield put(updateUserPaymentMethodFailure(error));
  }
}
