import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components/index';
import { isTrimmed } from 'utils/validators';

NameTextField.propTypes = {
  showLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  showRequiredHint: PropTypes.bool,
  handleChange: PropTypes.func
};

NameTextField.defaultProps = {
  showLabel: false,
  label: null,
  handleChange: () => null
};

export default function NameTextField({ showLabel, defaultValue, label, showRequiredHint, handleChange }) {
  return (
    <TextField
      id="user.firstname"
      autocomplete="given-name"
      label={showLabel ? label || 'Name' : null}
      messages={{
        isTrimmed: 'Bitte entferne überflüssige Leerzeichen.',
        valueMissing: 'Bitte gib deinen Namen ein.'
      }}
      defaultValue={defaultValue}
      model=".firstname"
      placeholder="Name"
      required
      validators={{
        isTrimmed: (val) => isTrimmed(val)
      }}
      showRequiredHint={showRequiredHint}
      handleChange={handleChange}
    />
  );
}
