import ListItem from './ListItem';
import ListLayoutBlock from './ListLayoutBlock';
import React from 'react';
import { PropTypes } from 'prop-types';
import { TEXT } from 'locales/translations';

const NewContent = ({ newContent, hasSubscription }) => {
  return (
    <div className="new-content">
      <ListLayoutBlock>
        {newContent.map((item, idx) => {
          return (
            <ListItem
              key={idx}
              newBadge="new-badge"
              specialBadge="no-badge"
              title={item.title}
              time={`${Math.round(item.length / 60)} ${TEXT.library.min}`}
              type={item.__typename?.toLowerCase()}
              unitsCount={item.__typename === 'Course' ? `${item.unitsCount} ${TEXT.library.units}` : ''}
              color={item.backgroundColor}
              illustration={item.__typename === 'Course' ? item.pictureV2 : undefined}
              icon={!item.locked || hasSubscription ? 'icon' : 'locked-icon'}
              slug={item.slug}
              categoryIcon={item.__typename === 'Single' && item.category.icon ? item.category.icon : undefined}
            />
          );
        })}
      </ListLayoutBlock>
    </div>
  );
};

NewContent.propTypes = {
  newContent: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired
};

export default NewContent;
