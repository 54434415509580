import { put } from 'redux-saga/effects';
import { sendOtpWithInsuranceNumberFailure, sendOtpWithInsuranceNumberSuccess } from 'redux/actions';
import { sendOtpByInsuranceNumberApiRequest } from 'api/balloon';

export function* requestOtpByInsuranceNumberSaga({ payload }) {
  const { user, sendDeepLinkOTP } = payload;
  yield user;

  try {
    const { data: response } = yield sendOtpByInsuranceNumberApiRequest({
      insuranceNumber: user.insuranceNumber,
      sendDeepLinkOTP
    });

    if (response.errors) {
      yield put(sendOtpWithInsuranceNumberFailure(response.errors));
      return;
    }

    yield put(
      sendOtpWithInsuranceNumberSuccess({
        emailHint: response.data.requestOnetimePasswordWithInsuranceNumber.emailHint,
        insuranceNumber: user.insuranceNumber
      })
    );
  } catch (error) {
    yield put(sendOtpWithInsuranceNumberFailure(error));
  }
}
