import styled from 'styled-components';
import { MainPage } from 'Container';
import { colors } from 'Theme';
import { asRem } from 'utils';

export default styled(MainPage).attrs({ className: 'plans' })`
  .form-coupon-text-field {
    background-color: ${colors.white.c500} !important;
    border-radius: ${asRem(6)} 0 0 ${asRem(6)};
    border-width: ${asRem(1)};
    height: ${asRem(59.5)};
    margin-top: ${asRem(1)};
  }
`;
