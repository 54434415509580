import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { actions, Form } from 'react-redux-form';
import { Button, Col, Headline, Row } from 'Common';
import { TextField } from 'components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearFlashes } from 'redux/actions';
import { clearRedeemUnlockCodeMessage, redeemUnlockCode } from 'redux/actions/unlockCode';
import { getSpecialUnlocked } from 'redux/reducers';
import { bindActionCreators } from 'redux';

class SpecialFeature extends Component {
  componentWillUnmount() {
    const { clearFlashes, clearRedeemUnlockCodeMessage, resetForm } = this.props;
    clearFlashes();
    clearRedeemUnlockCodeMessage();
    resetForm('entities.forms.specialFeature');
  }
  onSubmit = ({ specialfeature }) => {
    const { redeemUnlockCode } = this.props;
    redeemUnlockCode(specialfeature);
  };
  render() {
    const { specialUnlocked } = this.props;
    return (
      <div className="special-feature-data">
        <Helmet>
          <title>Freischalt-Code</title>
        </Helmet>
        <Row>
          <Col>
            <Headline level={1} className="profile-subheading">
              Dein Freischalt-Code
            </Headline>
            <p className="mb-3">Hier kannst du deinen Code einlösen und neue Special-Meditationen freischalten.</p>
            <Form model="entities.forms.specialFeature" hideNativeErrors onSubmit={this.onSubmit}>
              <TextField
                id="specialfeature"
                label="Dein Code"
                model=".specialfeature"
                type="text"
                placeholder="SPECIAL"
                required
                showRequiredHint={false}
              />
              {specialUnlocked && <span>Dein Special wurde erfolgreich freigeschaltet</span>}
              <Button className="w-100 password-change-btn">Special freischalten</Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

SpecialFeature.propTypes = {
  clearRedeemUnlockCodeMessage: PropTypes.func.isRequired,
  clearFlashes: PropTypes.func.isRequired,
  redeemUnlockCode: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  specialUnlocked: PropTypes.bool
};
SpecialFeature.defaultProps = {
  specialUnlocked: false
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: bindActionCreators(actions.reset, dispatch),
  clearRedeemUnlockCodeMessage: bindActionCreators(clearRedeemUnlockCodeMessage, dispatch),
  redeemUnlockCode: bindActionCreators(redeemUnlockCode, dispatch),
  clearFlashes: bindActionCreators(clearFlashes, dispatch)
});

const mapStateToProps = (state) => ({
  specialUnlocked: getSpecialUnlocked(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialFeature);
