import styled from 'styled-components';
import { breakpoints, spacing, colors } from 'components/Theme';
import { asRem } from 'utils';
import { Section } from 'Container';

import backgroundCircleDownLeft from 'images/background_circle_down_left.svg';
import backgroundCircleTopRight from 'images/background_circle_up_right.svg';

export const AuthorStyle = styled(Section)`
  p:last-of-type {
    margin-bottom: ${asRem(spacing.three)};

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: ${asRem(spacing.four)};
    }
  }
  .experts-image {
    aspect-ratio: 344 / 242;
    margin-top: ${asRem(spacing.six)};

    @media (min-width: ${breakpoints.lg}) {
      margin-top: 0;
    }
  }

  &.bubbles {
    @media (min-width: ${breakpoints.md}) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
      }

      &::after {
        background-image: url(${backgroundCircleTopRight});
        aspect-ratio: 201/217;
        width: ${asRem(201)};
        top: ${asRem(60)};
        right: ${asRem(-80)};
      }

      &::before {
        background-image: url(${backgroundCircleDownLeft});
        aspect-ratio: 338/330;
        width: ${asRem(338)};
        bottom: ${asRem(30)};
        left: ${asRem(-190)};
      }
    }
  }
`;
