import { GET_STORE_INFOS_REQUEST, GET_STORE_INFOS_SUCCESS, GET_STORE_INFOS_FAILURE } from 'redux/actions';

const initialState = {
  appStore: {
    rating: 0,
    reviewCount: 0
  },
  playStore: {
    rating: 0,
    reviewCount: 0
  }
};

export default function storeInfos(state = initialState, action) {
  switch (action.type) {
    case GET_STORE_INFOS_REQUEST:
      return {
        ...state
      };

    case GET_STORE_INFOS_SUCCESS:
      return {
        ...state,
        appStore: action.payload.appstore,
        playStore: action.payload.playstore
      };

    case GET_STORE_INFOS_FAILURE:
      return {
        ...state,
        error: true
      };

    default:
      return state;
  }
}
