import React from 'react';
import { connect } from 'react-redux';
import { Library } from 'components';
import { getAllCategories, getCurrentEntryContent, getEntryContentButtonText, getNewContent } from 'redux/reducers';
import { hasSubscription, getToken } from 'redux/query';
import PropTypes from 'prop-types';

import withSpinner from 'components/withSpinner';
import withLibrary from 'containers/withLibrary';

const LibraryContainer = (props) => {
  const { categories, entryContent, hasSubscription, entryContentButtonText, newContent } = props;
  return (
    <Library
      categories={categories}
      entryContent={entryContent}
      hasSubscription={hasSubscription}
      entryContentButtonText={entryContentButtonText}
      newContent={newContent}
    />
  );
};

LibraryContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  entryContent: PropTypes.object.isRequired,
  newContent: PropTypes.array.isRequired,
  entryContentButtonText: PropTypes.string.isRequired,
  token: PropTypes.string
};

LibraryContainer.defaultProps = {
  hasSubscription: false,
  entryContentButtonText: 'Kurs starten'
};

const mapStateToProps = (state) => ({
  categories: getAllCategories(state),
  entryContent: getCurrentEntryContent(state),
  entryContentButtonText: getEntryContentButtonText(state),
  hasSubscription: hasSubscription(state),
  newContent: getNewContent(state),
  token: getToken(state)
});

export default withLibrary(withSpinner(connect(mapStateToProps, {})(LibraryContainer)));
