import { Section } from 'Container';
import styled from 'styled-components';
import { breakpoints, colors } from 'components/Theme';
import { asRem } from 'utils';

export const HealthLandingPageSectionStyle = styled(Section)`
  .content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn-row {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: ${breakpoints.lg}) {
      flex-direction: row;
    }
  }

  .button {
    margin-top: ${asRem(10)};
    @media (min-width: ${breakpoints.lg}) {
      margin-top: 0;
      margin-right: ${asRem(20)};
    }
  }

  .health-image-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: column;
    }
  }

  .health-image {
    width: 100%;
    margin-bottom: ${asRem(24)};

    @media (min-width: ${breakpoints.md}) {
      aspect-ratio: auto;
      max-height: ${asRem(220)};
      height: 100%;
      align-self: center;
      margin-top: ${asRem(24)};
      margin-bottom: 0;
    }
  }
`;
