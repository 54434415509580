import React from 'react';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';
import { Link } from 'Common';
import { colors } from 'Theme';
import { ROUTES } from 'utils';

Steps.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default function Steps({ title, children }) {
  return (
    <div>
      <ul className="mb-5">
        <li>
          <h5>{TEXT.kaifuHowTo.title_1}</h5>
          {TEXT.kaifuHowTo.text_1}
        </li>
        <li>
          <h5>{TEXT.kaifuHowTo.title_2}</h5>
          Entweder direkt über den Button freischalten oder unter
          <Link to={ROUTES.plans} className="textlink" color={colors.green.c500}>
            Abonnieren
          </Link>{' '}
          den Code "KAIFU" eingeben.
        </li>
        <li>
          <h5>{TEXT.kaifuHowTo.title_3}</h5>
          {TEXT.kaifuHowTo.text_3}
          <small className="small-explainer mt-3">{TEXT.kaifuHowTo.info}</small>
        </li>
      </ul>
    </div>
  );
}
