import { requestApi } from 'utils';

export const cooperationPartnerAuthCodeFlowApiRequest = ({ b2bClientKey, authorizationCode }) =>
  requestApi.mutate(
    `
  mutation CooperationPartnerAuthCodeFlow($b2bClientKey: String!, $authorizationCode: String!) {
    cooperationPartnerAuthCodeFlow(b2bClientKey: $b2bClientKey, authorizationCode: $authorizationCode) 
  }
`,
    {
      b2bClientKey,
      authorizationCode
    }
  );
