import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LibraryNavItem = ({ title, type, slug }) => {
  return (
    <li className={'library-nav-item library-nav-item--' + type}>
      <Link to={slug ? `/category/${slug}` : '/bibliothek'}>{title}</Link>
    </li>
  );
};

LibraryNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default LibraryNavItem;
