import styled from 'styled-components';
import { colors } from 'components/Theme';
import { asRem } from 'utils';

export default styled.div`
  .implicit-acceptance {
    color: ${colors.blueGrey.c500};
    text-align: left !important;
  }

  .mime-elements-button {
    width: 100%;
    margin-bottom: ${asRem(32)};
  }

  .btn-google {
    & span,
    & {
      font-family: Poppins-SemiBold, sans-serif;
      font-size: ${asRem(16)};
      line-height: ${asRem(20)};
      letter-spacing: ${asRem(0.3)};
      text-transform: uppercase;
      white-space: nowrap;
    }

    & span {
      color: ${colors.grey.c800} !important;
    }
  }

  .fb-error {
    margin-bottom: ${asRem(16)};
  }
`;
