export const AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST = 'AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST';
export const AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS = 'AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS';
export const AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE = 'AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE';

export const authenticateInsuranceCooperationUserRequest = ({ insuranceNumber, token }) => ({
  type: AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST,
  payload: {
    insuranceNumber,
    token
  }
});

export const authenticateInsuranceCooperationUserSuccess = ({ token, user }) => ({
  type: AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS,
  payload: {
    token,
    user
  }
});

export const authenticateInsuranceCooperationUserFailure = (error) => ({
  type: AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE,
  error
});
