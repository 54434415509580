import React from 'react';
import { Col, Row } from 'Common';

export const ImageTextRow = ({ children }) => {
  return (
    <Row className="image-paragraph-row">
      {React.Children.map(children, (child, index) => (
        <Col key={`col-element-${index}`} className="p-0 ps-4 pe-4 ps-lg-4 pe-lg-4" xs="12" lg="4">
          {child}
        </Col>
      ))}
    </Row>
  );
};
