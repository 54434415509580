import React from 'react';
import { TEXT } from 'locales/translations';
import { PrivacyPolicyLink, TermsOfServiceLink } from 'components';
import { Paragraph } from 'Common';

export const PrivacyPolicyHint = () => (
  <Paragraph className="extra-small">
    {TEXT.formsGeneral.acceptText}
    <TermsOfServiceLink className="legal-link-box" buttonText={TEXT.formsGeneral.buttonTextTermsOfUse} color="green" />
    <span> </span>
    {TEXT.formsGeneral.andText}
    <PrivacyPolicyLink className="legal-link-box" buttonText={TEXT.formsGeneral.buttonTextPrivacyPolicy} color="green" />
  </Paragraph>
);
