export const SUBMIT_CHECKOUT_REQUEST = 'SUBMIT_CHECKOUT_REQUEST';
export const SUBMIT_CHECKOUT_SUCCESS = 'SUBMIT_CHECKOUT_SUCCESS';
export const SUBMIT_CHECKOUT_FAILURE = 'SUBMIT_CHECKOUT_FAILURE';

export const submitCheckoutRequest = () => ({
  type: SUBMIT_CHECKOUT_REQUEST
});

export const submitCheckoutSuccess = ({ userAutoAssigned, selectedSubscriptionPlan }) => ({
  type: SUBMIT_CHECKOUT_SUCCESS,
  payload: {
    selectedSubscriptionPlan,
    userAutoAssigned
  }
});

export const submitCheckoutFailure = (error) => ({
  type: SUBMIT_CHECKOUT_FAILURE,
  error
});
