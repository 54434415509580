import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'Component/Menu/Menu';
import { AccountMenu } from 'Component/AccountMenu/AccountMenu';
import { MenuLink } from 'Component/MenuLink/MenuLink';
import { cooperationRoutes, cooperationRoutesGreen, hiddenMenuLinksRoutes, hiddenMenuRoutes, ROUTES, tintedRoutes } from 'utils/constants';
import { hasSubscription, isAuthed, isGuest } from 'redux/query';
import { CooperationLogo } from 'Common/CooperationLogo/CooperationLogo';
import { TEXT } from 'locales/translations';
import { compareRouteWithPaths } from 'utils';

export const Navigation = (props) => {
  const location = useLocation();
  const showSubscriptionLink = !useSelector(hasSubscription);
  const authed = useSelector(isAuthed);
  const guest = useSelector(isGuest);

  const [isOpen, setIsOpen] = React.useState(false);

  const compareRouteWithPath = (route) => compareRouteWithPaths(location.pathname, route);

  const isTinted = useMemo(() => tintedRoutes.some(compareRouteWithPath), [location]);
  const isMenuHidden = useMemo(() => hiddenMenuRoutes.some(compareRouteWithPath), [location]);
  const isMenuLinksHidden = useMemo(() => hiddenMenuLinksRoutes.some(compareRouteWithPath), [location]);
  const isCooperation = useMemo(() => cooperationRoutes.some(compareRouteWithPath), [location]);
  const isCooperationGreen = useMemo(() => cooperationRoutesGreen.some(compareRouteWithPath), [location]);

  const menuLinkProps = {
    tag: Link,
    location
  };

  // We currently want the underline in the menu for /login AND /signup to be under "Anmelden". This
  // workaround helps. If you find a better solution for this feel free to change it.
  const menuAuthenticationLinkProps = {
    tag: Link,
    location: location.pathname === '/login' ? { pathname: '/signup' } : location,
    to: ROUTES.signup
  };

  const handleOnCloseMenu = () => {
    setIsOpen(false);
  };

  return isMenuHidden ? null : (
    <Menu
      tinted={isTinted}
      className={[isTinted && 'background-white', isCooperation && 'background-blue', isCooperationGreen && 'background-green']
        .filter((c) => !!c)
        .join(' ')}
      cooperationLogo={isCooperation && <CooperationLogo />}
      open={isOpen}
      onToggleNavbar={setIsOpen}
    >
      {!(isMenuLinksHidden || isCooperation) && (
        <>
          <MenuLink {...menuLinkProps} to={ROUTES.home}>
            {TEXT.header.menu.home}
          </MenuLink>
          <MenuLink {...menuLinkProps} to={ROUTES.krankenkasse}>
            {TEXT.header.menu.healthInsurance}
          </MenuLink>
          <MenuLink {...menuLinkProps} to={ROUTES.company}>
            {TEXT.header.menu.company}
          </MenuLink>
          <MenuLink {...menuLinkProps} to={ROUTES.library} activeAvailableRoutes={[ROUTES.category, ROUTES.play]}>
            {TEXT.header.menu.library}
          </MenuLink>
          {showSubscriptionLink && (
            <MenuLink {...menuLinkProps} to={ROUTES.plans} activeAvailableRoutes={[ROUTES.checkout]}>
              {TEXT.header.menu.plans}
            </MenuLink>
          )}
          {authed && !guest ? (
            <AccountMenu closeMenu={handleOnCloseMenu} />
          ) : (
            <MenuLink {...menuAuthenticationLinkProps}>{TEXT.header.menu.login}</MenuLink>
          )}
        </>
      )}
    </Menu>
  );
};
