import { registerGuestUserFailure, registerGuestUserSuccess } from 'redux/actions';
import { put } from 'redux-saga/effects';
import { registerGuestUserApiRequest } from 'api/balloon';

export function* registerUserGuestSaga() {
  try {
    const response = (yield registerGuestUserApiRequest()).data;
    if (response.errors) {
      yield put(registerGuestUserFailure(response.errors));
      return;
    }

    yield put(
      registerGuestUserSuccess({
        user: response.data.createUserGuest.user,
        token: response.data.createUserGuest.token
      })
    );
  } catch (error) {
    yield put(registerGuestUserFailure(error));
  }
}
