import { all, put } from 'redux-saga/effects';
import {
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  getSubscriptionPlansRequest,
  addFlash,
  clearSubscriptionPlans
} from 'redux/actions';
import { getSubscriptionPlansErrorMessage } from 'redux/query';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { getSubscriptionPlansApiRequest } from 'api/balloon';
import { SubscriptionPlan } from 'domain/enitity';

export function* getSubscriptionPlansSaga({ payload }) {
  const { group, coupon } = payload;
  try {
    yield put(clearSubscriptionPlans());
    const { data: response } = yield getSubscriptionPlansApiRequest(group, coupon);

    if (response.errors) {
      if (coupon) {
        const errmsg = getSubscriptionPlansErrorMessage(response.errors);

        yield all([
          put(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL)),
          put(addFlash(errmsg, 'danger', response.errors[0].message))
        ]);
      }
      yield put(getSubscriptionPlansFailure(response.errors));
      return;
    }
    yield put(
      getSubscriptionPlansSuccess({
        plans: response.data.webappSubscriptionPlans.map((plan) => new SubscriptionPlan(plan))
      })
    );
  } catch (error) {
    yield put(getSubscriptionPlansFailure(error));
  }
}
