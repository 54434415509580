import { CHANGE_REFERER_AFTER_SUCCESS_AUTHENTICATION, CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION } from 'redux/actions/actionTypes';

export const changeRefererAfterSuccessAuthentication = ({ pathname }) => ({
  type: CHANGE_REFERER_AFTER_SUCCESS_AUTHENTICATION,
  payload: {
    pathname
  }
});

export const clearRefererAfterSuccessAuthentication = () => ({
  type: CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION
});
