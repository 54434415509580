import { CONFIG } from 'config';

export default function loadGoogleAccountSdk(callbackFunction) {
  return new Promise((resolve, reject) => {
    // check if script is already loaded
    if (document.getElementById('google-account-sdk')) {
      resolve(true);
      return;
    }

    // load script
    const result = initializeGoogleLoginClient(callbackFunction);

    if (result instanceof Error) {
      reject(result);
    } else {
      resolve(true);
    }
  });
}

const initializeGoogleLoginClient = (callbackFunction) => {
  let retry = 0;
  const googleJs = document.createElement('script');
  googleJs.src = 'https://accounts.google.com/gsi/client';
  googleJs.async = true;
  googleJs.setAttribute('data-cmp-ab', 2);
  googleJs.setAttribute('id', 'google-account-sdk');
  googleJs.onload = () => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: CONFIG.GOOGLE_SIGN_IN_ID,
        callback: callbackFunction
      });
      return true;
    } else {
      retry++;
      if (retry < 3) {
        document.getElementById('google-account-sdk')?.remove();
        initializeGoogleLoginClient(callbackFunction);
      } else {
        return new Error('Google login failed to load after 3 retries');
      }
    }
  };

  googleJs.onerror = () => {
    document.getElementById('google-account-sdk')?.remove();
    return new Error('Google login failed to load script');
  };

  document.body && document.body.appendChild(googleJs);
};
