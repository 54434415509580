export const ADD_FLASH = 'ADD_FLASH';
export const REMOVE_FLASH = 'REMOVE_FLASH';
export const CLEAR_FLASHES = 'CLEAR_FLASHES';

export const addFlash = (message, alertType = 'info', errorCode = null) => ({
  type: ADD_FLASH,
  payload: {
    message,
    alertType,
    errorCode
  }
});

export const removeFlash = (index) => ({
  type: REMOVE_FLASH,
  index
});

export const clearFlashes = (index) => ({
  type: CLEAR_FLASHES
});
