import React, { Component } from 'react';
import { ROUTES } from 'utils/constants';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { TEXT } from 'locales/translations';

import birdImg from 'images/thank_you/angry_bird.png';

class UpgradeError extends Component {
  render() {
    return (
      <section className="main-decorated payment-error">
        <Container>
          <Row>
            <Col
              xs={{ size: '12', offset: '0' }}
              sm={{ size: '10', offset: '1' }}
              md={{ size: '8', offset: '2' }}
              lg={{ size: '6', offset: '3' }}
              className="card payment-error__card d-block text-center mt-6 mb-0 mb-sm-7 my-md-8"
            >
              <h2 className="text-pink-c500 mb-3 mb-sm-2 mb-0">{TEXT.upgradeError.title}</h2>
              <p className="text-grey-c600 px-2 px-md-5">{TEXT.upgradeError.copy}</p>
              <img className="mx-auto d-block" src={birdImg} alt="Aufgeregter Vogel" />
              <Link to={ROUTES.upgrade} className="btn btn-pink-c500 text-white d-sm-inline-block">
                {TEXT.upgradeError.btn}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UpgradeError;
