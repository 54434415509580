import { getToken } from 'redux/query';
import { select, put } from 'redux-saga/effects';
import { requestApi } from 'utils';
import { userSuccessfullyAuthenticated } from 'redux/actions';

export function* setApiTokenSaga() {
  const token = yield select(getToken);
  requestApi.setAuthToken(token);
  yield put(userSuccessfullyAuthenticated());
}
