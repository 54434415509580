/// this file contains a lots of query methods, this must be refactored to change the used and keep the reducer clean

/** @deprecated refactor to prevent to many bool toggles */
export const getUsersError = (state) => state.entities.users.error;

/** @deprecated refactor to prevent to many bool toggles */
export const isGoogleMailAlreadyExistError = (state) => state.entities.users.isGoogleMailAlreadyExistError;

/** @deprecated refactor to prevent to many bool toggles */
export const isGoogleMailAlreadyExistErrorSocial = (state) => state.entities.users.isGoogleMailAlreadyExistErrorSocial;

/** @deprecated refactor to prevent to many bool toggles */
export const isAppleMailAlreadyExistErrorSocial = (state) => state.entities.users.isAppleMailAlreadyExistErrorSocial;

/** @deprecated refactor to prevent to many bool toggles */
export const isAppleTokenError = (state) => state.entities.users.appleError;

/** @deprecated refactor to prevent to many bool toggles */
export const isGoogleMailError = (state) => state.entities.users.googleRegisterErrorMail;

/** @deprecated refactor to prevent to many bool toggles */
export const isPasswordAuthExpected = (state) => state.entities.users.passwordAuthExpected;

/** @deprecated refactor to prevent to many bool toggles */
export const isRegisterError = (state) => state.entities.users.isRegisterError;
