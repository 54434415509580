import React, { Component } from 'react';

class PaymentOptions extends Component {
  render() {
    return (
      <div className="payment-options">
        <h1 className="profile-subheading">Deine Zahlungsmittel</h1>
      </div>
    );
  }
}

export default PaymentOptions;
