import * as types from 'redux/actions/actionTypes';

const initialState = {
  isFetching: false,
  error: false
};

export default function withdrawCancelationSubscription(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: false
      };
    }
    case types.CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false
      };
    }
    case types.CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: true
      };
    }

    default:
      return state;
  }
}
export const isFetchingWithdrawCancelation = (state) => state && state.isFetching;
