/* eslint-disable array-callback-return */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import InitialItem from './InitialItem';
import AppAdTile from './AppAdTile';
import CouponAdTile from './CouponAdTile';
import CategoryList from './CategoryList';
import NewContent from './NewContent';
import { TEXT } from 'locales/translations';

const Library = ({ categories, entryContent, hasSubscription, entryContentButtonText, newContent }) => {
  return (
    <>
      <main className="main-decorated library">
        <Container>
          <Row className="flex-column flex-lg-row">
            <Col
              xs={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              lg={{ size: 3, offset: 0 }}
              className="fixed-top library-nav-col"
            >
              <CategoryList categories={categories} className="library-nav" />
            </Col>

            <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 9, offset: 0 }} className="library-content-col">
              <InitialItem
                typename="Kurs"
                units={`${entryContent.unitsCount} ${TEXT.library.units} |` || ''}
                time={Math.round(entryContent.length / 60) + ' Min'}
                title={entryContent.title}
                description={entryContent.description}
                type="course"
                buttonText={entryContentButtonText}
                color={entryContent.backgroundColor}
                illustration={entryContent.pictureV2}
                slug={entryContent.slug}
              />
              <NewContent newContent={newContent} hasSubscription={hasSubscription} />
              {!hasSubscription && <AppAdTile />}
              <CouponAdTile />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

Library.propTypes = {
  categories: PropTypes.array.isRequired,
  entryContent: PropTypes.object.isRequired,
  newContent: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  entryContentButtonText: PropTypes.string.isRequired
};

export default Library;
