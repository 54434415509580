import React from 'react';
import PropTypes from 'prop-types';
import Brandbar1x from 'images/landingpages/kaifu/brandbar@1x.png';
import Brandbar2x from 'images/landingpages/kaifu/brandbar@2x.png';
import Brandbar3x from 'images/landingpages/kaifu/brandbar@3x.png';

BrandBar.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.string
};

export default function BrandBar({ className, sizes }) {
  return (
    <img
      className={className}
      srcSet={`${Brandbar1x} 200w, ${Brandbar2x} 400w, ${Brandbar3x} 600w`}
      sizes={sizes}
      src={Brandbar1x}
      alt="Kaifu Logo, Balloon Logo Kooperation"
    />
  );
}
