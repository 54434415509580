import React from 'react';
import PropTypes from 'prop-types';

import blueRandTop from 'images/landingpages/bkk/blue-rand-oben.svg';

const LandingpageTopBlueLine = ({ severalClass }) => {
  return (
    <div className={`blue-line-container blue-line-container__${severalClass}`}>
      <div className="blue-line">
        <img src={blueRandTop} alt="" />
      </div>
    </div>
  );
};

LandingpageTopBlueLine.propTypes = {
  severalClass: PropTypes.string
};

export default LandingpageTopBlueLine;
