import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import { Section } from 'Container';

import backgroundCircleDownLeft from 'images/background_circle_down_left.svg';
import backgroundCircleTopRight from 'images/background_circle_up_right.svg';

export default styled(Section)`
  color: ${colors.blueGrey.c500};

  @media (min-width: ${breakpoints.md}) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }

    &::after {
      background-image: url(${backgroundCircleTopRight});
      aspect-ratio: 201/217;
      width: ${asRem(201)};
      top: ${asRem(-42)};
      right: ${asRem(-12)};
    }

    &::before {
      background-image: url(${backgroundCircleDownLeft});
      aspect-ratio: 338/330;
      width: ${asRem(338)};
      bottom: ${asRem(-50)};
      left: ${asRem(-80)};
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    background-size: 30%;
    background-position-y: 15%;
  }

  @media (max-width: ${breakpoints.sm}) {
    background-size: 32%;
    background: none;
  }

  .btn {
    min-width: ${asRem(303)};
  }

  .header-image {
    margin-top: ${asRem(23)};
    z-index: 0;
    aspect-ratio: 459 / 330;
    height: ${asRem(330)};
    
    @media (min-width: ${breakpoints.md}) {
      margin-top: ${asRem(60)};
      margin-left: -${asRem(100)};
      height: unset;
      width: ${asRem(459)};
    }

    @media (min-width: ${breakpoints.lg}) {
      margin-left: -${asRem(140)};
    }
  }
`;
