import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DecorativeImage } from 'components';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';

import BorisBalloonie from 'images/home/BallonieBoris@2x.png';

class BorisSection extends Component {
  render() {
    return (
      <section className="boris-section">
        <Container>
          <Row>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 12, offset: 0 }} className="boris-section__heading">
              <TextBlock heading={TEXT.borisSection.heading} textColor="greyBlue" textAlign="left" />
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ size: 8, offset: 2 }}
              md={{ size: 6, offset: 0 }}
              lg={{ size: 5, offset: 0 }}
              className="boris-section__image-wrapper"
            >
              <DecorativeImage src={BorisBalloonie} alt={TEXT.borisSection.alt} className="boris-section__portrait" />
            </Col>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 7, offset: 0 }}>
              <figure className="boris-section__quote-warpper">
                <blockquote className="boris-section__quote">{TEXT.borisSection.blockquote}</blockquote>
                <figcaption className="boris-section__caption">{TEXT.borisSection.figcaption}</figcaption>
              </figure>
              <TextBlock body={TEXT.borisSection.body} textColor="greyBlue" textAlign="left" />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default BorisSection;
