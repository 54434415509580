import React from 'react';
import PropTypes from 'prop-types';

import blueRandUnten from 'images/landingpages/bkk/blue-rand-unten.svg';

const LandingpageBottomBlueLine = ({ severalClass }) => {
  return (
    <div className={`blue-line-container blue-line-container__${severalClass}`}>
      <div className="blue-line">
        <img src={blueRandUnten} alt="" />
      </div>
    </div>
  );
};

LandingpageBottomBlueLine.propTypes = {
  severalClass: PropTypes.string
};

export default LandingpageBottomBlueLine;
