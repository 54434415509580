import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  content: []
};

export default function newContent(state = initialState, action) {
  switch (action.type) {
    case types.GET_NEW_CONTENT_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_NEW_CONTENT_SUCCESS: {
      let content = [];
      if (Object.keys(action.payload.result).length > 0) {
        content = Object.values(action.payload.result);
      }
      return {
        ...state,
        content: content,
        isFetching: false,
        error: undefined
      };
    }

    case types.GET_NEW_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state && state.isFetching;

export const getNewContent = (state) => state && state.content;
