import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TEXT } from 'locales/translations';
import { BackgroundImage, Headline, Paragraph, ScrollButton } from 'Common';
import { ResilienceHealthBenefitLink } from './ResilienceHealthBenefitLink';
import Style from './ResilienceHeader.style';
import balloonieUmbrella from 'images/landingpages/resilience/balloonie-umbrella.png';

export function ResilienceHeader() {
  return (
    <Style type="full-width" backgroundColor="white">
      <Container>
        <Row>
          <Col xs="12" md="8" lg="7">
            <Headline level={1}>{TEXT.resiliencePage.header.headline}</Headline>
            <Paragraph className="intro me-md-0 mb-md-0">{TEXT.resiliencePage.header.subline}</Paragraph>
            <ScrollButton color="pink" className="mt-3 mb-3 mt-md-3" to="section-checkout">
              {TEXT.resiliencePage.cta}
            </ScrollButton>
            <Col xs={{ size: 12 }} md={{ size: 7 }}>
              <ResilienceHealthBenefitLink classNames="mt-3 mt-md-0" />
            </Col>
          </Col>
          <Col xs="12" md="4" lg="5">
            <BackgroundImage image={balloonieUmbrella} className="header-image" />
          </Col>
        </Row>
      </Container>
    </Style>
  );
}
