import { put, select } from 'redux-saga/effects';
import { getCurrentUserId, getToken, isGuest } from 'redux/query';
import { registerGuestUserRequest, retrieveAlerts } from 'redux/actions';

export function* initializeUserSaga() {
  const state = yield select();

  const userId = yield getCurrentUserId(state);

  if (!userId) {
    yield put(registerGuestUserRequest());
  } else {
    const isUserGuest = yield isGuest(state);
    const token = yield getToken(state);

    if (token && !isUserGuest) {
      yield put(retrieveAlerts());
    }
  }
}
