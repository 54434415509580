import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselIndicators, Container, Col, Row } from 'reactstrap';

import MobileScreenMeditation from 'components/MobileScreenMeditation';
import MobileScreenExercise from 'components/MobileScreenExercise';
import MobileScreenCourse from 'components/MobileScreenCourse';

const items = [
  {
    key: 1,
    component: <MobileScreenMeditation className="mobile_showcase_section__mockup-img" />
  },
  {
    key: 2,
    component: <MobileScreenExercise className="mobile_showcase_section__mockup-img" />
  },
  {
    key: 3,
    component: <MobileScreenCourse className="mobile_showcase_section__mockup-img" />
  }
];

class AppCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.key}>
          <Container>
            <Row>
              <Col xs={{ size: 10, offset: 0 }}>{item.component}</Col>
            </Row>
          </Container>
        </CarouselItem>
      );
    });

    return (
      <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} className="app-carousel">
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} className="app-carousel__indicators" />
        {slides}
      </Carousel>
    );
  }
}

export default AppCarousel;
