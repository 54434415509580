export const SET_USER_PAYMENT_INFORMATION = 'SET_USER_PAYMENT_INFORMATION';

/**
 * @param payload
 * @param payload.email
 * @param payload.firstName
 * @param payload.lastName
 * @param payload.street
 * @param payload.city
 * @param payload.country
 * @param payload.zipCode
 * @param payload.paymentMethod
 * @param payload.paymentInformation
 * @returns {{payload: {firstName, lastName, country, zipCode, city, street, paymentMethod, paymentInformation, email}, type: string}}
 */
export const setUserPaymentInformation = (payload) => ({
  type: SET_USER_PAYMENT_INFORMATION,
  payload
});
