import styled from 'styled-components';
import { Menu, colors } from '@missionme/mime_elements';
import logoRed from 'images/navigation/balloon_logo_red.svg';
import logoWhite from 'images/navigation/balloon_logo_white.svg';

export default styled(Menu)`
  .navbar-brand,
  &.navbar-transparent.navbar-down .navbar-brand {
    background-image: url(${logoRed});
  }

  &.navbar-transparent .navbar-brand {
    background-image: url(${logoWhite});
  }

  &.background-white {
    background-color: ${colors.white.c500};
  }

  &.background-blue,
  &.background-blue.navbar-transparent.navbar-down {
    background-color: ${colors.blue.c500};
  }

  &.background-green,
  &.background-green.navbar-transparent.navbar-down {
    background-color: ${colors.green.c500};
  }

  &.background-blue,
  &.background-green {
    &.navbar-transparent.navbar-down .navbar-brand {
      background-image: url(${logoWhite});
    }
  }
`;
