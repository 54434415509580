import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
// eslint-disable-next-line no-unused-vars
import * as api from 'api/balloon/single';

import { GET_SINGLE_PENDING, GET_SINGLE_FAILURE, GET_SINGLE_SUCCESS } from 'redux/actions/actionTypes';

export const gettingSingle = () => ({
  type: GET_SINGLE_PENDING
});

export const getSingleFailure = (error) => ({
  type: GET_SINGLE_FAILURE,
  error
});

export const getSingleSuccess = (payload) => ({
  type: GET_SINGLE_SUCCESS,
  payload
});

export const getSingleBySlug =
  (slug) =>
  (dispatch, _, { api }) => {
    dispatch(gettingSingle());
    return api.getSingle(slug.trim()).then(
      (response) => {
        if (response.data.errors) {
          dispatch(getSingleFailure(response.data.errors));
        } else {
          dispatch(
            getSingleSuccess(
              normalize(response.data.data.singles[0], {
                single: schema.single
              })
            )
          );
        }
      },
      (error) => {
        handleAxiosError(error, () => {
          dispatch(getSingleFailure(error));
        });
      }
    );
  };
