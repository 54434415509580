import React from 'react';
import PropTypes from 'prop-types';
import { altAttribute } from 'utils/imageHelpers';

DecorativeImage.propTypes = {
  src: PropTypes.node.isRequired,
  className: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string
};

// Decorative images do specify an empty alt-tag
// https://www.w3.org/WAI/tutorials/images/decorative/

/** @deprecated use Image from Commons instead */
export default function DecorativeImage({ alt, ...others }) {
  let altAttr = alt ? alt : altAttribute(others.src);

  return <img {...others} alt={altAttr} title={altAttr} />;
}
