import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components';
import { couponValidLength } from 'utils/validators';

GiftCouponField.propTypes = {
  showLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  showRequiredHint: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired
};

GiftCouponField.defaultProps = {
  disabled: false,
  showRequiredHint: true
};

export default function GiftCouponField({ defaultValue, disabled, showRequiredHint, placeholder, messages, id }) {
  return (
    <TextField
      id={id}
      messages={messages}
      defaultValue={defaultValue}
      disabled={disabled}
      model=".code"
      placeholder={placeholder}
      required
      showRequiredHint={showRequiredHint}
      validators={{
        validLength: (val) => couponValidLength(val)
      }}
    />
  );
}
