import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components/index';

export default function DateField({
  showLabel,
  defaultValue,
  disabled,
  label,
  showRequiredHint,
  handleChange,
  fieldId,
  model,
  placeholder
}) {
  return (
    <TextField
      type={'date'}
      id={fieldId}
      label={showLabel ? label || 'Wunschdatum Kündigung' : null}
      showRequiredHint={showRequiredHint}
      defaultValue={defaultValue}
      disabled={disabled}
      model={model}
      placeholder={placeholder}
      required={false}
      handleChange={handleChange}
    />
  );
}

DateField.defaultProps = {
  showLabel: false,
  defaultValue: '',
  disabled: false,
  label: null,
  handleChange: () => null,
  fieldId: 'user.requested_cancellation_at',
  model: '.requested_cancellation_at',
  placeholder: 'Wunschdatum Kündigung'
};

DateField.propTypes = {
  showLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  showRequiredHint: PropTypes.bool,
  handleChange: PropTypes.func,
  fieldId: PropTypes.string,
  placeholder: PropTypes.string,
  model: PropTypes.string
};
