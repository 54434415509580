import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const dateFormat = 'DD.MM.YYYY HH:mm';

export const DateTimePresenter = ({ date }) => <span>{moment(date).format(dateFormat)}</span>;

DateTimePresenter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date), PropTypes.string]).isRequired
};
