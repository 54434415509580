import React from 'react';
import PropTypes from 'prop-types';
import { subscriptionPlan } from 'utils/propTypes';

CouponExplainer.propTypes = {
  className: PropTypes.string,
  plan: subscriptionPlan
};

const couponText = (plan) => {
  if (plan.interval === 'ONETIME') {
    return undefined;
  }
  if (plan && plan.coupon) {
    switch (plan.coupon.duration) {
      case 'ONCE':
        return 'einmalig, danach normaler Preis';
      case 'REPEATING':
        return 'für die ersten ' + plan.coupon.durationInMonths + ' Monate, danach normaler Preis';
      case 'FOREVER':
      default:
        return undefined;
    }
  }
};

const couponExplainer = (plan) => {
  const couponHint = couponText(plan);

  return couponHint ? '(' + couponHint + ')' : undefined;
};

export default function CouponExplainer({ className, plan }) {
  return <span className={className}>{couponExplainer(plan)}</span>;
}
