import React, { useEffect } from 'react';
import { TEXT } from 'locales/translations';
import { Col, Row, Container } from 'reactstrap';
import { DecorativeImage } from 'components';
import { AppStoreBadge, GooglePlayBadge } from 'components';

import RatingBalloonie from 'images/landingpages/health_insurance_marketing/rating_balloonie.png';
import { useDispatch } from 'react-redux';
import { getStoreInfosRequest } from 'redux/actions';
import { getStoreInfos } from 'redux/query';
import { useSelector } from 'react-redux';

import { RatingStars } from './RatingStars';

export const RatingSection = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStoreInfosRequest());
  }, []);

  const { appStore, playStore } = useSelector(getStoreInfos);

  const appStoreReviewCount = appStore.reviewCount;

  const playStoreReviewCount = playStore.reviewCount;

  const appStoreRating = appStore.rating.toFixed(1);

  const playStoreRating = playStore.rating.toFixed(1);

  return (
    <section className="rating-marketing">
      <Container>
        <Row>
          <Col
            xs={{ size: 12, order: 2 }}
            md={{ size: 8, order: 1 }}
            lg={{ size: 7 }}
            xl={{ size: 6 }}
            className="rating-marketing__content-container"
          >
            <h2>{TEXT.healthMarketingPage.ratingSection.headline}</h2>
            <p>{TEXT.healthMarketingPage.ratingSection.copy}</p>

            <div className="rating-marketing__store-section">
              <div className="rating-marketing__rating-container">
                <div className="rating-marketing__app-badge-row">
                  <h1 className="rating-marketing__number">{appStoreRating}</h1>
                  <AppStoreBadge />
                </div>

                <div className="rating-marketing__distance-wrapper">
                  <RatingStars rating={appStoreRating} />
                </div>

                <p className="rating-marketing__font-small">
                  {appStoreReviewCount}
                  {TEXT.healthMarketingPage.ratingSection.ratingCount}
                </p>
              </div>

              <div className="rating-marketing__rating-container">
                <div className="rating-marketing__app-badge-row">
                  <h1 className="rating-marketing__number">{playStoreRating}</h1>
                  <GooglePlayBadge />
                </div>
                <div className="rating-marketing__distance-wrapper">
                  <RatingStars rating={playStoreRating} />
                </div>
                <p className="rating-marketing__font-small">
                  {playStoreReviewCount}
                  {TEXT.healthMarketingPage.ratingSection.ratingCount}
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={{ size: 12, order: 1 }}
            md={{ size: 4, order: 2 }}
            lg={{ size: 4 }}
            xl={{ size: 5 }}
            className="rating-marketing__img-container"
          >
            <DecorativeImage src={RatingBalloonie} alt={TEXT.healthMarketingPage.ratingSection.altText} className="rating-marketing__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
