import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'Common';
import { connect } from 'react-redux';
import { getCurrentUserEmail, hasGiftCouponError, isGiftCouponAlreadyApplied, isGiftCouponCodeExpired } from 'redux/reducers';
import { getToken } from 'redux/query';
import { addFlash, clearFlashes, redeemGiftCoupon } from 'redux/actions';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';

import { Form } from 'react-redux-form';
import { GiftCouponField } from 'components';

import { withUser } from 'containers/withUser';
import withSpinner from 'components/withSpinner';
import BkkTermsField from './BkkTermsField';

class VoucherCodeEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: 0
    };
  }

  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  handleSubmit = (giftCoupon) => {
    this.props.redeemGiftCoupon({ code: giftCoupon.code, showFlashError: false });
  };

  render() {
    const { hasError, headline, requiredTerms, isAlreadyApplied, codeExpired } = this.props;
    return (
      <div className="code-entry">
        <ThemeContainer theme={themeUniversal}>
          <Row>
            <Col>
              <h2 className="profile-subheading">Zugangs-Code eingeben</h2>
              <div className="pb-4 text-center">{headline}</div>
            </Col>
          </Row>
          <Form className="giftcoupon-form" model="entities.forms.giftCoupon" hideNativeErrors onSubmit={this.handleSubmit}>
            <Row className="pb-4 text-center ms-0 me-0">
              <Col>
                <GiftCouponField
                  showRequiredHint={false}
                  id="giftcouponcode"
                  placeholder="Zugangs-Code"
                  messages={{
                    validLength: 'Sicher wird der Zugangs-Code nicht soo lang sein? 😉',
                    valueMissing: 'Bitte gib den Zugangs-Code ein.'
                  }}
                />
                {hasError && (
                  <Fragment>
                    {isAlreadyApplied ? (
                      <span className="fb-error">Dieser Inhalt ist für dich bereits freigeschaltet.</span>
                    ) : (
                      <>
                        {codeExpired ? (
                          <span className="fb-error">Dieser Code ist leider nicht mehr gültig! Bitte überprüfe deine Eingabe.</span>
                        ) : (
                          <span className="fb-error">Dieser Code ist leider nicht gültig! Bitte überprüfe deine Eingabe.</span>
                        )}
                      </>
                    )}
                  </Fragment>
                )}
              </Col>
            </Row>
            {requiredTerms && (
              <Row className="pb-4 text-center ms-0 me-0 mt-1">
                <Col>
                  <BkkTermsField />
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mb-4 code-entry-btn" dataCyName="voucher-submit-button">
                  Code einlösen
                </Button>
              </Col>
            </Row>
          </Form>
        </ThemeContainer>
      </div>
    );
  }
}

VoucherCodeEntry.propTypes = {
  addFlash: PropTypes.func.isRequired,
  clearFlashes: PropTypes.func.isRequired,
  redeemGiftCoupon: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  isAlreadyApplied: PropTypes.bool.isRequired,
  codeExpired: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  requiredTerms: PropTypes.bool.isRequired
};

VoucherCodeEntry.defaultProps = {
  hasError: false,
  isAlreadyApplied: false,
  email: '',
  requiredTerms: true
};

const mapStateToProps = (state) => {
  return {
    email: getCurrentUserEmail(state),
    hasError: hasGiftCouponError(state),
    isAlreadyApplied: isGiftCouponAlreadyApplied(state),
    codeExpired: isGiftCouponCodeExpired(state),
    token: getToken(state)
  };
};

export default withUser(
  withSpinner(
    connect(mapStateToProps, {
      clearFlashes,
      addFlash,
      redeemGiftCoupon
    })(VoucherCodeEntry)
  )
);
