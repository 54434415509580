export const STRIPE_SETUP_INTENT_REQUEST = 'STRIPE_SETUP_INTENT_REQUEST';
export const STRIPE_SETUP_INTENT_SUCCESS = 'STRIPE_SETUP_INTENT_SUCCESS';
export const STRIPE_SETUP_INTENT_FAILURE = 'STRIPE_SETUP_INTENT_FAILURE';

export const stripeSetupIntentRequest = () => ({
  type: STRIPE_SETUP_INTENT_REQUEST
});

export const stripeSetupIntentSuccess = (payload) => ({
  type: STRIPE_SETUP_INTENT_SUCCESS,
  payload
});

export const stripeSetupIntentFailure = (error) => ({
  type: STRIPE_SETUP_INTENT_FAILURE,
  error
});
