import { toCamelCase } from './toCamelCase';

const changeKeys = function changeKeys(transformer, obj) {
  let objectKeys;

  if (Array.isArray(obj)) {
    return obj.map(function keysMap(key) {
      // Commented out intentionally (and left here to see the different to the original code)
      // if (typeof key === 'string') {
      //   return transformer(key);
      // }

      return changeKeys(transformer, key);
    });
  } else if (typeof obj === 'object' && obj !== null) {
    objectKeys = Object.keys(obj);
    return objectKeys
      .map(function keysMap(key) {
        return transformer(key);
      })
      .reduce(function keysReducer(object, changedKey, index) {
        var objValue;
        var transformedValue;

        objValue = obj[objectKeys[index]];
        transformedValue = changeKeys(transformer, objValue);
        object[changedKey] = transformedValue;
        return object;
      }, {});
  }

  return obj;
};

export const changeCaseObject = {};
changeCaseObject.camel = changeCaseObject.camelCase = function camelCaseObject(obj) {
  if (typeof obj === 'string') {
    return toCamelCase(obj);
  }

  return changeKeys(toCamelCase, obj);
};
