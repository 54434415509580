import { InsuranceCooperationAuthenticationContext } from '../InsuranceCooperationAuthenticationContext';
import { useContext } from 'react';

export const SuccessContainer = ({ children }) => {
  const { allowFormSubmit } = useContext(InsuranceCooperationAuthenticationContext);
  if (!allowFormSubmit) {
    return null;
  }

  return <>{children}</>;
};
