import React, { Component, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'Common';
import TutorialVideo from 'images/home/tutorial.mp4';
import { TEXT } from 'locales/translations';

class VideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.closeModalAfterVideoPlayed = this.closeModalAfterVideoPlayed.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  closeModalAfterVideoPlayed() {
    const video = document.getElementById('tutorial-video');
    video.addEventListener('ended', () => {
      this.setState({ modal: false });
    });
  }

  render() {
    return (
      <Fragment>
        <Button className="w-100" type="inverted" onClick={this.toggle}>
          {TEXT.videoSection.link}
        </Button>
        <Modal className="video-modal" isOpen={this.state.modal} toggle={this.toggle} onOpened={this.closeModalAfterVideoPlayed}>
          <ModalBody>
            <Button type="transparent" className="video-modal__close-btn" onClick={this.toggle}>
              <span aria-hidden="true">×</span>
            </Button>
            <video id="tutorial-video" className="video" autoPlay>
              <source src={TutorialVideo} />
            </video>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default VideoModal;
