import React from 'react';
import PropTypes from 'prop-types';

const LandingpageBallooni = ({ imageClass, description, dataCyName }) => {
  return (
    <section className="ballooni-container" data-cy-name={dataCyName}>
      <div className={imageClass} />
      <p>{description}</p>
    </section>
  );
};

LandingpageBallooni.propTypes = {
  imageClass: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dataCyName: PropTypes.string
};

export default LandingpageBallooni;
