import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Button, Link, Paragraph } from 'Common';
import CodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUserEmail } from 'redux/reducers';
import { addFlash, clearFlashes, requestOnetimePassword, signInUserWithOnetimePassword } from 'redux/actions';
import { isLoginError } from 'redux/query';
import { TEXT } from 'locales/translations';
import { MainPage } from 'Container';
import { FlashListContainer } from 'containers';
import { ROUTES } from 'utils/constants';

class SignInUserWithOnetimePasswordBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.match.params.code
    };
  }

  componentWillUnmount() {
    const { clearFlashes } = this.props;
    clearFlashes();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;

    const { signInUserWithOnetimePassword, email } = this.props;
    signInUserWithOnetimePassword({ email, token: code });
  }

  setCode(code) {
    this.setState({ code: code });
  }

  handleResendCode(e) {
    e.preventDefault();
    const { requestOnetimePassword, email, addFlash } = this.props;

    requestOnetimePassword(email, false);

    addFlash(`Der Code wurde an die E-Mail ${email} geschickt`, 'success');
  }

  render() {
    const { email } = this.props;

    const { code } = this.state;
    return (
      <MainPage title={TEXT.registration.title} bodyClassName="main-decorated" className="sign-in-user-with-otp" id="sign-in-user-with-otp">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
            <Container>
              <Row>
                <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                  <div className="code-entry card-email card mb-5 pb-5">
                    <Row>
                      <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
                        <FlashListContainer />
                      </Col>
                      <Col className="card-header">
                        <h2 className="card-title mb-3">E-Mail bestätigen</h2>
                        <div className="pb-4 text-center newsletter-disclaimer">
                          Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an {email} verschickt, wenn du
                          bereits registriert bist. Andernfalls registriere dich bitte <Link to={ROUTES.signup}>hier.</Link>
                        </div>
                      </Col>
                    </Row>
                    <Fragment>
                      <form
                        className="sign-in-user-with-otp-form text-center"
                        onSubmit={this.handleSubmit.bind(this)}
                        id="user-with-otp__form"
                      >
                        <CodeInput
                          className="code-field"
                          type="number"
                          fields={6}
                          value={code}
                          onChange={this.setCode.bind(this)}
                          inputStyle={{
                            margin: '4px',
                            width: '40px',
                            borderRadius: '6px',
                            fontSize: '20px',
                            height: '53px',
                            color: '#717171',
                            textAlign: 'center',
                            border: this.props.loginError ? '1px solid red' : '1px solid #ededed',
                            backgroundColor: '#ededed'
                          }}
                        />
                        {this.props.loginError && <span className="fb-error">Code ist nicht gültig</span>}
                        <Button className="sign-in-user-otp-btn mt-4" dataCyName="otp-submit-button" id="sign-in-user-otp-btn">
                          Code Abschicken
                        </Button>
                      </form>
                      <div className="card-footer">
                        <Paragraph className="small-bold text-center">
                          <Link className="text-uppercase" color="green" onClick={this.handleResendCode.bind(this)}>
                            Neuen Code anfordern
                          </Link>
                        </Paragraph>
                      </div>
                    </Fragment>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </MainPage>
    );
  }
}

SignInUserWithOnetimePasswordBox.propTypes = {
  signInUserWithOnetimePassword: PropTypes.func.isRequired,
  requestOnetimePassword: PropTypes.func.isRequired,
  addFlash: PropTypes.func.isRequired,
  clearFlashes: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  loginError: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

SignInUserWithOnetimePasswordBox.defaultProps = {
  loginError: false
};

const mapStateToProps = (state) => {
  return {
    email: getCurrentUserEmail(state),
    loginError: isLoginError(state)
  };
};

export default connect(mapStateToProps, {
  clearFlashes,
  addFlash,
  signInUserWithOnetimePassword,
  requestOnetimePassword
})(SignInUserWithOnetimePasswordBox);
