import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Control, Errors } from 'react-redux-form';
import TermsOfServiceLink from 'components/TermsOfServiceLink';

/**
 * @deprecated replace them with a new component without react-redux-form
 */
export default function TermsAndConditionsField() {
  return (
    <FormGroup className="pb-4 ps-0" check>
      <Control.checkbox
        component={Input}
        defaultValue={false}
        mapProps={{
          valid: ({ fieldValue: { touched, valid } }) => (touched && !valid ? false : undefined)
        }}
        model=".agreed"
        id="agreed"
        required={true}
        className="me-2"
      />
      <Label for="agreed" check className="letter-spacing-04">
        Ich stimme den <TermsOfServiceLink /> zu.
      </Label>
      <FormFeedback>
        <Errors
          model=".agreed"
          show={(field) => !field.pristine || field.touched}
          messages={{
            valueMissing: 'Du musst den AGB zustimmen.'
          }}
        />
      </FormFeedback>
    </FormGroup>
  );
}
