import styled from 'styled-components';
import { breakpoints } from 'Theme';
import { Section } from 'Container';
import { asRem } from 'utils';

export const CompanyTrustStyle = styled(Section)`
  align-items: center;

  p {
    font-size: ${asRem(16)}!important;
    @media (min-width: ${breakpoints.lg}) {
    font-size: ${asRem(18)} !important;
    }
  }

  .logo {
    aspect-ratio: 122 / 61;
    width: ${asRem(122)};
    flex-shrink: 0;

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(122)};
    }
  }
}
`;
