import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isFetchingSingle } from 'redux/reducers';
import { isAuthed, getToken } from 'redux/query';
import { getSingleBySlug } from 'redux/actions';

function WithSingle(WrappedComponent) {
  class WithSingle extends Component {
    componentDidMount() {
      const { getSingleBySlug, isAuthed } = this.props;

      if (isAuthed) {
        getSingleBySlug(this.props.match.params.slug);
      }
    }

    componentDidUpdate(prevProps) {
      const { getSingleBySlug, token, isAuthed } = this.props;
      if (isAuthed && token && prevProps.token !== token) {
        getSingleBySlug(this.props.match.params.slug);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithSingle.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    getSingleBySlug: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    isAuthed: PropTypes.bool.isRequired,
    token: PropTypes.string
  };

  return WithSingle;
}

const mapStateToProps = (state) => ({
  isFetching: isAuthed(state) && isFetchingSingle(state),
  isAuthed: isAuthed(state),
  token: getToken(state)
});

export default compose(connect(mapStateToProps, { getSingleBySlug }), WithSingle);
