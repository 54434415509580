import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LandingpageHeaderSimple from '../LandingpageHeaderSimple';
import CheckmarkBoxGothaerSection from './components/CheckmarkBoxGothaerSection';
import { TEXT } from 'locales/translations';
import LandingpageBottomBlueLine from '../LandingpageBottomBlueLine';
import BallooniSectionGothaer from './components/BallooniSectionGothaer';
import LandingpageBuyBoxSection from '../LandingpageBuyBoxSection';
import { ROUTES } from 'utils/constants';
import { getSubscriptionPlansRequest } from 'redux/actions';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { getSubscriptionPlanByGroup, isSubscriptionPlansLoading } from 'redux/query';

const GothaerLandingPage = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSubscriptionPlansLoading);
  const plan = useSelector(getSubscriptionPlanByGroup(SUBSCRIPTION_PLAN_GROUPS.GOTHAER));

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.GOTHAER));
  }, [dispatch]);

  return (
    <main className="gothaer">
      <LandingpageHeaderSimple
        headline={TEXT.gothaer.header.headline}
        subline={TEXT.gothaer.header.subline}
        buttonLink={ROUTES.gothaer}
        buttonLinkClass="btn-gothaer"
        buttonText={TEXT.gothaer.header.button}
      />
      <LandingpageBottomBlueLine />
      <CheckmarkBoxGothaerSection />
      <BallooniSectionGothaer />
      <LandingpageBuyBoxSection
        plan={plan}
        isFetching={isLoading}
        headline={TEXT.gothaer.buy_box_section.headline}
        description={TEXT.gothaer.buy_box_section.description}
        relator={TEXT.gothaer.buy_box_section.relator}
        disclaimer={TEXT.landingpages.disclaimer}
        buttonLinkClass="plan-btn-gothaer"
      />
    </main>
  );
};

export default GothaerLandingPage;
