import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TEXT } from 'locales/translations';
import { ResilienceCheckoutBox } from './';
import { getInsuranceCompaniesRequest } from 'redux/actions';
import { getInsuranceCompanies } from 'redux/query';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';
import { Headline, Image, Link, Paragraph } from 'Common';
import Style from './ResilienceCheckout.style';

import balloonieHeadphones from 'images/landingpages/resilience/balloonie-headphones.png';

export function ResilienceCheckout({ showCooperationPartner }) {
  const [hasSelectedHealthInsuranceCompany, setHasSelectedHealthInsuranceCompany] = useState(false);
  const [insuranceCompanyName, setInsuranceCompanyName] = useState([]);
  const [insuranceRefunds, setInsuranceRefunds] = useState(0);
  const [insuranceTotalRefunds, setInsuranceTotalRefunds] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInsuranceCompaniesRequest());
  }, []);

  const insuranceCompanies = useSelector(getInsuranceCompanies);

  const filteredInsuranceCompanies = useMemo(() => {
    if (showCooperationPartner) {
      return insuranceCompanies;
    } else {
      return insuranceCompanies.filter((company) => !company.cooperationPartner);
    }
  }, [insuranceCompanies, showCooperationPartner]);

  const insuranceCompaniesData = {
    default: {
      checkoutBox: <ResilienceCheckoutBox />
    }
  };

  const identifySelectedInsurance = (companyUrl) => {
    for (let key in insuranceCompaniesData) {
      if (companyUrl?.includes(key)) {
        return key;
      }
    }
    return null;
  };

  const handleOnChangeInsuranceCompanyName = (companyName) => {
    const refunds = (companyName[0].reductionRate * 75.0) / 100;
    setInsuranceCompanyName(companyName);
    setHasSelectedHealthInsuranceCompany(true);
    setInsuranceRefunds(refunds);
    setInsuranceTotalRefunds(75 - refunds);
  };

  const resetInsuranceCompanyName = () => {
    setInsuranceCompanyName([]);
    setHasSelectedHealthInsuranceCompany(false);
    setInsuranceRefunds(0);
    setInsuranceTotalRefunds(0);
  };

  const selectedInsuranceKey = identifySelectedInsurance(insuranceCompanyName[0]?.cooperationUrl);

  const insuranceData = insuranceCompaniesData[selectedInsuranceKey];

  const InsuranceInfo = ({ headline, info, resetFunction }) => (
    <>
      <Headline level={2} className="text-center text-md-right mb-5">
        {headline}
      </Headline>
      <Paragraph className="text-center text-md-right ">{info}</Paragraph>

      <Paragraph className="text-center text-md-right">{TEXT.resiliencePage.checkout.subline}</Paragraph>
      <Link className="text-center text-md-right" onClick={resetFunction}>
        {TEXT.resiliencePage.checkout.btnText}
      </Link>
    </>
  );

  return (
    <ThemeContainer theme={themeUniversal}>
      <Style type="full-width" backgroundColor="lightGrey" id="section-checkout">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 6 }} className="info-box d-flex flex-column align-items-center">
              <div className="info-content d-flex flex-column me-0 me-lg-4">
                {hasSelectedHealthInsuranceCompany ? (
                  <>
                    {insuranceData ? (
                      <InsuranceInfo
                        headline={insuranceData.headline}
                        info={insuranceData.info}
                        resetFunction={resetInsuranceCompanyName}
                      />
                    ) : (
                      <>
                        <Headline level={2} className="text-center text-md-right mb-5">
                          {TEXT.resiliencePage.checkout.headline_1}
                        </Headline>
                        <Headline level={5} className="text-center text-md-right ">
                          {insuranceCompanyName[0].name} Versicherte bekommen {insuranceCompanyName[0].reductionRate}%
                          {TEXT.resiliencePage.checkout.refundText}
                          <br />
                          {TEXT.resiliencePage.checkout.time}
                        </Headline>

                        <Paragraph className="small text-center text-md-right">{TEXT.resiliencePage.checkout.subline_1}</Paragraph>
                        {insuranceCompanyName[0].cooperationUrl && (
                          <Paragraph className="small text-center text-md-right px-2">
                            <strong>{TEXT.resiliencePage.checkout.notice}</strong> {TEXT.resiliencePage.checkout.member}
                            {insuranceCompanyName[0].name} {TEXT.resiliencePage.checkout.info}
                            <Link href={insuranceCompanyName[0].cooperationUrl}>{insuranceCompanyName[0].cooperationUrl}</Link>{' '}
                            {TEXT.resiliencePage.checkout.info_2}
                          </Paragraph>
                        )}
                        <Link className="mb-2 text-center text-md-right" onClick={resetInsuranceCompanyName}>
                          {TEXT.resiliencePage.checkout.btnText}
                        </Link>
                        <div className="content-table-check d-flex flex-column justify-content-start">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={2}>
                                  {TEXT.resiliencePage.checkout.calcBox.refund} {insuranceCompanyName[0].reductionRate}%
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{TEXT.resiliencePage.checkout.calcBox.price}</td>
                                <td className="text-right">75,00 €</td>
                              </tr>
                              <tr>
                                <td>
                                  {TEXT.resiliencePage.checkout.calcBox.refund} {insuranceCompanyName[0].reductionRate} % (nach
                                  Kurs-Absolvierung)
                                </td>
                                <td className="text-right">
                                  -
                                  {insuranceCompanyName[0].reductionRate === 100
                                    ? '75,00'
                                    : insuranceRefunds.toLocaleString('de-DE', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })}{' '}
                                  €
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>{TEXT.resiliencePage.checkout.calcBox.yourShare}</td>
                                <td className="text-right">
                                  {insuranceCompanyName[0].reductionRate === 100
                                    ? '0,00'
                                    : insuranceTotalRefunds.toLocaleString('de-DE', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      })}{' '}
                                  €
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Headline level={2} className="text-center text-md-right px-2 mb-3">
                      {TEXT.resiliencePage.checkout.headline_2}
                    </Headline>
                    <Paragraph className="text-center text-md-right">{TEXT.resiliencePage.checkout.text}</Paragraph>
                    <Paragraph className="text-center text-md-right">{TEXT.resiliencePage.checkout.subline_2}</Paragraph>
                    <Typeahead
                      maxResults={400}
                      id="basic-typeahead-single"
                      labelKey="name"
                      className="dropdown-toggle"
                      onChange={handleOnChangeInsuranceCompanyName}
                      options={filteredInsuranceCompanies}
                      placeholder="Wähle deine Krankenkasse"
                      selected={insuranceCompanyName}
                      allowNew={false}
                      paginationText="Weitere anzeigen"
                      renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
                        const items = results.map((result, index) => (
                          <MenuItem
                            key={index}
                            option={result}
                            position={index}
                            className={
                              result.cooperationPartner && results[index + 1] && !results[index + 1].cooperationPartner
                                ? 'cooperation-partner'
                                : ''
                            }
                          >
                            {result.name}
                          </MenuItem>
                        ));
                        return (
                          <Menu {...menuProps} emptyLabel="Krankenkasse nicht gefunden">
                            {items}
                          </Menu>
                        );
                      }}
                    />
                  </>
                )}
              </div>
              {(!hasSelectedHealthInsuranceCompany || insuranceData) && (
                <Image className="d-none d-md-block mt-4 w-100 px-4" src={balloonieHeadphones} alt="Balloon mit Kopfhörern" />
              )}
            </Col>
            <Col
              xs={{ size: 12 }}
              sm={{ size: 10, offset: 1 }}
              md={{ size: 6, offset: 0 }}
              xxl={{ size: 5, offset: 1 }}
              className="info-box text-center"
            >
              {insuranceCompaniesData[selectedInsuranceKey]?.checkoutBox || insuranceCompaniesData.default.checkoutBox}
            </Col>
          </Row>
        </Container>
      </Style>
    </ThemeContainer>
  );
}
