import { Link } from 'react-router-dom';
import { Paragraph } from 'Common';
import React from 'react';
import { NavLinkStyle } from './NavLink.style';
import { breakpoints } from 'Theme';
import PropTypes from 'prop-types';

export const NavLink = ({ children, to, href, offset = { sm: 0, md: 0, lg: 0 } }) => {
  const handleClick = () => {
    if (to && to.includes('#')) {
      const [_, hash] = to.split('#');
      const element = document.getElementById(hash);
      if (element) {
        const width = window.innerWidth;

        switch (true) {
          case width <= parseInt(breakpoints.sm, 10):
            break;
          case width <= parseInt(breakpoints.md, 10):
            break;
          default:
            offset = offset.lg;
            break;
        }

        const yCoordinate = element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
      }
    }
  };

  return (
    <NavLinkStyle>
      <Paragraph className="extra-small-bold mb-2 mb-md-4 w-auto text-start">
        {to ? (
          <Link className="nav-link" to={to} onClick={handleClick}>
            {children}
          </Link>
        ) : (
          <a href={href} className="nav-link" onClick={handleClick}>
            {children}
          </a>
        )}
      </Paragraph>
    </NavLinkStyle>
  );
};

NavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  href: PropTypes.string,
  offset: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number
  })
};
