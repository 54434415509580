import styled from 'styled-components';
import { breakpoints, colors } from 'Theme';
import { asRem } from 'utils';

export default styled.div`
  .headline {
    color: ${colors.green.c500};
    margin: 0 0 ${asRem(16)};
  }

  .title {
    margin-left: 0;
  }

  .checkout-text {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.sm}) {
    .float-sm-right {
      float: right !important;
    }
  }
`;
