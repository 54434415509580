import React from 'react';
import { TEXT } from 'locales/translations';
import { Button, Link } from 'Common';
import { redirectToStoreDownload } from 'utils';

const LinksSection = () => {
  return (
    <section id="links-section-container">
      <div id="content-container">
        <h2>{TEXT.podcast.links_section.headline}</h2>
        <div id="bottom-container">
          <div id="text-container">
            <p>{TEXT.podcast.links_section.copy}</p>
          </div>
          <div id="link-container">
            <h4>{TEXT.podcast.links_section.links_headline}</h4>

            <Button className="mt-3 w-100" onClick={redirectToStoreDownload}>
              {TEXT.podcast.links_section.link_balloon_download}
            </Button>
            <Button className="mt-3 w-100" color="blue" href={TEXT.podcast.links_section.link_spotify_url}>
              {TEXT.podcast.links_section.link_spotify}
            </Button>

            <Button className="mt-3 w-100" color="green" href={TEXT.podcast.links_section.link_itunes_url}>
              {TEXT.podcast.links_section.link_itunes}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LinksSection;
