import { requestApi } from 'utils';

export const createUserWithOnetimePasswordApiRequest = ({ firstname, email, mailLinks, termsAccepted, origin }) =>
  requestApi.mutate(
    `
  mutation CreateUserWithOnetimePassword($email: String!, $mailLinks: Boolean!, $firstname: String!, $termsAccepted: Boolean!, $origin: AppOrigin!) {
    createUserWithOnetimePassword(email: $email, mailLinks: $mailLinks, firstName: $firstname, termsAccepted: $termsAccepted, origin: $origin) {
      user {
        id
        email
        firstName
        lastName
        isGuest
        unconfirmedEmail
      }
    }
  }
`,
    {
      email: email,
      mailLinks: mailLinks,
      firstname: firstname,
      termsAccepted: termsAccepted,
      origin: origin
    }
  );
