import Style from './PayPal.style';
import paypalImage from 'images/checkout/img-paypal.svg';

export const PayPal = ({ paypal }) => {
  return (
    <Style data-cy-name="user-payment-method-paypal">
      <img src={paypalImage} alt="Logo PayPal" className="logo" />
      {paypal?.payerName && (
        <div className="card-row">
          <div>Käufername</div>
          <div>{paypal.payerName}</div>
        </div>
      )}
      {paypal?.payerEmail && (
        <div className="card-row">
          <div>Paypal-Adresse</div>
          <div>{paypal.payerEmail}</div>
        </div>
      )}
    </Style>
  );
};
