import { colors } from 'Theme';

const colorMapping = {
  '#db9598': colors.pink.c500,
  '#8dc9bd': colors.green.c500,
  '#598096': colors.blue.c500,
  '#aa749d': colors.purple.c500,
  '#deae39': colors.yellow.c500,
  '#e9c85f': colors.yellow.c600
};

export const getColorSetByPrimaryColor = (color) => {
  const mappedColor = colorMapping[color];
  if (!mappedColor) {
    throw new Error(`Invalid primary color: ${colorValue}`);
  }
  switch (mappedColor) {
    case colors.pink.c500:
      return {
        primaryColor: colors.pink.c500,
        darkColor: colors.pink.c600,
        lightColor: colors.pink.c200,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'pink'
      };
    case colors.green.c500:
      return {
        primaryColor: colors.green.c500,
        darkColor: colors.green.c600,
        lightColor: colors.green.c200,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'green'
      };

    case colors.blue.c500:
      return {
        primaryColor: colors.blue.c500,
        darkColor: colors.blue.c600,
        lightColor: colors.blue.c200,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'blue'
      };
    case colors.purple.c500:
      return {
        primaryColor: colors.purple.c500,
        darkColor: colors.purple.c600,
        lightColor: colors.purple.c200,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'purple'
      };
    case colors.yellow.c500:
      return {
        primaryColor: colors.yellow.c500,
        darkColor: colors.yellow.c600,
        lightColor: colors.yellow.c200,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'yellow'
      };
    case colors.yellow.c600:
    default:
      return {
        primaryColor: colors.white.c500,
        darkColor: colors.white.o30,
        lightColor: colors.white.c500,
        whiteOpacity80: colors.white.o80,
        whiteOpacity60: colors.white.o60,
        themeColor: 'yellow'
      };
  }
};
