import React, { useEffect, useState } from 'react';
import { Spinner } from 'Common';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { ROUTES } from 'utils';
import { PaymentContainer } from 'Container';
import { confirmPaymentMethodFailure, confirmPaymentMethodSuccess } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentRefererUrl } from 'redux/query';

export const CheckoutConfirm = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const args = qs.parse(search);
  const refererUrl = useSelector(getPaymentRefererUrl);
  const [redirectToPaymentError, setRedirectToPaymentError] = useState(false);
  const [redirectToRefererUrl, setRedirectToRefererUrl] = useState(false);

  useEffect(() => {
    if (
      !('redirect_status' in args && 'setup_intent' in args && 'setup_intent_client_secret' in args) ||
      args.redirect_status === 'failed'
    ) {
      setRedirectToPaymentError(true);
    }
  }, [args]);

  const handleOnError = (error) => {
    dispatch(confirmPaymentMethodFailure(error));
    setRedirectToPaymentError(true);
  };

  const handleOnLoadSetupIntent = (setupIntent) => {
    if (args.redirect_status === 'failed') {
      return;
    }
    const paymentMethodAutoAssigned = refererUrl === ROUTES.profileSubscriptionStatus;
    dispatch(confirmPaymentMethodSuccess({ setupIntent, paymentMethodAutoAssigned }));
    setRedirectToRefererUrl(true);
  };

  return (
    <PaymentContainer>
      {args.redirect_status === 'succeeded' && (
        <PaymentContainer.PaymentSetup
          secret={args.setup_intent_client_secret}
          onError={handleOnError}
          onComplete={handleOnLoadSetupIntent}
        />
      )}
      {redirectToPaymentError && <Redirect to={ROUTES.paymenterror} />}
      {redirectToRefererUrl && <Redirect to={refererUrl ?? ROUTES.checkout} />}
      <Spinner />
    </PaymentContainer>
  );
};
