import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import * as api from 'api/balloon/users';
import { handleAxiosError } from 'utils';
import {
  CHECK_CONFIRM_MAIL_CODE_PENDING,
  CHECK_CONFIRM_MAIL_CODE_FAILURE,
  CHECK_CONFIRM_MAIL_CODE_SUCCESS
} from 'redux/actions/actionTypes';
import { addFlash, clearFlashes } from 'redux/actions';
import { getConfirmCodeErrorMessage } from 'redux/query';

export const checkConfirmMailCodePending = () => ({
  type: CHECK_CONFIRM_MAIL_CODE_PENDING
});

export const checkConfirmMailCodeFailure = (error) => ({
  type: CHECK_CONFIRM_MAIL_CODE_FAILURE,
  error
});

export const checkConfirmMailCodeSuccess = (payload) => ({
  type: CHECK_CONFIRM_MAIL_CODE_SUCCESS,
  payload
});

export const confirmEmailAddressChange = (code) => (dispatch) => {
  dispatch(clearFlashes());
  dispatch(checkConfirmMailCodePending());
  return api.confirmEmailAddressChange(code).then(
    (response) => {
      if (response.data.errors) {
        dispatch(addFlash(getConfirmCodeErrorMessage(response.data.errors[0].message), 'danger'));

        dispatch(checkConfirmMailCodeFailure(response.data.errors));
      } else {
        dispatch(
          addFlash('Deine aktuelle E-Mail wurde erfolgreich gespeichert. Wenn du Rückfragen hast melde dich gerne bei uns.', 'success')
        );
        dispatch(
          checkConfirmMailCodeSuccess(
            normalize(response.data, {
              user: schema.user
            })
          )
        );
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(checkConfirmMailCodeFailure(error));
      });
    }
  );
};
