import { requestApi } from 'utils';

export const signInUserWithOtpAndInsuranceNumberApiRequest = ({ insuranceNumber, token }) =>
  requestApi.mutate(
    `
  mutation SignInUserWithOnetimePasswordAndInsuranceNumber($insuranceNumber: String!, $token: String!) {
    signInUserWithOnetimePasswordAndInsuranceNumber(insuranceNumber: $insuranceNumber, token: $token) {
      user {
        id
        email
        firstName
        b2bClientKeys
      }
      token
    }
  }
`,
    {
      insuranceNumber,
      token
    }
  );
