export const VALIDATE_INSURANCE_NUMBER_REQUEST = 'VALIDATE_INSURANCE_NUMBER_REQUEST';
export const VALIDATE_INSURANCE_NUMBER_SUCCESS = 'VALIDATE_INSURANCE_NUMBER_SUCCESS';
export const VALIDATE_INSURANCE_NUMBER_FAILURE = 'VALIDATE_INSURANCE_NUMBER_FAILURE';

export const validateInsuranceNumberRequest = ({ insuranceNumber, email }) => ({
  type: VALIDATE_INSURANCE_NUMBER_REQUEST,
  payload: {
    insuranceNumber,
    email
  }
});

export const validateInsuranceNumberSuccess = () => ({
  type: VALIDATE_INSURANCE_NUMBER_SUCCESS
});

export const validateInsuranceNumberFailure = (error) => ({
  type: VALIDATE_INSURANCE_NUMBER_FAILURE,
  error
});
