import React, { Fragment, useMemo } from 'react';
import { Button, Col, Container, Row } from 'Common';
import { TEXT } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { registerUserRequest } from 'redux/actions';
import { Form } from 'react-redux-form';
import { EmailTextField, NameTextField, PrivacyPolicyLink, TermsOfServiceLink } from 'components';
import { getUserPaymentDetails, getUserPaymentInformation, isRegisterError } from 'redux/query';

export const ThankYouLoggedOut = () => {
  const dispatch = useDispatch();

  const hasRegisterError = useSelector((state) => isRegisterError(state));

  const userPaymentInformation = useSelector(getUserPaymentInformation);
  const userData = useMemo(() => {
    return {
      firstname: userPaymentInformation?.firstName?.split(' ')[0],
      email: userPaymentInformation?.email
    };
  }, [userPaymentInformation]);

  const registerUser = ({ firstname, email }) => {
    dispatch(
      registerUserRequest({
        firstname,
        email,
        sendDeepLinkOTP: false
      })
    );
  };

  return (
    <Fragment>
      <main className="main-decorated-discreet thank-you-logged-out">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
              <div className="card associate card-info mt-5 mt-sm-6 mt-md-7 mb-md-5">
                <div className="card-body mt-sm-2 mt-md-0 mt-lg-0">
                  <div className="title text-center mb-2 mb-sm-3">{TEXT.thankyouloggedout.title}</div>
                  <div className="mb-md-3 mt-3 mb-lg-4 text-center text-blueGrey-c500">
                    <p className="letter-spacing-02">{TEXT.thankyouloggedout.text}</p>
                  </div>
                  <Form model="entities.forms.user" onSubmit={registerUser} hideNativeErrors>
                    <NameTextField defaultValue={userData.firstname} />
                    <EmailTextField defaultValue={userData.email} />
                    {hasRegisterError && <span className="fb-error">{TEXT.registration.email_already_exists}</span>}
                    <Button className="w-100 mb-3">{TEXT.registration.register_btn}</Button>
                  </Form>
                  <p className="terms text-center">
                    {`${TEXT.thankyouloggedout.terms_1} `}
                    <TermsOfServiceLink />.
                    <br />
                    {`${TEXT.thankyouloggedout.terms_2} `}
                    <PrivacyPolicyLink buttonText="hier" />.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Fragment>
  );
};
