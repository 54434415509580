import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { AppStoreBadge, GooglePlayBadge } from 'components';

export default function AppStage(props) {
  return (
    <section id="section-appstores" className="app-stage">
      <Container>
        <Row>
          <Col xs="12" sm="12">
            <h2 className="app-stage__heading">Hol dir die Balloon App</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} className="mb-3">
            <p className="app-stage__copy">
              Jetzt App installieren und sofort loslegen. In unserem gratis Einstiegskurs lernst du in nur 7 Tagen, wie Meditieren dir
              helfen kann gelassener zu werden, weniger gestresst zu sein und besser zu schlafen. Probier es aus!
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <ul className="appstore-links mt-4">
              <li className="link-store appstore-link">
                <AppStoreBadge />
              </li>
              <li className="link-store googleplay-link">
                <GooglePlayBadge />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <div className="app-devices" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
