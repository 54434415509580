import React from 'react';
import { TEXT } from 'locales/translations';
import { Col, Container, Button, Row } from 'Common';
import { DecorativeImage } from 'components';
import { ROUTES } from 'utils/constants';
import { ThemeContainer } from 'Container';
import { themeFeeling } from 'Theme';

import BorisBalloonie from 'images/home/BallonieBoris@2x.png';

export const BorisSection = () => {
  return (
    <ThemeContainer theme={themeFeeling}>
      <section className="boris-marketing">
        <Container>
          <Row>
            <Col className="boris-marketing__img-container">
              <DecorativeImage src={BorisBalloonie} alt={TEXT.healthMarketingPage.borisSection.altText} className="boris-marketing__img" />
            </Col>
            <Col xs={12} md={6} className="boris-marketing__content-container">
              <h2 className="poppins-light">{TEXT.healthMarketingPage.borisSection.headline_1}</h2>
              <h2>{TEXT.healthMarketingPage.borisSection.headline_2}</h2>
              <p>{TEXT.healthMarketingPage.borisSection.copy}</p>
              <Button to={ROUTES.krankenkasse + '#boris-the-expert'}>{TEXT.healthMarketingPage.borisSection.borisInfoBtn}</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </ThemeContainer>
  );
};
