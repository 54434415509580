import React from 'react';
import PropTypes from 'prop-types';

import BrigitteLogo from 'components/images/BrigitteLogo';
import SternLogo from 'components/images/SternLogo';
import FlowLogo from 'components/images/FlowLogo';
import { TEXT } from 'locales/translations';

const Testimonial = ({ children, text }) => {
  return (
    <div className="testimonial">
      <div className="testimonial__img-wrapper">{children}</div>
      <p className="testimonial__quote">{text}</p>
    </div>
  );
};

Testimonial.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired
};

export const BrigitteTestimonial = ({ isColored = false }) => (
  <Testimonial text={TEXT.testimonialBrigitte.body}>
    <BrigitteLogo className="testimonial__img" sizes="132px" isColored={isColored} />
  </Testimonial>
);

BrigitteTestimonial.propTypes = {
  isColored: PropTypes.bool
};

export const FlowTestimonial = ({ isColored = false }) => (
  <Testimonial text={TEXT.testimonialFlow.body}>
    <FlowLogo className="testimonial__img" sizes="95px" isColored={isColored} />
  </Testimonial>
);

FlowTestimonial.propTypes = {
  isColored: PropTypes.bool
};

export const SternTestimonial = ({ isColored = false }) => (
  <Testimonial text={TEXT.testimonialStern.body}>
    <SternLogo className="testimonial__img" sizes="135px" isColored={isColored} />
  </Testimonial>
);

SternTestimonial.propTypes = {
  isColored: PropTypes.bool
};
