import React from 'react';
import { BorderedSectionContainer, InsuranceContainer, InsuranceCooperationAuthenticationContainer } from 'Container';
import {
  HealthRequirementsHint,
  ImageParagraph,
  ImageTextRow,
  LogoChain,
  PartnerLogo,
  PrivacyPolicyHint,
  InsuranceHeaderSection,
  InsuranceNumberAlreadyExistsButton,
  Section,
  TextBlockImg
} from 'Component';
import { Button, Col, Headline, Icon, Image, Input, Link, List, Paragraph, Row, ScrollButton, Toggle } from 'Common';
import { LogoBrigitte, LogoFlow, LogoGesundLeben } from 'images/trust_band';
import { flower, heart, thumb } from 'images/landingpages/b2b_clients';
import { logoHeaderImage, mobilePhoneImage, logoAuth, formArrow, clock, successImage } from 'images/landingpages/tk';
import { colors } from 'Theme';
import { TEXT } from 'locales/translations';
import InsuranceLandingPage from './TkLandingPage.style';
import { formatDate } from 'utils';
import { useSelector } from 'react-redux';
import { getOAuthTokenExpiresDate } from 'redux/query';

export const TkLandingPage = () => {
  const { formSection: formText, header, checkmarkSection, ballooniSection, trustBandSection, authSection } = TEXT.tk;
  const oAuthTokenExpiresDate = useSelector(getOAuthTokenExpiresDate);

  const replaceLinks = (text) => {
    const textArr = [];
    let split = text.split('Hilfe zum Anmelden bei der TK');
    textArr.push(split[0]);
    textArr.push(
      <Link
        href="https://www.tk.de/techniker/leistungen-und-mitgliedschaft/informationen-versicherte/was-ist-meine-tk/haeufige-fragen-zu-meine-tk/login-meine-tk-mit-passwort/mein-login-funktioniert-nicht-woran-koennte-das-liegen-2010480"
        target="_blank"
        key="loginHelpLink"
      >
        Hilfe zum Anmelden bei der TK
      </Link>
    );
    split = split[1].split('Leistungsanspruch für Gesundheitskurse bei der TK');
    textArr.push(split[0]);
    textArr.push(
      <Link
        href="https://www.tk.de/techniker/versicherung/leistungen/weitere-leistungen/praevention/gesundheitskurse/erstattungshoehe-gesundheitskurse-2004196"
        target="_blank"
        key="claimToPerfomranceLink"
      >
        Leistungsanspruch für Gesundheitskurse bei der TK
      </Link>
    );
    textArr.push(split[1]);

    return textArr;
  };

  // noinspection DuplicatedCode
  return (
    <InsuranceLandingPage>
      <InsuranceHeaderSection className="header-section">
        <Headline level={1}>{header.headline} </Headline>
        <Paragraph className="intro">{header.subline}</Paragraph>
        <ScrollButton type="inverted" color="blue" to="anmeldung" offset={-50}>
          {header.button}
        </ScrollButton>
        <PartnerLogo imageSrc={logoHeaderImage} altText={header.altText} logoLink={header.logoLink} />
        <Paragraph className="add-on-text">{header.addOnText}</Paragraph>
      </InsuranceHeaderSection>
      <Section backgroundColor="white" className="checkmark-section" dataCyName="checkmark-section">
        <Row>
          <Col xs="12" md="7" lg="6">
            <Headline level={2}>{checkmarkSection.headline}</Headline>
            <Paragraph>{checkmarkSection.subline_1}</Paragraph>
            <Paragraph>{checkmarkSection.subline_2}</Paragraph>
            <List icon={<Icon color={[colors.blue.c200, colors.blue.c600]} size={{ sm: 32, md: 32, lg: 40 }} name="Checkmark" />}>
              {[checkmarkSection.checkmark_1, checkmarkSection.checkmark_2, checkmarkSection.checkmark_3]}
            </List>
          </Col>
          <Col xs="12" md="5" lg="6" className="image">
            <Image src={mobilePhoneImage} alt="Kursbeispiel Mobil" className="image-course-phone" />
          </Col>
        </Row>
      </Section>
      <BorderedSectionContainer topBorder className="text-center" dataCyName="image-text-row-section">
        <Headline level={2}>{ballooniSection.headline}</Headline>
        <ImageTextRow>
          <ImageParagraph dataCyName="paragraph-image-1" image={thumb}>
            {ballooniSection.ballooni_1}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-2" image={flower}>
            {ballooniSection.ballooni_2}
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-3" image={heart}>
            {ballooniSection.ballooni_3}
          </ImageParagraph>
        </ImageTextRow>
      </BorderedSectionContainer>
      <InsuranceCooperationAuthenticationContainer provider="TK">
        {({ allowFormSubmit }) => (
          <>
            <Section backgroundColor="white" className="auth-section" dataCyName="auth-section">
              <TextBlockImg image={logoAuth}>
                <InsuranceCooperationAuthenticationContainer.SuccessContainer>
                  <Headline level={2}>{authSection.headline_success}</Headline>
                  <Headline level={2}>
                    <Image src={clock} alt="Icon Uhr" className="icon icon-clock" />{' '}
                    {authSection.headline_clock.replace('##TIME##', formatDate(oAuthTokenExpiresDate, 'HH:ii'))}
                  </Headline>
                  <Paragraph>{authSection.description_success}</Paragraph>
                </InsuranceCooperationAuthenticationContainer.SuccessContainer>
                <InsuranceCooperationAuthenticationContainer.GetTokenContainer>
                  {({ openWindow }) => (
                    <>
                      <Headline level={2}>{authSection.headline}</Headline>
                      <Paragraph>{authSection.description}</Paragraph>
                      <Button onClick={openWindow}>
                        {authSection.authButton}
                        <Image src={formArrow} alt="Pfeil" className="arrow-image" />
                      </Button>
                    </>
                  )}
                </InsuranceCooperationAuthenticationContainer.GetTokenContainer>
                <InsuranceCooperationAuthenticationContainer.ErrorContainer>
                  {({ openWindow }) => (
                    <>
                      <Headline level={2}>{authSection.errorHeadline}</Headline>
                      <Paragraph>{authSection.errorDescription1}</Paragraph>
                      <Button onClick={openWindow}>{authSection.authButton}</Button>
                      <Paragraph className="link-box">{replaceLinks(authSection.errorDescription2)}</Paragraph>
                    </>
                  )}
                </InsuranceCooperationAuthenticationContainer.ErrorContainer>
              </TextBlockImg>
            </Section>
            <Section backgroundColor="white" verticalPadding="small" className="form-section pt-2" dataCyName="form-section">
              <InsuranceCooperationAuthenticationContainer.FormBlockerContainer />
              <InsuranceContainer b2bClientKey="TK">
                <InsuranceContainer.FormView>
                  <div className="header">
                    <Headline level={2}>{formText.headline}</Headline>
                    <Paragraph className="small">
                      {formText.infoText}
                      <Link href={`mailto:${formText.contactMail}`}>{formText.contactMail}</Link>
                    </Paragraph>
                  </div>
                  <InsuranceContainer.Form disabled={!allowFormSubmit} withoutInsuranceNumber defaultValues={{ personalDataSync: false }}>
                    {({ register }) => (
                      <>
                        <Input {...register('name')} placeholder={formText.placeholderName} />
                        <Input {...register('email')} placeholder={formText.placeholderMail} type="email" />
                        <InsuranceNumberAlreadyExistsButton />
                        <Paragraph className="extra-small mb-4">{formText.insuranceNumberInfo}</Paragraph>
                        <Toggle {...register('healthCheck')} className="mb-3" id="health-terms-toggle">
                          <HealthRequirementsHint />
                        </Toggle>
                        <Toggle {...register('termsCheck')} className="mb-3" id="privacy-toggle">
                          <PrivacyPolicyHint />
                        </Toggle>
                        <Toggle
                          {...register('personalDataSync')}
                          className="mb-3"
                          id="personal-data-sync-toggle"
                          dataCyName="personal-data-sync"
                        >
                          <Paragraph className="extra-small">{formText.personalDataSync}</Paragraph>
                        </Toggle>
                      </>
                    )}
                  </InsuranceContainer.Form>
                </InsuranceContainer.FormView>
                <InsuranceContainer.OtpView />
                <InsuranceContainer.SuccessView>
                  <Image src={successImage} alt="Antistress-Coaching-Kurs" />
                </InsuranceContainer.SuccessView>
              </InsuranceContainer>
            </Section>
          </>
        )}
      </InsuranceCooperationAuthenticationContainer>
      <Section backgroundColor="lightGrey" verticalPadding="small" className="brands-section bottom text-centerd-none d-md-block">
        <LogoChain
          headline={<Headline level={5}>{trustBandSection.headline}</Headline>}
          logos={[
            <LogoBrigitte primaryColor={colors.blueGrey.c500} />,
            <LogoFlow primaryColor={colors.blueGrey.c500} />,
            <LogoGesundLeben primaryColor={colors.blueGrey.c500} secondaryColor={colors.white.c500} />
          ]}
        />
      </Section>
    </InsuranceLandingPage>
  );
};
