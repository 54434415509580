import React from 'react';
import Style from './InsuranceContainer.ErrorView.style';
import { Headline, Paragraph, Button } from 'Common';
import birdImg from 'images/thank_you/angry_bird.png';

export const ErrorView = () => {
  return (
    <Style>
      <Headline level="2" className="mb-3 mb-sm-2 mb-0">
        Huch - das tut uns Leid!
      </Headline>
      <Paragraph className="px-2 px-md-5 mb-4">
        Da ist irgendwas schief gelaufen mit deiner Registrierung. Bitte beginne von Vorne oder - wenn du unsicher bist,
        kontaktiere unseren Kunden-Support.
      </Paragraph>
      <img className="mx-auto d-block" src={birdImg} alt="Aufgeregter Vogel" />
      <Button color="pink" className="mt-3" onClick={() => location.reload()}>
        Zurück zur Registrierung
      </Button>
    </Style>
  );
};
