import { removeFailedRequest, removeOpenRequest } from 'utils';
import {
  STRIPE_SETUP_INTENT_REQUEST,
  STRIPE_SETUP_INTENT_SUCCESS,
  STRIPE_SETUP_INTENT_FAILURE,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAILURE,
  CONFIRM_PAYMENT_METHOD_REQUEST,
  CONFIRM_PAYMENT_METHOD_SUCCESS,
  CONFIRM_PAYMENT_METHOD_FAILURE,
  GET_USER_PAYMENT_INFOS_REQUEST,
  GET_USER_PAYMENT_INFOS_SUCCESS,
  GET_USER_PAYMENT_INFOS_FAILURE,
  UPDATE_USER_PAYMENT_INFOS_REQUEST,
  UPDATE_USER_PAYMENT_INFOS_SUCCESS,
  UPDATE_USER_PAYMENT_INFOS_FAILURE,
  GET_USER_PRORATION_PRICE_REQUEST,
  GET_USER_PRORATION_PRICE_SUCCESS,
  GET_USER_PRORATION_PRICE_FAILURE,
  SUBMIT_CHECKOUT_REQUEST,
  SUBMIT_CHECKOUT_SUCCESS,
  SUBMIT_CHECKOUT_FAILURE,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_SUCCESS,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE,
  SET_USER_PAYMENT_REFERER_URL,
  CLEAR_USER_PAYMENT_INFOS,
  CLEAR_SUBSCRIPTION_PLANS,
  SELECT_SUBSCRIPTION_PLAN,
  STORE_PURCHASE_USER_ID,
  ADD_FLASH,
  SET_USER_PAYMENT_INFORMATION,
  CLEAR_STRIPE_PAYMENT_INFOS,
  CLEAR_PAYER_USER_ID
} from 'redux/actions';
import PaymentState from 'redux/stores/paymentState';

// @deprecated import
import { LOGOUT_USER } from 'redux/actions/actionTypes';

const initialState = PaymentState.getInitialState();

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        ...initialState
      };
    }

    case CLEAR_SUBSCRIPTION_PLANS:
      return {
        ...state,
        updatedAt: Date.now(),
        stripe: initialState.stripe,
        userPaymentInformation: initialState.userPaymentInformation,
        subscriptionPlans: [],
        selectedPlan: null
      };

    case ADD_FLASH:
      return {
        ...state,
        updatedAt: Date.now(),
        invalidCouponCode: action.payload.errorCode === 'couponNotFound'
      };

    case SELECT_SUBSCRIPTION_PLAN:
      return {
        ...state,
        lastError: null,
        updatedAt: Date.now(),
        failedRequests: [],
        stripe: initialState.stripe,
        userPaymentInformation: initialState.userPaymentInformation,
        selectedPlan: action.payload.planId
      };

    case CLEAR_PAYER_USER_ID:
      return {
        ...state,
        updatedAt: Date.now(),
        purchaseUserId: null
      };

    case SET_USER_PAYMENT_INFORMATION:
      return {
        ...state,
        updatedAt: Date.now(),
        userPaymentInformation: {
          ...state.userPaymentInformation,
          ...action.payload
        }
      };

    case SUBMIT_CHECKOUT_REQUEST:
    case GET_USER_PAYMENT_INFOS_REQUEST:
    case POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST:
    case GET_USER_PRORATION_PRICE_REQUEST:
    case UPDATE_USER_PAYMENT_INFOS_REQUEST:
    case CONFIRM_PAYMENT_METHOD_REQUEST:
    case STRIPE_SETUP_INTENT_REQUEST:
      return {
        ...state,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now()
      };

    case GET_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        invalidCouponCode: false,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now()
      };

    case CONFIRM_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        stripe: {
          ...state.stripe,
          setupIntent: action.payload.setupIntent
        }
      };

    case STORE_PURCHASE_USER_ID:
      return {
        ...state,
        updatedAt: Date.now(),
        purchaseUserId: action.payload.userId
      };

    case GET_USER_PRORATION_PRICE_SUCCESS:
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        userPaymentDetails: {
          ...state.userPaymentDetails,
          upgrade: {
            ...state.userPaymentDetails.upgrade,
            reducedPrice: action.payload.reducedPrice,
            lastUpdateDate: Date.now()
          }
        }
      };

    case POST_CHECKOUT_SUBSCRIPTION_UPGRADE_SUCCESS:
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state)
      };

    case SET_USER_PAYMENT_REFERER_URL:
      return {
        ...state,
        updatedAt: Date.now(),
        stripe: {
          ...state.stripe,
          refererUrl: action.payload.refererUrl
        }
      };

    case CLEAR_USER_PAYMENT_INFOS:
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: [],
        userPaymentDetails: initialState.userPaymentDetails
      };

    case CLEAR_STRIPE_PAYMENT_INFOS:
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: [],
        stripe: initialState.stripe
      };

    case GET_USER_PAYMENT_INFOS_SUCCESS: {
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        userPaymentDetails: {
          ...initialState.userPaymentDetails,
          ...state.userPaymentDetails,
          ...(action.payload.paymentInfos || {})
        }
      };
    }

    case UPDATE_USER_PAYMENT_INFOS_SUCCESS: {
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        userPaymentDetails: {
          ...state.userPaymentDetails,
          renewalInfo: {
            ...state.userPaymentDetails.renewalInfo,
            address: action.payload.renewalInfo.address
          }
        }
      };
    }

    case SUBMIT_CHECKOUT_SUCCESS:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        stripe: initialState.stripe,
        userPaymentInformation: {
          ...initialState.userPaymentInformation,
          email: state.userPaymentInformation.email,
          firstName: state.userPaymentInformation.firstName
        },
        subscriptionPlans: [],
        selectedPlan: null
      };

    case STRIPE_SETUP_INTENT_SUCCESS:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        stripe: {
          ...state.stripe,
          clientSecret: action.payload.clientSecret,
          id: action.payload.id
        },
        updatedAt: Date.now()
      };

    case GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        subscriptionPlans: action.payload.plans,
        updatedAt: Date.now()
      };

    case CONFIRM_PAYMENT_METHOD_FAILURE:
    case GET_USER_PRORATION_PRICE_FAILURE:
    case SUBMIT_CHECKOUT_FAILURE:
    case UPDATE_USER_PAYMENT_INFOS_FAILURE:
    case GET_USER_PAYMENT_INFOS_FAILURE:
    case POST_CHECKOUT_SUBSCRIPTION_UPGRADE_FAILURE:
    case GET_SUBSCRIPTION_PLANS_FAILURE:
    case STRIPE_SETUP_INTENT_FAILURE:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        failedRequests: [...state.failedRequests, action.type],
        lastError: action.error,
        updatedAt: Date.now()
      };

    default:
      return state;
  }
};
