import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import { CONFIG } from 'config';
import { loginWithApple } from 'redux/actions/users';
import { isAppleTokenError } from 'redux/query';

const AppleLoginButton = (props) => {
  const [isAppleIdReady, setisAppleIdReady] = useState(false);

  useEffect(() => {
    if (!window.AppleID) {
      const appleJs = document.createElement('script');
      appleJs.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/de_DE/appleid.auth.js';
      appleJs.async = true;
      appleJs.setAttribute('data-cmp-ab', 2);
      appleJs.onload = () => {
        setTimeout(() => {
          initAppleIdScript();
        }, 500);
      };
      document.body && document.body.appendChild(appleJs);
    } else if (window.AppleID && !isAppleIdReady) {
      initAppleIdScript();
    }
  }, [isAppleIdReady]);

  const { loginWithApple, black } = props;

  const initAppleIdScript = () => {
    const params = {
      usePopup: true,
      clientId: CONFIG.APPLE_CLIENT_ID_WEBAPP,
      redirectURI: `${window.location.href}`
    };
    window.AppleID.auth.init(params);
    setisAppleIdReady(true);
  };

  useEffect(() => {
    const onSuccess = (data) => {
      const uid = decode(data.detail.authorization.id_token)['sub'];

      const { code } = data.detail.authorization;
      loginWithApple({ uid, grantCode: code });
    };

    const onError = (error) => {
      throw error;
    };

    document.addEventListener('AppleIDSignInOnSuccess', onSuccess);
    document.addEventListener('AppleIDSignInOnFailure', onError);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', onSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', onError);
    };
  }, [loginWithApple]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div
        id="appleid-signin"
        className="signin-button cta-apple-signin"
        data-color={black ? 'black' : 'white'}
        data-border={black ? 'false' : 'true'}
        data-type="sign in"
        data-scale={6}
      />
    </div>
  );
};

AppleLoginButton.propTypes = {
  black: PropTypes.bool,
  appleLoginError: PropTypes.bool,
  loginWithApple: PropTypes.func.isRequired
};

AppleLoginButton.defaultProps = {
  appleLoginError: false,
  black: false
};

const mapStateToProps = (state) => ({
  appleLoginError: isAppleTokenError(state)
});

export default connect(mapStateToProps, {
  loginWithApple
})(AppleLoginButton);
