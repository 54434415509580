import React, { useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'Common';
import { GiftSubscriptionPlan } from 'Component';
import { getSubscriptionPlansRequest } from 'redux/actions';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { useDispatch, useSelector } from 'react-redux';
import { TEXT } from 'locales/translations';
import { GiftFaq } from './GiftFaq';
import illuBalloniHell from 'images/gift/balloon-geschenk-hell@2x.png';
import { getSubscriptionPlans, isSubscriptionPlansLoading } from 'redux/query';

export const BuyGift = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSubscriptionPlansLoading);
  const subscriptionPlans = useSelector(getSubscriptionPlans);

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.GIFT));
  }, [dispatch]);

  return (
    <>
      <main className="main-decorated-top buy-gift">
        <Container>
          <Row>
            <Col xs={{ size: '12' }}>
              <h1 className="buy-gift__heading mt-0 px-md-2 mb-1 mb-sm-3 d-block">{TEXT.gift.headline}</h1>
            </Col>
            <Col xs={{ size: '12' }}>
              <p className="buy-gift__subheading">{TEXT.gift.subline}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: '12' }}>
              {isLoading || subscriptionPlans.length === 0 ? (
                <div className="w-100 d-flex justify-content-center buy-gift__spinner">
                  <Spinner className="m-0" />
                </div>
              ) : (
                <div className="buy-gift__wrapper-plan">
                  {subscriptionPlans.map((plan, idx) => (
                    <GiftSubscriptionPlan plan={plan} key={`subscription-plan-${idx}`} />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          <Row className="buy-gift__wrapper-works">
            <Col xs={{ size: '12' }}>
              <h1 className="buy-gift__wrapper-works-heading px-md-2 mb-1 mb-sm-3">{TEXT.gift.works.headline}</h1>
            </Col>
            <Col xs={{ size: '12' }}>
              <Row className="buy-gift__wrapper-works-bubble">
                <GiftFaq number={1} />
                <GiftFaq number={2} />
                <GiftFaq number={3} />
              </Row>
            </Col>
            <Col xs={{ size: '12' }} className="justify-content-center d-flex align-items-center mt-6">
              <img className="buy-gift__wrapper-works-img" src={illuBalloniHell} alt="balloon mit Geschenk" />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};
