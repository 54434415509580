import React, { Component } from 'react';
import { ROUTES } from 'utils/constants';
import XingHeader from './../Xing/components/XingHeader';
import XingCheckmarkBoxSection from './../Xing/components/XingCheckmarkBoxSection';
import BallooniSectionXing from './../Xing/components/BallooniSectionXing';
import { TEXT } from 'locales/translations';
import BottomHeaderLine from '../Xing/components/BottomHeaderLine';
import XingFormSection from '../Xing/components/XingFormSection';
import xingBottom from 'images/landingpages/xing_pro_jobs/xing_projobs_bottom.svg';
import xingLogoHeader from 'images/landingpages/xing_pro_jobs/xing-projob-header.svg';
import phoneLarge from 'images/landingpages/xing_pro_jobs/app-xing-large@3x.png';
import phoneMobil from 'images/landingpages/xing_pro_jobs/app-xing-mobil.png';
import phoneMobilMedium from 'images/landingpages/xing_pro_jobs/app-xing-mobil@2x.png';
import phoneMobilLarge from 'images/landingpages/xing_pro_jobs/app-xing-mobil@3x.png';
import successPhone from 'images/landingpages/xing_pro_jobs/success-phone.png';
import successMediumPhone from 'images/landingpages/xing_pro_jobs/success-phone@2x.png';
import successLargePhone from 'images/landingpages/xing_pro_jobs/success-phone@3x.png';
import { Link } from 'Common';

class XingProJobsLandingPage extends Component {
  render() {
    return (
      <main className="xing-pro-jobs">
        <XingHeader headline={TEXT.xing_pro_jobs.header.headline} subline={TEXT.xing_pro_jobs.header.subline} route={ROUTES.xingProJobs} />
        <BottomHeaderLine imageSrc={xingLogoHeader} />
        <XingCheckmarkBoxSection
          headline={TEXT.xing_pro_jobs.checkmark_section.headline}
          subline={TEXT.xing_pro_jobs.checkmark_section.subline_1}
          checkmark_1={TEXT.xing_pro_jobs.checkmark_section.checkmark_1}
          checkmark_2={TEXT.xing_pro_jobs.checkmark_section.checkmark_2}
          checkmark_3={TEXT.xing_pro_jobs.checkmark_section.checkmark_3}
          hasAdditionalCheckmarks={true}
          additionalCheckmark_1={
            <>
              <strong>Zusätzlich für ProJobs</strong>: 3 Kurse à 10 Einheiten für Resilienz und klare Sicht in fordernden Zeiten.
            </>
          }
          additionalCheckmark_2={TEXT.xing_pro_jobs.checkmark_section.plus_2}
          phoneMobil={phoneMobil}
          phoneMobil_300={phoneMobil}
          phoneMobil_768={phoneMobilMedium}
          phoneMobil_1280={phoneMobilLarge}
          phoneLarge={phoneLarge}
        />
        <BallooniSectionXing
          headline={TEXT.xing_pro_jobs.ballooni_section.headline}
          partnerName="XING ProJobs"
          duration={12}
          ballooni_first={
            <>
              Deinen persönlichen Zugangs-Code zu Balloon erhältst du im{' '}
              <Link href="https://www.xing.com/you/projobs/partners/balloon" color="white">
                XING ProJobs Vorteilsbereich
              </Link>{' '}
              unter „von Profis lernen”
            </>
          }
          ballooni_last={<>Der kostenlose Zugang umfasst 7 Kurse, 10 Einzel-Übungen und 2 Live-Events.</>}
          bookmark={ROUTES.xingProJobs}
        />
        <XingFormSection
          partnerName="XING ProJobs"
          duration={12}
          successPhone={successPhone}
          successMediumPhone={successMediumPhone}
          successLargePhone={successLargePhone}
          universalLink="https://www.balloonapp.de/app/category/2C4CCA94B046CE2D0033458376796553EF27BB214524484A1D42D7775DBD1576/"
        />
        <div className="xing-footer justify-content-end">
          <a target="_blank" rel="noopener noreferrer" href="https://www.xing.com/you/projobs/partners/balloon">
            <img src={xingBottom} alt="balloon-xing" />
          </a>
        </div>
      </main>
    );
  }
}

export default XingProJobsLandingPage;
