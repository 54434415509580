import styled from 'styled-components';
import { MainPage } from 'Container';

export default styled(MainPage)`
  h4 {
    text-align: left;
  }

  &.app {
    margin-top: -30px;
  }

  &.popup {
    margin-top: -40px;
  }
`;
