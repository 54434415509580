import { handleAxiosError } from 'utils';

import * as api from 'api/balloon/withdrawCancelationSubscription';

import {
  CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_PENDING,
  CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_FAILURE,
  CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS
} from 'redux/actions/actionTypes';

export const createRequestWithdrawCancelationSubcriptionPending = () => ({
  type: CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_PENDING
});

export const createRequestWithdrawCancelationSubcriptionFailure = () => ({
  type: CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_FAILURE
});

export const createRequestWithdrawCancelationSubcriptionSuccess = (payload) => ({
  type: CREATE_REQUEST_WITHDRAW_CANCELATION_SUBSCRIPTION_SUCCESS,
  payload
});

export const createRequestWithdrawCancelationSubcription = (subscriptionId) => (dispatch) => {
  dispatch(createRequestWithdrawCancelationSubcriptionPending());
  return api.withdrawCancelationSubcription(subscriptionId).then(
    (response) => {
      if (response.data.errors) {
        dispatch(createRequestWithdrawCancelationSubcriptionFailure(response.data.errors));
      } else {
        dispatch(createRequestWithdrawCancelationSubcriptionSuccess());
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(createRequestWithdrawCancelationSubcriptionFailure(error));
      });
      throw error;
    }
  );
};
