const img_alt_texts_mapping = {
  favicon: 'Meditation per App | Balloon',
  logo: 'Meditation per App | Balloon',
  bus: 'Meditation mit Balloon abonnieren',
  thank_you_smiley: 'Danke für dein Meditiations-Abo',
  brigitte: 'Brigitte empfiehlt Meditation mit Balloon',
  flow: 'Flow empfiehlt Mediation mit Balloon',
  stern: 'Stern empfiehlt Mediation mit Balloon',
  hero_balloon: 'Ruhe und Gelassenheit durch Meditation mit Balloon',
  hero_bench: 'In sich ruhen durch Meditation mit Balloon',
  hero_clouds_back: 'In sich ruhen durch Meditation mit Balloon',
  hero_clouds_front: 'In sich ruhen durch Meditation mit Balloon'
};

export const IMG_ALT_TEXTS = Object.keys(img_alt_texts_mapping).reduce((acc, key) => {
  return acc.concat([
    {
      pattern: new RegExp(key, 'i'),
      substitution: img_alt_texts_mapping[key]
    }
  ]);
}, []);
