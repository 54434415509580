import { put } from 'redux-saga/effects';
import { signInWithOtpAndInsuranceNumberFailure, signInWithOtpAndInsuranceNumberSuccess } from 'redux/actions';
import { signInUserWithOtpAndInsuranceNumberApiRequest } from 'api/balloon';

export function* signInWithOtpAndInsuranceNumberSaga({ payload }) {
  const { insuranceNumber, token } = payload;

  try {
    const { data: response } = yield signInUserWithOtpAndInsuranceNumberApiRequest({ insuranceNumber, token });

    if (response.errors) {
      yield put(signInWithOtpAndInsuranceNumberFailure(response.errors));
      return;
    }

    yield put(
      signInWithOtpAndInsuranceNumberSuccess({
        ...response.data.signInUserWithOnetimePasswordAndInsuranceNumber
      })
    );
  } catch (error) {
    yield put(signInWithOtpAndInsuranceNumberFailure(error));
  }
}
