import { TRACK_PLAYER, STOP_PLAYER, SET_PLAYER_PROCESS, SET_PLAYER_STATUS } from 'redux/actions/actionTypes';

export const trackPlayer = (unit) => ({
  type: TRACK_PLAYER,
  unit
});

export const stopPlayer = (timestopped) => ({
  type: STOP_PLAYER
});

export const setPlayerProcess = (process) => ({
  type: SET_PLAYER_PROCESS,
  process
});

export const setPlayerStatus = (status) => ({
  type: SET_PLAYER_STATUS,
  status
});
