import { all } from 'redux-saga/effects';
import { initSubscription } from './subscriptionSaga';
import { dakBgmUserWatcher } from './DakBgm';
import { balloonUserWatcher } from './Balloon';
import { insuranceWatcher } from './Insurance';
import { paymentWatcher } from './Payment';
import { watchCreateB2bContact } from './Balloon/createB2bContactSaga';
import { actionWatcher } from './appSaga';

export function* rootSaga() {
  yield all([
    actionWatcher(),
    initSubscription(),
    dakBgmUserWatcher(),
    balloonUserWatcher(),
    insuranceWatcher(),
    watchCreateB2bContact(),
    paymentWatcher()
  ]);
}
