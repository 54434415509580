import React from 'react';

const EpisodeIllu = () => {
  return (
    <div className="illu-container">
      <div className="illu" />
    </div>
  );
};
export default EpisodeIllu;
