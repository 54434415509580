import { TEXT } from 'locales/translations';
import React from 'react';
import PropTypes from 'prop-types';
import { isTrimmed } from 'utils/validators';
import { getCurrentUser, isGuest } from 'redux/query';
import { TextField } from 'components';
import { useSelector } from 'react-redux';

export const NameField = ({ placeholder }) => {
  const user = useSelector(getCurrentUser);

  const isCurrentUserGuest = useSelector(isGuest);

  return (
    <TextField
      id="user.name"
      messages={{
        isTrimmed: `${TEXT.formsGeneral.isTrimmed}`,
        valueMissing: `${TEXT.formsGeneral.valueMissingName}`
      }}
      defaultValue={isCurrentUserGuest ? '' : user.firstName}
      model=".name"
      placeholder={placeholder}
      required
      validators={{
        isTrimmed: (val) => isTrimmed(val)
      }}
      cyName="name-input"
    />
  );
};

NameField.propTypes = {
  placeholder: PropTypes.string
};
NameField.defaultProps = {
  placeholder: TEXT.formsGeneral.placeholderVornameNachname
};

export default NameField;
