import React from 'react';
import Author from './Author';
import TeamIllu from './TeamIllu';
import { TEXT } from 'locales/translations';

const TeamSection = () => {
  return (
    <section id="team-section-container">
      <TeamIllu />
      <h2>{TEXT.podcast.team_section.headline}</h2>
      <h3>{TEXT.podcast.team_section.subline}</h3>
      <div id="bottom-container">
        <Author
          imageClass="boris-image"
          name={TEXT.podcast.team_section.boris.name}
          description={TEXT.podcast.team_section.boris.description}
        />
        <Author
          imageClass="sinja-image"
          name={TEXT.podcast.team_section.sinja.name}
          description={TEXT.podcast.team_section.sinja.description}
        />
      </div>
    </section>
  );
};

export default TeamSection;
