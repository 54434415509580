import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/unit';

import { GET_UNIT_URL_PENDING, GET_UNIT_URL_FAILURE, GET_UNIT_URL_SUCCESS } from 'redux/actions/actionTypes';

export const getUnitUrlPending = () => ({
  type: GET_UNIT_URL_PENDING
});

export const getUnitUrlFailure = (error) => ({
  type: GET_UNIT_URL_FAILURE,
  error
});

export const getUnitUrlSuccess = (payload) => ({
  type: GET_UNIT_URL_SUCCESS,
  payload
});

export const getUnitProtectedFileUrl = (id) => (dispatch) => {
  dispatch(getUnitUrlPending());
  return api.getUnitProtectedFileUrl(id).then(
    (response) => {
      dispatch(getUnitUrlSuccess(response.data.data.units[0]));
    },
    (error) => {
      handleAxiosError(error, () => {});
    }
  );
};
