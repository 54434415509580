import { combineReducers } from 'redux';
import { modelReducer } from 'react-redux-form';
import invoices, * as fromInvoices from './invoices';
import categories, * as fromCategories from './categories';
import category, * as fromCategory from './category';
import course, * as fromCourses from './courses';
import single, * as fromSingles from './singles';
import entryContent, * as fromEntryContent from './entryContent';
import newContent, * as fromNewContent from './newContent';
import giftCoupons, * as fromGiftCoupons from './giftCoupons';
import alerts, * as fromAlerts from './alerts';
import requestCancellation, * as fromRequestCancellation from './requestCancellation';
import redeemUnlockCode, * as fromRedeemUnlockCode from './redeemUnlockCode';
import { getStripeErrorMsg } from 'utils/stripe';
import { b2bContact } from './b2bContact';

const entitiesReducer = {
  forms: combineReducers({
    giftCoupon: modelReducer('entities.forms.giftCoupon', {}),
    termsAndConditions: modelReducer('entities.forms.termsAndConditions', {}),
    user: modelReducer('entities.forms.user', {}),
    terminateUserAbo: modelReducer('entities.forms.terminateUserAbo', {}),
    login: modelReducer('entities.forms.login', {}),
    personalData: modelReducer('entities.forms.personalData', {}),
    specialFeature: modelReducer('entities.forms.specialFeature', {}),
    b2bContact: modelReducer('entities.forms.b2bContact', {})
  }),
  alerts,
  categories,
  course,
  single,
  entryContent,
  newContent,
  giftCoupons,
  invoices,
  redeemUnlockCode,
  category,
  requestCancellation,
  b2bApiRequest: b2bContact
};

export default entitiesReducer;

// Helper method for those entities that receive a stripe Error message,
export const getStripeErrorMessage = (state) => {
  if (state.error && state.error.data && state.error.data.errors) {
    const errs = state.error.data.errors;
    if (errs.stripe && errs.stripe.error) {
      return getStripeErrorMsg(errs.stripe.error);
    } else {
      // TODO: Check the controller to see which other errors are possible.
      // Theoretically, at least validation errors could happen (when, for example,
      // the previous steps are buggy and a form without all mandatory fields is submitted).
      return 'Unknown error';
    }
  }
};

// forms selectors
export const isFetchingInvoices = (state) => fromInvoices.isFetching(state.invoices);
export const getInvoicesForUser = (state, uid) => fromInvoices.getForUser(state.invoices, uid);
export const getInvoiceByNumber = (state, number) => fromInvoices.getByNumber(state.invoices, number);

// user selectors

// giftCoupon selectors
/**
 * @deprecated - use the redux query method instead
 */
export const getGiftCouponErrorMessage = (state) => fromGiftCoupons.getErrorMessage(state.giftCoupons);

export const isFetchingGiftCoupon = (state) => fromGiftCoupons.isFetching(state.giftCoupons);

export const getGiftCouponRedeemedVoucher = (state) => fromGiftCoupons.redeemedVoucher(state.giftCoupons);

export const getGiftCouponCodeVoucher = (state) => fromGiftCoupons.redeemedEntryVoucher(state.giftCoupons);

export const hasGiftCouponError = (state) => fromGiftCoupons.hasError(state.giftCoupons);

export const isGiftCouponAlreadyApplied = (state) => fromGiftCoupons.isAlreadyApplied(state.giftCoupons);

export const isGiftCouponCodeExpired = (state) => fromGiftCoupons.isCodeExpired(state.giftCoupons);

// alerts selectors
export const getAlerts = (state) => fromAlerts.getAlerts(state.alerts);

// categories selectors
export const getAllCategories = (state) => fromCategories.getAllCategories(state.categories);

export const isFetchingCategories = (state) => fromCategories.isFetching(state.categories);

export const getCategoryById = (state, id) => fromCategories.getCategoryById(state.categories, id);

// entryContentSelectors
export const getCurrentEntryContent = (state) => fromEntryContent.getCurrentEntryContent(state);

export const isFetchingCurrentEntryContent = (state) => fromEntryContent.isFetching(state);

export const getEntryContentButtonText = (state) => fromEntryContent.getEntryContentButtonText(state);

// course selectors
export const getCourse = (state) => fromCourses.getCourse(state);
export const isFetchingCourse = (state) => fromCourses.isFetching(state);

// single selectors
export const getSingle = (state) => fromSingles.getSingle(state);
export const isFetchingSingle = (state) => fromSingles.isFetching(state);

// unlockCode selectors
export const isFetchingUnlockCode = (state) => fromRedeemUnlockCode.isFetching(state.redeemUnlockCode);

export const getSpecialUnlocked = (state) => fromRedeemUnlockCode.getSpecialUnlocked(state.redeemUnlockCode);

// newContentSelectors
export const isFetchingNewContent = (state) => fromNewContent.isFetching(state.newContent);
export const getNewContent = (state) => fromNewContent.getNewContent(state.newContent);

// category selectors
export const getCurrentCategory = (state) => fromCategory.getCurrentCategory(state.category);

export const isFetchingCategory = (state) => fromCategory.isFetching(state.category);

// createRequestCancellation selectors
export const isFetchingRequestCancellation = (state) => fromRequestCancellation.isFetching(state.requestCancellation);
export const getRequestCancellation = (state) => fromRequestCancellation.getRequestCancellation(state.requestCancellation);
