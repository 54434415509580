import { getCurrentUser } from 'redux/query/user/balloon/getCurrentUser/getCurrentUser';

export const getOAuthTokenExpiresDate = (state) => {
  const currentUser = getCurrentUser(state);
  if (!currentUser?.oAuthTokenExpiresAt) {
    return null;
  }
  if (typeof currentUser?.oAuthTokenExpiresAt === 'string') {
    return new Date(currentUser?.oAuthTokenExpiresAt);
  }
  return currentUser?.oAuthTokenExpiresAt;
};
