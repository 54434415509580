import styled from 'styled-components';
import { Section } from 'Container';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';

export default styled(Section).attrs({ backgroundColor: 'white' })`
  z-index: 0;

  .mime-elements-headline-2 {
    margin-top: ${asRem(33)};
    margin-bottom: ${asRem(16)};
    color: ${colors.blue.c500};

    @media (min-width: ${breakpoints.md}) {
      margin-top: ${asRem(48)};
      margin-bottom: ${asRem(27)};
      align-self: flex-start;
    }

    @media (min-width: ${breakpoints.lg}) {
      margin-top: ${asRem(79)};
      margin-bottom: ${asRem(27)};
      align-self: flex-start;
    }
  }
`;
