import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEXT } from 'locales/translations';
import { ACTIVE_SECTION, CheckoutContext } from '../Checkout';
import { isRegisteredUser } from 'redux/query';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Button } from 'Common';
import { SectionHeader, TextField } from 'Component';
import { setUserPaymentInformation } from 'redux/actions';

export const EmailSection = () => {
  const dispatch = useDispatch();
  const { activeCollapse, userData, setActiveCollapse, onChangeUser } = useContext(CheckoutContext);
  const isUserRegistered = useSelector(isRegisteredUser);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const checked = activeCollapse > ACTIVE_SECTION.MAIL;

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      email: userData.email
    },
    mode: 'onBlur'
  });

  useEffect(() => {
    if (!disableSubmitButton) {
      clearErrors('email');
    }
  }, [disableSubmitButton]);

  const toggleEditable = () => {
    setActiveCollapse(ACTIVE_SECTION.MAIL);
  };

  const handleOnSubmit = () => {
    const { email } = getValues();

    onChangeUser({ email });
    dispatch(setUserPaymentInformation({ email }));
    setActiveCollapse(ACTIVE_SECTION.ADDRESS);
  };

  return (
    <div className="section-container background-grey-c100 email-section">
      <Row>
        <Col xs="12">
          <SectionHeader number={1} checked={checked}>
            {TEXT.checkout.sectionHeadlineMail}
          </SectionHeader>
        </Col>
      </Row>
      {activeCollapse === ACTIVE_SECTION.MAIL ? (
        <Fragment>
          <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <Row>
              <Col xs="12" className="mt-3">
                <TextField
                  register={register}
                  name="email"
                  clearError={() => clearErrors('email')}
                  dataCyName="mail-input"
                  placeholder="max.mustermann@gmail.com"
                  type="email"
                  errorMessage={errors.email?.message}
                  validationPattern={{
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                    message: TEXT.formsGeneral.validEmail
                  }}
                  required={TEXT.formsGeneral.requiredEmail}
                  onChange={() => setDisableSubmitButton(!!errors.email)}
                />
              </Col>
              <Col xs="12">
                <Button disabled={disableSubmitButton} id="sendBtn" className="btn btn-blue-c500 w-100 mb-3" type="submit">
                  {TEXT.checkout.btnMail}
                </Button>
              </Col>
            </Row>
          </Form>
        </Fragment>
      ) : (
        <Fragment>
          <Row>
            <Col className="checkout-text mb-1 mt-2" xs="12" data-cy-name="checkout-user-email">
              {userData.email}
            </Col>
          </Row>
          {!isUserRegistered && (
            <Row>
              <Col className="d-flex justify-content-end" xs="12">
                <Button className="btn-link p-0" onClick={toggleEditable}>
                  {TEXT.checkout.btnEdit}
                </Button>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </div>
  );
};
