import { select, put } from 'redux-saga/effects';
import { isAuthed, isGuest } from 'redux/query';
import { resetUserGuestStateAuthentication } from 'redux/actions';
import { ROUTES } from 'utils';
export function* listenInsuranceOtpSaga() {
  const state = yield select();
  const isGuestUser = isGuest(state);
  const isAuthedUser = isAuthed(state);

  // needs no query method, because will be refactor soon
  const otpUrl = state.persistApp.currentOtpUrl;
  let isOtpUrlChanged = otpUrl !== window.location.pathname;

  if ([ROUTES.login, ROUTES.signup, ROUTES.thankyouloggedout].includes(otpUrl) && window.location.pathname.startsWith(ROUTES.confirmMail)) {
    isOtpUrlChanged = false;
  }

  if (!isGuestUser && !isAuthedUser && otpUrl && isOtpUrlChanged) {
    yield put(resetUserGuestStateAuthentication());
  }
}
