import React, { useEffect, useState } from 'react';
import { browserHistory } from 'redux/configureStore';
import { pushDataLayerPageView } from 'components/GtmHelper';
import { ROUTES } from 'utils';
import { Store } from 'react-notifications-component';
import { Link } from 'Common';
import { colors } from 'Theme';

const includeBannerSites = [ROUTES.home, ROUTES.plans, ROUTES.library];

export const useHealthInsuranceBanner = () => {
  const [bootstrapped, setBootstrapped] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const doTimeout = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(() => {
        if (typeof Store.add === 'function') {
          setBootstrapped(true);
          return;
        }
        doTimeout();
      }, 50)
    );
  };

  useEffect(() => {
    doTimeout();
  }, []);

  useEffect(() => {
    if (!bootstrapped) {
      return;
    }
    browserHistory.listen((location) => {
      pushDataLayerPageView(location.pathname);
      //because some pages work with `MainPage` and others don`t it has to be solved with setTimeout unfortunately
      // ToDo: add notification to all pages
      setTimeout(() => {
        if (window.notificationId && window.notificationId.length > 0 && includeBannerSites.includes(window.location.pathname)) {
          document.body.classList.add('notification-box-active');
        }
      }, 500);
      if (window.notificationId && window.notificationId.length > 0 && !includeBannerSites.includes(window.location.pathname)) {
        Store.removeNotification(window.notificationId);
        window.notificationId = '';
      }
    });

    window.notificationId = '';
    if (includeBannerSites.includes(window.location.pathname)) {
      window.notificationId = Store.addNotification({
        type: 'info',
        insert: 'top',
        container: 'top-full',
        //Text aus den Translations klappt hier leider nicht
        content: () => (
          <div className="notification-box">
            <p>
              NEU: Stress-Präventionskurs mit bis zu 100% Kostenerstattung.{' '}
              <Link color={colors.white.c500} className="notification-box-link" to={ROUTES.krankenkasse}>
                Erfahre mehr
              </Link>{' '}
            </p>
            <div className="notification-box-close-mark"></div>
          </div>
        ),
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          showIcon: true
        },
        onRemoval: () => {
          if (document.body.classList.contains('notification-box-active')) {
            document.body.classList.remove('notification-box-active');
            window.notificationId = '';
          }
        }
      });
      document.body.classList.add('notification-box-active');
    }
  }, [bootstrapped]);
};
