import React, { useContext, useMemo } from 'react';
import Style from './CheckoutPaymentSection.style';
import { PaymentContainer } from 'Container';
import { PaymentCollapse, SectionHeader, UserPaymentMethod } from 'Component';
import { TEXT } from 'locales/translations';
import { ACTIVE_SECTION, CheckoutContext } from '../Checkout';
import { Col, Row, Button, Spinner } from 'Common';
import { ROUTES } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { isConfirmPaymentMethod } from 'redux/query';
import { clearStripePaymentInfos, clearUserPaymentInfos } from 'redux/actions';

export const CheckoutPaymentSection = () => {
  const dispatch = useDispatch();

  const { activeCollapse, setActiveCollapse, onChangeUser, userData } = useContext(CheckoutContext);
  const isLoading = useSelector(isConfirmPaymentMethod);

  const checked = activeCollapse > ACTIVE_SECTION.PAYMENT;
  const active = activeCollapse === ACTIVE_SECTION.PAYMENT;

  const handleOnSubmit = (event) => {
    setActiveCollapse(ACTIVE_SECTION.CONFIRMATION);
  };

  const handleOnChangeForm = (userData) => {
    onChangeUser(userData);
  };

  const toggleEditable = () => {
    dispatch(clearUserPaymentInfos());
    dispatch(clearStripePaymentInfos());
    setActiveCollapse(ACTIVE_SECTION.PAYMENT);
  };

  const billingDetails = useMemo(
    () => ({
      name: `${userData.firstName} ${userData.lastName}`,
      email: userData.email,
      address: {
        line1: userData.street,
        line2: '',
        city: userData.city,
        postal_code: userData.zipCode,
        country: userData.country,
        state: ''
      }
    }),
    [userData]
  );

  return (
    <Style>
      <PaymentCollapse
        headline={
          <SectionHeader number={3} checked={checked}>
            {TEXT.checkout.sectionHeadlineText}
          </SectionHeader>
        }
      >
        {active ? (
          <PaymentContainer.Form
            onChange={handleOnChangeForm}
            onSubmit={handleOnSubmit}
            billingDetails={billingDetails}
            refererUrl={ROUTES.checkout}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <Button className="submit-button" dataCyName="submit-checkout-payment" disabled={!userData.paymentFilled}>
                {TEXT.checkout.btnPaymentMethod}
              </Button>
            )}
          </PaymentContainer.Form>
        ) : checked ? (
          <>
            <Row>
              <Col className="checkout-text mb-1 mt-2" xs="12">
                <UserPaymentMethod />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end" xs="12">
                <Button className="btn-link p-0" onClick={toggleEditable}>
                  {TEXT.checkout.btnEdit}
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
      </PaymentCollapse>
    </Style>
  );
};
