import React, { Component } from 'react';
import { Form } from 'react-redux-form';
import { TEXT } from 'locales/translations';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';

import { AppleLoginButton, EmailTextField, GiftCouponField, GoogleLoginButton, LoginRegisterSocialErrors } from 'components';
import { Button, Link } from 'Common';

import BkkTermsField from './BkkTermsField';
import { requestOnetimePassword } from 'redux/actions/users';

class RequestOnetimeFormBox extends Component {
  loginUser = (user) => {
    this.props.requestOnetimePassword(user.email, false);
  };
  render() {
    const { headline, subline, requiredTerms, showLoginForm } = this.props;
    return (
      <section id="form-register-section-container">
        <ThemeContainer theme={themeUniversal}>
          <h2>{headline}</h2>
          <p className="sign-in-text card-link text-center">
            {' '}
            Du hast noch kein Balloon-Konto?{' '}
            <Link onClick={showLoginForm.bind(this)} color="green">
              Registriere dich hier.
            </Link>
          </p>
          <p className="newsletter-disclaimer">{subline}</p>
          <Form model="entities.forms.user" hideNativeErrors onSubmit={this.loginUser} className="login-form">
            <GiftCouponField
              showRequiredHint={false}
              id="giftcouponcode"
              placeholder="Zugangs-Code"
              messages={{
                validLength: 'Zugangs-Code ist nicht gültig',
                valueMissing: 'Bitte gib den Zugangs-Code ein.'
              }}
            />
            <h2 className="anmeldedaten">Deine Balloon-Anmeldedaten</h2>
            <EmailTextField />
            {requiredTerms && <BkkTermsField />}
            <Button className="btn-secondary">Freischalten & Anmelden</Button>
          </Form>
          <div className="card-social">
            <p>oder</p>
            <GoogleLoginButton voucherCode={this.props.voucherCode} textButton={TEXT.login.login_google_btn} />
            <AppleLoginButton voucherCode={this.props.voucherCode} />
            <LoginRegisterSocialErrors isRegister={false} />
          </div>
        </ThemeContainer>
      </section>
    );
  }
}

RequestOnetimeFormBox.propTypes = {
  showLoginForm: PropTypes.func.isRequired,
  requestOnetimePassword: PropTypes.func.isRequired,
  voucherCode: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  requiredTerms: PropTypes.bool.isRequired
};

RequestOnetimeFormBox.defaultProps = {
  requiredTerms: true
};

const mapStateToProps = (state) => {
  return {
    voucherCode: state.entities.forms.user.code
  };
};

export default connect(mapStateToProps, { requestOnetimePassword })(RequestOnetimeFormBox);
