import { ListItem } from 'components/Library';
import PropTypes from 'prop-types';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils';
import { colors } from 'components/Theme';
import { Col, Container, Button, Row } from 'Common';
import { getColorSetByPrimaryColor } from 'utils/getColorSetByPrimaryColor';

const SingleTeaser = ({ category, single, buttonColor }) => {
  const suggested_singles = category.singles.filter((cs) => cs.id !== single.id).slice(0, 3);

  return (
    <section className="course-teaser">
      <Container>
        <Row>
          <Col xs={{ size: 12 }} className="d-flex justify-content-center">
            <h2 className="course-teaser__title mt-5">{TEXT.singleTeaser.headline}</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {suggested_singles.map((item, ix) => {
            return (
              <Col key={ix} xs={{ size: 12 }} lg={{ size: 4 }}>
                <ListItem
                  categoryBadge="noBadge"
                  newBadge={item.isNew ? 'new-badge' : 'no-badge'}
                  specialBadge={item.isSpecial ? 'special-badge' : 'no-badge'}
                  title={item.title}
                  time={Math.round(item.length / 60) + ' Min'}
                  type="single"
                  color={item.backgroundColor}
                  icon={item.locked ? 'locked-icon' : 'icon'}
                  slug={item.slug}
                  categoryIcon={category.icon || undefined}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={{ size: 12 }} lg={{ size: 4 }}>
            <Button to={ROUTES.library} color={getColorSetByPrimaryColor(buttonColor).themeColor} className="w-100">
              {TEXT.courseTeaser.libraryButton}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

SingleTeaser.propTypes = {
  category: PropTypes.object.isRequired,
  single: PropTypes.object.isRequired,
  buttonColor: PropTypes.string.isRequired
};

SingleTeaser.defaultProps = {
  category: {},
  single: {},
  buttonColor: colors.green.c500
};

export default SingleTeaser;
