import React from 'react';
import { ResilienceCheckout, ResilienceExpert, ResilienceHeader, ResilienceInstructions, ResilienceIntro } from './';
import { Helmet } from 'react-helmet';

export function ResiliencePage() {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <ResilienceHeader />
      <ResilienceIntro />
      <ResilienceExpert />
      <ResilienceCheckout showCooperationPartner={false} />
      <ResilienceInstructions />
    </div>
  );
}
