export const UPDATE_USER_PAYMENT_METHOD_REQUEST = 'UPDATE_USER_PAYMENT_METHOD_REQUEST';
export const UPDATE_USER_PAYMENT_METHOD_SUCCESS = 'UPDATE_USER_PAYMENT_METHOD_SUCCESS';
export const UPDATE_USER_PAYMENT_METHOD_FAILURE = 'UPDATE_USER_PAYMENT_METHOD_FAILURE';

export const updateUserPaymentMethodRequest = () => ({
  type: UPDATE_USER_PAYMENT_METHOD_REQUEST
});

export const updateUserPaymentMethodSuccess = () => ({
  type: UPDATE_USER_PAYMENT_METHOD_SUCCESS
});

export const updateUserPaymentMethodFailure = (error) => ({
  type: UPDATE_USER_PAYMENT_METHOD_FAILURE,
  error
});
