import React, { Component } from 'react';
import { Col, Container, Row } from 'Common';
import VideoModal from './VideoModal';
import DecorativeImage from 'components/DecorativeImage';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';

import VideoImage from 'images/home/phoneMockupVideo@2x.png';
import { ThemeContainer } from 'Container';
import { themeCalm } from 'Theme';

class VideoSection extends Component {
  render() {
    return (
      <ThemeContainer theme={themeCalm}>
        <section className="video-section">
          <Container>
            <Row>
              <Col className="video-section__content" xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 0 }} lg={{ size: 5, offset: 0 }}>
                <TextBlock heading={TEXT.videoSection.heading} body={TEXT.videoSection.body} textColor="white" />
                <VideoModal />
              </Col>
              <Col xs={{ size: 12 }} md={{ size: 6, offset: 0 }} lg={{ size: 5, offset: 2 }} className="video-section__img-wrapper">
                <DecorativeImage alt={TEXT.videoSection.alt} src={VideoImage} className="video-section__video-image" />
              </Col>
            </Row>
          </Container>
        </section>
      </ThemeContainer>
    );
  }
}

export default VideoSection;
