import React, { Component } from 'react';
import { Button, ScrollLink, Col, Row, Paragraph } from 'Common';
import { ROUTES } from 'utils/constants';
import DecorativeImage from 'components/DecorativeImage';
import TextBlock from './TextBlock';
import { TEXT } from 'locales/translations';
import krankenkasseImage from 'images/home/krankenkasse-erstattungsfaehig.svg';
import { ThemeContainer } from 'Container';
import { themeFeeling } from 'Theme';
import { HealthLandingPageSectionStyle } from './HealthLandingPageSection.style';

const HealthLandingPageSection = () => {
  return (
    <ThemeContainer theme={themeFeeling}>
      <HealthLandingPageSectionStyle id="health-section" backgroundColor="white">
        <Row>
          <Col xs={{ size: 12 }} md={{ size: 7, offset: 0 }} lg={{ size: 5, offset: 0 }} className="health-image-wrapper">
            <TextBlock heading={TEXT.healthPageSection.heading} textColor="greyBlue" textAlign="center" />
            <DecorativeImage alt={TEXT.healthPageSection.alt} src={krankenkasseImage} className="health-image" />
          </Col>
          <Col className="content" xs={{ size: 10, offset: 1 }} md={{ size: 5, offset: 0 }} lg={{ size: 5, offset: 2 }}>
            <TextBlock body={TEXT.healthPageSection.body} textColor="blueGrey" />
            <Paragraph className="body">
              {TEXT.healthPageSection.subline}
              <ScrollLink offset={-110} to={'cooperations-slider'}>
                {TEXT.healthPageSection.textLink}
              </ScrollLink>
              {TEXT.healthPageSection.subline_1}
            </Paragraph>
            <Button className="w-100" to={ROUTES.krankenkasse + '#section-checkout'}>
              {TEXT.healthPageSection.btn}
            </Button>
          </Col>
        </Row>
      </HealthLandingPageSectionStyle>
    </ThemeContainer>
  );
};

export default HealthLandingPageSection;
