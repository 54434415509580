import React, { useMemo } from 'react';
import Style from './CheckboxField.style';
import { createUniqKey } from 'utils';
import { Label, FormFeedback } from 'reactstrap';

export const CheckboxField = ({
  label,
  id,
  name,
  dataCyName,
  disabled,
  required,
  register,
  errorMessage,
  validationPattern,
  onChange = () => {},
  clearError = () => {}
}) => {
  const handleOnChange = (evt) => {
    onChange(evt.target.checked);
    clearError();
  };

  const _id = useMemo(() => id || createUniqKey(), [id]);
  const _register = useMemo(() => {
    if (!register) {
      return {
        name,
        onChange: handleOnChange
      };
    }
    return register(name, {
      required,
      pattern: validationPattern,
      onChange: handleOnChange
    });
  }, [register]);

  return (
    <Style data-cy-name={dataCyName}>
      <input {..._register} disabled={disabled} id={_id} type="checkbox" className="me-2 form-check-input" />
      {label && (
        <Label for={_id}>
          {label}
          {required && '*'}
        </Label>
      )}
      {errorMessage && <FormFeedback valid={!!errorMessage ? false : undefined}>{errorMessage}</FormFeedback>}
    </Style>
  );
};
