import { requestApi } from 'utils';

export const getPreviewingProrationApiRequest = ({ subscriptionPlanId }) =>
  requestApi.query(`
  query GetPreviewingProration {
    previewingProration(newPlanId: "${subscriptionPlanId}") {
      reducedPrice
    }
  }
`);
