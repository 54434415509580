import styled from 'styled-components';
import { InsuranceLandingPage } from 'Container';
import { asRem } from 'utils';
import { colors } from 'Theme';

export default styled(InsuranceLandingPage).attrs({ className: 'tk-landing-page' })`
  &.insuranceCooperationLandingPage {
    section.auth-section {
      padding: ${asRem(20)} 0;

      .arrow-image {
        position: absolute;
        bottom: ${asRem(-52)};
        left: ${asRem(-120)};
      }

      .text-block-img {
        h2 {
          color: #00a0e3;
        }

        button {
          background-color: #00a0e3;
          border-color: #00a0e3;
          position: relative;
        }

        .image {
          width: ${asRem(80)};
        }
      }

      .popup-error {
        color: ${colors.error.c500};
        padding-top: ${asRem(42)};
        max-width: ${asRem(600)};
        font-weight: bold;
      }
    }

    p.link-box {
      padding: ${asRem(24)} ${asRem(72)} 0;
      max-width: ${asRem(600)};

      a {
        color: #00a0e3;
      }
    }
  }
`;
