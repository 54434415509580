import React, { Component } from 'react';
import { ScrollButton, Col, Row, Container } from 'Common';
import { Section } from 'Container';
import { HealthInstructionCard } from 'components/Landingpages/HealthInsurance';
import balloonInstructions1x from 'images/landingpages/health_insurance/balloon_instructions.png';
import balloonInstructions2x from 'images/landingpages/health_insurance/balloon_instructions@2x.png';
import balloonInstructions3x from 'images/landingpages/health_insurance/balloon_instructions@3x.png';
import { TEXT } from 'locales/translations';

class HealthInstructions extends Component {
  instructionCardsContent = [
    {
      num: 1,
      headlineLight: 'Schritt eins',
      headlineBold: 'Registrierung / Anmeldung',
      cardBody:
        'Registriere dich oder melde dich an und hole dir für 75,00 € deinen Zugang zum Kurs „Stressreduktion durch Achtsamkeit – ein App-gestützter Online-Kurs" (52 Kurs-Einheiten). Um das Gelernte zu vertiefen, erhältst du außerdem für 12 Monate Zugang zur gesamten Balloon-App.'
    },
    {
      num: 2,
      headlineLight: 'Schritt zwei',
      headlineBold: 'Kurs absolvieren',
      cardBody:
        'Absolviere den Kurs „Stressreduktion durch Achtsamkeit – ein App-gestützter Online Kurs“ in der Balloon-App vollständig innerhalb von 6 Monaten, um dir die Kosten von deiner Krankenkasse erstatten zu lassen.'
    },
    {
      num: 3,
      headlineLight: 'Schritt drei',
      headlineBold: 'Zertifikat erhalten',
      cardBody:
        'Wenn du den Kurs erfolgreich innerhalb von 6 Monaten abgeschlossen hast, bekommst du dein Teilnahme-Zertifikat per E-Mail zugeschickt. Unser Kundensupport ist bei Fragen oder Problemen gerne für dich da!'
    },
    {
      num: 4,
      headlineLight: 'Schritt vier',
      headlineBold: 'Kosten erstatten lassen',
      cardBody:
        'Reiche den Zahlungsbeleg mit dem Zertifikat  bei der Krankenkasse ein, um deine Erstattung zu erhalten. Beachte, dass  der Kurs innerhalb von 6 Monaten vollständig abgeschlossen werden muss und dass die Erstattungssumme je nach Krankenkasse unterschiedlich ausfallen kann.'
    }
  ];

  render() {
    return (
      <Section backgroundColor="lightGrey" className="position-relative styled_section_balloon_bottom_left pt-md-5 py-4 py-md-6">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 12 }}>
              <img
                className="img_balloon_instrucktions_kk d-block mx-auto"
                srcSet={`${balloonInstructions1x} 165w, ${balloonInstructions2x} 330w, ${balloonInstructions3x} 495w`}
                src={balloonInstructions1x}
                alt="Balloon hört eine Meditation"
              />

              <h2 className="h2_kk text-center text-blueGrey-c500 mb-5">
                {TEXT.healthInstructions.headline_1}
                <span className="h2_bold_kk d-block">{TEXT.healthInstructions.headline_2}</span>
              </h2>
            </Col>
          </Row>
          <Row>
            {this.instructionCardsContent.map((instructionCardContent) => {
              const { num, headlineLight, headlineBold, cardBody } = instructionCardContent;
              return (
                <Col xs={{ size: 12 }} md={{ size: 6 }} key={num}>
                  <HealthInstructionCard num={num} headlineLight={headlineLight} headlineBold={headlineBold} cardBody={cardBody} />
                </Col>
              );
            })}

            <Col xs={{ size: 12 }} md={{ size: 12 }}>
              <h3 className="instruction-card-headline text-center text-blueGrey-c500 mt-4 mb-3">
                <span className="h2_bold_kk d-block">{TEXT.healthInstructions.headlineBold_5}</span>
              </h3>
              <p className="p_lg_kk text-center">{TEXT.healthInstructions.cardBody_5_1}</p>
              <p className="p_lg_kk text-center">{TEXT.healthInstructions.cardBody_5_2}</p>
              <div className="my-5 text-center">
                <ScrollButton className="btn-w-auto" to="section-checkout">
                  {TEXT.healthInstructions.ctaBtn}
                </ScrollButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default HealthInstructions;
