export const REGISTER_USER_GUEST_REQUEST = 'REGISTER_USER_GUEST_REQUEST';
export const REGISTER_USER_GUEST_SUCCESS = 'REGISTER_USER_GUEST_SUCCESS';
export const REGISTER_USER_GUEST_FAILURE = 'REGISTER_USER_GUEST_FAILURE';

export const registerGuestUserRequest = () => ({
  type: REGISTER_USER_GUEST_REQUEST
});

export const registerGuestUserFailure = (error) => ({
  type: REGISTER_USER_GUEST_FAILURE,
  error
});

export const registerGuestUserSuccess = ({ user, token }) => ({
  type: REGISTER_USER_GUEST_SUCCESS,
  payload: {
    user,
    token
  }
});
