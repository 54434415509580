import { arrayContains } from 'utils';
import {
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_USER_PRORATION_PRICE_REQUEST,
  POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST
} from 'redux/actions/payment';

export const isSubscriptionPlansLoading = (state) =>
  arrayContains(state.payment.openRequests, [
    GET_SUBSCRIPTION_PLANS_REQUEST,
    POST_CHECKOUT_SUBSCRIPTION_UPGRADE_REQUEST,
    GET_USER_PRORATION_PRICE_REQUEST
  ]);
