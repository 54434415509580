import { requestApi } from 'utils';

export let getPayableInfosApiRequest = () =>
  requestApi.query(`
  query GetPayableInfos {
    payableInfos {
      payableId
      payableType
      provider
      interval
      product
      startsAt
      endsAt
      canceled
      cancelationWithdrawable
      active
    }
  }
`);
