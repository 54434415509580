import { requestApi } from 'utils';

export const createProgress = (id, recordedAt, timeInSeconds, completenessInSeconds) =>
  requestApi.mutate(`
  mutation CreateProgress {
    createProgress(progressableId: "${id}"
      recordedAt: "${recordedAt}"
      timeInSeconds: ${timeInSeconds}
      completenessInSeconds: ${completenessInSeconds})
    {
      id
      timeInSeconds
      recordedAt
    }
  }
`);
