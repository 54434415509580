import { environment } from 'config';
import Bugsnag from '@bugsnag/js';

export function handleAxiosError(error, onResponse = () => {}, onTimeout = () => {}) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    onResponse();
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // TODO: Show error "Server gerade nicht erreichbar."
    onTimeout();
    // console.log(error.request); // eslint-disable-line no-console
    // dispatch(registerUserFailure(error.request));
  } else {
    // Something happened in setting up the request that triggered an Error
    if (!environment.isProduction) {
      Bugsnag.notify(error);
      throw error;
    }
    // TODO: Use bugsnag to report error in production
    // (Anything to do here? Should this be possible, or would it be programmer error?)
  }
}
