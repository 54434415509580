import React from 'react';
import PropTypes from 'prop-types';
import { getColorSetByPrimaryColor, ROUTES } from 'utils';
import TruncatedText from './TruncatedText';
import playIcon from 'images/library/play-symbol.svg';
import { Button } from 'Common';

const InitialItem = ({ type, typename, color, title, units, time, description, illustration, slug, buttonText }) => {
  if (!color) return null;
  const { primaryColor, whiteOpacity80, darkColor, themeColor } = getColorSetByPrimaryColor(color);

  return (
    <div className={`library-initial-item library-initial-item--${type}`} style={{ backgroundColor: color }}>
      <div
        className="library-initial-item__content "
        style={{
          color: darkColor,
          backgroundColor: whiteOpacity80
        }}
      >
        <div>
          <div className="library-initial-item__headline">
            <TruncatedText>{title}</TruncatedText>
          </div>
          <div className="library-initial-item__sub-headline">
            {typename} | {units} {time}
          </div>
          <div className="library-initial-item__description">
            <TruncatedText>{description}</TruncatedText>
          </div>
        </div>
        <Button to={`${ROUTES.webplayer.replace(':slug', slug)}`} color={themeColor}>
          <img src={playIcon} className="library-initial-item__icon" alt="" />
          {buttonText}
        </Button>
      </div>
      {illustration && (
        <div
          className="library-initial-item__illustration-wrapper"
          style={{
            backgroundColor: primaryColor,
            backgroundImage: `url(${illustration})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }}
        />
      )}
    </div>
  );
};

// props nicht required, da beim Loading der Seite sonst Fehler auftreten, TODO: Refactor!
InitialItem.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  typename: PropTypes.string,
  units: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
  illustration: PropTypes.string,
  slug: PropTypes.string,
  buttonText: PropTypes.string
};
export default InitialItem;
