import React from 'react';
import { Button, Headline } from 'Common';
import { TEXT } from 'locales/translations';
import { InvoicesContentContainer } from 'containers';
import { usePageTitle } from '../../containers/PageTitleContainer/PageTitleContainer';

export default function Invoices(props) {
  usePageTitle(TEXT.invoices.title);

  return (
    <div className="subscription-status">
      <Headline level={1} className="profile-subheading">
        {TEXT.invoices.heading}
      </Headline>
      <InvoicesContentContainer />
      <div className="mb-4">
        Solltest du einen Kauf über den Apple App Store oder den Google Play Store getätigt haben, kannst du über diese Buttons zu dem
        jeweiligen Anbieter gelangen um dort Informationen zu deinen Käufen zu erhalten.
      </div>
      <Button href="https://support.apple.com/de-de/HT202039" className="d-flex w-100 justify-content-center mb-4">
        Apple App Store Hilfe
      </Button>
      <Button
        href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=de"
        className="d-flex w-100 justify-content-center"
      >
        Google App Store Hilfe
      </Button>
    </div>
  );
}
