import { InsuranceCooperationAuthenticationContext } from '../InsuranceCooperationAuthenticationContext';
import { useContext } from 'react';

export const FormBlockerContainer = ({ children }) => {
  const { allowFormSubmit } = useContext(InsuranceCooperationAuthenticationContext);
  if (allowFormSubmit) {
    return null;
  }

  return (
    <div className="insurance-form-blocker" data-cy-name="form-blocker">
      {children}
    </div>
  );
};
