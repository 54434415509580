import { put } from 'redux-saga/effects';
import { getInsuranceCompaniesFailure, getInsuranceCompaniesSuccess } from 'redux/actions';

import { getInsuranceCompaniesApiRequest } from 'api/balloon';

export function* getInsuranceCompaniesSaga(action) {
  try {
    const { data: response } = yield getInsuranceCompaniesApiRequest(action.isCooperationPartner);
    if (response.errors) {
      yield put(getInsuranceCompaniesFailure(response.errors));
      return;
    }
    yield put(getInsuranceCompaniesSuccess(response.data.insuranceCompanies));
  } catch (error) {
    yield put(getInsuranceCompaniesFailure(error));
  }
}
