export const REGISTER_HEALTH_INSURANCE_USER_REQUEST = 'REGISTER_HEALTH_INSURANCE_USER_REQUEST';
export const REGISTER_HEALTH_INSURANCE_USER_SUCCESS = 'REGISTER_HEALTH_INSURANCE_USER_SUCCESS';
export const REGISTER_HEALTH_INSURANCE_USER_FAILURE = 'REGISTER_HEALTH_INSURANCE_USER_FAILURE';

export const registerHealthInsuranceUserRequest = ({ user, sendDeepLinkOTP }) => ({
  type: REGISTER_HEALTH_INSURANCE_USER_REQUEST,
  payload: {
    user,
    sendDeepLinkOTP
  }
});

export const registerHealthInsuranceUserSuccess = () => ({
  type: REGISTER_HEALTH_INSURANCE_USER_SUCCESS
});

export const registerHealthInsuranceUserFailure = (error) => ({
  type: REGISTER_HEALTH_INSURANCE_USER_FAILURE,
  error
});
