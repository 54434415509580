import styled from 'styled-components';
import { breakpoints, colors, spacing } from 'components/Theme';
import { asRem } from 'utils';

export const HealthAdvisorStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${spacing.two}px ${spacing.one}px;
  background-color: ${colors.white.c500};

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }

  .img {
    aspect-ratio: 96 / 83;
    width: ${asRem(96)};
    margin-bottom: ${spacing.two}px;

    @media (min-width: ${breakpoints.lg}) {
      margin-right: ${spacing.two}px;
      margin-bottom: 0;
    }
  }

  .claim {
    width: ${asRem(320)};
    margin-left: ${asRem(10)};

    @media (min-width: ${breakpoints.lg}) {
      width: auto;
      margin-left: 0;
    }
  }
`;
