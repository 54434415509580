const getInitialState = () => ({
  openRequests: [],
  failedRequests: [],
  updatedAt: 0,
  stripe: {
    clientSecret: '',
    id: '',
    setupIntent: null,
    paymentMethodType: null,
    refererUrl: null
  },
  lastError: null,
  userPaymentInformation: {
    email: null,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    country: null,
    zipCode: null,
    paymentInformation: null
  },
  purchaseUserId: null,
  userPaymentDetails: {
    payableId: null,
    payableType: null,
    provider: null,
    interval: null,
    product: null,
    startsAt: null,
    endsAt: null,
    canceled: null,
    cancelationWithdrawable: null,
    active: false,
    renewalInfo: null,
    paymentDetails: null,
    upgrade: {
      reducedPrice: null,
      lastUpdateDate: null
    }
  },
  invalidCouponCode: false,
  subscriptionPlans: [],
  selectedPlan: null
});

export default {
  getInitialState
};
