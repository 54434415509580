import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import { TEXT } from 'locales/translations';

const basePageTitle = 'Balloon';

export const usePageTitle = (title) => {
  useEffect(() => {
    if (title === undefined || title === null) {
      return;
    }
    document.title = [title, basePageTitle].join(' | ');
  }, [title]);

  return (title) => {
    document.title = [title, basePageTitle].join(' | ');
  };
};

export const PageTitleContainer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const lowerCasePathname = pathname.toLowerCase();
    const routeKey = Object.keys(ROUTES).find((key) => ROUTES[key] === lowerCasePathname);
    if (!routeKey || !TEXT.meta.pageTitle[routeKey]) {
      document.title = `${TEXT.meta.pageTitle.home} | ${basePageTitle}`;
      return;
    }
    document.title = [TEXT.meta.pageTitle[routeKey], basePageTitle].join(' | ');
  }, [pathname]);

  return null;
};
