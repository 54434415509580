import { requestApi } from 'utils';

export const validateInsuranceNumberApiRequest = ({ insuranceNumber, email = '' }) =>
  requestApi.mutate(

    `
  mutation ValidateInsuranceNumber($insuranceNumber: String!, $email: String!) {
    validateInsuranceNumber(insuranceNumber: $insuranceNumber, email: $email) 
  }
`,
    {
      insuranceNumber,
      email
    }
  );
