import React from 'react';
import { Paragraph } from 'Common';
import { TextBlockImg } from 'Component';

export const ImageParagraph = ({ children, image, className = '', style, dataCyName }) => {
  return (
    <TextBlockImg image={image} className={className} style={style} dataCyName={dataCyName}>
      <Paragraph>{children}</Paragraph>
    </TextBlockImg>
  );
};
