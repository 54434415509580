import * as types from 'redux/actions/actionTypes';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getCategories,
  getEntryContent,
  retrieveAlerts,
  registerGuestUserRequest,
  AUTHENTICATE_WITH_GIS_SUCCESS,
  APPLICATION_BOOTSTRAPPED,
  REDEEM_GIFT_COUPON_SUCCESS,
  REGISTER_USER_GUEST_SUCCESS
} from 'redux/actions';
import { ROUTES } from 'utils';
import { browserHistory } from '../configureStore';
import { loadUserInformationSaga } from './loadUserInformationSaga';
import { requestApi } from 'utils';

function* logoutUserHandle() {
  requestApi.resetAuthToken();
  yield put(registerGuestUserRequest());
}

function* updateState() {
  yield put(getCategories());
  yield put(getEntryContent());
  yield put(retrieveAlerts());
}

function* updateLibrary() {
  yield put(getCategories());
  yield put(getEntryContent());
}

function forwardToProfilPage() {
  browserHistory.push(ROUTES.profilePersonalData);
}

/** @deprecated handle actions/ sagas in the correct scopes */
export function* actionWatcher() {
  yield takeLatest(REGISTER_USER_GUEST_SUCCESS, updateState);
  yield takeLatest(types.SET_USER_AS_AUTHENTICATED, updateState);
  yield takeLatest(types.LOGIN_APPLE_USER_SUCCESS, updateState);
  yield takeLatest(AUTHENTICATE_WITH_GIS_SUCCESS, updateState);
  yield takeLatest(types.CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION, updateState);
  yield takeLatest(types.LOGOUT_USER, logoutUserHandle);
  yield takeLatest(types.GET_COURSE_SUCCESS, updateLibrary);
  yield takeLatest(types.GET_SINGLE_SUCCESS, updateLibrary);
  yield takeLatest(types.REDEEM_CODE_SUCCESS, updateLibrary);
  yield takeLatest(types.CHECK_CONFIRM_MAIL_CODE_SUCCESS, forwardToProfilPage);
  yield takeLatest(types.SIGN_IN_USER_OTP_SUCCESS, updateState);
  yield takeLatest([REDEEM_GIFT_COUPON_SUCCESS, APPLICATION_BOOTSTRAPPED], loadUserInformationSaga);
}
