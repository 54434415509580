import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';

export default styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .register-user-with-otp {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0 ${asRem(10)};
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: ${asRem(16)} 0 ${asRem(24)};

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

      &:focus {
        outline: ${colors.green.c500} auto ${asRem(1)};
      }
    }
  }

  .fb-error {
    color: ${colors.error.c500};
    text-align: center;
  }

  .resend-code-wrapper {
    margin-bottom: ${asRem(32)};

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: ${asRem(48)};
    }
  }

  .btn-link-underlined {
    background: ${colors.white.c500};
    color: ${colors.green.c500};
    text-decoration: underline;
    letter-spacing: ${asRem(1)};
    font-size: ${asRem(14)};
    border: none;

    &:hover {
      color: ${colors.green.c600};
      background: ${colors.white.c500};
    }
  }
`;
