import React, { useEffect, useState } from 'react';
import { Button } from 'Common';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';
import { useDispatch, useSelector } from 'react-redux';
import { Amount, Spinner } from 'Common';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink';
import TermsOfServiceLink from 'components/TermsOfServiceLink';
import { AccessibilityLink, HealthRequirementsHint } from 'Component';
import { getZppCertifiedSubscriptionPlan } from 'redux/query';
import { selectSubscriptionPlan, getSubscriptionPlansRequest } from 'redux/actions';
import { useHistory } from 'react-router-dom';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { colors } from 'Theme';

export const HealthInsuranceCheckoutBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [healthRequirementChecked, setHealthRequirementChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const subscriptionPlan = useSelector(getZppCertifiedSubscriptionPlan) ?? null;

  useEffect(() => {
    dispatch(getSubscriptionPlansRequest(SUBSCRIPTION_PLAN_GROUPS.NULL));
  }, []);

  const handleOnClickHealthRequirements = () => {
    setHealthRequirementChecked(!healthRequirementChecked);
  };

  const handleOnClickTerms = () => {
    setTermsChecked(!termsChecked);
  };

  const handleOnSelectedPlan = () => {
    dispatch(selectSubscriptionPlan(subscriptionPlan.id));
    history.push(ROUTES.checkout);
  };

  let disabledButton = true;
  if (healthRequirementChecked && termsChecked) {
    disabledButton = false;
  }
  return subscriptionPlan ? (
    <div id="section-checkout-box" className="plans-box-kk px-3 py-5 p-sm-5 d-flex flex-column mx-auto mt-5 mt-md-0">
      <p className="plans-btn-kk__title">{subscriptionPlan.title}</p>
      <p className="plans-btn-kk__interval">{TEXT.healthCheckoutBox.infoText}</p>
      <p className="plans-box-kk__amount mb-2 align-self-center">
        <Amount value={subscriptionPlan.chargeAmount} currency={subscriptionPlan.currency} />
      </p>

      <p className="plans-btn-kk__interval">{TEXT.healthCheckoutBox.interval}</p>

      <label className="checkbox-toggle-container mt-auto mt-md-5" htmlFor="switch1">
        <input
          className="checkbox-toggle__input"
          id="switch1"
          type="checkbox"
          checked={healthRequirementChecked}
          onChange={handleOnClickHealthRequirements}
        />
        <div className="checkbox-toggle__track">
          <div className="checkbox-toggle__button" />
        </div>
        <p className="plans-box-kk__legal mb-2">
          <HealthRequirementsHint paragraphClassName="small" />
        </p>
      </label>
      <label className="checkbox-toggle-container mt-auto" htmlFor="switch2">
        <input className="checkbox-toggle__input" id="switch2" type="checkbox" checked={termsChecked} onChange={handleOnClickTerms} />
        <div className="checkbox-toggle__track">
          <div className="checkbox-toggle__button" />
        </div>
        <p className="plans-box-kk__legal mb-4">
          {TEXT.healthCheckoutBox.approvalText_2} <TermsOfServiceLink buttonText="AGB" color={colors.green.c500} />{' '}
          {TEXT.healthCheckoutBox.approvalText_3} <PrivacyPolicyLink buttonText="Datenschutz" color={colors.green.c500} />{' '}
        </p>
      </label>
      <div className="text-center">
        <Button className="btn-w-auto mt-auto" onClick={handleOnSelectedPlan} disabled={disabledButton}>
          {TEXT.healthCheckoutBox.ctaBtn}
        </Button>
      </div>

      <div className="plans-box-kk__info-text mt-4 px-3 px-md-0">
        {TEXT.healthCheckoutBox.asteriskText}
        <br />
        Zu den <AccessibilityLink />
      </div>
    </div>
  ) : (
    <div id="section-checkout-box" className="d-flex flex-column mx-auto mt-auto text-center h-100 kk_info_box justify-content-center">
      <Spinner />
    </div>
  );
};
