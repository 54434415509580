import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Headline, Image, Paragraph, ScrollButton } from 'Common';
import { ResilienceInstructionCard } from './';
import { TEXT } from 'locales/translations';
import Style from './ResilienceInstructions.style';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';

import ballooniePoints from 'images/landingpages/resilience/balloonie-points.png';

export function ResilienceInstructions() {
  const instructionCardsContent = [
    {
      num: 1,
      headlineLight: TEXT.resiliencePage.instructions.headlineLight_1,
      headlineBold: TEXT.resiliencePage.instructions.headlineBold_1,
      cardBody: TEXT.resiliencePage.instructions.cardBody_1
    },
    {
      num: 2,
      headlineLight: TEXT.resiliencePage.instructions.headlineLight_2,
      headlineBold: TEXT.resiliencePage.instructions.headlineBold_2,
      cardBody: TEXT.resiliencePage.instructions.cardBody_2
    },
    {
      num: 3,
      headlineLight: TEXT.resiliencePage.instructions.headlineLight_3,
      headlineBold: TEXT.resiliencePage.instructions.headlineBold_3,
      cardBody: TEXT.resiliencePage.instructions.cardBody_3
    },
    {
      num: 4,
      headlineLight: TEXT.resiliencePage.instructions.headlineLight_4,
      headlineBold: TEXT.resiliencePage.instructions.headlineBold_4,
      cardBody: TEXT.resiliencePage.instructions.cardBody_4
    }
  ];

  return (
    <ThemeContainer theme={themeUniversal}>
      <Style type="full-width" backgroundColor="white">
        <Container>
          <Row>
            <Col xs={{ size: 12 }} md={{ size: 12 }} className="d-flex flex-column align-items-center">
              <Image className="image-md mx-auto" src={ballooniePoints} alt="Balloon hört eine Meditation" />
              <Headline level={2} className="headline text-center mb-md-5">
                {TEXT.resiliencePage.instructions.headline_1}
                <span className="h2_bold_kk d-block">{TEXT.resiliencePage.instructions.headline_2}</span>
              </Headline>
              <Image className="image-xs d-block d-md-none mb-5" src={ballooniePoints} alt="Balloon hört eine Meditation" />
            </Col>
          </Row>
          <Row>
            {instructionCardsContent.map((instructionCardContent) => {
              const { num, headlineLight, headlineBold, cardBody } = instructionCardContent;
              return (
                <Col xs={{ size: 12 }} md={{ size: 6 }} key={num}>
                  <ResilienceInstructionCard num={num} headlineLight={headlineLight} headlineBold={headlineBold} cardBody={cardBody} />
                </Col>
              );
            })}

            <Col xs={{ size: 12 }}>
              <Headline level={4} className="text-start text-md-center mt-4 mb-3">
                {TEXT.resiliencePage.instructions.headlineBold_5}
              </Headline>
              <Paragraph className="text-start text-md-center">{TEXT.resiliencePage.instructions.cardBody_5_a}</Paragraph>
              <Paragraph className="text-start text-md-center">{TEXT.resiliencePage.instructions.cardBody_5_b}</Paragraph>
              <div className="mt-5 text-center">
                <ScrollButton to="section-checkout">{TEXT.resiliencePage.cta}</ScrollButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Style>
    </ThemeContainer>
  );
}
