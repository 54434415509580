export const getSubscriptionPlansErrorMessage = (errors) => {
  let msg;
  if (errors && errors[0]) {
    const messages = {
      couponAlreadyApplied: 'Dieser Promotion-Code wurde bereits eingelöst.',
      couponNotValid: 'Dieser Promotion-Code ist leider nicht gültig.',
      couponNotFound: 'Diesen Promotion-Code konnten wir leider nicht finden.'
    };

    const fallback = 'Hmm, dies hätte nicht passieren sollen. Probier es bitte nochmal.';

    msg = messages[errors[0].message] || fallback;
  }
  return msg;
};
