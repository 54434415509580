import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'Common';
// ToDo: Button aus mime_elements nutzen
import { Button } from 'reactstrap';
import { SectionHeader, UserPaymentMethod } from 'Component';
import { TEXT } from 'locales/translations';
import { PaymentContainer } from 'Container';
import { getCurrentUser, getUserPaymentDetails, isUserPaymentMethodAutoAssigned } from 'redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'utils';
import { clearStripePaymentInfos, updateUserPaymentMethodRequest } from 'redux/actions';

export const UserPaymentMethodForm = () => {
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [paymentFilled, setPaymentFilled] = useState(false);
  const { renewalInfo } = useSelector(getUserPaymentDetails);
  const user = useSelector(getCurrentUser);
  const isPaymentAutoAssigned = useSelector(isUserPaymentMethodAutoAssigned);

  const handleOnChangeForm = ({ paymentFilled }) => {
    setPaymentFilled(paymentFilled);
  };

  const handleOnSubmit = () => {
    dispatch(updateUserPaymentMethodRequest());
  };

  useEffect(() => {
    if (!isPaymentAutoAssigned) {
      return;
    }
    handleOnSubmit();
  }, [isPaymentAutoAssigned]);

  const handleOnEditPayment = () => {
    dispatch(clearStripePaymentInfos());
    setEditable(true);
  };

  const billingDetails = {
    name: renewalInfo?.address?.name || '',
    email: user.email,
    address: {
      line1: renewalInfo?.address?.street || '',
      line2: '',
      city: renewalInfo?.address?.city || '',
      postal_code: renewalInfo?.address?.zipCode || '',
      country: renewalInfo?.address?.country || '',
      state: ''
    }
  };

  return (
    <div className={'m-0 p-0 mb-4 mt-4'}>
      <Row>
        <Col xs="12">
          <SectionHeader number={false}>
            {editable ? TEXT.subscriptionStatus.paymentMethodEditable : TEXT.subscriptionStatus.paymentMethodNotEditable}
          </SectionHeader>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          {isPaymentAutoAssigned ? (
            <Spinner />
          ) : !editable ? (
            <UserPaymentMethod />
          ) : (
            <PaymentContainer>
              <PaymentContainer.Form
                onChange={handleOnChangeForm}
                onSubmit={handleOnSubmit}
                billingDetails={billingDetails}
                refererUrl={ROUTES.profileSubscriptionStatus}
              >
                <Button className="w-100 submit-button" disabled={!paymentFilled} data-cy-name="submit-button-payment">
                  SPEICHERN
                </Button>
              </PaymentContainer.Form>
            </PaymentContainer>
          )}
        </Col>
      </Row>
      {!editable && (
        <Row>
          <Col className="d-flex justify-content-sm-end justify-content-start mt-sm-2" xs="12">
            <Button className={'btn-link-underlined p-0 m-0'} onClick={handleOnEditPayment} data-cy-name="user-profile-edit-payment-method">
              {TEXT.checkout.btnEdit}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};
