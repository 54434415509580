import React from 'react';
import PropTypes from 'prop-types';
import { TermsModal } from 'components';
import { Markdown } from 'Common';
import markdownContent from '!!raw-loader!static/terms.md';

TermsOfServiceLink.propTypes = {
  classNames: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.string
};

export default function TermsOfServiceLink({ className, buttonText = 'AGB', color }) {
  return (
    <TermsModal
      buttonColor="link"
      buttonClasses={className}
      modalHeaderText="Allgemeine Geschäftsbedingungen (AGB)"
      buttonText={buttonText}
      color={color}
      renderAs="link"
    >
      <Markdown className="popup" containerId="modal-body" scrollOffset={0}>
        {markdownContent}
      </Markdown>
    </TermsModal>
  );
}
