import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ListLayoutBlock extends Component {
  render() {
    return (
      <div className="list-layout-block" id={this.props.headline}>
        <span className="list-layout-block__headline">{this.props.headline}</span>
        <div className="list-layout-block__items">{this.props.children}</div>
      </div>
    );
  }
}

ListLayoutBlock.propTypes = {
  headline: PropTypes.string,
  children: PropTypes.array.isRequired
};

export default ListLayoutBlock;
