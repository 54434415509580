import React from 'react';
import ThankYouComponent from './ThankYouComponent';
import { TEXT } from 'locales/translations';
import { Button } from 'Common';
import { ROUTES } from 'utils/constants';

export const ThankYouUpgrade = () => {
  return (
    <ThankYouComponent title={TEXT.thankYouUpgrade.title} showFooter>
      <Button type="inverted" to={ROUTES.library}>
        {TEXT.thankYou.btnLibrary}
      </Button>
    </ThankYouComponent>
  );
};
