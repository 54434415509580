import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { clearFlashes } from 'redux/actions';
import { TEXT } from 'locales/translations';
import { AppStoreBadge, GooglePlayBadge } from 'components';
import { ROUTES } from 'utils/constants';
import FormHeader from 'components/Landingpages/FormHeader';
import Style from './InsuranceContainer.SuccessView.style';
import { InsuranceContainerContext } from 'components/Container/InsuranceContainer/InsuranceContainerContext';
import { useInsuranceFormType } from 'hookApi';
import { Button } from 'Common';
import { useSelector } from 'react-redux';
import { isZppUserAlreadyApplied } from 'redux/query';

export const SuccessView = ({ children }) => {
  const { b2bClientKey } = useContext(InsuranceContainerContext);
  const formType = useInsuranceFormType({ b2bClientKey });
  const zppAlreadyApplied = useSelector(isZppUserAlreadyApplied);

  useEffect(() => {
    clearFlashes();
  }, []);

  if (formType !== 'SuccessView') {
    return null;
  }

  const { successScreen: text } = TEXT.formsGeneral;

  return (
    <Style>
      {zppAlreadyApplied ? (
        <FormHeader headline={text.successHeadlineWelcomeBack} infotext_1={text.successInfotextWelcomeBack} />
      ) : (
        <FormHeader headline={text.successHeadline} infotext_1={text.successInfotext} />
      )}
      {isMobile ? (
        <div className="btn-wrapper">
          <Button type="outline" href={ROUTES.openBalloonApp} color="green">
            {text.openApp}
          </Button>
        </div>
      ) : (
        <div className="app-badge-wrapper">
          <GooglePlayBadge />
          <AppStoreBadge />
        </div>
      )}
      {children}
    </Style>
  );
};
