import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline } from 'Common';
import { LogoChain } from 'Container';
import { BrandsStyle } from './Brands.style';

import { LogoBrigitte } from 'images/trust_band/LogoBrigitte';
import { LogoGesundLeben } from 'images/trust_band/LogoGesundLeben';
import { LogoFlow } from 'images/trust_band/LogoFlow';
import { colors } from 'components/Theme';

export const Brands = () => {
  return (
    <BrandsStyle className={'text-center'} verticalPadding="small">
      <LogoChain
        headline={<Headline level={4}>{TEXT.company.brands.headline}</Headline>}
        logos={[
          <LogoBrigitte primaryColor={colors.white.c500} />,
          <LogoGesundLeben primaryColor={colors.white.c500} secondaryColor={colors.purple.c500} />,
          <LogoFlow primaryColor={colors.white.c500} />
        ]}
      />
    </BrandsStyle>
  );
};
