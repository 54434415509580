import React from 'react';
import { ScrollButton } from 'Common';
import PropTypes from 'prop-types';

const LandingpageHeaderSimple = ({ headline, subline, buttonText, buttonLinkClass, buttonLink }) => {
  return (
    <section className="header-container">
      <div className="header">
        <h1>{headline}</h1>
        <h3>{subline}</h3>
        <ScrollButton type="inverted" color="blue" className="btn-xing" to="form-section-container" offset={-50}>
          {buttonText}
        </ScrollButton>
      </div>
    </section>
  );
};

LandingpageHeaderSimple.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLinkClass: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired
};

export default LandingpageHeaderSimple;
