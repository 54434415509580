import React from 'react';
import { Button, Col, Container, Form, Row } from 'Common';
import { TextField } from 'Component';
import { TEXT } from 'locales/translations';
import { useForm } from 'react-hook-form';

export const UserBillingAddressForm = ({ onChange, onSubmit, submitButtonLabel = '' }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      street: '',
      zipCode: '',
      city: '',
      country: 'DE'
    },
    mode: 'onBlur'
  });

  const handleOnChange = (field) => () => {
    const value = getValues()[field];
    onChange && onChange({ field, value });
  };

  const handleOnSubmit = () => {
    onSubmit && onSubmit(getValues());
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Container className="px-0 mx-0 mt-3">
        <TextField
          register={register}
          name="firstName"
          label="Vorname"
          clearError={() => clearErrors('firstName')}
          placeholder="Max"
          errorMessage={errors.firstName?.message}
          required="Bitte gib deinen Vornamen ein."
          onChange={handleOnChange('firstName')}
        />
        <TextField
          register={register}
          name="lastName"
          label="Nachname"
          clearError={() => clearErrors('lastName')}
          placeholder="Mustermann"
          errorMessage={errors.lastName?.message}
          required="Bitte gib deinen Nachnamen ein."
          onChange={handleOnChange('lastName')}
        />
        <TextField
          register={register}
          name="street"
          label="Straße und Hausnummer"
          clearError={() => clearErrors('street')}
          placeholder="Musterstraße 123"
          errorMessage={errors.street?.message}
          required="Bitte gib Straße und Hausnummer ein."
          onChange={handleOnChange('street')}
        />
        <Row>
          <Col xs="12" md="4" className="pe-md-0">
            <TextField
              register={register}
              name="zipCode"
              label="Postleitzahl"
              validationPattern={{
                value: /^[0-9]{5}$/,
                message: 'Bitte gib eine gültige Postleitzahl ein.'
              }}
              clearError={() => clearErrors('zipCode')}
              placeholder="12345"
              errorMessage={errors.zipCode?.message}
              required="Die Postleitzahl fehlt."
              onChange={handleOnChange('zipCode')}
            />
          </Col>
          <Col xs="12" md="8">
            <TextField
              register={register}
              name="city"
              label="Stadt"
              clearError={() => clearErrors('city')}
              placeholder="Musterstadt"
              errorMessage={errors.city?.message}
              required="Bitte gib eine Stadt an."
              onChange={handleOnChange('city')}
            />
          </Col>
        </Row>
        <TextField
          label="Land"
          required={true}
          customField={() => <div className="form-control input disabled country">Deutschland</div>}
        />
        <p className="justify-content-end mx-0 px-0 mandatory-fields-hint">*Pflichtfelder</p>
        <Button dataCyName="submit-button-address" color="blue" className="w-100 mb-2" type="submit">
          {submitButtonLabel}
        </Button>
      </Container>
    </Form>
  );
};
