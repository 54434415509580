import styled from 'styled-components';
import { colors } from 'components/Theme';
import { asRem } from 'utils';

export default styled.div`
  color: ${colors.white.c500};
  background-color: ${colors.green.c500};
  flex: 1;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${asRem(320)};
    padding: ${asRem(48)} 0;
  }

  .mime-elements-headline-3 {
    color: ${colors.white.c500};
  }

  .calmness-img {
    width: ${asRem(250)};
  }
`;
