import styled from 'styled-components';
import { MainPage } from 'Container';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import circleDownLeft from 'images/background_circle_down_left.svg';
import circleUpRight from 'images/background_circle_up_right.svg';

export default styled(MainPage)`
  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: ${asRem(64)};

    &:before {
      content: '';
      background-image: url(${circleDownLeft});
      width: 365px;
      height: 506px;
      position: absolute;
      top: 368px;
      left: -192px;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:after {
      content: '';
      background-image: url(${circleUpRight});
      width: 186px;
      height: 246px;
      position: absolute;
      top: 200px;
      right: 0;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .mime-elements-tab-content a {
    color: inherit;
    position: relative;
    text-decoration: underline;

    &.bold {
      font-family: Poppins-SemiBold, sans-serif;
    }
  }

  .rounded-box {
    @media (max-width: ${breakpoints.sm}) {
      border-radius: 0;
      margin-bottom: 0;
    }
  }

  .mime-elements-button {
    width: 100%;
    margin-bottom: ${asRem(32)};
  }

  .cta-google button,
  .cta-facebook button {
    width: 100%;
    text-align: center;
    justify-content: center;
    height: 60px;
    margin-bottom: ${asRem(16)};
  }

  .voucher-hint {
    background-color: ${colors.blue.c100};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${asRem(10)};
    height: ${asRem(62)};
    margin-bottom: ${asRem(29)};
  }
`;
