import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isFetchingCategories, isFetchingCategory } from 'redux/reducers';
import { getToken } from 'redux/query';
import { getCategoriesTitle, getCategoryBySlug } from 'redux/actions';

function withCategory(WrappedComponent) {
  class withCategory extends Component {
    componentDidMount() {
      const { token, getCategoryBySlug, getCategoriesTitle, match } = this.props;
      if (token) {
        getCategoryBySlug(match.params.slug);
        getCategoriesTitle();
      }
    }

    componentDidUpdate(prevProps) {
      const { getCategoryBySlug, token, match } = this.props;
      if (token && prevProps.match.params.slug !== match.params.slug) {
        getCategoryBySlug(match.params.slug);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  withCategory.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    token: PropTypes.string,
    getCategoryBySlug: PropTypes.func.isRequired,
    getCategoriesTitle: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  return withCategory;
}

const mapStateToProps = (state) => ({
  isFetching: isFetchingCategories(state) || isFetchingCategory(state),
  token: getToken(state)
});

export default compose(
  connect(mapStateToProps, {
    getCategoryBySlug,
    getCategoriesTitle
  }),
  withCategory
);
