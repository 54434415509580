import React from 'react';
import PropTypes from 'prop-types';
import { Control, Errors } from 'react-redux-form';
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import { labelText } from 'utils/labelHelper';

Textfield.propTypes = {
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  id: PropTypes.string,
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  messages: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredHint: PropTypes.bool,
  hasFormError: PropTypes.bool,
  type: PropTypes.string,
  validators: PropTypes.object,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  cyName: PropTypes.string
};

Textfield.defaultProps = {
  disabled: false,
  handleChange: () => null,
  showRequiredHint: true
};

/**
 * @deprecated use `Component/TextField` instead without react-redux-form
 */
export default function Textfield({
  autocomplete,
  id,
  defaultValue,
  disabled,
  label,
  messages,
  model,
  placeholder,
  required,
  type,
  validators,
  showRequiredHint,
  hasFormError,
  handleChange,
  className,
  cyName
}) {
  const textProps = {
    component: Input,
    id,
    autoComplete: autocomplete,
    type,
    defaultValue,
    disabled,
    placeholder,
    model,
    validateOn: ['blur', 'change'],
    required,
    validators,
    className
  };

  if (cyName) {
    textProps['data-cy-name'] = cyName;
  }

  return (
    <FormGroup>
      {label && (
        <Label className="label--small" for={id}>
          {showRequiredHint ? labelText(label, required) : label}
        </Label>
      )}
      <Control.text
        {...textProps}
        mapProps={{
          valid: ({ fieldValue: { touched, valid }, id }) => {
            if ((touched && !valid) || hasFormError) {
              const element = document.getElementById(id);
              element.classList.add('is-invalid');
              return false;
            } else {
              const element = document.getElementById(id);
              if (element && element.classList.contains('is-invalid')) {
                return true;
              }
              return undefined;
            }
          }
        }}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <FormFeedback>
        <Errors model={model} show={(field) => !field.pristine || field.touched} messages={messages} />
      </FormFeedback>
    </FormGroup>
  );
}
