import React from 'react';
import { PrimaryLink } from 'components';
import { ROUTES } from 'utils/constants';
import { TEXT } from 'locales/translations';

const NoSubscription = () => (
  <div>
    <div className="pb-3">{TEXT.subscriptionStatus.noSubscriptionTeaser}</div>
    <div className="pb-3">{TEXT.subscriptionStatus.noSubscriptionCta}</div>
    <div className="pb-5">
      <PrimaryLink className="w-100 btn-secondary" to={ROUTES.plans}>
        {TEXT.subscriptionStatus.subscribeButtonText}
      </PrimaryLink>
    </div>
  </div>
);

export default NoSubscription;
