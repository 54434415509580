import { MainPage } from 'Container';
import styled from 'styled-components';
import { colors } from 'Theme';
import { asRem } from 'utils';

export default styled(MainPage).attrs({
  className: 'checkout mt-0 mt-md-5',
  bodyClassName: 'main-decorated mt-2 mt-md-6 px-0',
  containerClassName: 'px-0'
})`
  .email-section {
    input[name='email'] {
      background-color: ${colors.white.c500} !important;
    }
  }

  .mime-elements-button {
    justify-content: center;
  }

  .btn-link {
    justify-content: start;
    margin-left: ${asRem(36)};
  }
`;
