import { requestApi } from 'utils';

export const withdrawCancelationSubcription = (subscriptionId) =>
  requestApi.mutate(
    `
  mutation WithdrawCancelation($subscriptionId: String!){
    withdrawCancelation(
      subscriptionId: $subscriptionId
    )
    {
      payableInfos {
        active
        canceled
        provider
      }
    }
  }
`,
    {
      subscriptionId
    }
  );
