import axios from 'axios';
import { platform } from 'config';

const debugStyle = 'padding: 4px 20px 4px 8px; color: #bada55;';

export const graphqlApiUrl = '/api/v2/graphql';
export const requestApi = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json'
  }
});

requestApi.showLogs = !platform.isProduction;

requestApi.setAuthToken = (token) => {
  requestApi.defaults.headers.common['X-Authorization'] = `Bearer ${token}`;
  if (requestApi.showLogs) {
    console.group('%c REQUEST_API.setAuthToken', debugStyle);
    console.groupCollapsed('%c >> headers.common', debugStyle);
    console.log(`%c ${JSON.stringify(requestApi.defaults.headers.common, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupEnd();
  }
};

requestApi.resetAuthToken = () => {
  delete requestApi.defaults.headers.common['X-Authorization'];
  if (requestApi.showLogs) {
    console.group('%c REQUEST_API.resetAuthToken', debugStyle);
    console.groupCollapsed('%c >> headers.common', debugStyle);
    console.log(`%c ${JSON.stringify(requestApi.defaults.headers.common, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupEnd();
  }
};

requestApi.query = async (query, variables) => {
  const response = await requestApi.post(graphqlApiUrl, { query, variables });
  if (requestApi.showLogs) {
    console.group('%c REQUEST_API.query', debugStyle);
    console.groupCollapsed(`%c >> ${`${query.match(new RegExp('(.*){', 'm'))[0]}`.replace('{', '').trim()}`, debugStyle);
    console.log(`%c ${query}`, debugStyle);
    variables && console.log(`%c ${JSON.stringify(variables, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupCollapsed('%c >> response.data', debugStyle);
    console.log(`%c ${JSON.stringify(response.data, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupEnd();
  }
  return response;
};

requestApi.mutate = async (mutation, variables) => {
  const response = await requestApi.post(graphqlApiUrl, { query: mutation, variables });
  if (requestApi.showLogs) {
    console.group('%c REQUEST_API.mutate', debugStyle);
    console.groupCollapsed(`%c >> ${`${mutation.match(new RegExp('(.*){', 'm'))[0]}`.replace('{', '').trim()}`, debugStyle);
    console.log(`%c ${mutation}`, debugStyle);
    variables && console.log(`%c ${JSON.stringify(variables, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupCollapsed('%c >> response.data', debugStyle);
    console.log(`%c ${JSON.stringify(response?.data, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupEnd();
  }
  return response;
};

requestApi.request = async (config) => {
  const response = await axios({
    url: graphqlApiUrl,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    ...config
  });

  if (requestApi.showLogs) {
    console.group('%c REQUEST_API.request', debugStyle);
    console.groupCollapsed('%c >> config', debugStyle);
    console.log(`%c ${JSON.stringify(config, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupCollapsed('%c >> response.data', debugStyle);
    console.log(`%c ${JSON.stringify(response?.data, null, 2)}`, debugStyle);
    console.groupEnd();
    console.groupEnd();
  }
  return response;
};
