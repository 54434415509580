import React, { Component } from 'react';
import { Container } from 'reactstrap';
import TrustRow from './TrustRow';
import { TEXT } from 'locales/translations';

class TrustBandSection extends Component {
  render() {
    return (
      <section className="trust-band-section">
        <Container>
          <h3>{TEXT.trustBandSection.heading}</h3>
          <TrustRow />
        </Container>
      </section>
    );
  }
}

export default TrustBandSection;
