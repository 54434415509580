import React, { useState } from 'react';
import { Form } from 'react-redux-form';
import { Button, Link, Paragraph } from 'Common';
import { EmailTextField, NameTextField } from 'components';
import { revokeEmailAddressChange, updateUserEmail, updateUserName } from 'redux/actions';
import { TEXT } from 'locales/translations';
import { ROUTES } from 'utils';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const PersonalDataContent = ({ email: currentEmail, firstname, hasUnconfirmedEmail, unconfirmedEmail }) => {
  const [disabledButton, setDisabledButton] = useState(true);

  const dispatch = useDispatch();

  const changePersonalDataValues = ({ firstname, email }) => {
    const filtername = firstname.replace(
      /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    );

    dispatch(updateUserName(filtername.trim()));
    if (email?.normalize() !== currentEmail?.normalize()) {
      dispatch(updateUserEmail(email));
    }
    setDisabledButton(true);
  };

  const handleChange = () => {
    setDisabledButton(false);
  };

  const revokeEmailAddress = () => {
    dispatch(revokeEmailAddressChange());
  };

  const renderUnconfirmedEmailContent = () => {
    if (hasUnconfirmedEmail) {
      return (
        <>
          <EmailTextField
            label={TEXT.profile.newMailAddressLabel}
            showLabel={true}
            disabled={true}
            defaultValue={unconfirmedEmail}
            showRequiredHint={false}
            fieldId={'user.unconfirmedEmail'}
            model={'.unconfirmedEmail'}
          />
          <Paragraph className="link-unconfirm-mail">
            {TEXT.profile.unConfirmedMail}{' '}
            <Link className="deeplink-unconfirm-mail" to={ROUTES.deeplinkConfirmMail.replace('/:code?', '')}>
              {TEXT.profile.unConfirmedMailLink}
            </Link>
          </Paragraph>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Form model="entities.forms.personalData" onSubmit={changePersonalDataValues}>
        <NameTextField
          label={TEXT.profile.nameLabel}
          showLabel={true}
          defaultValue={firstname}
          showRequiredHint={false}
          handleChange={handleChange}
        />
        <EmailTextField
          label={hasUnconfirmedEmail ? TEXT.profile.currentMailAddressLabel : TEXT.profile.emailAddressLabel}
          showLabel={true}
          disabled={false}
          defaultValue={currentEmail}
          showRequiredHint={false}
          handleChange={handleChange}
          fieldId={'user.email'}
          model={'.email'}
        />
        {renderUnconfirmedEmailContent()}
        <Button className="w-100 personal-data-change-btn" disabled={disabledButton}>
          Speichern
        </Button>
      </Form>
      {hasUnconfirmedEmail && (
        <Paragraph className="p-0 mt-5 small-bold text-center">
          <Link className="text-uppercase" color="green" onClick={revokeEmailAddress}>
            Neue E-Mail löschen
          </Link>
        </Paragraph>
      )}
    </>
  );
};
export default PersonalDataContent;

PersonalDataContent.propTypes = {
  firstname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  unconfirmedEmail: PropTypes.string.isRequired,
  hasUnconfirmedEmail: PropTypes.bool.isRequired
};
