export const SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST = 'SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST';
export const SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_SUCCESS = 'SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_SUCCESS';
export const SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_FAILURE = 'SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_FAILURE';

export const sendInsuranceCooperationOAuthTokenRequest = ({ token, tokenExpirationDate, insuranceCooperationCompany }) => ({
  type: SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST,
  payload: {
    token,
    tokenExpirationDate,
    insuranceCooperationCompany
  }
});

export const sendInsuranceCooperationOAuthTokenSuccess = (payload) => ({
  type: SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_SUCCESS,
  payload
});

export const sendInsuranceCooperationOAuthTokenFailure = (error) => ({
  type: SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_FAILURE,
  error
});
