import React from 'react';
import Review from './Review';

const ReviewSection = () => {
  return (
    <section id="review-section">
      <h2>Stimmen zum Podcast</h2>
      <h3>DAS SAGEN DIE HÖRER</h3>
      <div id="item-container">
        <Review
          name="Chris"
          meta=", iTunes, 31.01.2020"
          headline="Der Podcast ist echt Klasse!"
          copy="Ich bekomme bei jeder Folge so viele tolle Impulse! Ich freue mich für jeden, der diesen Podcast für sich entdeckt hat bzw. entdecken kann."
        />
        <Review
          name="Nasira"
          meta=", iTunes, 13.01.2020"
          headline="Für mich liefern diese Folgen großartige Hintergrundinfos"
          copy="zur Meditations-App. Auch für diejenigen super, die einfach wissen wollen was Meditation überhaupt ist. Ich höre die Folgen gern beim spazieren gehen. Boris und Sinja sind äußerst sympathisch. Freue mich schon auf die neuen Themen."
        />
        <Review
          name="Jakobius"
          meta=", iTunes, 12.09.2019"
          headline="Ich bin Wissenschaftler"
          copy="und hatte anfangs gedacht, dass Meditation etwas esoterisches oder spirituelles ist. Boris hat mir einen ganz anderen Zugang dazu eröffnet, weil er wissenschaftlich fundiert erklärt, was aus psychologischer Sicht im Kopf passiert, und wo Meditation helfen kann. Es ist das erste Mal, dass ich einen Podcast bewerte. Ich kann nur jedem wünschen, diesen Podcast zu entdecken. Einfach super interessant."
        />
      </div>
      <div id="balloonie" />
    </section>
  );
};
export default ReviewSection;
