import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  category: []
};

export default function categories(state = initialState, action) {
  switch (action.type) {
    case types.GET_CATEGORY_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_CATEGORY_SUCCESS: {
      let categories = [];
      if (Object.keys(action.payload.entities).length > 0) {
        categories = Object.values(action.payload.entities.categories);
      }
      return {
        ...state,
        category: categories,
        isFetching: false,
        error: undefined
      };
    }

    case types.GET_CATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state.isFetching;

export const getCurrentCategory = (state) => state && state.category;
