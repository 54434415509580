import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearLoginUserFailureError, clearRegisterUserFailureError } from 'redux/actions/users';
import { authenticateWithGISRequest } from 'redux/actions';
import { isGoogleMailError } from 'redux/query';
import googleIcon from 'images/google_login/google_icon.svg';
import { TEXT } from 'locales/translations';

import loadGoogleAccountSdk from 'utils/socialMediaLoginsLoader';
import Bugsnag from '@bugsnag/js';

const GoogleLoginButton = (props) => {
  const [isGoogleReady, setisGoogleReady] = useState(false);

  useEffect(() => {
    if (!isGoogleReady && !window?.google) {
      loadGoogleAccountSdk(handleCredentialResponse).then(
        (value) => {
          setisGoogleReady(value);
        },
        (error) => {
          Bugsnag.notify(error);
          setisGoogleReady(false);
        }
      );
    }
  });

  const handleCredentialResponse = (response) => {
    props.authenticateWithGISRequest(response.credential);
  };

  const googleLogin = () => {
    if (!isGoogleReady && !window?.google) {
      return;
    }
    // eslint-disable-next-line no-undef
    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment() || notification.isDismissedMoment()) {
        document.cookie = 'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
      if (notification.isNotDisplayed() && !notification.isSkippedMoment() && !notification.isDismissedMoment()) {
        document.getElementById('google-sign-in-button').remove();

        window.google.accounts.id.renderButton(
          document.getElementById('buttonDiv'),
          {
            theme: 'outline',
            size: 'large',
            logo_alignment: 'center',
            width: document.getElementsByClassName('btn-google')[0].clientWidth
          } // customization attributes
        );
        setTimeout(function () {
          document.getElementById('buttonDivInfo').style.display = 'block';

          document.getElementById('alternativeLogin').style.display = 'block';
        }, 300);
      }
    });
  };

  const { textButton } = props;

  return (
    <div className="cta-google">
      <div id="alternativeLogin" className="alternative-login">
        <div id="buttonDiv"></div>
        <p className="button-div-info pt-1" id="buttonDivInfo">
          {TEXT.googleLogin.info}
        </p>
      </div>
      <button
        id="google-sign-in-button"
        style={isGoogleReady ? { transition: 'opacity 0.5s ease 0s' } : { opacity: '0.6' }}
        disabled={!isGoogleReady}
        className="btn-google"
        onClick={() => googleLogin()}
      >
        <img src={googleIcon} alt="google icon" />
        <span>{textButton}</span>
      </button>
    </div>
  );
};

GoogleLoginButton.propTypes = {
  authenticateWithGISRequest: PropTypes.func.isRequired,
  textButton: PropTypes.string,
  isLogin: PropTypes.bool,
  googleLoginError: PropTypes.bool,
  googleMailError: PropTypes.bool,
  emailHasAlreadyBeenTaken: PropTypes.bool,
  clearLoginUserFailureError: PropTypes.func.isRequired,
  clearRegisterUserFailureError: PropTypes.func.isRequired,
  voucherCode: PropTypes.string
};

GoogleLoginButton.defaultProps = {
  textButton: 'Google',
  isLogin: false,
  googleLoginError: false,
  googleMailError: false,
  emailHasAlreadyBeenTaken: false,
  voucherCode: ''
};

const mapStateToProps = (state) => ({
  googleMailError: isGoogleMailError(state)
});

export default connect(mapStateToProps, {
  authenticateWithGISRequest,
  clearLoginUserFailureError,
  clearRegisterUserFailureError
})(GoogleLoginButton);
