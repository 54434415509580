import { dateStringToDate } from './dateStringToDate';

export const toIsoDate = (dateString) => {
  const date = dateStringToDate(dateString);
  if (date.toString() === 'Invalid Date') {
    console.error(`Date string ${dateString} is not in the format dd.mm.yyyy`);
    return new Date().toISOString();
  }
  return date.toISOString();
};
