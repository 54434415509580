export const CREATE_B2B_CONTACT_REQUEST = 'CREATE_B2B_CONTACT_REQUEST';
export const CREATE_B2B_CONTACT_SUCCESS = 'CREATE_B2B_CONTACT_SUCCESS';
export const CREATE_B2B_CONTACT_FAILURE = 'CREATE_B2B_CONTACT_FAILURE';
export const RESET_B2B_CONTACT = 'RESET_B2B_CONTACT';

export const createB2bContactRequest = (payload) => ({
  type: CREATE_B2B_CONTACT_REQUEST,
  payload
});

export const createB2bContactSuccess = (response) => ({
  type: CREATE_B2B_CONTACT_SUCCESS,
  response
});

export const createB2bContactFailure = (error) => ({
  type: CREATE_B2B_CONTACT_FAILURE,
  error
});

export const resetB2bContact = () => ({
  type: RESET_B2B_CONTACT
});
