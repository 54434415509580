import React from 'react';
import starImageMask from 'images/landingpages/health_insurance_marketing/starMask.png';

export const RatingStars = ({ rating }) => {
  const calcPercentage = (rating) => {
    const percentage = [];
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        percentage.push(100);
      } else {
        let value = (rating - (i - 1)) * 100;
        percentage.push((value = value < 0 ? 0 : value));
      }
    }
    return percentage;
  };

  const numbers = calcPercentage(rating);

  return (
    <div className="rating-stars__wrapper">
      {numbers.map((number, index) => {
        return (
          <div key={index}>
            <div className="rating-stars__percentage-container">
              <img src={starImageMask} className="rating-stars__mask-item" alt="star rating" />
              <div className="rating-stars__percentage-bar" style={{ width: `${number}%` }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
