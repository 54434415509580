import { GET_INSURANCE_COMPANIES_REQUEST, GET_INSURANCE_COMPANIES_SUCCESS, GET_INSURANCE_COMPANIES_FAILURE } from 'redux/actions';

const initialState = {
  insuranceCompanies: []
};

export default function insuranceCompanies(state = initialState, action) {
  switch (action.type) {
    case GET_INSURANCE_COMPANIES_REQUEST:
      return {
        ...state
      };

    case GET_INSURANCE_COMPANIES_SUCCESS:
      return {
        ...state,
        insuranceCompanies: action.payload
      };

    case GET_INSURANCE_COMPANIES_FAILURE:
      return {
        ...state,
        error: true
      };

    default:
      return state;
  }
}
