import React from 'react';
import { TEXT } from 'locales/translations';
import { Headline, Row, Col } from 'Common';
import { TextBlockImg } from 'Component';
import { CollaborationBenefitStyle } from './CollaborationBenefit.style';

import { illuBasis, illuCoCoach, illuExtrameile } from 'images/landingpages/b2b_landingpage';
import { Paragraph } from '@missionme/mime_elements';

export default () => {
  return (
    <CollaborationBenefitStyle className="text-center" backgroundColor="white">
      <Headline level={2}>{TEXT.healthAdvisor.collaborationBenefit.headline}</Headline>
      <Row className="text-start text-md-center">
        <Col xs="12" md="4">
          <TextBlockImg image={illuCoCoach} smRow>
            <div className="text">
              <Headline level={5} className="mb-0">
                {TEXT.healthAdvisor.collaborationBenefit.coach}
              </Headline>
              <Paragraph>{TEXT.healthAdvisor.collaborationBenefit.support}</Paragraph>
            </div>
          </TextBlockImg>
        </Col>
        <Col xs="12" md="4">
          <TextBlockImg image={illuBasis} smRow>
            <div className="text">
              <Headline level={5} className="mb-0">
                {TEXT.healthAdvisor.collaborationBenefit.base}
              </Headline>
              <Paragraph>{TEXT.healthAdvisor.collaborationBenefit.science}</Paragraph>
            </div>
          </TextBlockImg>
        </Col>
        <Col xs="12" md="4">
          <TextBlockImg image={illuExtrameile} smRow>
            <div className="text">
              <Headline level={5} className="mb-0">
                {TEXT.healthAdvisor.collaborationBenefit.mile}
              </Headline>
              <Paragraph>{TEXT.healthAdvisor.collaborationBenefit.assistance}</Paragraph>
            </div>
          </TextBlockImg>
        </Col>
      </Row>
    </CollaborationBenefitStyle>
  );
};
