import { getSubscriptionPlans } from '../getSubscriptionPlans/getSubscriptionPlans';

export const getFilteredSubscriptionPlans =
  (coupon = null) =>
  (state) => {
    const subscriptionPlans = getSubscriptionPlans(state);
    const plans = {
      badged: [],
      standard: []
    };
    subscriptionPlans.forEach((plan) => {
      if (!!coupon) {
        if (!plan.zppCertified && !!plan.badgeTitle) {
          return plans.badged.push(plan);
        }
        if (!plan.zppCertified && !plan.badgeTitle) {
          return plans.standard.push(plan);
        }
        return;
      }
      if (plan.zppCertified) {
        return plans.badged.push(plan);
      }
      return plans.standard.push(plan);
    });
    return plans;
  };
