import React from 'react';
import { TermsModal } from 'components/index';
import { colors } from 'components/Theme';
import markdownContent from '!!raw-loader!static/accessibility.md';
import { Markdown } from 'Common';

export function AccessibilityLink({ className, renderAs = 'button' }) {
  return (
    <TermsModal
      buttonColor="link"
      buttonClasses={className}
      modalHeaderText="Angaben zur Barrierefreiheit"
      buttonText="Angaben zur Barrierefreiheit"
      renderAs="link"
      color={colors.green.c500}
    >
      <Markdown className="popup" containerId="modal-body" scrollOffset={0}>{markdownContent}</Markdown>
    </TermsModal>
  );
}
