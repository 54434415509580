import { logoutUser } from 'redux/actions';
import { getUserFirstname } from 'redux/query';
import React from 'react';
import { LogoutLink, ProfileLink } from 'components/Navigation/Links';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

export const AccountMenu = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const userName = useSelector(getUserFirstname);

  const onLogout = () => {
    closeMenu();
    dispatch(logoutUser());
  };

  const handleOnPressDropdown = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  return (
    <UncontrolledDropdown nav inNavbar onClick={handleOnPressDropdown}>
      <DropdownToggle nav caret className="no-padding" data-cy-name="user-dropdown">
        {userName}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        <ProfileLink onClick={closeMenu} />
        <DropdownItem divider />
        <LogoutLink onLogout={onLogout} />
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
