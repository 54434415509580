import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { MainPage } from 'Container';
import { connect } from 'react-redux';
import { AppStoreBadge, GooglePlayBadge } from 'components';

class FallbackAppPage extends Component {
  render() {
    return (
      <MainPage bodyClassName="main-decorated" className="sign-in-user-with-otp" title="Öffne die Balloon App">
        <Row>
          <Col xs={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
            <Container>
              <Row>
                <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 11, offset: 1 }}>
                  <h1 className="plans__heading">{'Öffne die Balloon App'}</h1>
                </Col>
                <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} xl={{ size: 9, offset: 2 }}>
                  <div className="card mt-5 mb-5 pb-5">
                    <div className="card-body">
                      <Row>
                        <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                          <div className="pb-4 text-center newsletter-disclaimer">
                            <Row>
                              <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} className="mb-4 mt-2">
                                Entschuldige, das war ein kleiner Umweg. <br />
                                Öffne einfach die Balloon App auf deinem Handy!
                              </Col>
                              <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} className="mb-1">
                                <a
                                  className="btn btn-green-c500 font-weight-bold mb-4 d-sm-inline-block w-65"
                                  href={`balloon:/${window.location.pathname}`}
                                >
                                  Hier geht’s zur App
                                </a>
                              </Col>
                              <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }}>
                                Der Button funktioniert nur, wenn die App auf diesem Gerät installiert ist. <br />
                                Hier kannst du sie herunterladen:
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12 }} className="app-badge">
                          <GooglePlayBadge />
                          <AppStoreBadge />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </MainPage>
    );
  }
}

export default withRouter(connect(null, {})(FallbackAppPage));
