import { requestApi } from 'utils';

export const getStoreInfosApiRequest = () =>
  requestApi.query(`
  query GetStoreInfosApiRequest {
    storeInfos{
      appstore{
        id
        reviewCount
        rating
      }
      id
      playstore{
        id
        reviewCount
        rating
      }
    }
  }
`);
