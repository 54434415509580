import { store, rehydrateStore } from './configureStore';
import { applicationBootstrapped, logoutUser } from 'redux/actions';
import { getToken } from 'redux/query';
import { checkUserTokenApiRequest } from 'api/balloon';
import { requestApi } from 'utils';

async function hydrateStore() {
  await rehydrateStore(store);
  const state = store.getState();

  const token = getToken(state);

  if (token) {
    try {
      await checkUserTokenApiRequest({ token });
    } catch (error) {
      // logout the user to create a new guest user token before bootstrapping the app
      if (error.response.status === 401) {
        store.dispatch(logoutUser());
      }
    }
  }

  token && requestApi.setAuthToken(token);
  store.dispatch(applicationBootstrapped());
  return true;
}

export default () =>
  new Promise(async (resolve) => {
    await hydrateStore();
    resolve(true);
  });

console.warning = () => {};
