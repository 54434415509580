import * as types from 'redux/actions/actionTypes';
import axiosError from './axiosError';

const initialState = {
  isFetching: false,
  categories: []
};

export default function categories(state = initialState, action) {
  switch (action.type) {
    case types.GET_CATEGORIES_PENDING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GET_CATEGORIES_SUCCESS: {
      let categories = [];
      if (Object.keys(action.payload.entities).length > 0) {
        categories = Object.values(action.payload.entities.categories);
      }
      return {
        ...state,
        categories: categories,
        isFetching: false,
        error: undefined
      };
    }

    case types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    default:
      return state;
  }
}

export const isFetching = (state) => state.isFetching;

export const getAllCategories = (state) => state && state.categories;

export const getCategoryById = (state, id) => state && state.categories.find((x) => x.id === id);
