import Style from './Markdown.style';
import ReactMarkdown from 'react-markdown';
import slug from 'rehype-slug-custom-id';
import { Link, ScrollLink } from 'Common';
import React from 'react';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';

/**
 * If you want a single break in your md file, use two spaces at the end of the line.
 */
export const Markdown = ({ children, className, id, containerId, scrollOffset = -85 }) => {
  return (
    <Style className={className} id={id}>
      <ThemeContainer theme={themeUniversal}>
        <ReactMarkdown
          rehypePlugins={[[slug, { removeAccents: true }]]}
          components={{
            a: ({ ...props }) => {
              if (props.href.startsWith('#')) {
                const hrefWithoutHash = props.href.slice(1);
                return (
                  <ScrollLink offset={scrollOffset} containerId={containerId} to={hrefWithoutHash}>
                    {props.children}
                  </ScrollLink>
                );
              }
              return <Link href={props.href}>{props.children}</Link>;
            }
          }}
        >
          {children}
        </ReactMarkdown>
      </ThemeContainer>
    </Style>
  );
};
