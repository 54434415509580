import Home from './Home';
export { default as Stage } from './Stage';
export { default as AdvantageItem } from './AdvantageItem';
export { default as AdvantagesSection } from './AdvantagesSection';
export { default as AppCarousel } from './AppCarousel';
export { default as BenefitInfoSection } from './BenefitInfoSection';
export { default as BorisSection } from './BorisSection';
export { default as CtaInfoSection } from './CtaInfoSection';
export { default as MobileShowcaseSection } from './MobileShowcaseSection';
export { default as PodcastSection } from './PodcastSection';
export { default as TextBlock } from './TextBlock';
export { default as TrustBandSection } from './TrustBandSection';
export { default as TrustRow } from './TrustRow';
export { default as VideoModal } from './VideoModal';
export { default as VideoSection } from './VideoSection';
export { default as HealthLandingPageSection } from './HealthLandingPageSection';

export default Home;
