import * as React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { TEXT } from 'locales/translations';
import giftImg from 'images/voucher_manual/gift@2x.png';
import macbookImg from 'images/voucher_manual/mac-book@2x.png';
import phoneImg from 'images/voucher_manual/phone@2x.png';
import { ROUTES } from 'utils/constants';
import { Link } from 'react-router-dom';

const { headline, description, step_1, step_2, step_3, step_4, step_4_green, subheadline, btn_text } = TEXT.voucher_manual;

class VoucherManual extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Container className="voucher-manual" fluid={true}>
          <Row className="flex-wrap-reverse flex-md-wrap-reverse flex-lg-wrap">
            <Col xs={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} xl={{ size: 3, offset: 0 }} className="balloony-img">
              <img className="balloony-gift" src={giftImg} alt="gift" />
            </Col>
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 9, offset: 0 }} className="headcontent">
              <div className="headline">
                <h1 className="plans__heading">{headline}</h1>
                <p className="plans__subheading">{description}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 12, offset: 0 }} className="steps-content">
              <Row>
                <Col xs={{ size: 10, offset: 0 }} md={{ size: 10, offset: 0 }} xl={{ size: 2, offset: 0 }} className="step1 step-box">
                  <p>{step_1}</p>
                </Col>
                <Col xs={{ size: 10, offset: 0 }} md={{ size: 10, offset: 0 }} xl={{ size: 2, offset: 0 }} className="step2 step-box">
                  <p>{step_2}</p>
                </Col>
                <Col xs={{ size: 10, offset: 0 }} md={{ size: 10, offset: 0 }} xl={{ size: 2, offset: 0 }} className="step3 step-box">
                  <p>{step_3}</p>
                </Col>
                <Col xs={{ size: 10, offset: 0 }} md={{ size: 10, offset: 0 }} xl={{ size: 2, offset: 0 }} className="step4 step-box">
                  <p>{step_4}</p>
                  <p className="notice">{step_4_green}</p>
                </Col>
                <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} xl={{ size: 12, offset: 0 }} className="btn-content">
                  <p>{subheadline}</p>
                  <Link to={ROUTES.voucher} className="btn btn-pink-c500">
                    {btn_text}
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col xs={{ size: 12, offset: 0 }} md={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }} className="mac-content">
              <img className="d-none d-lg-block" src={macbookImg} alt="mac" />
              <img className="d-lg-none" src={phoneImg} alt="mac" />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default VoucherManual;
