import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import { handleAxiosError } from 'utils';
import * as api from 'api/balloon/categories';
import { GET_CATEGORIES_PENDING, GET_CATEGORIES_FAILURE, GET_CATEGORIES_SUCCESS } from 'redux/actions/actionTypes';
import { logoutUser } from 'redux/actions/users';

export const gettingCategories = () => ({
  type: GET_CATEGORIES_PENDING
});

export const getCategoriesFailure = (error) => ({
  type: GET_CATEGORIES_FAILURE,
  error
});

export const getCategoriesSuccess = (data) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: data
});

export const getCategories = () => (dispatch) => {
  dispatch(gettingCategories());
  return api.getCategories().then(
    (response) => {
      dispatch(getCategoriesSuccess(normalize(response.data.data.categories, [schema.category])));
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(getCategoriesFailure(error));
        dispatch(logoutUser());
      });
    }
  );
};

export const getCategoriesTitle = () => (dispatch) => {
  dispatch(gettingCategories());
  return api.getCategoriesTitle().then(
    (response) => {
      dispatch(getCategoriesSuccess(normalize(response.data.data.categories, [schema.category])));
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(getCategoriesFailure(error));
      });
    }
  );
};
