import React, { Fragment } from 'react';
import { Amount } from 'Common';

export default function NegativeAmount(props) {
  return (
    <Fragment>
      {'−'}
      <Amount {...props} />
    </Fragment>
  );
}
