import React, { useContext, useEffect, useState } from 'react';
import { useInsuranceFormType } from 'hookApi';
import { InsuranceContainerContext } from 'components/Container/InsuranceContainer/InsuranceContainerContext';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, isLoginError, isOtpLoadingByHealthInsurance } from 'redux/query';
import {
  attendInsuranceCooperationUserDataRequest,
  clearFlashes,
  requestOnetimePassword,
  sendOtpWithInsuranceNumberRequest,
  signInWithOtpAndInsuranceNumberRequest
} from 'redux/actions';
import Style from './InsuranceContainer.OtpView.style';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';
import { FlashListContainer } from 'containers';
import { TEXT } from 'locales/translations';
import { Button, CodeInput, Link, Paragraph, Spinner, Span, Form } from 'Common';
import FormHeader from 'components/Landingpages/FormHeader';

export const OtpView = () => {
  const { b2bClientKey } = useContext(InsuranceContainerContext);
  const isLoading = useSelector(isOtpLoadingByHealthInsurance);
  const formType = useInsuranceFormType({ b2bClientKey });

  const [token, setToken] = useState('');

  const user = useSelector(getCurrentUser);
  const email = user.email;
  const loginError = useSelector(isLoginError);

  const dispatch = useDispatch();
  const isLoginWithInsuranceNumber = new RegExp('\\*{8}').test(email);

  useEffect(() => {
    return () => {
      dispatch(clearFlashes);
    };
  }, [dispatch]);

  const handleOnSubmit = (evt) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    return dispatch(
      attendInsuranceCooperationUserDataRequest({
        onetimePassword: token,
        attendUserData: {
          insuranceNumber: user.insuranceNumber,
          b2bClientKey
        }
      })
    );
  };

  const handleOnSendNewToken = () => {
    if (isLoading) {
      return;
    }
    if (isLoginWithInsuranceNumber) {
      return dispatch(sendOtpWithInsuranceNumberRequest({ user, sendDeepLinkOTP: false }));
    }
    dispatch(requestOnetimePassword(email, false));
  };

  if (formType !== 'OtpView') {
    return null;
  }

  const { registerUserWithOtp: text } = TEXT.formsGeneral;

  return (
    <Style>
      <ThemeContainer theme={themeUniversal}>
        <FlashListContainer />
        <div className="register-user-with-otp" id="sign-in-user-with-otp">
          <FormHeader
            headline={text.unknownUserHeadline}
            infotext_1={text.unknownUserInfotest_1}
            infotext_2={text.unknownUserInfotest_2}
            variableText={email}
            isText
          />
        </div>
        <Form className="form" onSubmit={handleOnSubmit} id="user-with-otp__form">
          <CodeInput value={token} onChange={setToken} error={loginError} />
          {loginError && <Span className="extra-small fb-error">{text.invalidCode}</Span>}
          <Button className="mt-4" disabled={token.length < 6} dataCyName="otp-submit-button" id="sign-in-user-otp-btn">
            {isLoading ? <Spinner /> : text.submitCode}
          </Button>
        </Form>
        <div className="resend-code-wrapper">
          <Paragraph className="small-bold text-center mt-4">
            <Link className="text-uppercase" color="green" onClick={handleOnSendNewToken} dataCyName="otp-resend-code-button">
              {text.resendCode}
            </Link>
          </Paragraph>
        </div>
      </ThemeContainer>
    </Style>
  );
};
