import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
import { RoundedBox } from 'Component';

export default styled(RoundedBox)`
  border-radius: ${asRem(15)};
  border: ${asRem(2)} solid ${colors.green.c500};
  color: ${colors.blueGrey.c500};
  
  justify-content: space-between;
  white-space: inherit; // allow wrapping (override Bootstrp Button style
  height: 100%;
  width: 100%;


  @media (min-width: ${breakpoints.md}) {
    min-height: ${asRem(336)};
  }
  @media (min-width: ${breakpoints.xs}) {
    padding: 0;
    margin: 0 ${asRem(80)};
  }

  &:hover {
    @media (min-width: ${breakpoints.md}) {
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .info-text {
    color: ${colors.grey.c600};
  }
`;
