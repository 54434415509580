import React from 'react';
import PropTypes from 'prop-types';

export const LogoBrigitte = ({ primaryColor }) => (
  <svg enableBackground="new 0 0 150 60" viewBox="0 0 150 60" xmlns="http://www.w3.org/2000/svg">
    <g clipRule="evenodd" fill={primaryColor} fillRule="evenodd" transform="translate(23 21.5)">
      <path d="m35.9 7.8h-1.9v-.3h7.9v16h1.9v.3h-9.8v-.3h1.9z" />
      <path d="m35.7 3.6c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1-1.4 3-3.1 3c-1.7.1-3.1-1.3-3.1-3" />
      <path d="m87.4 7.8h-2.2v13.9c0 .5.2 1.5.9 1.5s.9-.9.9-1.5v-3.7h.3v3.6c0 1.8-2.1 3.3-4.2 3-3.3-.6-4.2-3.1-4.2-4.8v-12h-3.3v13.9c0 .5.2 1.5.9 1.5s.9-.9.9-1.5v-3.7h.3v3.6c0 1.8-2.1 3.3-4.2 3-3.3-.6-4.2-3.1-4.2-4.8v-12h-1.7v-.3h1.7l6.4-5.5v5.4h3.3l6.2-5.4v5.4h2.2z" />
      <path d="m60.4 7.8h-1.9v-.3h7.9v16h2v.3h-8.8v-.3h.8z" />
      <path d="m60.1 3.6c0-1.7 1.5-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1s-1.4 3-3.1 3c-1.6.1-3.1-1.3-3.1-3" />
      <path d="m.2 1.8v-.3h10.2c2.5 0 8.9 1 9 5.5s-6 5.1-6 5.1 7.6 1.1 7.6 6.4c0 4.4-5.2 5.6-6.8 5.6h-14.2v-.3h2.5v-22zm8.8 0h1.4c1.1 0 3 .6 3.1 5.2 0 4.8-1.9 4.9-2.8 4.9h-1.7zm0 10.3h1.9c1.3 0 3.2 1.1 3.3 6.1.1 4.2-1.7 5.6-2.9 5.6h-2.3z" />
      <path d="m28.6 10.4v13.1h1.9v.3h-9.8v-.3h1.9v-15.7h-1.9v-.3h7.9v1.7c.2-1 1.6-2 3.3-2 1.8 0 3 1.5 2.9 3.1-.1 1.5-1.3 2.8-2.8 2.8-1.6 0-2.9-1.3-2.9-2.8 0-.6.4-1.8 1.7-2.5-.1.1-2.2.3-2.2 2.6" />
      <path d="m94.1 11.1v4.1h3.3v-4.1c0-1.6-.1-3.8-1.7-3.8s-1.6 2.2-1.6 3.8zm8.4 6.8c-.1 2.8-2.2 5.7-4.6 5.7-1.7-.1-3.8-.9-3.8-4.4v-3.7h8.9v-1.9c0-3-2.3-6.5-7-6.5s-8.4 3.9-8.5 8.7 3.5 8.3 8.5 8.4c4.9.1 6.8-3.8 6.8-6.4h-.3z" />
      <path d="m44.2 21.4c-.4-.6-.8-1.4-.8-2.4-.1-2.8 3.2-4 3.2-4s-2.6-1.6-2.6-3.5c0-2.4 2.3-4.2 4.9-4.6 1.5-.2 2.9-.1 2.9-.1-1.1-2.6.8-4.3 3-4.3 1.5 0 2.3 1.2 2.3 2.5 0 1.2-1.1 2.3-2.3 2.3-1.3 0-2.4-1.2-2.4-2.4 0-1.1.4-1.2.4-1.2-.9.1-1.6 1.5-.7 3.3 0 0 5.2.9 5.2 4.6 0 3.5-4.2 4.4-6.5 4.5-1.8 0-3.8-.9-3.8-.9s-1.4.1-1.2 1.2c.2 1.8 3 1.1 5.2 1.1 2.3.1 3.9-.2 6.4 1.3.7.4 1.5 1.4 1.8 2.6.2.6.3 1.2.3 1.9-.1 4.8-3.9 6.9-8.5 7.2-3.6.1-7.7-1.2-7.8-4.2 0-2.8 3.7-3.3 3.7-3.2-.1 0-1.7-.4-2.7-1.7zm7.8 1.8c1.7-.1 4.7.9 4.6 3.2-.1 2.5-2.2 3.5-5.9 3.7-2.8.2-5.2-.9-5.2-3.8-.1-2.5 1.6-3 1.6-3s3.4.1 4.9-.1zm-2.7-9.5c0 1 .5 2 1.5 2 .9 0 1.5-1 1.5-2v-4.2c0-1.1-.3-2.5-1.5-2.5s-1.5 1.4-1.5 2.5z" />
    </g>
  </svg>
);

LogoBrigitte.propTypes = {
  primaryColor: PropTypes.string
};
