import React from 'react';
import LandingpageBallooni from './../../LandingpageBallooni';
import { TEXT } from 'locales/translations';
import LandingpageTopBlueLine from './../../LandingpageTopBlueLine';
import LandingpageBottomBlueLine from './../../LandingpageBottomBlueLine';

const BallooniSection = () => {
  return (
    <section id="ballooni-section-container">
      <LandingpageTopBlueLine severalClass="ballooni-top" />
      <h2>{TEXT.bkk.ballooni_section.headline}</h2>
      <div id="bottom-container">
        <LandingpageBallooni imageClass="ballooni3-image" description={TEXT.bkk.ballooni_section.ballooni_1} />
        <LandingpageBallooni imageClass="ballooni1-image" description={TEXT.bkk.ballooni_section.ballooni_2} />
        <LandingpageBallooni imageClass="ballooni2-image" description={TEXT.bkk.ballooni_section.ballooni_3} />
      </div>
      <LandingpageBottomBlueLine severalClass="ballooni-bottom" />
    </section>
  );
};

export default BallooniSection;
