import React from 'react';
import PropTypes from 'prop-types';
import ImgMobileRed from 'images/home/phoneMockupRed@2x.png';
import DecorativeImage from 'components/DecorativeImage';
import { TEXT } from 'locales/translations';

MobileScreenExercise.propTypes = {
  className: PropTypes.string
};

export default function MobileScreenExercise({ className }) {
  return <DecorativeImage alt={TEXT.mobileShowcase.altScreenExercise} src={ImgMobileRed} className={className} />;
}
