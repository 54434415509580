import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button, Link } from 'Common';

class TermsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { buttonColor, buttonClasses, linkClasses, color, buttonText, children, modalHeaderText, renderAs = 'button' } = this.props;

    const renderTypes = {
      button: {
        type: Button,
        props: {
          color: buttonColor,
          className: buttonClasses,
          onClick: this.toggle
        }
      },
      link: {
        type: Link,
        props: { onClick: this.toggle, className: linkClasses, color: color }
      }
    };

    const triggerElement = React.createElement(renderTypes[renderAs].type, renderTypes[renderAs].props, buttonText);

    return (
      <Fragment>
        {triggerElement}
        <Modal className="terms-modal" isOpen={this.state.modal} toggle={this.toggle}>
          {modalHeaderText ? <ModalHeader toggle={this.toggle}>{modalHeaderText}</ModalHeader> : null}
          <ModalBody id="modal-body">{children}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

TermsModal.propTypes = {
  buttonColor: PropTypes.string,
  buttonClasses: PropTypes.string,
  linkClasses: PropTypes.string,
  color: PropTypes.string,
  modalHeaderText: PropTypes.string,
  buttonText: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  renderAs: PropTypes.string
};

export default TermsModal;
