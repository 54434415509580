import { normalize } from 'normalizr';
import * as schema from 'redux/actions/schema';
import * as api from 'api/balloon/users';
import { handleAxiosError } from 'utils';
import {
  UPDATE_USER_PENDING,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_MAIL_SUCCESS,
  UPDATE_USER_MAIL_FAILURE,
  REVOKE_CONFIRM_MAIL_CODE_PENDING,
  REVOKE_CONFIRM_MAIL_CODE_FAILURE,
  REVOKE_CONFIRM_MAIL_CODE_SUCCESS
} from 'redux/actions/actionTypes';
import { addFlash, clearFlashes } from 'redux/actions';
import { getUpdateUserEmailErrorMessage } from 'redux/query';

export const updateUserPending = () => ({
  type: UPDATE_USER_PENDING
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  error
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload
});

export const updateUserMailSuccess = (payload) => ({
  type: UPDATE_USER_MAIL_SUCCESS,
  payload
});

export const updateUserMailFailure = (payload) => ({
  type: UPDATE_USER_MAIL_FAILURE,
  payload
});

export const revokeConfirmMailCodePending = () => ({
  type: REVOKE_CONFIRM_MAIL_CODE_PENDING
});

export const revokeConfirmMailCodeFailure = (error) => ({
  type: REVOKE_CONFIRM_MAIL_CODE_FAILURE,
  error
});

export const revokeConfirmMailCodeSuccess = (payload) => ({
  type: REVOKE_CONFIRM_MAIL_CODE_SUCCESS,
  payload
});

export const updateUserName = (name) => (dispatch) => {
  dispatch(updateUserPending());
  return api.updateUserName(name).then(
    (response) => {
      dispatch(
        updateUserSuccess(
          normalize(response.data, {
            user: schema.user
          })
        )
      );
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(updateUserFailure(error.response.data.errors));
      });
    }
  );
};

export const updateUserEmail = (email) => (dispatch) => {
  dispatch(updateUserPending());
  dispatch(clearFlashes());
  return api.updateUserEmail({ email, origin: 'WEBAPP' }).then(
    (response) => {
      if (response.data.errors) {
        dispatch(addFlash(getUpdateUserEmailErrorMessage(response.data.errors[0]), 'danger'));

        dispatch(updateUserMailFailure(response.data.errors));
      } else {
        dispatch(
          updateUserMailSuccess(
            normalize(response.data, {
              user: schema.user
            })
          )
        );
      }
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(updateUserMailFailure(error.response.data.errors));
      });
    }
  );
};

export const revokeEmailAddressChange = () => (dispatch) => {
  dispatch(updateUserPending());
  return api.revokeEmailAddressChange().then(
    (response) => {
      dispatch(
        revokeConfirmMailCodeSuccess(
          normalize(response.data, {
            user: schema.user
          })
        )
      );
    },
    (error) => {
      handleAxiosError(error, () => {
        dispatch(revokeConfirmMailCodeFailure(error.response.data.errors));
      });
    }
  );
};
