import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints } from 'components/Theme';

export default styled.div`
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .logo-chain {
    max-width: ${asRem(150)};

    @media (min-width: ${breakpoints.md}) {
      max-width: ${asRem(400)};
    }
    @media (min-width: ${breakpoints.lg}) {
      max-width: ${asRem(630)};
    }
  }
`;
