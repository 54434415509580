export const getUnlockCodeErrorMessage = (state) => {
  const { error } = state.entities.redeemUnlockCode;

  const messages = {
    codeExpired: 'Dieser Code ist leider nicht gültig.',
    codeNotFound: 'Leider können wir diesen Code nicht finden.',
    codeAlreadyApplied: 'Du hast diesen Code bereits freigeschaltet.'
  };

  const fallback = `Hmm, dies hätte nicht passieren sollen. Probier es bitte nochmal (${error.status}, ${error.statusText})`;

  return messages[error.data] || fallback;
};
