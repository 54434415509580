import { put, all, select } from 'redux-saga/effects';
import { authenticateInsuranceCooperationUserSuccess, authenticateInsuranceCooperationUserFailure, registeringUser } from 'redux/actions';
import { actions } from 'react-redux-form';
import { signInUserWithOnetimePasswordApiRequest, signInUserWithOtpAndInsuranceNumberApiRequest } from 'api/balloon';
import { getCurrentUser } from 'redux/query';

export function* authenticateInsuranceCooperationUserSaga({ payload }) {
  const { token, attendUserData } = payload;
  yield token;
  yield attendUserData;

  const currentUser = yield select(getCurrentUser);
  const email = currentUser.email;

  try {
    yield all([
      yield put(registeringUser()),
      yield put(actions.setPending('entities.forms.login', true)),
      yield put(actions.setPending('entities.forms.user', true))
    ]);

    if (new RegExp('\\*{8}').test(email)) {
      const { data: signInWithInsuranceNumberResponse } = yield signInUserWithOtpAndInsuranceNumberApiRequest({
        insuranceNumber: currentUser.insuranceNumber,
        token
      });

      if (signInWithInsuranceNumberResponse.errors) {
        yield put(authenticateInsuranceCooperationUserFailure(signInWithInsuranceNumberResponse.errors));
        return;
      }
      return yield put(
        authenticateInsuranceCooperationUserSuccess({
          token: signInWithInsuranceNumberResponse.data.signInUserWithOnetimePasswordAndInsuranceNumber.token,
          user: signInWithInsuranceNumberResponse.data.signInUserWithOnetimePasswordAndInsuranceNumber.user
        })
      );
    }
    const { data: signInUserResponse } = yield signInUserWithOnetimePasswordApiRequest({
      email: currentUser.email,
      token
    });

    if (signInUserResponse.errors) {
      yield put(authenticateInsuranceCooperationUserFailure(signInUserResponse.errors));
      return;
    }
    return yield put(
      authenticateInsuranceCooperationUserSuccess({
        token: signInUserResponse.data.signInUserWithOnetimePassword.token,
        user: signInUserResponse.data.signInUserWithOnetimePassword.user
      })
    );
  } catch (error) {
    yield put(authenticateInsuranceCooperationUserFailure(error));
  }
}
