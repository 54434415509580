import { defineMessages } from 'react-intl';

const defaultLocale = 'de';

const defaultMessages = defineMessages({
  alertsEndingSubscriptionTitle: {
    id: 'alerts.ending_subscription.title',
    defaultMessage: 'Dein Balloon Abo'
  },
  alertsEndingSubscriptionWebUrlTitle: {
    id: 'alerts.ending_subscription.web_url_title',
    defaultMessage: 'Abo verlängern'
  },
  alertsEndingSubscriptionMessage: {
    id: 'alerts.ending_subscription.message',
    defaultMessage:
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.'
  },
  alertsEndingSubscriptionWebMessage: {
    id: 'alerts.ending_subscription.web_message',
    defaultMessage:
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.'
  },
  alertsImpossiblePaymentTitle: {
    id: 'alerts.impossible_payment.title',
    defaultMessage: 'Zahlung nicht möglich'
  },
  alertsImpossiblePaymentWebUrlTitle: {
    id: 'alerts.impossible_payment.web_url_title',
    defaultMessage: 'Zahlungsdaten aktualisieren'
  },
  alertsImpossiblePaymentMessage: {
    id: 'alerts.impossible_payment.message',
    defaultMessage:
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen bei {provider} und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.'
  },
  alertsImpossiblePaymentWebMessage: {
    id: 'alerts.impossible_payment.web_message',
    defaultMessage:
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.'
  },
  alertsCustomAlertTitle: {
    id: 'alerts.custom_alert.title',
    defaultMessage: 'Hinweis vom Kundenservice'
  },
  alertsCustomAlertMessage: {
    id: 'alerts.custom_alert.message',
    defaultMessage: ''
  },
  alertsInvalidEmailTitle: {
    id: 'alerts.invalid_email.title',
    defaultMessage: 'Fehlerhafte E-Mail-Adresse'
  },
  alertsInvalidEmailWebUrlTitle: {
    id: 'alerts.invalid_email.web_url_title',
    defaultMessage: 'Fehlerhafte E-Mail-Adresse'
  },
  alertsInvalidEmailMessage: {
    id: 'alerts.invalid_email.message',
    defaultMessage:
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.'
  },
  alertsInvalidEmailWebMessage: {
    id: 'alerts.invalid_email.web_message',
    defaultMessage:
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.'
  },
  dateFormatsDefault: {
    id: 'date.formats.default',
    defaultMessage: '%d.%m.%Y'
  },
  timeFormatsDefault: {
    id: 'time.formats.default',
    defaultMessage: '%d.%m.%Y um %H:%Mh'
  },
  navigationWhyBalloon: {
    id: 'navigation.why_balloon',
    defaultMessage: 'Deine Vorteile'
  },
  navigationHeads: { id: 'navigation.heads', defaultMessage: 'Die Köpfe' },
  homeTeaserAppTitle: {
    id: 'home.teaser.app_title',
    defaultMessage: 'Balloon'
  },
  homeTeaserAppSubtitle: {
    id: 'home.teaser.app_subtitle',
    defaultMessage: 'Einfach meditieren'
  },
  homeTeaserAppDescription: {
    id: 'home.teaser.app_description',
    defaultMessage:
      'Balloon ist deine App für wissenschaftlich fundierte Meditation. Tauche mit nur 12 Minuten täglich in die Welt des Meditierens ein.'
  },
  homeWhyTitle: { id: 'home.why.title', defaultMessage: 'Deine Vorteile' },
  homeWhyFirstHeadline: {
    id: 'home.why.first_headline',
    defaultMessage: 'Wirkungsvoll'
  },
  homeWhyFirstText: {
    id: 'home.why.first_text',
    defaultMessage: 'Mit nur 12 Minuten Meditation am Tag mehr Klarheit im Leben'
  },
  homeWhySecondHeadline: {
    id: 'home.why.second_headline',
    defaultMessage: 'Fundierte Kurse'
  },
  homeWhySecondText: {
    id: 'home.why.second_text',
    defaultMessage: 'Konzipiert und gesprochen von den besten Achtsamkeits-Experten'
  },
  homeWhyThirdHeadline: {
    id: 'home.why.third_headline',
    defaultMessage: 'Für alle Fälle'
  },
  homeWhyThirdText: {
    id: 'home.why.third_text',
    defaultMessage: 'Von A wie Ausgeglichen arbeiten bis Z wie Zufrieden einschlafen'
  },
  homeHeadsTitle: { id: 'home.heads.title', defaultMessage: 'Die Köpfe' },
  homeHeadsFirstName: {
    id: 'home.heads.first.name',
    defaultMessage: 'Boris Bornemann'
  },
  homeHeadsFirstBio: {
    id: 'home.heads.first.bio',
    defaultMessage:
      'Boris beschäftigt sich seit seinem Psychologie-Studium intensiv mit Meditation. Nach dem Studium hat er das ReSource-Projekt, die weltweit größte wissenschaftliche Studie zu Meditation, mitkonzipiert. Er hat über Meditation promoviert und interessiert sich jetzt vor allem dafür, wie sie sich möglichst einfach und effektiv vermitteln lässt.'
  },
  homeHeadsSecondName: {
    id: 'home.heads.second.name',
    defaultMessage: 'Britta Hölzel'
  },
  homeHeadsSecondBio: {
    id: 'home.heads.second.bio',
    defaultMessage:
      'Britta leitet das I AM - Institut für Achtsamkeit und Meditation. Mit Yoga und Meditation kam sie in Kontakt, als sie nach dem Abitur durch Indien reiste. Britta hat Psychologie studiert, über die neuronalen Mechanismen der Achtsamkeitsmeditation promoviert und an der Harvard Medical School geforscht.'
  },
  homeHeadsThirdName: {
    id: 'home.heads.third.name',
    defaultMessage: 'Claudia Braun'
  },
  homeHeadsThirdBio: {
    id: 'home.heads.third.bio',
    defaultMessage:
      'Claudia arbeitet bei der Unternehmensberatung Return on Meaning, die u.a. auf Achtsamkeit in Organisationen spezialisiert ist. Sie hat verschiedene mehrjährige Achtsamkeitsausbildungen und ist Co-Autorin des Buchs „Kulturwandel in Unternehmen“. Als studierte internt. BWLerin mit MPA war sie zuvor mehrere Jahre bei McKinsey.'
  },
  authenticationsGoogle: {
    id: 'authentications.google',
    defaultMessage: 'Google'
  },
  authenticationsApple: {
    id: 'authentications.apple',
    defaultMessage: 'Apple'
  },
  errorsMessagesAlreadyConfirmed: {
    id: 'errors.messages.already_confirmed',
    defaultMessage: 'wurde bereits bestätigt, bitte versuchen Sie, sich anzumelden'
  },
  errorsMessagesConfirmationPeriodExpired: {
    id: 'errors.messages.confirmation_period_expired',
    defaultMessage: 'musste innerhalb von {period} bestätigt werden, bitte neu anfordern.'
  },
  errorsMessagesExpired: {
    id: 'errors.messages.expired',
    defaultMessage: 'ist abgelaufen, bitte neu anfordern'
  },
  errorsMessagesNotFound: {
    id: 'errors.messages.not_found',
    defaultMessage: 'nicht gefunden'
  },
  errorsMessagesNotLocked: {
    id: 'errors.messages.not_locked',
    defaultMessage: 'ist nicht gesperrt'
  },
  errorsMessagesNotSavedOne: {
    id: 'errors.messages.not_saved.one',
    defaultMessage: '{resource} konnte aufgrund eines Fehlers nicht gespeichert werden:'
  },
  errorsMessagesNotSavedOther: {
    id: 'errors.messages.not_saved.other',
    defaultMessage: '{count} Fehler verhinderten das Speichern von {resource}:'
  },
  frontpageTestimonialsHeading: {
    id: 'frontpage.testimonials.heading',
    defaultMessage: 'Balloon ist bekannt aus:'
  },

  frontpageCtaBody: {
    id: 'frontpage.cta.body',
    defaultMessage:
      "Hol' dir Balloon im Abo. Du hast sofort Zugriff auf unsere komplette Meditations-Bibliothek mit Kursen und Einzel-Übungen zu Themen wie weniger Stress, besserer Schlaf und mehr Gelassenheit. Lass Balloon dein Leben leichter machen."
  },
  metaDescription: {
    id: 'meta.description',
    defaultMessage:
      "Du willst besser schlafen? Weniger gestresst sein? Meditieren mit Balloon macht dein Leben leichter. Probier's aus: Meditation per App."
  },
  metaKeywords: {
    id: 'meta.keywords',
    defaultMessage: 'Balloon,Meditation,einfach,Leben,Leichtigkeit,Achtsamkeit'
  },
  metaTitle: {
    id: 'meta.title',
    defaultMessage: 'Meditation per App | Balloon'
  }
});

export { defaultMessages, defaultLocale };
